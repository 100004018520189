package digital.steva.formumat.fontawesome

import androidx.compose.foundation.text.BasicText
import androidx.compose.material3.LocalContentColor
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import dev.icerock.moko.resources.compose.fontFamilyResource
import digital.steva.formumat.public.MR

/**
 * FaIcon composable to draw Font awesome icons
 * @param faIcon use the code point from FaIcons object which is unique for
 * each icon to draw for the corresponding font.
 * @param modifier to apply layout changes.
 * @param size Provide the Icon size in DP. Since it's a font to not change Icon size with
 * font scaling of device it ignores device font scaling.
 * @param tint By default it uses the onSurface color of the theme if not provided
 * You can provide any color you want to set tint for
 */

@Composable
fun FaIcon(
    faIcon: FaIconType,
    modifier: Modifier = Modifier,
    size: Dp = 24.dp,
    tint: Color = LocalContentColor.current
) {
    val scaleIndependentFontSize =  scaleIndependentFontSize(size, LocalDensity.current.fontScale)

    val faTextStyle =
        TextStyle(
            color = tint,
            fontFamily = getFontFamily(faIcon),
            fontSize = scaleIndependentFontSize
        )

    BasicText(
        text = faIcon.src.codePointToString(),
        modifier = modifier,
        style = faTextStyle,
    )
}

@Composable
private fun getFontFamily(faIconType: FaIconType): FontFamily {
    return when (faIconType) {
        is FaIconType.BrandIcon -> fontFamilyResource(MR.fonts.fa_brands_400)
        is FaIconType.SolidIcon -> fontFamilyResource(MR.fonts.fa_solid_900)
        is FaIconType.RegularIcon -> fontFamilyResource(MR.fonts.fa_regular_400)
    }
}

/** @fun scaleIndependentFontSize: Since FA icons are font that requires Scale pixel
 * to render and will resize on device font settings. We want to keep icon size
 * constant to provided DP value so we calculate scale factor and cancel it if Any
 *
 * @materialIconOffset: FA icons at same dp taking more space then the Material Icon
 * Since we will be using both icons side by side this value offset Fa Icons to stay in line
 * with Material icons sizes.
 */

private fun scaleIndependentFontSize(sizeInDp: Dp, scaleFactor: Float): TextUnit {
    val materialIconOffset = 3.dp
    return ((sizeInDp - materialIconOffset).value / scaleFactor).sp
}

private fun Int.codePointToString() = this.toChar().toString()
