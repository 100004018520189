package digital.steva.dot.app.middlewares

import co.touchlab.kermit.Logger
import digital.steva.dot.app.AppState
import digital.steva.dot.app.InitializeFromStorage
import digital.steva.dot.app.LoadDocumentFromStorage
import digital.steva.dot.app.LoadDocumentTypeFromStorage
import digital.steva.dot.app.SetCurrentDocument
import digital.steva.dot.app.SetCurrentDocumentType
import digital.steva.dot.app.SetDocumentTypesInfos
import digital.steva.dot.app.SetDocumentsInfos
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.dispatch
import digital.steva.dot.app.domain.Storage
import digital.steva.formumat.redux.SetDataSchema
import digital.steva.formumat.redux.SetUiSchema
import digital.steva.formumat.redux.SetValues
import digital.steva.formumat.schema.parseDataSchema
import digital.steva.formumat.schema.parseUiSchema
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.reduxkotlin.middleware

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
object MainMiddleware {
    operator fun invoke(
    ) = middleware<AppState> { store, next, action ->
        when (action) {
            is ShowToast -> {
                if (action.message != null) {
                    CoroutineScope(Dispatchers.Default).launch {
                        delay(2000)
                        dispatch(ShowToast(null))
                    }
                }
                next(action)
            }

            is InitializeFromStorage -> {
                CoroutineScope(Dispatchers.Default).launch {
                    dispatch(SetDocumentTypesInfos(Storage.getDocumentTypesInfos(this)))
                    dispatch(SetDocumentsInfos(Storage.getDocumentsInfos(this)))
                }
            }

            is LoadDocumentTypeFromStorage -> {
                CoroutineScope(Dispatchers.Default).launch {
                    Storage.getDocumentType(this, action.id)?.also { documentType ->
                        dispatch(SetCurrentDocumentType(documentType))
                        try {
                            dispatch(SetDataSchema(parseDataSchema(documentType.entity?.schema ?: "")))
                        } catch (e: Throwable) {
                            Logger.e { "Unable to parse data schema: ${e.message}" }
                        }
                        try {
                            dispatch(SetUiSchema(parseUiSchema(documentType.form?.schema ?: "")))
                        } catch (e: Throwable) {
                            Logger.e { "Unable to parse ui schema: ${e.message}" }
                        }
                    }
                }
            }

            is LoadDocumentFromStorage -> {
                CoroutineScope(Dispatchers.Default).launch {
                    Storage.getDocument(this, action.id)?.also { document ->
                        dispatch(SetCurrentDocument(document))
                        try {
                            dispatch(SetValues(document.data))
                        } catch (e: Throwable) {
                            Logger.e { "Unable to parse values: ${e.message}" }
                        }
                    }
                }
            }

            else -> next(action)
        }
    }
}
