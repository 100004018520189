package digital.steva.dot.app.domain

import androidx.compose.ui.text.intl.Locale
import digital.steva.formumat.redux.parseValues
import kotlinx.collections.immutable.PersistentList
import kotlinx.collections.immutable.PersistentMap
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

typealias I18nString = Map<String, String?>

fun I18nString.getCurrentLocale() = this[Locale.current.language]

@Serializable
data class FormumatFile(
    val key: String
)

@Serializable
data class DocumentEntity(
    val id: String,
    val schema: String? = null,
    val files: List<FormumatFile> = emptyList()
)

@Serializable
data class DocumentForm(
    val id: String,
    val schema: String? = null,
    val files: List<FormumatFile> = emptyList()
)

@Serializable
data class DocumentTypeInfo(
    val id: String,
    val displayName: String? = null,
    val displayNameI18n: I18nString = emptyMap(),
    val description: String? = null,
    val descriptionI18n: I18nString = emptyMap(),
    val icon: String? = null,
)

fun List<DocumentTypeInfo>.findById(id: String?) = find { it.id == id }

@Serializable
data class DocumentType(
    val id: String,
    val name: String? = null,
    val displayName: String? = null,
    val displayNameI18n: I18nString = emptyMap(),
    val description: String? = null,
    val descriptionI18n: I18nString = emptyMap(),
    val createdAt: String? = null,
    val updatedAt: String? = null,
    val icon: String? = null,
    val entity: DocumentEntity? = null,
    val form: DocumentForm? = null
)

fun List<DocumentType>.findById(id: String?) = find { it.id == id }

@Serializable
data class DocumentDatum(
    val id: String,
    @SerialName("data")
    val dataString: String? = null,
    val files: List<FormumatFile> = emptyList()
)

enum class DocumentState {
    @SerialName("open")
    OPEN,

    @SerialName("done")
    DONE
}

@Serializable
data class DocumentInfo(
    val id: String,
    val documentTypeId: String? = null,
    val number: String? = null,
    val state: DocumentState = DocumentState.OPEN,
    val isNew: Boolean = false,
    val isModified: Boolean = false,
) {
    val done: Boolean
        get() = state == DocumentState.DONE
}

fun PersistentList<DocumentInfo>.replace(id: String?, newDocumentInfo: DocumentInfo) = set(indexOfFirst { it.id == id }, newDocumentInfo)

@Serializable
data class Document(
    val id: String,
    val documentTypeId: String? = null,
    val number: String? = null,
    val state: DocumentState = DocumentState.OPEN,
    val createdAt: String? = null,
    val updatedAt: String? = null,
    val datum: DocumentDatum? = null,
    val isNew: Boolean = false,
    val isModified: Boolean = false,
    val isDownloaded: Boolean = false
) {
    val done: Boolean
        get() = state == DocumentState.DONE

    val data: PersistentMap<String, Any> by lazy { parseValues(datum?.dataString ?: "{}") }

    fun toDocumentInfo() = DocumentInfo(
        id = id,
        documentTypeId = documentTypeId,
        number = number,
        state = state,
        isNew = isNew,
        isModified = isModified
    )
}
