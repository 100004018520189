// https://fonts.google.com/icons

package digital.steva.formumat.ui

import androidx.compose.foundation.layout.size
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIconType
import digital.steva.formumat.fontawesome.FaIcons

@Composable
fun IconByName(
    name: String,
    color: Color = Color.Companion.Unspecified,
    size: Dp = 18.dp,
    modifier: Modifier = Modifier
) {
    val icon: FaIconType? = remember(name) { FaIcons.byName(name) }
    if (icon != null) {
        FaIcon(icon, tint = color, size = size, modifier = modifier.size(size))
    }
}
