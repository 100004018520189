package digital.steva.formumat

import io.github.irgaly.kottage.Kottage
import io.github.irgaly.kottage.KottageEnvironment
import io.github.irgaly.kottage.KottageStorage
import io.github.irgaly.kottage.platform.KottageContext
import kotlinx.coroutines.CoroutineScope

object FormumatPublicLibrary {
    val VERSION = "2024.07.18-D1022"

    var kottageContext: KottageContext? = null
    var kottageDir: String = "kottage"

    private lateinit var environment: KottageEnvironment

    fun storage(scope: CoroutineScope): KottageStorage {
        val environment = KottageEnvironment(kottageContext ?: KottageContext())
        val kottage = Kottage("formumat", kottageDir, environment, scope) {
        }
        return kottage.storage("formumat")
    }

    init {
//        CoroutineScope(Dispatchers.Default).launch {
//        }
    }
}
