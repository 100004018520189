package digital.steva.dot.app.views

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.clickable
import androidx.compose.foundation.combinedClickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.dot.app.AddDocument
import digital.steva.dot.app.ClearFormumat
import digital.steva.dot.app.LoadDocumentFromStorage
import digital.steva.dot.app.LoadDocumentTypeFromStorage
import digital.steva.dot.app.MR
import digital.steva.dot.app.Page
import digital.steva.dot.app.SetCurrentDocument
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.domain.Document
import digital.steva.dot.app.domain.DocumentDatum
import digital.steva.dot.app.domain.DocumentInfo
import digital.steva.dot.app.domain.DocumentTypeInfo
import digital.steva.dot.app.domain.findById
import digital.steva.dot.app.domain.getCurrentLocale
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIconType
import digital.steva.formumat.fontawesome.FaIcons
import digital.steva.dot.app.isPresent
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetCurrentPage
import digital.steva.formumat.ui.IconByName
import kotlinx.collections.immutable.PersistentList
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.uuid.UUID

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PageDocuments(
    documentsInfos: List<DocumentInfo>,
    documentTypesInfos: List<DocumentTypeInfo>,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    LazyColumn(
        modifier = Modifier.padding(paddingValues = paddingValues)
    ) {
        items(documentsInfos.sortedBy { it.number }) { documentInfo ->
            val documentTypeInfo = documentTypesInfos.findById(documentInfo.documentTypeId)
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .combinedClickable(
                        onClick = {
                            dispatch(LoadDocumentTypeFromStorage(documentInfo.documentTypeId!!))
                            dispatch(LoadDocumentFromStorage(documentInfo.id))
                            dispatch(ShowPage(Page.PAGE_DOCUMENT))
                        },
                        onLongClick = {
                        }
                    ),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Box(
                    modifier = Modifier.padding(start = 20.dp)
                ) {
                    BadgedDocumentIcon(documentInfo, documentTypeInfo)
                }
                Column(
                    modifier = Modifier
                        .weight(1.0f)
                        .padding(start = 12.dp, top = 24.dp, bottom = 24.dp),
                ) {
                    Text(
                        text = documentInfo.number ?: "",
                        fontSize = 22.sp,
                        fontWeight = FontWeight.W700,
                        modifier = Modifier.padding(bottom = 6.dp)
                    )
                    Text(
                        text = documentTypeInfo?.displayNameI18n?.getCurrentLocale() ?: documentTypeInfo?.displayName ?: "",
                        fontSize = 20.sp,
                        fontWeight = FontWeight.W500,
                        modifier = Modifier.padding(bottom = 6.dp)
                    )
                    Row(
                        horizontalArrangement = Arrangement.spacedBy(8.dp)
                    ) {
                        if (documentInfo.isNew) {
                            Text(
                                text = stringResource(MR.strings.document_state_new),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                        if (documentInfo.isModified) {
                            Text(
                                text = stringResource(MR.strings.document_state_modified),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                        if (documentInfo.done) {
                            Text(
                                text = stringResource(MR.strings.document_state_done),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                        if (!documentInfo.isNew && !documentInfo.isModified && !documentInfo.done) {
                            Text(
                                text = stringResource(MR.strings.document_state_current),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                    }
                }
            }
            HorizontalDivider(
                color = MaterialTheme.colorScheme.inverseOnSurface,
                thickness = 2.dp
            )
        }
    }
}

@Composable
fun BadgedDocumentIcon(
    documentInfo: DocumentInfo,
    documentTypeInfo: DocumentTypeInfo?
) {
    Box(
        contentAlignment = Alignment.TopEnd
    ) {
        IconByName(
            name = if (documentTypeInfo?.icon.isPresent()) documentTypeInfo?.icon!! else "clipboard-list",
            size = 64.dp,
            color = MaterialTheme.colorScheme.secondary
        )
        when {
            documentInfo.isNew ->
                BadgeIcon(FaIcons.Star, color = Color(0xFFCCCC33))

            documentInfo.isModified ->
                BadgeIcon(FaIcons.Pen, color = Color(0xFFCC4444))

            documentInfo.done ->
                BadgeIcon(FaIcons.Check, color = Color(0xFF55AA55))

            else -> {
            }
        }
    }
}

@Composable
fun BadgeIcon(faIcon: FaIconType, color: Color, size: Dp = 22.dp) {
    Box(contentAlignment = Alignment.Center) {
        FaIcon(
            faIcon = faIcon,
            size = size * 0.6f,
            tint = Color.White,
            modifier = Modifier
                .padding(2.dp)
                .drawBehind {
                    drawCircle(
                        color = Color.White,
                        radius = size.toPx() / 2,
                        style = Stroke(width = 2.dp.toPx())
                    )
                    drawCircle(
                        color = color,
                        radius = size.toPx() / 2
                    )
                }
        )
    }
}

@Composable
fun PageNewDocument(
    documentTypesInfos: PersistentList<DocumentTypeInfo>,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    LazyColumn(
        modifier = Modifier.padding(paddingValues = paddingValues)
    ) {
        items(documentTypesInfos) { documentTypeInfo ->
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .clickable {
                        dispatch(ClearFormumat())
                        dispatch(LoadDocumentTypeFromStorage(documentTypeInfo.id))
                        val now = Clock.System.now().toLocalDateTime(TimeZone.UTC)
                        val document = Document(
                            id = UUID().toString(),
                            documentTypeId = documentTypeInfo.id,
                            datum = DocumentDatum(
                                id = UUID().toString(),
                            ),
                            number = (now.year * 10000000000 + now.monthNumber * 100000000 + now.dayOfMonth * 1000000 + now.hour * 10000 + now.minute * 100 + now.second).toString(),
                            isNew = true
                        )
                        dispatch(AddDocument(document))
                        dispatch(SetCurrentDocument(document))
                        dispatch(ShowPage(Page.PAGE_FORMUMAT))
                    }
            ) {
                Box(
                    modifier = Modifier.padding(start = 20.dp)
                ) {
                    IconByName(
                        name = if (documentTypeInfo.icon.isNullOrBlank()) "Task" else documentTypeInfo.icon,
                        modifier = Modifier.size(64.dp),
                        color = MaterialTheme.colorScheme.secondary
                    )
                }
                Column(
                    modifier = Modifier
                        .weight(1.0f)
                        .padding(start = 12.dp, end = 12.dp, top = 29.dp, bottom = 29.dp),
                ) {
                    Text(
                        text = documentTypeInfo.displayNameI18n.getCurrentLocale() ?: documentTypeInfo.displayName ?: "",
                        fontSize = 22.sp,
                        fontWeight = FontWeight.W500,
                        modifier = Modifier.padding(bottom = 10.dp)
                    )
                    Text(
                        text = documentTypeInfo.descriptionI18n.getCurrentLocale() ?: documentTypeInfo.description ?: "",
                        style = MaterialTheme.typography.headlineSmall,
                        modifier = Modifier.padding(bottom = 10.dp),
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis
                    )
                }
            }
            HorizontalDivider(
                color = MaterialTheme.colorScheme.inverseOnSurface,
                thickness = 2.dp
            )
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Suppress("UNUSED_PARAMETER")
@Composable
fun PageDocument(
    document: Document?,
    formumatState: FormumatState,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    val values = FormumatValues(formumatState.data, formumatState.dataSchema.typesByKey, formumatState.uiSchema.fieldsByKey, dispatch)
    val pages = formumatState.uiSchema.allVisiblePages(values)
    val pagerState = rememberPagerState(pageCount = { pages.size })

    LaunchedEffect(formumatState.currentPage) {
        pagerState.scrollToPage(pages.indexOf(formumatState.currentPage))
    }

    LaunchedEffect(pagerState, pages) {
        snapshotFlow { pagerState.currentPage }.collect { page ->
            if (page < pages.size) {
                dispatch(SetCurrentPage(pages[page]))
            }
        }
    }

    HorizontalPager(
        state = pagerState
    ) { page ->
        Column(
            verticalArrangement = Arrangement.Top,
            modifier = Modifier.fillMaxSize().padding(paddingValues = paddingValues),
        ) {
            Box {
                val selectedPage = pages[page]
                digital.steva.formumat.ui.PageView(
                    selectedPage,
                    selectedPage.items,
                    formumatState.dataSchema.typesByKey,
                    values,
                    showTitle = false,
                    dispatch
                )
            }
        }
    }
}
