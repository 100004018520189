package digital.steva.formumat.ui

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import digital.steva.formumat.FormumatNonpublic
import digital.steva.formumat.FormumatPublicLibrary
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIcons
import digital.steva.formumat.helpers.encodeToBase58String
import digital.steva.formumat.redux.ClearValue
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetValue
import digital.steva.formumat.redux.StorageDeleteValue
import digital.steva.formumat.redux.StorageSetValue
import digital.steva.formumat.schema.SignatureField
import digital.steva.formumat.schema.StringType
import kotlinx.coroutines.launch
import kotlinx.uuid.SecureRandom
import kotlin.reflect.typeOf

@Suppress("UNUSED_PARAMETER")
@Composable
fun SignatureView(
    signatureField: SignatureField,
    type: StringType?,
    values: FormumatValues,
    dispatch: Dispatcher,
    enabled: Boolean = true,
    modifier: Modifier = Modifier
) {
    val coroutineScope = rememberCoroutineScope()
    val value = values[signatureField.property?.eval(values)]?.toString()
    val label = signatureField.title.eval(values)
    var showDialog by remember { mutableStateOf(false) }
    var imageBytes by remember { mutableStateOf(ByteArray(0)) }

    Column(
        modifier = modifier
    ) {
        if (label.isNotBlank()) {
            Text(
                text = label,
                modifier = Modifier.padding(vertical = 8.dp)
            )
        }

        if (!value.isNullOrBlank()) {
            coroutineScope.launch {
                if (FormumatPublicLibrary.storage(this).exists(value)) {
                    imageBytes =
                        FormumatPublicLibrary.storage(this).get(value, typeOf<ByteArray>())
                }
            }
        }

        Row(
            verticalAlignment = Alignment.Top
        ) {
            Column {
                if (imageBytes.isEmpty()) {
                    OutlinedButton(
                        onClick = { showDialog = true },
                        shape = CircleShape,
                        border = BorderStroke(2.dp, MaterialTheme.colorScheme.primary),
                        contentPadding = PaddingValues(0.dp),
                        colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.primary),
                        modifier = Modifier.size(48.dp)
                    ) {
                        FaIcon(FaIcons.PenAlt)
                    }
                } else {
                    if (signatureField.clearable.eval(values)) {
                        OutlinedButton(
                            onClick = {
                                val key = signatureField.property?.eval(values)
                                if (key != null) {
                                    dispatch(StorageDeleteValue(key))
                                    dispatch(ClearValue(signatureField.property.eval(values), values.listContext))
                                    imageBytes = ByteArray(0)
                                }
                            },
                            shape = CircleShape,
                            border = BorderStroke(2.dp, MaterialTheme.colorScheme.primary),
                            contentPadding = PaddingValues(0.dp),
                            colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.primary),
                            modifier = Modifier.size(48.dp)
                        ) {
                            FaIcon(FaIcons.Times)
                        }
                    }
                }
            }
            if (imageBytes.isNotEmpty()) {
                Spacer(modifier = Modifier.size(8.dp))
                Image(
                    bitmap = convertByteArrayToImageBitmap(imageBytes),
                    contentDescription = "",
                    contentScale = ContentScale.FillWidth
                )
            }
        }
    }

    if (showDialog) {
        SignatureDialog(
            onClose = { showDialog = false },
            onSave = {
                if (it != null) {
                    val key = SecureRandom.nextBytes(FormumatNonpublic.FILE_KEY_LENGTH).encodeToBase58String()
                    dispatch(StorageSetValue(key, it, typeOf<ByteArray>()))
                    dispatch(SetValue(signatureField.property?.eval(values) ?: "", key, values.listContext))
                    showDialog = false
                }
            },
            onClear = {
                val key = signatureField.property?.eval(values)
                if (key != null) {
                    dispatch(StorageDeleteValue(key))
                    dispatch(ClearValue(signatureField.property.eval(values), values.listContext))
                    imageBytes = ByteArray(0)
                }
            }
        )
    }
}

@Composable
expect fun SignatureDialog(
    onClose: () -> Unit,
    onSave: (ByteArray?) -> Unit,
    onClear: () -> Unit
)
