package digital.steva.dot.app.domain

import digital.steva.dot.app.Login
import digital.steva.dot.app.domain.RestHelper.client
import digital.steva.formumat.redux.stringifyValues
import io.ktor.client.call.body
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.forms.submitForm
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders
import io.ktor.http.contentType

object DocumentResource {
    suspend fun getAll(login: Login): List<DocumentInfo> {
        return client(login).get("${login.serverUrl}/api/dms/documents")
            .body()
    }

    suspend fun get(login: Login, id: String): Document {
        return client(login).get("${login.serverUrl}/api/dms/documents/${id}")
            .body()
    }

    suspend fun create(login: Login, document: Document) {
        client(login).post("${login.serverUrl}/api/dms/documents") {
            contentType(ContentType.Application.Json)
            setBody(document.copy(datum = document.datum?.copy(dataString = stringifyValues(document.data))))
        }
    }

    suspend fun update(login: Login, document: Document) {
        client(login).put("${login.serverUrl}/api/dms/documents/${document.id}") {
            contentType(ContentType.Application.Json)
            setBody(document.copy(datum = document.datum?.copy(dataString = stringifyValues(document.data))))
        }
    }

    suspend fun createDataFile(login: Login, document: Document, fileKey: String, fileData: ByteArray) {
        client(login).submitForm("${login.serverUrl}/api/dms/documents/${document.id}/data-files") {
            setBody(MultiPartFormDataContent(formData {
                append("file", fileData,
                    Headers.build {
                        append(HttpHeaders.ContentDisposition, "filename=${fileKey}")
                        append(HttpHeaders.ContentType, "image/jpeg")
                    })
            }))
        }
    }

    suspend fun getDatumFile(login: Login, id: String, fileKey: String): ByteArray {
        return client(login).get("${login.serverUrl}/api/dms/documents/${id}/data-files/${fileKey}")
            .body()
    }
}
