package digital.steva.formumat.ui

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIcons
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetValue
import digital.steva.formumat.schema.LabelField
import digital.steva.formumat.schema.LabelStyle
import digital.steva.formumat.schema.StringType
import digital.steva.formumat.schema.TextField
import visualTransformToPlaceholderIfEmpty

@Suppress("UNUSED_PARAMETER")
@Composable
fun LabelView(
    labelField: LabelField,
    values: FormumatValues,
    dispatch: Dispatcher,
    enabled: Boolean = true,
    modifier: Modifier = Modifier
) {
    Text(
        text = labelField.title.eval(values),
        style = when (labelField.style) {
            LabelStyle.HEADING -> MaterialTheme.typography.headlineLarge
            LabelStyle.TITLE -> MaterialTheme.typography.headlineMedium
            else -> MaterialTheme.typography.labelMedium
        },
        modifier = modifier.padding(vertical = 4.dp)
    )
}

@Composable
fun TextView(
    textField: TextField,
    stringType: StringType?,
    values: FormumatValues,
    dispatch: Dispatcher,
    enabled: Boolean = true,
    modifier: Modifier = Modifier
) {
    val property = textField.property?.eval(values) ?: ""
    val valueString = values.getWithoutDefault(property)?.toString() ?: ""
    val defaultValueString = values.getDefault(property)?.toString() ?: ""
    val label = textField.title.eval(values)
    val fieldEnabled = enabled && textField.enabled.eval(values)
    val required = textField.isRequired(stringType, values)
    if (textField.multiline) {
        TextAreaView(textField, values, valueString, defaultValueString, label, fieldEnabled, required, dispatch, modifier)
    } else {
        TextFieldView(textField, values, valueString, defaultValueString, label, fieldEnabled, required, dispatch, modifier)
    }
}

@Composable
fun TextFieldView(
    textField: TextField,
    values: FormumatValues,
    valueString: String,
    defaultValueString: String,
    label: String,
    enabled: Boolean,
    required: Boolean,
    dispatch: Dispatcher,
    modifier: Modifier
) {
    OutlinedTextField(
        value = valueString,
        onValueChange = { dispatch(SetValue(textField.property?.eval(values) ?: "", it, values.listContext)) },
        visualTransformation = visualTransformToPlaceholderIfEmpty(valueString, defaultValueString, MaterialTheme.colorScheme.tertiary),
        label = { Text(label) },
        singleLine = true,
        enabled = enabled,
        trailingIcon = { RequiredIcon(required, valueString, enabled) },
        modifier = modifier.fillMaxWidth()
    )
}

@Composable
fun TextAreaView(
    textField: TextField,
    values: FormumatValues,
    valueString: String,
    defaultValueString: String,
    label: String,
    enabled: Boolean,
    required: Boolean,
    dispatch: Dispatcher,
    modifier: Modifier
) {
    OutlinedTextField(
        value = valueString,
        onValueChange = { dispatch(SetValue(textField.property?.eval(values) ?: "", it, values.listContext)) },
        visualTransformation = visualTransformToPlaceholderIfEmpty(valueString, defaultValueString, MaterialTheme.colorScheme.tertiary),
        label = { Text(label) },
        singleLine = false,
        minLines = 5,
        maxLines = Int.MAX_VALUE,
        enabled = enabled,
        trailingIcon = { RequiredIcon(required, valueString, enabled) },
        modifier = modifier.fillMaxWidth()
    )
}

@Composable
fun RequiredIcon(
    required: Boolean,
    value: String?,
    enabled: Boolean
) {
    if (required) {
        if (value.isNullOrBlank()) {
            FaIcon(
                faIcon = FaIcons.Asterisk,
                size = MaterialTheme.typography.bodyLarge.fontSize.value.dp,
                tint = if (enabled) Color(0xFFF06292) else Color(0xFFFFEBEE)
            )
        } else {
            FaIcon(
                faIcon = FaIcons.Check,
                size = MaterialTheme.typography.bodyLarge.fontSize.value.dp,
                tint = if (enabled) Color(0xFF81C784) else Color(0xFFC8E6C9),
            )
        }
    }
}
