package digital.steva.dot.app

import dev.icerock.moko.graphics.Color
import dev.icerock.moko.resources.FontResource
import dev.icerock.moko.resources.ImageResource
import dev.icerock.moko.resources.ResourceContainer
import dev.icerock.moko.resources.ResourcePlatformDetails
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.`internal`.RemoteJsStringLoaderHolder
import dev.icerock.moko.resources.`internal`.SupportedLocale
import dev.icerock.moko.resources.`internal`.SupportedLocales
import dev.icerock.moko.resources.provider.RemoteJsStringLoader
import kotlin.String
import kotlin.collections.List

public actual object MR {
  private val contentHash: String = "0da1dc160a2e38126a93bfabf137429c"

  public actual object strings : ResourceContainer<StringResource>, RemoteJsStringLoaderHolder {
    actual override val __platformDetails: ResourcePlatformDetails = ResourcePlatformDetails()

    private val fallbackFileUrl: String =
        js("require(\"./localization/digitalstevadotapp_stringsJson.json\")") as String

    private val supportedLocales: SupportedLocales = SupportedLocales(listOf(
        SupportedLocale("de",
            js("require(\"./localization/digitalstevadotapp_stringsJson_de.json\")") as String),
        ))

    override val stringsLoader: RemoteJsStringLoader = RemoteJsStringLoader.Impl(supportedLocales =
        supportedLocales, fallbackFileUri = fallbackFileUrl)

    public actual val action_close: StringResource = StringResource(key = "action_close", loader =
        stringsLoader)

    public actual val action_documents: StringResource = StringResource(key = "action_documents",
        loader = stringsLoader)

    public actual val action_finish: StringResource = StringResource(key = "action_finish", loader =
        stringsLoader)

    public actual val action_login: StringResource = StringResource(key = "action_login", loader =
        stringsLoader)

    public actual val action_logout: StringResource = StringResource(key = "action_logout", loader =
        stringsLoader)

    public actual val action_new: StringResource = StringResource(key = "action_new", loader =
        stringsLoader)

    public actual val action_ok: StringResource = StringResource(key = "action_ok", loader =
        stringsLoader)

    public actual val action_options: StringResource = StringResource(key = "action_options", loader
        = stringsLoader)

    public actual val action_save: StringResource = StringResource(key = "action_save", loader =
        stringsLoader)

    public actual val action_transfer: StringResource = StringResource(key = "action_transfer",
        loader = stringsLoader)

    public actual val dialog_error_title: StringResource = StringResource(key =
        "dialog_error_title", loader = stringsLoader)

    public actual val document_state_current: StringResource = StringResource(key =
        "document_state_current", loader = stringsLoader)

    public actual val document_state_new: StringResource = StringResource(key =
        "document_state_new", loader = stringsLoader)

    public actual val document_state_modified: StringResource = StringResource(key =
        "document_state_modified", loader = stringsLoader)

    public actual val document_state_done: StringResource = StringResource(key =
        "document_state_done", loader = stringsLoader)

    public actual val error_login_wrong_username_or_password: StringResource = StringResource(key =
        "error_login_wrong_username_or_password", loader = stringsLoader)

    public actual val error_login: StringResource = StringResource(key = "error_login", loader =
        stringsLoader)

    public actual val error_synchronization: StringResource = StringResource(key =
        "error_synchronization", loader = stringsLoader)

    public actual val icon_task: StringResource = StringResource(key = "icon_task", loader =
        stringsLoader)

    public actual val icon_document: StringResource = StringResource(key = "icon_document", loader =
        stringsLoader)

    public actual val icon_error: StringResource = StringResource(key = "icon_error", loader =
        stringsLoader)

    public actual val icon_person: StringResource = StringResource(key = "icon_person", loader =
        stringsLoader)

    public actual val icon_key: StringResource = StringResource(key = "icon_key", loader =
        stringsLoader)

    public actual val icon_logout: StringResource = StringResource(key = "icon_logout", loader =
        stringsLoader)

    public actual val icon_visibility: StringResource = StringResource(key = "icon_visibility",
        loader = stringsLoader)

    public actual val icon_web: StringResource = StringResource(key = "icon_web", loader =
        stringsLoader)

    public actual val page_document: StringResource = StringResource(key = "page_document", loader =
        stringsLoader)

    public actual val page_documents: StringResource = StringResource(key = "page_documents", loader
        = stringsLoader)

    public actual val page_formumat: StringResource = StringResource(key = "page_formumat", loader =
        stringsLoader)

    public actual val page_infos_and_settings: StringResource = StringResource(key =
        "page_infos_and_settings", loader = stringsLoader)

    public actual val page_new_document: StringResource = StringResource(key = "page_new_document",
        loader = stringsLoader)

    public actual val page_login: StringResource = StringResource(key = "page_login", loader =
        stringsLoader)

    public actual val page_login_server_url: StringResource = StringResource(key =
        "page_login_server_url", loader = stringsLoader)

    public actual val page_login_username: StringResource = StringResource(key =
        "page_login_username", loader = stringsLoader)

    public actual val page_login_password: StringResource = StringResource(key =
        "page_login_password", loader = stringsLoader)

    public actual val sync_progress_title: StringResource = StringResource(key =
        "sync_progress_title", loader = stringsLoader)

    public actual val sync_progress_title_download: StringResource = StringResource(key =
        "sync_progress_title_download", loader = stringsLoader)

    public actual val sync_progress_title_upload: StringResource = StringResource(key =
        "sync_progress_title_upload", loader = stringsLoader)

    public actual val sync_progress_document_types_create: StringResource = StringResource(key =
        "sync_progress_document_types_create", loader = stringsLoader)

    public actual val sync_progress_document_types_delete: StringResource = StringResource(key =
        "sync_progress_document_types_delete", loader = stringsLoader)

    public actual val sync_progress_document_types_update: StringResource = StringResource(key =
        "sync_progress_document_types_update", loader = stringsLoader)

    public actual val sync_progress_success: StringResource = StringResource(key =
        "sync_progress_success", loader = stringsLoader)

    public actual val sync_progress_documents_create: StringResource = StringResource(key =
        "sync_progress_documents_create", loader = stringsLoader)

    public actual val sync_progress_documents_update: StringResource = StringResource(key =
        "sync_progress_documents_update", loader = stringsLoader)

    public actual val sync_progress_documents_delete: StringResource = StringResource(key =
        "sync_progress_documents_delete", loader = stringsLoader)

    public actual val sync_progress_show_summary: StringResource = StringResource(key =
        "sync_progress_show_summary", loader = stringsLoader)

    public actual val sync_summary_success_document_types_deleted: StringResource =
        StringResource(key = "sync_summary_success_document_types_deleted", loader = stringsLoader)

    public actual val sync_summary_success_document_types_downloaded: StringResource =
        StringResource(key = "sync_summary_success_document_types_downloaded", loader =
        stringsLoader)

    public actual val sync_summary_success_documents_created: StringResource = StringResource(key =
        "sync_summary_success_documents_created", loader = stringsLoader)

    public actual val sync_summary_success_documents_deleted: StringResource = StringResource(key =
        "sync_summary_success_documents_deleted", loader = stringsLoader)

    public actual val sync_summary_success_documents_downloaded: StringResource = StringResource(key
        = "sync_summary_success_documents_downloaded", loader = stringsLoader)

    public actual val sync_summary_success_documents_uploaded: StringResource = StringResource(key =
        "sync_summary_success_documents_uploaded", loader = stringsLoader)

    public actual val sync_summary_failure_document_type: StringResource = StringResource(key =
        "sync_summary_failure_document_type", loader = stringsLoader)

    public actual val sync_summary_failure_document_types: StringResource = StringResource(key =
        "sync_summary_failure_document_types", loader = stringsLoader)

    public actual val sync_summary_failure_document_create: StringResource = StringResource(key =
        "sync_summary_failure_document_create", loader = stringsLoader)

    public actual val sync_summary_failure_document_upload: StringResource = StringResource(key =
        "sync_summary_failure_document_upload", loader = stringsLoader)

    public actual val sync_summary_failure_document_download: StringResource = StringResource(key =
        "sync_summary_failure_document_download", loader = stringsLoader)

    public actual val sync_summary_failure_documents: StringResource = StringResource(key =
        "sync_summary_failure_documents", loader = stringsLoader)

    public actual val sync_summary_failure_file_download: StringResource = StringResource(key =
        "sync_summary_failure_file_download", loader = stringsLoader)

    public actual val sync_summary_failure_file_upload: StringResource = StringResource(key =
        "sync_summary_failure_file_upload", loader = stringsLoader)

    public actual val titles_error: StringResource = StringResource(key = "titles_error", loader =
        stringsLoader)

    actual override fun values(): List<StringResource> = listOf(action_close, action_documents,
        action_finish, action_login, action_logout, action_new, action_ok, action_options,
        action_save, action_transfer, dialog_error_title, document_state_current,
        document_state_new, document_state_modified, document_state_done,
        error_login_wrong_username_or_password, error_login, error_synchronization, icon_task,
        icon_document, icon_error, icon_person, icon_key, icon_logout, icon_visibility, icon_web,
        page_document, page_documents, page_formumat, page_infos_and_settings, page_new_document,
        page_login, page_login_server_url, page_login_username, page_login_password,
        sync_progress_title, sync_progress_title_download, sync_progress_title_upload,
        sync_progress_document_types_create, sync_progress_document_types_delete,
        sync_progress_document_types_update, sync_progress_success, sync_progress_documents_create,
        sync_progress_documents_update, sync_progress_documents_delete, sync_progress_show_summary,
        sync_summary_success_document_types_deleted, sync_summary_success_document_types_downloaded,
        sync_summary_success_documents_created, sync_summary_success_documents_deleted,
        sync_summary_success_documents_downloaded, sync_summary_success_documents_uploaded,
        sync_summary_failure_document_type, sync_summary_failure_document_types,
        sync_summary_failure_document_create, sync_summary_failure_document_upload,
        sync_summary_failure_document_download, sync_summary_failure_documents,
        sync_summary_failure_file_download, sync_summary_failure_file_upload, titles_error)
  }

  public actual object images : ResourceContainer<ImageResource> {
    actual override val __platformDetails: ResourcePlatformDetails = ResourcePlatformDetails()

    public actual val dot_icon: ImageResource = ImageResource(fileUrl =
        js("require(\"./images/dot_icon.png\")") as String, fileName = "dot_icon.png")

    actual override fun values(): List<ImageResource> = listOf(dot_icon)
  }

  public actual object fonts : ResourceContainer<FontResource> {
    actual override val __platformDetails: ResourcePlatformDetails = ResourcePlatformDetails()

    public actual val barlow_blackitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-BlackItalic.ttf\")") as String, fontFamily =
        "barlow_blackitalic")

    public actual val barlow_extrabold: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraBold.ttf\")") as String, fontFamily = "barlow_extrabold")

    public actual val barlow_light: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Light.ttf\")") as String, fontFamily = "barlow_light")

    public actual val barlow_lightitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-LightItalic.ttf\")") as String, fontFamily =
        "barlow_lightitalic")

    public actual val barlow_thinitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ThinItalic.ttf\")") as String, fontFamily =
        "barlow_thinitalic")

    public actual val barlow_extralight: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraLight.ttf\")") as String, fontFamily =
        "barlow_extralight")

    public actual val barlow_extrabolditalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraBoldItalic.ttf\")") as String, fontFamily =
        "barlow_extrabolditalic")

    public actual val barlow_bold: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Bold.ttf\")") as String, fontFamily = "barlow_bold")

    public actual val barlow_italic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Italic.ttf\")") as String, fontFamily = "barlow_italic")

    public actual val barlow_semibold: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-SemiBold.ttf\")") as String, fontFamily = "barlow_semibold")

    public actual val barlow_black: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Black.ttf\")") as String, fontFamily = "barlow_black")

    public actual val barlow_medium: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Medium.ttf\")") as String, fontFamily = "barlow_medium")

    public actual val barlow_regular: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Regular.ttf\")") as String, fontFamily = "barlow_regular")

    public actual val barlow_bolditalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-BoldItalic.ttf\")") as String, fontFamily =
        "barlow_bolditalic")

    public actual val barlow_extralightitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-ExtraLightItalic.ttf\")") as String, fontFamily =
        "barlow_extralightitalic")

    public actual val barlow_mediumitalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-MediumItalic.ttf\")") as String, fontFamily =
        "barlow_mediumitalic")

    public actual val barlow_semibolditalic: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-SemiBoldItalic.ttf\")") as String, fontFamily =
        "barlow_semibolditalic")

    public actual val barlow_thin: FontResource = FontResource(fileUrl =
        js("require(\"./fonts/Barlow-Thin.ttf\")") as String, fontFamily = "barlow_thin")

    actual override fun values(): List<FontResource> = listOf(barlow_blackitalic, barlow_extrabold,
        barlow_light, barlow_lightitalic, barlow_thinitalic, barlow_extralight,
        barlow_extrabolditalic, barlow_bold, barlow_italic, barlow_semibold, barlow_black,
        barlow_medium, barlow_regular, barlow_bolditalic, barlow_extralightitalic,
        barlow_mediumitalic, barlow_semibolditalic, barlow_thin)

    public fun addFontsToPage() {
      js("require(\"./fonts/digitalstevadotapp-generated-declarations.css\")")
    }
  }
}
