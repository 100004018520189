package digital.steva.formumat

import digital.steva.formumat.redux.And
import digital.steva.formumat.redux.If
import digital.steva.formumat.redux.Not
import digital.steva.formumat.redux.Or
import digital.steva.formumat.schema.ImageField
import digital.steva.formumat.schema.PhotoField
import digital.steva.formumat.schema.SignatureField
import digital.steva.formumat.schema.StringType
import digital.steva.formumat.ui.ImageView
import digital.steva.formumat.ui.PhotoView
import digital.steva.formumat.ui.SignatureView

object FormumatNonpublic {
    @Suppress("UNUSED_ANONYMOUS_PARAMETER")
    fun configure(): Boolean {
        FormumatConfig.fields.add {
            it.subclass(ImageField::class, ImageField.serializer())
            it.subclass(PhotoField::class, PhotoField.serializer())
            it.subclass(SignatureField::class, SignatureField.serializer())
        }
        FormumatConfig.views.add { field, type, types, values, dispatch, enabled, modifier ->
            when (field) {
                is ImageField -> ImageView(field, type as StringType?, values, dispatch, enabled, modifier)
                is PhotoField -> PhotoView(field, type as StringType?, values, dispatch, enabled, modifier)
                is SignatureField -> SignatureView(field, type as StringType?, values, dispatch, enabled, modifier)
                else -> {}
            }
        }
        FormumatConfig.functions.addAll(listOf(If, Not, Or, And))
        return true
    }

    val FILE_KEY_LENGTH = 38
}
