package digital.steva.formumat.schema

import digital.steva.formumat.redux.Boolish
import digital.steva.formumat.redux.Stringish
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("image")
data class ImageField(
    override val title: Stringish = Stringish.Literal(""),
    override val property: Stringish? = null,
    override val name: String? = null,
    override val visible: Boolish = Boolish.Literal(true),
    override val enabled: Boolish = Boolish.Literal(true),
    override val required: Boolish = Boolish.Literal(false),
    override val clearable: Boolish = Boolish.Literal(true),
    val file: Stringish? = null,
    val data: Stringish? = null,
) : Field

@Serializable
@SerialName("photo")
data class PhotoField(
    override val title: Stringish = Stringish.Literal(""),
    override val property: Stringish? = null,
    override val name: String? = null,
    override val visible: Boolish = Boolish.Literal(true),
    override val enabled: Boolish = Boolish.Literal(true),
    override val required: Boolish = Boolish.Literal(false),
    override val clearable: Boolish = Boolish.Literal(true)
) : Field

@Serializable
@SerialName("signature")
data class SignatureField(
    override val title: Stringish = Stringish.Literal(""),
    override val property: Stringish? = null,
    override val name: String? = null,
    override val visible: Boolish = Boolish.Literal(true),
    override val enabled: Boolish = Boolish.Literal(true),
    override val required: Boolish = Boolish.Literal(false),
    override val clearable: Boolish = Boolish.Literal(true)
) : Field
