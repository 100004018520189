import digital.steva.formumat.FormumatPublicLibrary
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.StorageDeleteValue
import digital.steva.formumat.redux.StorageSetValue
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import org.reduxkotlin.middleware

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
fun createStorageMiddlware() =
    middleware<FormumatState> { store, next, action ->
        when (action) {
            is StorageDeleteValue -> {
                CoroutineScope(Dispatchers.Default).launch {
                    coroutineScope {
                        FormumatPublicLibrary.storage(this).remove(action.key)
                    }
                }
            }

            is StorageSetValue -> {
                CoroutineScope(Dispatchers.Default).launch {
                    coroutineScope {
                        FormumatPublicLibrary.storage(this).put(action.key, action.value, action.type)
                    }
                }
            }

            else -> next(action)
        }
    }
