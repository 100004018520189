package digital.steva.dot.app.middlewares

import com.github.michaelbull.result.Err
import com.github.michaelbull.result.Ok
import com.github.michaelbull.result.Result
import com.github.michaelbull.result.onFailure
import com.github.michaelbull.result.onSuccess
import dev.icerock.moko.resources.StringResource
import digital.steva.dot.app.AppState
import digital.steva.dot.app.HideProgressSpinner
import digital.steva.dot.app.InitializeFromStorage
import digital.steva.dot.app.Login
import digital.steva.dot.app.MR
import digital.steva.dot.app.Page
import digital.steva.dot.app.Progress
import digital.steva.dot.app.ProgressSummary
import digital.steva.dot.app.RestLogin
import digital.steva.dot.app.RestLogout
import digital.steva.dot.app.RestSynchronize
import digital.steva.dot.app.ShowError
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.ShowProgress
import digital.steva.dot.app.ShowProgressSpinner
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.dispatch
import digital.steva.dot.app.domain.AuthenticationException
import digital.steva.dot.app.domain.Document
import digital.steva.dot.app.domain.DocumentResource
import digital.steva.dot.app.domain.DocumentType
import digital.steva.dot.app.domain.DocumentTypeResource
import digital.steva.dot.app.domain.SessionResource
import digital.steva.dot.app.domain.Storage
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.schema.StringFormat
import digital.steva.formumat.schema.StringType
import digital.steva.formumat.schema.parseDataSchema
import digital.steva.formumat.schema.parseUiSchema
import io.ktor.http.HttpStatusCode
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.reduxkotlin.middleware

class SynchronizationException : Throwable()

@Suppress("UNUSED_PARAMETER")
object RestMiddleware {
    operator fun invoke() = middleware<AppState> { store, next, action ->
        val login = store.state.login

        when (action) {
            is RestLogin -> CoroutineScope(Dispatchers.Default).launch {
                try {
                    dispatch(ShowProgressSpinner())
                    when (SessionResource.login(login).status) {
                        HttpStatusCode.OK -> {
                            dispatch(InitializeFromStorage())
                            dispatch(ShowPage(Page.PAGE_DOCUMENTS))
                        }

                        HttpStatusCode.Unauthorized -> {
                            dispatch(ShowToast(MR.strings.error_login_wrong_username_or_password))
                        }

                        else -> {
                            dispatch(ShowToast(MR.strings.error_login))
                        }
                    }
                } catch (e: Throwable) {
                    println(e.message)
                    dispatch(ShowToast(MR.strings.error_login))
                }
                dispatch(HideProgressSpinner())
            }

            is RestLogout -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                dispatch(ShowPage(Page.PAGE_LOGIN))
                dispatch(HideProgressSpinner())
            }

            is RestSynchronize -> CoroutineScope(Dispatchers.Default).launch {
                val summary = ProgressSummary()
                dispatch(ShowProgressSpinner())
                synchronizeCheckServer(login)
                    .onSuccess {
                        synchronizeUpload(store.state, this, login, summary)
                        synchronizeDownload(store.state, this, login, summary)
                        dispatch(InitializeFromStorage())
                        dispatch(
                            ShowProgress(
                                Progress(
                                    title = MR.strings.sync_progress_title,
                                    mainMessage = MR.strings.sync_progress_success,
                                    mainProgress = 1.0f,
                                    summary = summary,
                                )
                            )
                        )
                        if (summary.hasErrors) {
                            dispatch(ShowError(SynchronizationException()))
                        }
                    }.onFailure {
                        dispatch(ShowError(it))
                    }
                dispatch(HideProgressSpinner())
            }

            else -> next(action)
        }
    }


    private suspend fun synchronizeCheckServer(login: Login): Result<HttpStatusCode, Throwable> {
        return if (SessionResource.login(login).status == HttpStatusCode.OK) {
            Ok(HttpStatusCode.OK)
        } else {
            Err(AuthenticationException())
        }
    }

    private suspend fun synchronizeUpload(
        state: AppState,
        scope: CoroutineScope,
        login: Login,
        summary: ProgressSummary
    ) {
        dispatch(
            ShowProgress(
                Progress(
                    title = MR.strings.sync_progress_title_upload,
                    mainMessage = "", mainProgress = 0f,
                    subMessage = "", subProgress = 0f
                )
            )
        )

        synchronizeUploadDocuments(
            scope = scope,
            state = state,
            login = login,
            summary = summary,
            step = 0,
            numSteps = 3,
        )
    }

    private suspend fun synchronizeUploadDocuments(
        state: AppState,
        scope: CoroutineScope,
        login: Login,
        summary: ProgressSummary,
        step: Int,
        numSteps: Int,
    ) {
        suspend fun uploadDocumentFiles(document: Document) {
            val documentType: DocumentType? = Storage.getDocumentType(scope, document.documentTypeId)
            if (documentType != null && documentType.entity?.schema != null && documentType.form?.schema != null) {
                val dataSchema = parseDataSchema(documentType.entity.schema)
                val uiSchema = parseUiSchema(documentType.form.schema)
                val formumatValues = FormumatValues(document.data, dataSchema.typesByKey, uiSchema.fieldsByKey, ::dispatch)
                dataSchema.typesByKey.entries
                    .filter { it.value is StringType && (it.value as StringType).format == StringFormat.FILE }.forEach {
                        val fileKey = formumatValues.get(it.key).toString()
                        Storage.getFileData(scope, fileKey)?.also { fileData ->
                            DocumentResource.createDataFile(login, document, fileKey, fileData)
                        }
                    }
            }
        }

        val newDocuments = state.documentsInfos.filter { it.isNew }
        newDocuments.forEachIndexed { index, documentInfo ->
            dispatchProgress(
                title = MR.strings.sync_progress_title_upload,
                message = MR.strings.sync_progress_documents_update,
                numSteps = numSteps,
                step = step,
                count = newDocuments.size,
                index = index,
            )
            Storage.getDocument(scope, documentInfo.id)?.also { document ->
                DocumentResource.create(login, document)
                uploadDocumentFiles(document)
            }
        }

        val modifiedDocuments = state.documentsInfos.filter { it.isModified }
        modifiedDocuments.forEachIndexed { index, documentInfo ->
            dispatchProgress(
                title = MR.strings.sync_progress_title_upload,
                message = MR.strings.sync_progress_documents_update,
                numSteps = numSteps,
                step = step + 1,
                count = modifiedDocuments.size,
                index = index,
            )
            Storage.getDocument(scope, documentInfo.id)?.also { document ->
                DocumentResource.update(login, document)
                uploadDocumentFiles(document)
            }
        }

        dispatchProgress(
            title = MR.strings.sync_progress_title_upload,
            message = MR.strings.sync_progress_documents_delete,
            numSteps = numSteps,
            step = step + 2,
            count = 0,
            index = 0,
        )
    }

    private suspend fun synchronizeDownload(
        state: AppState,
        scope: CoroutineScope,
        login: Login,
        summary: ProgressSummary
    ) {
        dispatch(
            ShowProgress(
                Progress(
                    title = MR.strings.sync_progress_title_download,
                    mainMessage = "", mainProgress = 0f,
                    subMessage = "", subProgress = 0f
                )
            )
        )

        synchronizeDownloadDocumentTypes(
            state = state,
            scope = scope,
            login = login,
            summary = summary,
            step = 0,
            numSteps = 6,
        )

        synchronizeDownloadDocuments(
            state = state,
            scope = scope,
            login = login,
            summary = summary,
            step = 3,
            numSteps = 6,
        )
    }

    private suspend fun synchronizeDownloadDocumentTypes(
        state: AppState,
        scope: CoroutineScope,
        login: Login,
        summary: ProgressSummary,
        step: Int,
        numSteps: Int,
    ) {
        val documentTypesInfos = DocumentTypeResource.getAll(login)
        Storage.putDocumentTypesInfos(scope, documentTypesInfos)
        documentTypesInfos.mapIndexed { index, documentTypeInfo ->
            dispatchProgress(
                title = MR.strings.sync_progress_title_download,
                message = MR.strings.sync_progress_document_types_create,
                numSteps = numSteps,
                step = step,
                count = documentTypesInfos.size,
                index = index,
            )
            val documentType = DocumentTypeResource.get(login, documentTypeInfo.id)
            Storage.putDocumentType(scope, documentType)
            documentType.entity?.files?.forEach { file ->
                val data = DocumentTypeResource.getEntityFile(login, documentType.id, file.key)
                Storage.putFileData(scope, file, data)
            }
            documentType.form?.files?.forEach { file ->
                val data = DocumentTypeResource.getFormFile(login, documentType.id, file.key)
                Storage.putFileData(scope, file, data)
            }
        }

        dispatchProgress(
            title = MR.strings.sync_progress_title_download,
            message = MR.strings.sync_progress_document_types_update,
            numSteps = numSteps,
            step = step + 1,
            count = 0,
            index = 0,
        )

        dispatchProgress(
            title = MR.strings.sync_progress_title_download,
            message = MR.strings.sync_progress_document_types_delete,
            numSteps = numSteps,
            step = step + 2,
            count = 0,
            index = 0,
        )
    }

    private suspend fun synchronizeDownloadDocuments(
        state: AppState,
        scope: CoroutineScope,
        login: Login,
        summary: ProgressSummary,
        step: Int,
        numSteps: Int,
    ) {
        val documentsInfos = DocumentResource.getAll(login)
        Storage.putDocumentsInfos(scope, documentsInfos)
        documentsInfos.mapIndexed { index, documentInfo ->
            dispatchProgress(
                title = MR.strings.sync_progress_title_download,
                message = MR.strings.sync_progress_documents_create,
                numSteps = numSteps,
                step = step,
                count = documentsInfos.size,
                index = index,
            )
            val document = DocumentResource.get(login, documentInfo.id)
            Storage.putDocument(scope, document)
            document.datum?.files?.forEach { file ->
                val data = DocumentResource.getDatumFile(login, document.id, file.key)
                Storage.putFileData(scope, file, data)
            }
        }

        dispatchProgress(
            title = MR.strings.sync_progress_title_download,
            message = MR.strings.sync_progress_documents_update,
            numSteps = numSteps,
            step = step + 1,
            count = 0,
            index = 0,
        )

        dispatchProgress(
            title = MR.strings.sync_progress_title_download,
            message = MR.strings.sync_progress_documents_delete,
            numSteps = numSteps,
            step = step + 2,
            count = 0,
            index = 0,
        )
    }

    private fun dispatchProgress(
        title: StringResource, message: StringResource,
        numSteps: Int, step: Int, count: Int, index: Int
    ) {
        dispatch(
            ShowProgress(
                Progress(
                    title = title,
                    mainMessage = message,
                    mainProgress = (1f / numSteps) * step,
                    subMessage = "${index + 1}/$count",
                    subProgress = (index + 1).toFloat() / count,
                )
            )
        )
    }
}
