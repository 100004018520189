package digital.steva.formumat.redux

import digital.steva.formumat.schema.Page
import kotlinx.collections.immutable.PersistentList
import kotlinx.collections.immutable.PersistentMap
import kotlinx.collections.immutable.persistentListOf
import kotlinx.collections.immutable.persistentMapOf

@Suppress("UNCHECKED_CAST")
fun mainReducer(state: FormumatState, action: Any) =
    when (action) {
        is SetDataSchema -> state.copy(dataSchema = action.dataSchema)

        is SetUiSchema -> {
            state.copy(
                uiSchema = action.uiSchema,
                currentPage = action.uiSchema.fieldsByKey.values
                    .filterIsInstance<Page>()
                    .firstOrNull { it.name == state.currentPage?.name || it.title == state.currentPage?.title }
            )
        }

        is SetValues -> state.copy(data = action.values)

        is SetValue -> when (action.listContext) {
            null -> {
                val type = state.dataSchema.typesByKey[action.property]
                type?.let {
                    state.copy(data = state.data.put(action.property, type.convertToType(action.value)))
                } ?: state
            }

            else -> {
                val type = state.dataSchema.typesByKey["${action.listContext.listProperty}.${action.property}"]
                type?.let {
                    val list =
                        state.data[action.listContext.listProperty] as PersistentList<Map<String, Any>>
                    val entry = list[action.listContext.listIndex] as PersistentMap<String, Any>
                    val newEntry = entry.put(action.property, type.convertToType(action.value))
                    val newList = list.set(action.listContext.listIndex, newEntry)
                    state.copy(data = state.data.put(action.listContext.listProperty, newList))
                } ?: state
            }
        }

        is ClearValue -> when (action.listContext) {
            null -> state.copy(data = state.data.remove(action.property))
            else -> {
                val list =
                    state.data[action.listContext.listProperty] as PersistentList<Map<String, Any>>
                val entry = list[action.listContext.listIndex] as PersistentMap<String, Any>
                val newEntry = entry.remove(action.property)
                val newList = list.set(action.listContext.listIndex, newEntry)
                state.copy(data = state.data.put(action.listContext.listProperty, newList))
            }
        }

        is AddListRow -> state.copy(
            data = state.data.put(
                action.listProperty,
                (state.data[action.listProperty] as? PersistentList<Any> ?: persistentListOf()).add(
                    persistentMapOf<String, Any>()
                )
            )
        )

        is RemoveListRow -> state.copy(
            data = state.data.put(
                action.listProperty,
                (state.data[action.listProperty] as PersistentList<Any>).removeAt(action.index)
            )
        )

        is SetCurrentPage -> state.copy(currentPage = action.page)

        else -> state
    }
