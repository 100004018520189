package digital.steva.dot.app.views

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.Card
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.LinearProgressIndicator
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Snackbar
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.layout
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import dev.icerock.moko.resources.StringResource
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.dot.app.ErrorMessage
import digital.steva.dot.app.MR
import digital.steva.dot.app.Progress
import digital.steva.dot.app.ProgressSummary
import digital.steva.dot.app.ShowError
import digital.steva.dot.app.ShowProgress
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.theme.textStyleSplashScreenTitle
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIcons
import digital.steva.formumat.redux.Dispatcher

@Composable
fun CircleBordered(
    color: Color,
    modifier: Modifier = Modifier,
    borderWidth: Dp = 1.dp,
    content: @Composable () -> Unit,
) {
    Box(
        contentAlignment = Alignment.Center,
        modifier = modifier
            .border(borderWidth, color, CircleShape)
            .layout { measurable, constraints ->
                val placeable = measurable.measure(constraints)
                val currentHeight = placeable.height
                val heightCircle = if (placeable.width > currentHeight) {
                    placeable.width
                } else {
                    currentHeight
                }
                val currentWidth = placeable.width
                val widthCircle = if (placeable.height > currentWidth) {
                    placeable.height
                } else {
                    currentWidth
                }
                layout(heightCircle, heightCircle) {
                    placeable.placeRelative(
                        (widthCircle - currentWidth) / 2,
                        (heightCircle - currentHeight) / 2,
                    )
                }
            },
    ) {
        content()
    }
}

@Composable
fun Toast(message: String?, dispatch: Dispatcher) {
    AnimatedVisibility(
        visible = message != null,
        enter = fadeIn(),
        exit = fadeOut()
    ) {
        Box(
            modifier = Modifier.fillMaxSize()
        ) {
            Snackbar(
                modifier = Modifier
                    .align(Alignment.BottomCenter)
                    .padding(16.dp)
                    .clickable { dispatch(ShowToast(null)) }
            ) {
                Text(message.orEmpty())
            }
        }
    }
}

@Composable
fun ProgressSpinner(progressSpinner: Boolean) {
    if (progressSpinner) {
        Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.End) {
            CircularProgressIndicator(
                color = MaterialTheme.colorScheme.primary,
                modifier = Modifier.padding(top = 12.dp, end = 8.dp),
            )
        }
    }
}

@Composable
fun ProgressDialog(progress: Progress?, dispatch: Dispatcher) {
    if (progress != null) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxSize()
                .clickable { }) {
            Card {
                Column(
                    verticalArrangement = Arrangement.spacedBy(12.dp),
                    modifier = Modifier
                        .padding(20.dp)
                        .width(IntrinsicSize.Max),
                ) {
                    Text(
                        if (progress.title is StringResource) stringResource(progress.title) else progress.title.toString(),
                        style = MaterialTheme.typography.headlineMedium
                    )
                    HorizontalDivider()
                    if (progress.mainMessage != null) {
                        Text(if (progress.mainMessage is StringResource) stringResource(progress.mainMessage) else progress.mainMessage.toString())
                        if (progress.mainProgress != null) {
                            LinearProgressIndicator(progress = { progress.mainProgress })
                        } else {
                            LinearProgressIndicator()
                        }
                    }
                    if (progress.subMessage != null) {
                        Text(if (progress.subMessage is StringResource) stringResource(progress.subMessage) else progress.subMessage.toString())
                        if (progress.subProgress != null) {
                            LinearProgressIndicator(progress = { progress.subProgress })
                        } else {
                            LinearProgressIndicator()
                        }
                    }
                    if (progress.summary != null) {
                        var showSummary by remember { mutableStateOf(false) }
                        if (!showSummary) {
                            Text(
                                color = MaterialTheme.colorScheme.primary,
                                text = stringResource(MR.strings.sync_progress_show_summary),
                                modifier = Modifier
                                    .padding(top = 8.dp)
                                    .clickable { showSummary = true }
                            )
                        } else {
                            val successColor = MaterialTheme.colorScheme.tertiary
                            val failureColor = MaterialTheme.colorScheme.error
                            Text(
                                text = buildAnnotatedString {
                                    progress.summary.forEachEntry {
                                        when (it) {
                                            is ProgressSummary.Success -> withStyle(SpanStyle(color = successColor)) {
                                                append(stringResource(it.res, *it.args) + "\n")
                                            }

                                            is ProgressSummary.Failure -> withStyle(SpanStyle(color = failureColor)) {
                                                append(stringResource(it.res, *it.args) + "\n")
                                            }
                                        }
                                    }
                                },
                                modifier = Modifier
                                    .padding(top = 8.dp)
                            )
                        }
                    }
                    if (progress.mainProgress == 1.0f) {
                        Button(modifier = Modifier.align(Alignment.End),
                            onClick = { dispatch(ShowProgress(null)) }
                        ) {
                            Text(stringResource(MR.strings.action_close))
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun ErrorDialog(
    error: ErrorMessage?,
    dispatch: Dispatcher
) {
    if (error != null) {
        AlertDialog(
            title = { Text(stringResource(MR.strings.titles_error)) },
            text = {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Text(stringResource(error.res, error.args))
                    CircleBordered(
                        color = MaterialTheme.colorScheme.error,
                        borderWidth = 2.dp,
                        modifier = Modifier.padding(start = 20.dp, end = 16.dp)
                    ) {
                        FaIcon(
                            faIcon = FaIcons.Exclamation,
                            tint = MaterialTheme.colorScheme.error,
                            size = 40.dp,
                            modifier = Modifier.padding(8.dp)
                        )
                    }
                }
            },
            confirmButton = {
                Button(
                    modifier = Modifier.padding(end = 16.dp),
                    onClick = {
                        dispatch(ShowError(null))
                    }) {
                    Text(stringResource(MR.strings.action_ok))
                }
            },
            onDismissRequest = {
                dispatch(ShowError(null))
            }
        )
    }
}

@Composable
fun SplashScreen() {
    BoxWithConstraints() {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxSize()
                .background(
                    Brush.horizontalGradient(
                        colorStops = arrayOf(
                            0.0f to Color(0xff404797),
                            1.0f to Color(0xff21b095)
                        )
                    )
                )
        ) {
            Text(
                text = "steva.",
                style = textStyleSplashScreenTitle()
            )
        }
    }
}
