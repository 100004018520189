package digital.steva.dot.app.reducers

import digital.steva.dot.app.AddDocument
import digital.steva.dot.app.AppState
import digital.steva.dot.app.ErrorMessage
import digital.steva.dot.app.FinishCurrentDocument
import digital.steva.dot.app.HideProgressSpinner
import digital.steva.dot.app.HideSplash
import digital.steva.dot.app.MR
import digital.steva.dot.app.SaveCurrentDocument
import digital.steva.dot.app.SetCurrentDocument
import digital.steva.dot.app.SetCurrentDocumentType
import digital.steva.dot.app.SetDocumentTypesInfos
import digital.steva.dot.app.SetDocumentsInfos
import digital.steva.dot.app.SetFormumatState
import digital.steva.dot.app.SetLogin
import digital.steva.dot.app.SetZoomLevel
import digital.steva.dot.app.ShowError
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.ShowPreviousPage
import digital.steva.dot.app.ShowProgress
import digital.steva.dot.app.ShowProgressSpinner
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.domain.AuthenticationException
import digital.steva.dot.app.domain.Document
import digital.steva.dot.app.domain.DocumentInfo
import digital.steva.dot.app.domain.DocumentState
import digital.steva.dot.app.domain.Storage
import digital.steva.dot.app.domain.replace
import digital.steva.dot.app.middlewares.SynchronizationException
import digital.steva.formumat.redux.stringifyValues
import kotlinx.collections.immutable.PersistentList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch

object MainReducer {
    operator fun invoke() = fun(state: AppState, action: Any) =
        when (action) {
            is SetZoomLevel -> state.copy(zoomLevel = action.zoomLevel)

            is HideSplash -> state.copy(showSplash = false)

            is SetFormumatState -> state.copy(formumatState = action.formumatState)

            is ShowPage -> state.copy(previousPage = state.currentPage, currentPage = action.page)

            is ShowPreviousPage -> state.copy(currentPage = state.previousPage)

            is ShowError -> state.copy(
                error = when (action.error) {
                    is SynchronizationException -> ErrorMessage(MR.strings.error_synchronization)
                    is AuthenticationException -> ErrorMessage(MR.strings.error_login)
                    is ErrorMessage -> action.error
                    else -> null
                }
            )

            is ShowToast -> state.copy(toastMessage = action.message)

            is ShowProgressSpinner -> state.copy(showProgressSpinner = true)

            is HideProgressSpinner -> state.copy(showProgressSpinner = false)

            is ShowProgress -> state.copy(progress = action.progress)

            is SetLogin -> state.copy(login = action.login)

            is SetDocumentTypesInfos -> state.copy(documentTypesInfos = action.documentTypesInfos)

            is SetDocumentsInfos -> state.copy(documentsInfos = action.documentsInfos)

            is AddDocument -> {
                val documentsInfos = state.documentsInfos.add(action.document.toDocumentInfo())
                CoroutineScope(Dispatchers.Default).launch {
                    Storage.putDocument(this, action.document)
                    Storage.putDocumentsInfos(this, documentsInfos)
                }
                state.copy(documentsInfos = documentsInfos)
            }

            is SetCurrentDocumentType -> state.copy(currentDocumentType = action.documentType)

            is SetCurrentDocument -> state.copy(currentDocument = action.document)

            is SaveCurrentDocument -> {
                val document = state.currentDocument
                if (document != null) {
                    val newDocument = document.copy(
                        datum = document.datum?.copy(dataString = stringifyValues(state.formumatState.data)),
                        isModified = true
                    )
                    val newDocumentsInfos = state.documentsInfos.replace(document.id, newDocument.toDocumentInfo())
                    updateStateWithNewDocument(state, newDocument, newDocumentsInfos)
                } else state
            }

            is FinishCurrentDocument -> {
                val document = state.currentDocument
                if (document != null) {
                    val newDocument = state.currentDocument.copy(
                        state = DocumentState.DONE,
                        isModified = true
                    )
                    val newDocumentsInfos = state.documentsInfos.replace(document.id, newDocument.toDocumentInfo())
                    updateStateWithNewDocument(state, newDocument, newDocumentsInfos)
                } else state
            }

            else -> state
        }

    private fun updateStateWithNewDocument(state: AppState, newDocument: Document, newDocumentsInfos: PersistentList<DocumentInfo>): AppState {
        CoroutineScope(Dispatchers.Default).launch {
            Storage.putDocument(this, newDocument)
            Storage.putDocumentsInfos(this, newDocumentsInfos)
        }
        return state.copy(
            currentDocument = newDocument,
            documentsInfos = newDocumentsInfos
        )
    }
}