package digital.steva.dot.app.views

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.defaultMinSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material3.BottomAppBar
import androidx.compose.material3.Button
import androidx.compose.material3.DrawerState
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.ModalDrawerSheet
import androidx.compose.material3.ModalNavigationDrawer
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.material3.TopAppBar
import androidx.compose.material3.TopAppBarDefaults
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.unit.dp
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.dot.app.AppState
import digital.steva.dot.app.BuildConfig
import digital.steva.dot.app.ClearFormumat
import digital.steva.dot.app.FinishCurrentDocument
import digital.steva.dot.app.MR
import digital.steva.dot.app.Page
import digital.steva.dot.app.RestLogout
import digital.steva.dot.app.RestSynchronize
import digital.steva.dot.app.SaveCurrentDocument
import digital.steva.dot.app.SetCurrentDocument
import digital.steva.dot.app.SetCurrentDocumentType
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.ShowPreviousPage
import digital.steva.dot.app.dispatch
import digital.steva.dot.app.domain.Document
import digital.steva.dot.app.domain.findById
import digital.steva.dot.app.domain.getCurrentLocale
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIcons
import digital.steva.formumat.FormumatNonpublicLibrary
import digital.steva.formumat.FormumatPublicLibrary
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.ui.PageNavigationView
import digital.steva.formumat.ui.Progress
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun MainView(
    state: AppState,
    dispatcher: Dispatcher
) {
    val values =
        FormumatValues(state.formumatState.data, state.formumatState.dataSchema.typesByKey, state.formumatState.uiSchema.fieldsByKey, dispatcher)
    val coroutineScope = rememberCoroutineScope()

    BoxWithConstraints() {
        val wideView = constraints.maxWidth >= 1200
        val needDrawer = state.currentPage == Page.PAGE_DOCUMENT || state.currentPage == Page.PAGE_FORMUMAT
        val drawerState: DrawerState = rememberDrawerState(initialValue = if (wideView) DrawerValue.Open else DrawerValue.Closed)

        if (wideView) {
            Scaffold(
                topBar = {
                    Header(state, values, drawerState, wideView, needDrawer, dispatcher)
                },
                bottomBar = { Footer(state.currentPage, state.currentDocument, wideView) }
            ) { paddingValues ->
                Row {
                    Column(
                        modifier = Modifier.width(IntrinsicSize.Max).widthIn(0.dp, 800.dp)
                            .padding(paddingValues),

                        ) {
                        if (needDrawer && drawerState.isOpen) {
                            ModalDrawerSheet(
                                drawerShape = RectangleShape
                            ) {
                                DrawerContent(
                                    state.formumatState,
                                    values,
                                    wideView,
                                    dispatcher,
                                    coroutineScope,
                                    drawerState
                                )
                            }
                        }
                    }
                    PageView(state, dispatcher, paddingValues)
                }
            }
        } else {
            ModalNavigationDrawer(
                drawerState = drawerState,
                drawerContent = {
                    ModalDrawerSheet(
                    ) {
                        DrawerContent(
                            state.formumatState,
                            values,
                            wideView,
                            dispatcher,
                            coroutineScope,
                            drawerState
                        )
                    }
                }
            ) {
                Scaffold(
                    topBar = { Header(state, values, drawerState, wideView, needDrawer, dispatcher) },
                    bottomBar = { Footer(state.currentPage, state.currentDocument, wideView) }
                ) { paddingValues ->
                    PageView(state, dispatcher, paddingValues)
                }
            }
        }
    }
}

@Composable
fun PageView(
    state: AppState,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    when (state.currentPage) {
        Page.PAGE_LOGIN -> PageLogin(state, dispatch)

        Page.PAGE_FORMUMAT -> PageFormumat(state.formumatState, dispatch, paddingValues)

        Page.PAGE_DOCUMENTS -> PageDocuments(
            state.documentsInfos,
            state.documentTypesInfos,
            dispatch,
            paddingValues
        )

        Page.PAGE_DOCUMENT -> PageDocument(
            state.currentDocument,
            state.formumatState,
            dispatch,
            paddingValues
        )

        Page.PAGE_NEW_DOCUMENT -> PageNewDocument(state.documentTypesInfos, dispatch, paddingValues)
        Page.PAGE_INFOS_AND_SETTINGS -> PageInfosAndSettings(state, dispatch, paddingValues)
    }
}

@Composable
fun DrawerContent(
    formumatState: FormumatState,
    values: FormumatValues,
    wideView: Boolean,
    dispatch: Dispatcher,
    coroutineScope: CoroutineScope,
    drawerState: DrawerState,
) {
    Column(
        modifier = Modifier.verticalScroll(rememberScrollState()).padding(top = 8.dp, bottom = 8.dp)
    ) {
        PageNavigationView(formumatState.uiSchema.items,
            formumatState.dataSchema.typesByKey,
            values,
            formumatState.currentPage,
            dispatch,
            onPageSelected = {
                if (!wideView) {
                    coroutineScope.launch { drawerState.close() }
                }
            })
    }
}

@ExperimentalFoundationApi
@Suppress("UNUSED_PARAMETER")
@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun Header(
    state: AppState,
    values: FormumatValues,
    drawerState: DrawerState,
    wideView: Boolean,
    needDrawer: Boolean,
    dispatcher: Dispatcher,
) {
    val coroutineScope = rememberCoroutineScope()
    val title = state.documentTypesInfos.findById(state.currentDocument?.documentTypeId)?.displayNameI18n?.getCurrentLocale()
        ?: stringResource(state.currentPage.title)
    val subTitle = if (state.currentPage == Page.PAGE_DOCUMENT) state.formumatState.currentPage?.title?.eval(values) else null
    TopAppBar(
        title = {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.padding(top = 12.dp, bottom = 12.dp)
            ) {
                if (state.currentDocument != null) {
                    Progress(
                        state.formumatState.uiSchema.items.filterIsInstance<digital.steva.formumat.schema.Page>(),
                        state.formumatState.dataSchema.typesByKey, values
                    )
                    Spacer(modifier = Modifier.size(8.dp))
                }
                Column {
                    Text(
                        text = title,
                        style = MaterialTheme.typography.headlineLarge,
                        modifier = Modifier.padding(start = 2.dp)
                    )
                    if (subTitle != null) {
                        Text(
                            text = subTitle,
                            style = MaterialTheme.typography.headlineMedium,
                            modifier = Modifier.padding(start = 2.dp)
                        )
                    }
                }
            }
        },
        colors = TopAppBarDefaults.topAppBarColors(
            containerColor = MaterialTheme.colorScheme.primaryContainer
        ),
        actions = {
            if (needDrawer) {
                IconButton(
                    onClick = {
                        coroutineScope.launch {
                            if (drawerState.isClosed) {
                                drawerState.open()
                            } else {
                                drawerState.close()
                            }
                        }
                    },
                ) {
                    FaIcon(
                        faIcon = FaIcons.Bars,
                        size = 20.dp
                    )
                }
            }
        }
    )
}

@Composable
fun Footer(
    page: Page,
    currentDocument: Document?,
    wideView: Boolean,
) {
    BottomAppBar(
        modifier = if (wideView) {
            Modifier.fillMaxWidth().horizontalScroll(rememberScrollState())
        } else {
            Modifier.fillMaxWidth()
        }
    ) {
        Row(
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            Button(
                onClick = {
                    dispatch(SetCurrentDocument(null))
                    dispatch(ClearFormumat())
                    dispatch(ShowPage(Page.PAGE_DOCUMENTS))
                },
                contentPadding = PaddingValues(12.dp, 8.dp),
                modifier = Modifier.padding(start = 8.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
            ) {
                FaIcon(
                    faIcon = FaIcons.List,
                    size = 20.dp
                )
                if (wideView) {
                    Text(
                        text = stringResource(MR.strings.action_documents),
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }
            }

            Button(
                onClick = {
                    dispatch(SetCurrentDocument(null))
                    dispatch(ClearFormumat())
                    dispatch(ShowPage(Page.PAGE_NEW_DOCUMENT))
                },
                contentPadding = PaddingValues(12.dp, 8.dp),
                modifier = Modifier.padding(start = 8.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
            ) {
                FaIcon(
                    faIcon = FaIcons.Plus,
                    size = 20.dp
                )
                if (wideView) {
                    Text(
                        text = stringResource(MR.strings.action_new),
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }
            }

            Button(
                onClick = {
                    dispatch(SaveCurrentDocument())
                    dispatch(ClearFormumat())
                    dispatch(SetCurrentDocument(null))
                    dispatch(SetCurrentDocumentType(null))
                    dispatch(ShowPage(Page.PAGE_DOCUMENTS))
                },
                contentPadding = PaddingValues(12.dp, 8.dp),
                modifier = Modifier.padding(start = 8.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),
                enabled = currentDocument != null
            ) {
                FaIcon(
                    faIcon = FaIcons.Save,
                    size = 20.dp
                )
                if (wideView) {
                    Text(
                        text = stringResource(MR.strings.action_save),
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }
            }

            Button(
                onClick = {
                    dispatch(FinishCurrentDocument())
                    dispatch(ClearFormumat())
                    dispatch(SetCurrentDocument(null))
                    dispatch(ShowPage(Page.PAGE_DOCUMENTS))
                },
                contentPadding = PaddingValues(12.dp, 8.dp),
                modifier = Modifier.padding(start = 8.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp),
                enabled = currentDocument != null
            ) {
                FaIcon(
                    faIcon = FaIcons.CheckCircle,
                    size = 20.dp
                )
                if (wideView) {
                    Text(
                        text = stringResource(MR.strings.action_finish),
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }
            }

            Button(
                onClick = {
                    dispatch(RestSynchronize())
                    dispatch(ClearFormumat())
                    dispatch(ShowPage(Page.PAGE_DOCUMENTS))
                },
                contentPadding = PaddingValues(12.dp, 8.dp),
                modifier = Modifier.padding(start = 8.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
            ) {
                FaIcon(
                    faIcon = FaIcons.CloudUploadAlt,
                    size = 20.dp
                )
                if (wideView) {
                    Text(
                        text = stringResource(MR.strings.action_transfer),
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }
            }

            Button(
                onClick = {
                    if (page != Page.PAGE_INFOS_AND_SETTINGS) {
                        dispatch(ShowPage(Page.PAGE_INFOS_AND_SETTINGS))
                    } else {
                        dispatch(ShowPreviousPage())
                    }
                },
                contentPadding = PaddingValues(12.dp, 8.dp),
                modifier = Modifier.padding(start = 8.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
            ) {
                FaIcon(
                    faIcon = FaIcons.EllipsisV,
                    size = 20.dp
                )
                if (wideView) {
                    Text(
                        text = stringResource(MR.strings.action_options),
                        modifier = Modifier.padding(start = 8.dp)
                    )
                }
            }
        }
    }
}

@Composable
fun PageInfosAndSettings(
    @Suppress("UNUSED_PARAMETER") state: AppState,
    @Suppress("UNUSED_PARAMETER") dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    @Composable
    fun Separator() {
        HorizontalDivider(
            color = MaterialTheme.colorScheme.inverseOnSurface,
            thickness = 2.dp,
            modifier = Modifier.padding(top = 14.dp, bottom = 14.dp)
        )
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .verticalScroll(rememberScrollState())
            .padding(paddingValues = paddingValues)
    ) {
        Column(
            modifier = Modifier.padding(start = 18.dp, top = 20.dp)
        ) {
            Text(
                text = "dot.",
                style = MaterialTheme.typography.headlineLarge,
                modifier = Modifier.padding(bottom = 20.dp)
            )
            Text(
                text = "©2023-2024 steva GmbH",
                style = MaterialTheme.typography.headlineMedium,
                modifier = Modifier.padding(bottom = 14.dp)
            )
            Text(
                text = "dot. App Version: ${BuildConfig.PROJECT_VERSION}",
                style = MaterialTheme.typography.headlineSmall,
                modifier = Modifier.padding(bottom = 6.dp)
            )
            Text(
                text = "Formumat Public Version: ${FormumatPublicLibrary.VERSION}",
                style = MaterialTheme.typography.headlineSmall,
                modifier = Modifier.padding(bottom = 6.dp)
            )
            Text(
                text = "Formumat Nonpublic Version: ${FormumatNonpublicLibrary.VERSION}",
                style = MaterialTheme.typography.headlineSmall,
                modifier = Modifier.padding(bottom = 6.dp)
            )
            Text(
                text = "Multi Platform Expressions Version: ${com.github.murzagalin.evaluator.Library.VERSION}",
                style = MaterialTheme.typography.headlineSmall,
                modifier = Modifier.padding(bottom = 14.dp)
            )
        }
        Separator()
        Button(
            onClick = {
                digital.steva.dot.app.dispatch(SetCurrentDocument(null))
                digital.steva.dot.app.dispatch(ClearFormumat())
                digital.steva.dot.app.dispatch(RestLogout())
            },
            contentPadding = PaddingValues(12.dp, 8.dp),
            modifier = Modifier.padding(start = 14.dp).defaultMinSize(minWidth = 1.dp, minHeight = 1.dp)
        ) {
            FaIcon(
                faIcon = FaIcons.SignOutAlt,
                size = 20.dp
            )
            Text(
                text = stringResource(MR.strings.action_logout),
                modifier = Modifier.padding(start = 8.dp)
            )
        }
        Separator()
    }
}
