package digital.steva.dot.app.middlewares

import digital.steva.dot.app.AppState
import digital.steva.dot.app.ClearFormumat
import digital.steva.dot.app.DispatchFormumat
import digital.steva.dot.app.EMPTY_DATA_SCHEMA
import digital.steva.dot.app.EMPTY_UI_SCHEMA
import digital.steva.dot.app.EMPTY_VALUES
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.SetDataSchema
import digital.steva.formumat.redux.SetUiSchema
import digital.steva.formumat.redux.SetValues
import digital.steva.formumat.redux.parseValues
import digital.steva.formumat.schema.parseDataSchema
import digital.steva.formumat.schema.parseUiSchema
import org.reduxkotlin.Store
import org.reduxkotlin.middleware

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
object OtherStoresMiddleware {
    operator fun invoke(
        formumatStore: Store<FormumatState>
    ) = middleware<AppState> { store, next, action ->
        when (action) {
            is DispatchFormumat -> formumatStore.dispatch(action.formumatAction)

            is ClearFormumat -> {
                formumatStore.dispatch(SetDataSchema(parseDataSchema(EMPTY_DATA_SCHEMA)))
                formumatStore.dispatch(SetUiSchema(parseUiSchema(EMPTY_UI_SCHEMA)))
                formumatStore.dispatch(SetValues(parseValues(EMPTY_VALUES)))
            }

            else -> next(action)
        }
    }
}
