package digital.steva.dot.app

import androidx.compose.animation.Crossfade
import androidx.compose.animation.core.tween
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.Density
import co.touchlab.kermit.Logger
import co.touchlab.kermit.Severity
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.dot.app.theme.AppTheme
import digital.steva.dot.app.views.ErrorDialog
import digital.steva.dot.app.views.MainView
import digital.steva.dot.app.views.PageLogin
import digital.steva.dot.app.views.ProgressDialog
import digital.steva.dot.app.views.ProgressSpinner
import digital.steva.dot.app.views.SplashScreen
import digital.steva.dot.app.views.Toast
import digital.steva.formumat.FormumatNonpublic
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatAction
import digital.steva.formumat.redux.createFormumatStore
import kotlinx.coroutines.delay

//language=JSON
val EMPTY_DATA_SCHEMA = """{
  "type": "dataSchema",
  "properties": {
  }
}"""

//language=JSON
val EMPTY_UI_SCHEMA = """{
  "type": "uiSchema",
  "items": [
  ]
}"""

//language=JSON
val EMPTY_VALUES = """{
}
"""

val formumatStore by lazy { createFormumatStore(EMPTY_DATA_SCHEMA, EMPTY_UI_SCHEMA, EMPTY_VALUES) }

val appStore by lazy { createAppStore(formumatStore) }

fun dispatch(action: Any) = when (action) {
    is FormumatAction -> appStore.dispatch(DispatchFormumat(action))
    else -> appStore.dispatch(action)
}

@Composable
fun AppView() {
    @Suppress("UNUSED_VARIABLE") val configured by remember { mutableStateOf(FormumatNonpublic.configure()) }
    val store by remember { mutableStateOf(appStore) }
    var state by remember { mutableStateOf(store.state) }
    DisposableEffect(store) {
        Logger.setMinSeverity(Severity.Info)
        Logger.i { "Dot initialized" }
        val unsubscribe = store.subscribe {
            state = store.state
        }
        onDispose { unsubscribe() }
    }
    val dispatcher: Dispatcher = { dispatch(it) }

    if (state.zoomLevel > 0f) {
        CompositionLocalProvider(
            LocalDensity provides Density(
                state.zoomLevel,
                1.0f
            )
        ) {
            AppView(state, dispatcher)
        }
    } else {
        AppView(state, dispatcher)
    }
}

@Composable
fun AppView(
    state: AppState,
    dispatcher: Dispatcher
) {
    AppTheme(useDarkTheme = false) {
        if (state.showSplash) {
            LaunchedEffect(state.showSplash) {
                delay(1000)
                appStore.dispatch(HideSplash())
            }
        }

        Crossfade(
            targetState = state.showSplash,
            animationSpec = tween(1000),
        ) { showSplash ->
            if (showSplash) {
                SplashScreen()
            } else {
                when (state.currentPage) {
                    Page.PAGE_LOGIN -> PageLogin(state, dispatcher)
                    else -> MainView(state, dispatcher)
                }
            }
        }
        ProgressSpinner(state.showProgressSpinner)
        ProgressDialog(state.progress, dispatcher)
        ErrorDialog(state.error, dispatcher)
        if (state.toastMessage != null) {
            Toast(stringResource(state.toastMessage), dispatcher)
        }
    }
}
