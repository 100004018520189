package digital.steva.dot.app

import dev.icerock.moko.resources.StringResource
import digital.steva.dot.app.middlewares.MainMiddleware
import digital.steva.dot.app.middlewares.OtherStoresMiddleware
import digital.steva.dot.app.middlewares.RestMiddleware
import digital.steva.dot.app.reducers.MainReducer
import digital.steva.dot.app.domain.Document
import digital.steva.dot.app.domain.DocumentInfo
import digital.steva.dot.app.domain.DocumentType
import digital.steva.dot.app.domain.DocumentTypeInfo
import digital.steva.formumat.redux.FormumatAction
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatStore
import digital.steva.formumat.redux.createFormumatState
import kotlinx.collections.immutable.PersistentList
import kotlinx.collections.immutable.persistentListOf
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import org.reduxkotlin.Store
import org.reduxkotlin.applyMiddleware
import org.reduxkotlin.threadsafe.createThreadSafeStore

data class Progress(
    val title: Any,
    val mainMessage: Any? = null,
    val mainProgress: Float? = null,
    val subMessage: Any? = null,
    val subProgress: Float? = null,
    val summary: ProgressSummary? = null
)

class ProgressSummary {
    sealed class Entry
    class Success(val res: StringResource, vararg val args: Any) : Entry()
    class Failure(val res: StringResource, vararg val args: Any) : Entry()

    val entries = mutableListOf<Entry>()

    val hasErrors: Boolean
        get() {
            return entries.count { it is Failure } > 0
        }

    fun addSuccess(res: StringResource, vararg args: Any) {
        entries.add(Success(res, *args))
    }

    fun addFailure(res: StringResource, vararg args: Any) {
        entries.add(Failure(res, *args))
    }

    inline fun forEachEntry(action: (Entry) -> Unit) = entries.forEach(action)
}

data class ErrorMessage(
    val res: StringResource,
    val args: List<Any> = listOf()
)

enum class Page(val title: StringResource) {
    PAGE_LOGIN(MR.strings.page_login),
    PAGE_FORMUMAT(MR.strings.page_formumat),
    PAGE_DOCUMENTS(MR.strings.page_documents),
    PAGE_DOCUMENT(MR.strings.page_document),
    PAGE_NEW_DOCUMENT(MR.strings.page_new_document),
    PAGE_INFOS_AND_SETTINGS(MR.strings.page_infos_and_settings);
}

@Serializable
data class Login(
    val serverUrl: String = "",
    val username: String = "",
    val password: String = ""
) {
    @Transient
    val isValid: Boolean = serverUrl.isPresent() && username.isPresent() && password.isPresent()
}

@Serializable
data class AppState(
    val login: Login = Login(
        serverUrl = BuildConfig.DEFAULT_SERVER_URL,
        username = BuildConfig.DEFAULT_USERNAME,
        password = BuildConfig.DEFAULT_PASSWORD,
    ),
    @Transient
    val documentTypesInfos: PersistentList<DocumentTypeInfo> = persistentListOf(),
    @Transient
    val documentsInfos: PersistentList<DocumentInfo> = persistentListOf(),
    @Transient
    val formumatState: FormumatState = createFormumatState(EMPTY_DATA_SCHEMA, EMPTY_UI_SCHEMA, EMPTY_VALUES),
    @Transient
    val currentPage: Page = Page.PAGE_LOGIN,
    @Transient
    val previousPage: Page = Page.PAGE_INFOS_AND_SETTINGS,
    @Transient
    val currentDocumentType: DocumentType? = null,
    @Transient
    val currentDocument: Document? = null,
    @Transient
    val zoomLevel: Float = 0f,
    @Transient
    val showSplash: Boolean = true,
    @Transient
    val toastMessage: StringResource? = null,
    @Transient
    val error: ErrorMessage? = null,
    @Transient
    val progress: Progress? = null,
    @Transient
    val showProgressSpinner: Boolean = false,
)

open class AppAction

data class SetZoomLevel(val zoomLevel: Float) : AppAction()
class HideSplash : AppAction()
data class DispatchFormumat(val formumatAction: FormumatAction) : AppAction()
data class SetFormumatState(val formumatState: FormumatState) : AppAction()
class ClearFormumat : AppAction()
data class SetDocumentTypesInfos(val documentTypesInfos: PersistentList<DocumentTypeInfo>) : AppAction()
data class SetDocumentsInfos(val documentsInfos: PersistentList<DocumentInfo>) : AppAction()
data class AddDocument(val document: Document) : AppAction()
data class SetCurrentDocumentType(val documentType: DocumentType?) : AppAction()
data class SetCurrentDocument(val document: Document?) : AppAction()
class SaveCurrentDocument : AppAction()
class FinishCurrentDocument : AppAction()
data class ShowPage(val page: Page) : AppAction()
class ShowPreviousPage : AppAction()
data class SetLogin(val login: Login) : AppAction()
data class ShowError(val error: Any?) : AppAction()
data class RestLogin(val login: Login) : AppAction()
class RestLogout : AppAction()
class RestSynchronize : AppAction()
data class ShowToast(val message: StringResource?) : AppAction()
class ShowProgressSpinner : AppAction()
class HideProgressSpinner : AppAction()
data class ShowProgress(val progress: Progress?) : AppAction()
class InitializeFromStorage : AppAction()
data class LoadDocumentTypeFromStorage(val id: String) : AppAction()
data class LoadDocumentFromStorage(val id: String) : AppAction()

fun createAppStore(formumatStore: FormumatStore): Store<AppState> {
    return createThreadSafeStore(
        MainReducer(),
        AppState(formumatState = formumatStore.state),
        applyMiddleware(
            OtherStoresMiddleware(formumatStore),
            MainMiddleware(),
            RestMiddleware()
        )
    ).apply {
        formumatStore.subscribe {
            dispatch(SetFormumatState(formumatStore.state))
        }
    }
}
