package digital.steva.formumat.fontawesome

import digital.steva.formumat.fontawesome.FaIconType.*

/**
 * FaIcons is collection of all the icons from font awesome free icon pack
 * It includes mainly three styles SOLID, BRAND, REGULAR
 *
 * Most icons are solid(1000+) then branded(250+) then regular(150+) at last.
 *
 * Default icons are solid as they are the most icons eg: AddressBook
 * Regular icons are SUFFIXED with "Regular" eg: AddressBookRegular
 * Brand icons are available as well eg: Airbnb, amazon
 */

object FaIcons {

    // https://fontawesome.com/icons/accessible-icon?style=brands
    // Brands icon : AccessibleIcon
    @Suppress("unused") val AccessibleIcon = BrandIcon(0xf368)

    // https://fontawesome.com/icons/accusoft?style=brands
    // Brands icon : Accusoft
    @Suppress("unused") val Accusoft = BrandIcon(0xf369)

    // https://fontawesome.com/icons/acquisitions-incorporated?style=brands
    // Brands icon : AcquisitionsIncorporated
    @Suppress("unused") val AcquisitionsIncorporated = BrandIcon(0xf6af)

    // https://fontawesome.com/icons/ad?style=solid
    // Solid icon : Ad
    @Suppress("unused") val Ad = SolidIcon(0xf641)

    // https://fontawesome.com/icons/address-book?style=solid
    // Solid icon : AddressBook
    @Suppress("unused") val AddressBook = SolidIcon(0xf2b9)

    // https://fontawesome.com/icons/address-book?style=regular
    // Regular icon : AddressBook
    @Suppress("unused") val AddressBookRegular = RegularIcon(0xf2b9)

    // https://fontawesome.com/icons/address-card?style=solid
    // Solid icon : AddressCard
    @Suppress("unused") val AddressCard = SolidIcon(0xf2bb)

    // https://fontawesome.com/icons/address-card?style=regular
    // Regular icon : AddressCard
    @Suppress("unused") val AddressCardRegular = RegularIcon(0xf2bb)

    // https://fontawesome.com/icons/adjust?style=solid
    // Solid icon : Adjust
    @Suppress("unused") val Adjust = SolidIcon(0xf042)

    // https://fontawesome.com/icons/adn?style=brands
    // Brands icon : Adn
    @Suppress("unused") val Adn = BrandIcon(0xf170)

    // https://fontawesome.com/icons/adversal?style=brands
    // Brands icon : Adversal
    @Suppress("unused") val Adversal = BrandIcon(0xf36a)

    // https://fontawesome.com/icons/affiliatetheme?style=brands
    // Brands icon : Affiliatetheme
    @Suppress("unused") val Affiliatetheme = BrandIcon(0xf36b)

    // https://fontawesome.com/icons/air-freshener?style=solid
    // Solid icon : AirFreshener
    @Suppress("unused") val AirFreshener = SolidIcon(0xf5d0)

    // https://fontawesome.com/icons/airbnb?style=brands
    // Brands icon : Airbnb
    @Suppress("unused") val Airbnb = BrandIcon(0xf834)

    // https://fontawesome.com/icons/algolia?style=brands
    // Brands icon : Algolia
    @Suppress("unused") val Algolia = BrandIcon(0xf36c)

    // https://fontawesome.com/icons/align-center?style=solid
    // Solid icon : AlignCenter
    @Suppress("unused") val AlignCenter = SolidIcon(0xf037)

    // https://fontawesome.com/icons/align-justify?style=solid
    // Solid icon : AlignJustify
    @Suppress("unused") val AlignJustify = SolidIcon(0xf039)

    // https://fontawesome.com/icons/align-left?style=solid
    // Solid icon : AlignLeft
    @Suppress("unused") val AlignLeft = SolidIcon(0xf036)

    // https://fontawesome.com/icons/align-right?style=solid
    // Solid icon : AlignRight
    @Suppress("unused") val AlignRight = SolidIcon(0xf038)

    // https://fontawesome.com/icons/alipay?style=brands
    // Brands icon : Alipay
    @Suppress("unused") val Alipay = BrandIcon(0xf642)

    // https://fontawesome.com/icons/allergies?style=solid
    // Solid icon : Allergies
    @Suppress("unused") val Allergies = SolidIcon(0xf461)

    // https://fontawesome.com/icons/amazon?style=brands
    // Brands icon : Amazon
    @Suppress("unused") val Amazon = BrandIcon(0xf270)

    // https://fontawesome.com/icons/amazon-pay?style=brands
    // Brands icon : AmazonPay
    @Suppress("unused") val AmazonPay = BrandIcon(0xf42c)

    // https://fontawesome.com/icons/ambulance?style=solid
    // Solid icon : Ambulance
    @Suppress("unused") val Ambulance = SolidIcon(0xf0f9)

    // https://fontawesome.com/icons/american-sign-language-interpreting?style=solid
    // Solid icon : AmericanSignLanguageInterpreting
    @Suppress("unused") val AmericanSignLanguageInterpreting = SolidIcon(0xf2a3)

    // https://fontawesome.com/icons/amilia?style=brands
    // Brands icon : Amilia
    @Suppress("unused") val Amilia = BrandIcon(0xf36d)

    // https://fontawesome.com/icons/anchor?style=solid
    // Solid icon : Anchor
    @Suppress("unused") val Anchor = SolidIcon(0xf13d)

    // https://fontawesome.com/icons/android?style=brands
    // Brands icon : Android
    @Suppress("unused") val Android = BrandIcon(0xf17b)

    // https://fontawesome.com/icons/angellist?style=brands
    // Brands icon : Angellist
    @Suppress("unused") val Angellist = BrandIcon(0xf209)

    // https://fontawesome.com/icons/angle-double-down?style=solid
    // Solid icon : AngleDoubleDown
    @Suppress("unused") val AngleDoubleDown = SolidIcon(0xf103)

    // https://fontawesome.com/icons/angle-double-left?style=solid
    // Solid icon : AngleDoubleLeft
    @Suppress("unused") val AngleDoubleLeft = SolidIcon(0xf100)

    // https://fontawesome.com/icons/angle-double-right?style=solid
    // Solid icon : AngleDoubleRight
    @Suppress("unused") val AngleDoubleRight = SolidIcon(0xf101)

    // https://fontawesome.com/icons/angle-double-up?style=solid
    // Solid icon : AngleDoubleUp
    @Suppress("unused") val AngleDoubleUp = SolidIcon(0xf102)

    // https://fontawesome.com/icons/angle-down?style=solid
    // Solid icon : AngleDown
    @Suppress("unused") val AngleDown = SolidIcon(0xf107)

    // https://fontawesome.com/icons/angle-left?style=solid
    // Solid icon : AngleLeft
    @Suppress("unused") val AngleLeft = SolidIcon(0xf104)

    // https://fontawesome.com/icons/angle-right?style=solid
    // Solid icon : AngleRight
    @Suppress("unused") val AngleRight = SolidIcon(0xf105)

    // https://fontawesome.com/icons/angle-up?style=solid
    // Solid icon : AngleUp
    @Suppress("unused") val AngleUp = SolidIcon(0xf106)

    // https://fontawesome.com/icons/angry?style=solid
    // Solid icon : Angry
    @Suppress("unused") val Angry = SolidIcon(0xf556)

    // https://fontawesome.com/icons/angry?style=regular
    // Regular icon : Angry
    @Suppress("unused") val AngryRegular = RegularIcon(0xf556)

    // https://fontawesome.com/icons/angrycreative?style=brands
    // Brands icon : Angrycreative
    @Suppress("unused") val Angrycreative = BrandIcon(0xf36e)

    // https://fontawesome.com/icons/angular?style=brands
    // Brands icon : Angular
    @Suppress("unused") val Angular = BrandIcon(0xf420)

    // https://fontawesome.com/icons/ankh?style=solid
    // Solid icon : Ankh
    @Suppress("unused") val Ankh = SolidIcon(0xf644)

    // https://fontawesome.com/icons/app-store?style=brands
    // Brands icon : AppStore
    @Suppress("unused") val AppStore = BrandIcon(0xf36f)

    // https://fontawesome.com/icons/app-store-ios?style=brands
    // Brands icon : AppStoreIos
    @Suppress("unused") val AppStoreIos = BrandIcon(0xf370)

    // https://fontawesome.com/icons/apper?style=brands
    // Brands icon : Apper
    @Suppress("unused") val Apper = BrandIcon(0xf371)

    // https://fontawesome.com/icons/apple?style=brands
    // Brands icon : Apple
    @Suppress("unused") val Apple = BrandIcon(0xf179)

    // https://fontawesome.com/icons/apple-alt?style=solid
    // Solid icon : AppleAlt
    @Suppress("unused") val AppleAlt = SolidIcon(0xf5d1)

    // https://fontawesome.com/icons/apple-pay?style=brands
    // Brands icon : ApplePay
    @Suppress("unused") val ApplePay = BrandIcon(0xf415)

    // https://fontawesome.com/icons/archive?style=solid
    // Solid icon : Archive
    @Suppress("unused") val Archive = SolidIcon(0xf187)

    // https://fontawesome.com/icons/archway?style=solid
    // Solid icon : Archway
    @Suppress("unused") val Archway = SolidIcon(0xf557)

    // https://fontawesome.com/icons/arrow-alt-circle-down?style=solid
    // Solid icon : ArrowAltCircleDown
    @Suppress("unused") val ArrowAltCircleDown = SolidIcon(0xf358)

    // https://fontawesome.com/icons/arrow-alt-circle-down?style=regular
    // Regular icon : ArrowAltCircleDown
    @Suppress("unused") val ArrowAltCircleDownRegular = RegularIcon(0xf358)

    // https://fontawesome.com/icons/arrow-alt-circle-left?style=solid
    // Solid icon : ArrowAltCircleLeft
    @Suppress("unused") val ArrowAltCircleLeft = SolidIcon(0xf359)

    // https://fontawesome.com/icons/arrow-alt-circle-left?style=regular
    // Regular icon : ArrowAltCircleLeft
    @Suppress("unused") val ArrowAltCircleLeftRegular = RegularIcon(0xf359)

    // https://fontawesome.com/icons/arrow-alt-circle-right?style=solid
    // Solid icon : ArrowAltCircleRight
    @Suppress("unused") val ArrowAltCircleRight = SolidIcon(0xf35a)

    // https://fontawesome.com/icons/arrow-alt-circle-right?style=regular
    // Regular icon : ArrowAltCircleRight
    @Suppress("unused") val ArrowAltCircleRightRegular = RegularIcon(0xf35a)

    // https://fontawesome.com/icons/arrow-alt-circle-up?style=solid
    // Solid icon : ArrowAltCircleUp
    @Suppress("unused") val ArrowAltCircleUp = SolidIcon(0xf35b)

    // https://fontawesome.com/icons/arrow-alt-circle-up?style=regular
    // Regular icon : ArrowAltCircleUp
    @Suppress("unused") val ArrowAltCircleUpRegular = RegularIcon(0xf35b)

    // https://fontawesome.com/icons/arrow-circle-down?style=solid
    // Solid icon : ArrowCircleDown
    @Suppress("unused") val ArrowCircleDown = SolidIcon(0xf0ab)

    // https://fontawesome.com/icons/arrow-circle-left?style=solid
    // Solid icon : ArrowCircleLeft
    @Suppress("unused") val ArrowCircleLeft = SolidIcon(0xf0a8)

    // https://fontawesome.com/icons/arrow-circle-right?style=solid
    // Solid icon : ArrowCircleRight
    @Suppress("unused") val ArrowCircleRight = SolidIcon(0xf0a9)

    // https://fontawesome.com/icons/arrow-circle-up?style=solid
    // Solid icon : ArrowCircleUp
    @Suppress("unused") val ArrowCircleUp = SolidIcon(0xf0aa)

    // https://fontawesome.com/icons/arrow-down?style=solid
    // Solid icon : ArrowDown
    @Suppress("unused") val ArrowDown = SolidIcon(0xf063)

    // https://fontawesome.com/icons/arrow-left?style=solid
    // Solid icon : ArrowLeft
    @Suppress("unused") val ArrowLeft = SolidIcon(0xf060)

    // https://fontawesome.com/icons/arrow-right?style=solid
    // Solid icon : ArrowRight
    @Suppress("unused") val ArrowRight = SolidIcon(0xf061)

    // https://fontawesome.com/icons/arrow-up?style=solid
    // Solid icon : ArrowUp
    @Suppress("unused") val ArrowUp = SolidIcon(0xf062)

    // https://fontawesome.com/icons/arrows-alt?style=solid
    // Solid icon : ArrowsAlt
    @Suppress("unused") val ArrowsAlt = SolidIcon(0xf0b2)

    // https://fontawesome.com/icons/arrows-alt-h?style=solid
    // Solid icon : ArrowsAltH
    @Suppress("unused") val ArrowsAltH = SolidIcon(0xf337)

    // https://fontawesome.com/icons/arrows-alt-v?style=solid
    // Solid icon : ArrowsAltV
    @Suppress("unused") val ArrowsAltV = SolidIcon(0xf338)

    // https://fontawesome.com/icons/artstation?style=brands
    // Brands icon : Artstation
    @Suppress("unused") val Artstation = BrandIcon(0xf77a)

    // https://fontawesome.com/icons/assistive-listening-systems?style=solid
    // Solid icon : AssistiveListeningSystems
    @Suppress("unused") val AssistiveListeningSystems = SolidIcon(0xf2a2)

    // https://fontawesome.com/icons/asterisk?style=solid
    // Solid icon : Asterisk
    @Suppress("unused") val Asterisk = SolidIcon(0xf069)

    // https://fontawesome.com/icons/asymmetrik?style=brands
    // Brands icon : Asymmetrik
    @Suppress("unused") val Asymmetrik = BrandIcon(0xf372)

    // https://fontawesome.com/icons/at?style=solid
    // Solid icon : At
    @Suppress("unused") val At = SolidIcon(0xf1fa)

    // https://fontawesome.com/icons/atlas?style=solid
    // Solid icon : Atlas
    @Suppress("unused") val Atlas = SolidIcon(0xf558)

    // https://fontawesome.com/icons/atlassian?style=brands
    // Brands icon : Atlassian
    @Suppress("unused") val Atlassian = BrandIcon(0xf77b)

    // https://fontawesome.com/icons/atom?style=solid
    // Solid icon : Atom
    @Suppress("unused") val Atom = SolidIcon(0xf5d2)

    // https://fontawesome.com/icons/audible?style=brands
    // Brands icon : Audible
    @Suppress("unused") val Audible = BrandIcon(0xf373)

    // https://fontawesome.com/icons/audio-description?style=solid
    // Solid icon : AudioDescription
    @Suppress("unused") val AudioDescription = SolidIcon(0xf29e)

    // https://fontawesome.com/icons/autoprefixer?style=brands
    // Brands icon : Autoprefixer
    @Suppress("unused") val Autoprefixer = BrandIcon(0xf41c)

    // https://fontawesome.com/icons/avianex?style=brands
    // Brands icon : Avianex
    @Suppress("unused") val Avianex = BrandIcon(0xf374)

    // https://fontawesome.com/icons/aviato?style=brands
    // Brands icon : Aviato
    @Suppress("unused") val Aviato = BrandIcon(0xf421)

    // https://fontawesome.com/icons/award?style=solid
    // Solid icon : Award
    @Suppress("unused") val Award = SolidIcon(0xf559)

    // https://fontawesome.com/icons/aws?style=brands
    // Brands icon : Aws
    @Suppress("unused") val Aws = BrandIcon(0xf375)

    // https://fontawesome.com/icons/baby?style=solid
    // Solid icon : Baby
    @Suppress("unused") val Baby = SolidIcon(0xf77c)

    // https://fontawesome.com/icons/baby-carriage?style=solid
    // Solid icon : BabyCarriage
    @Suppress("unused") val BabyCarriage = SolidIcon(0xf77d)

    // https://fontawesome.com/icons/backspace?style=solid
    // Solid icon : Backspace
    @Suppress("unused") val Backspace = SolidIcon(0xf55a)

    // https://fontawesome.com/icons/backward?style=solid
    // Solid icon : Backward
    @Suppress("unused") val Backward = SolidIcon(0xf04a)

    // https://fontawesome.com/icons/bacon?style=solid
    // Solid icon : Bacon
    @Suppress("unused") val Bacon = SolidIcon(0xf7e5)

    // https://fontawesome.com/icons/bacteria?style=solid
    // Solid icon : Bacteria
    @Suppress("unused") val Bacteria = SolidIcon(0xe059)

    // https://fontawesome.com/icons/bacterium?style=solid
    // Solid icon : Bacterium
    @Suppress("unused") val Bacterium = SolidIcon(0xe05a)

    // https://fontawesome.com/icons/bahai?style=solid
    // Solid icon : Bahai
    @Suppress("unused") val Bahai = SolidIcon(0xf666)

    // https://fontawesome.com/icons/balance-scale?style=solid
    // Solid icon : BalanceScale
    @Suppress("unused") val BalanceScale = SolidIcon(0xf24e)

    // https://fontawesome.com/icons/balance-scale-left?style=solid
    // Solid icon : BalanceScaleLeft
    @Suppress("unused") val BalanceScaleLeft = SolidIcon(0xf515)

    // https://fontawesome.com/icons/balance-scale-right?style=solid
    // Solid icon : BalanceScaleRight
    @Suppress("unused") val BalanceScaleRight = SolidIcon(0xf516)

    // https://fontawesome.com/icons/ban?style=solid
    // Solid icon : Ban
    @Suppress("unused") val Ban = SolidIcon(0xf05e)

    // https://fontawesome.com/icons/band-aid?style=solid
    // Solid icon : BandAid
    @Suppress("unused") val BandAid = SolidIcon(0xf462)

    // https://fontawesome.com/icons/bandcamp?style=brands
    // Brands icon : Bandcamp
    @Suppress("unused") val Bandcamp = BrandIcon(0xf2d5)

    // https://fontawesome.com/icons/barcode?style=solid
    // Solid icon : Barcode
    @Suppress("unused") val Barcode = SolidIcon(0xf02a)

    // https://fontawesome.com/icons/bars?style=solid
    // Solid icon : Bars
    @Suppress("unused") val Bars = SolidIcon(0xf0c9)

    // https://fontawesome.com/icons/baseball-ball?style=solid
    // Solid icon : BaseballBall
    @Suppress("unused") val BaseballBall = SolidIcon(0xf433)

    // https://fontawesome.com/icons/basketball-ball?style=solid
    // Solid icon : BasketballBall
    @Suppress("unused") val BasketballBall = SolidIcon(0xf434)

    // https://fontawesome.com/icons/bath?style=solid
    // Solid icon : Bath
    @Suppress("unused") val Bath = SolidIcon(0xf2cd)

    // https://fontawesome.com/icons/battery-empty?style=solid
    // Solid icon : BatteryEmpty
    @Suppress("unused") val BatteryEmpty = SolidIcon(0xf244)

    // https://fontawesome.com/icons/battery-full?style=solid
    // Solid icon : BatteryFull
    @Suppress("unused") val BatteryFull = SolidIcon(0xf240)

    // https://fontawesome.com/icons/battery-half?style=solid
    // Solid icon : BatteryHalf
    @Suppress("unused") val BatteryHalf = SolidIcon(0xf242)

    // https://fontawesome.com/icons/battery-quarter?style=solid
    // Solid icon : BatteryQuarter
    @Suppress("unused") val BatteryQuarter = SolidIcon(0xf243)

    // https://fontawesome.com/icons/battery-three-quarters?style=solid
    // Solid icon : BatteryThreeQuarters
    @Suppress("unused") val BatteryThreeQuarters = SolidIcon(0xf241)

    // https://fontawesome.com/icons/battle-net?style=brands
    // Brands icon : BattleNet
    @Suppress("unused") val BattleNet = BrandIcon(0xf835)

    // https://fontawesome.com/icons/bed?style=solid
    // Solid icon : Bed
    @Suppress("unused") val Bed = SolidIcon(0xf236)

    // https://fontawesome.com/icons/beer?style=solid
    // Solid icon : Beer
    @Suppress("unused") val Beer = SolidIcon(0xf0fc)

    // https://fontawesome.com/icons/behance?style=brands
    // Brands icon : Behance
    @Suppress("unused") val Behance = BrandIcon(0xf1b4)

    // https://fontawesome.com/icons/behance-square?style=brands
    // Brands icon : BehanceSquare
    @Suppress("unused") val BehanceSquare = BrandIcon(0xf1b5)

    // https://fontawesome.com/icons/bell?style=solid
    // Solid icon : Bell
    @Suppress("unused") val Bell = SolidIcon(0xf0f3)

    // https://fontawesome.com/icons/bell?style=regular
    // Regular icon : Bell
    @Suppress("unused") val BellRegular = RegularIcon(0xf0f3)

    // https://fontawesome.com/icons/bell-slash?style=solid
    // Solid icon : BellSlash
    @Suppress("unused") val BellSlash = SolidIcon(0xf1f6)

    // https://fontawesome.com/icons/bell-slash?style=regular
    // Regular icon : BellSlash
    @Suppress("unused") val BellSlashRegular = RegularIcon(0xf1f6)

    // https://fontawesome.com/icons/bezier-curve?style=solid
    // Solid icon : BezierCurve
    @Suppress("unused") val BezierCurve = SolidIcon(0xf55b)

    // https://fontawesome.com/icons/bible?style=solid
    // Solid icon : Bible
    @Suppress("unused") val Bible = SolidIcon(0xf647)

    // https://fontawesome.com/icons/bicycle?style=solid
    // Solid icon : Bicycle
    @Suppress("unused") val Bicycle = SolidIcon(0xf206)

    // https://fontawesome.com/icons/biking?style=solid
    // Solid icon : Biking
    @Suppress("unused") val Biking = SolidIcon(0xf84a)

    // https://fontawesome.com/icons/bimobject?style=brands
    // Brands icon : Bimobject
    @Suppress("unused") val Bimobject = BrandIcon(0xf378)

    // https://fontawesome.com/icons/binoculars?style=solid
    // Solid icon : Binoculars
    @Suppress("unused") val Binoculars = SolidIcon(0xf1e5)

    // https://fontawesome.com/icons/biohazard?style=solid
    // Solid icon : Biohazard
    @Suppress("unused") val Biohazard = SolidIcon(0xf780)

    // https://fontawesome.com/icons/birthday-cake?style=solid
    // Solid icon : BirthdayCake
    @Suppress("unused") val BirthdayCake = SolidIcon(0xf1fd)

    // https://fontawesome.com/icons/bitbucket?style=brands
    // Brands icon : Bitbucket
    @Suppress("unused") val Bitbucket = BrandIcon(0xf171)

    // https://fontawesome.com/icons/bitcoin?style=brands
    // Brands icon : Bitcoin
    @Suppress("unused") val Bitcoin = BrandIcon(0xf379)

    // https://fontawesome.com/icons/bity?style=brands
    // Brands icon : Bity
    @Suppress("unused") val Bity = BrandIcon(0xf37a)

    // https://fontawesome.com/icons/black-tie?style=brands
    // Brands icon : BlackTie
    @Suppress("unused") val BlackTie = BrandIcon(0xf27e)

    // https://fontawesome.com/icons/blackberry?style=brands
    // Brands icon : Blackberry
    @Suppress("unused") val Blackberry = BrandIcon(0xf37b)

    // https://fontawesome.com/icons/blender?style=solid
    // Solid icon : Blender
    @Suppress("unused") val Blender = SolidIcon(0xf517)

    // https://fontawesome.com/icons/blender-phone?style=solid
    // Solid icon : BlenderPhone
    @Suppress("unused") val BlenderPhone = SolidIcon(0xf6b6)

    // https://fontawesome.com/icons/blind?style=solid
    // Solid icon : Blind
    @Suppress("unused") val Blind = SolidIcon(0xf29d)

    // https://fontawesome.com/icons/blog?style=solid
    // Solid icon : Blog
    @Suppress("unused") val Blog = SolidIcon(0xf781)

    // https://fontawesome.com/icons/blogger?style=brands
    // Brands icon : Blogger
    @Suppress("unused") val Blogger = BrandIcon(0xf37c)

    // https://fontawesome.com/icons/blogger-b?style=brands
    // Brands icon : BloggerB
    @Suppress("unused") val BloggerB = BrandIcon(0xf37d)

    // https://fontawesome.com/icons/bluetooth?style=brands
    // Brands icon : Bluetooth
    @Suppress("unused") val Bluetooth = BrandIcon(0xf293)

    // https://fontawesome.com/icons/bluetooth-b?style=brands
    // Brands icon : BluetoothB
    @Suppress("unused") val BluetoothB = BrandIcon(0xf294)

    // https://fontawesome.com/icons/bold?style=solid
    // Solid icon : Bold
    @Suppress("unused") val Bold = SolidIcon(0xf032)

    // https://fontawesome.com/icons/bolt?style=solid
    // Solid icon : Bolt
    @Suppress("unused") val Bolt = SolidIcon(0xf0e7)

    // https://fontawesome.com/icons/bomb?style=solid
    // Solid icon : Bomb
    @Suppress("unused") val Bomb = SolidIcon(0xf1e2)

    // https://fontawesome.com/icons/bone?style=solid
    // Solid icon : Bone
    @Suppress("unused") val Bone = SolidIcon(0xf5d7)

    // https://fontawesome.com/icons/bong?style=solid
    // Solid icon : Bong
    @Suppress("unused") val Bong = SolidIcon(0xf55c)

    // https://fontawesome.com/icons/book?style=solid
    // Solid icon : Book
    @Suppress("unused") val Book = SolidIcon(0xf02d)

    // https://fontawesome.com/icons/book-dead?style=solid
    // Solid icon : BookDead
    @Suppress("unused") val BookDead = SolidIcon(0xf6b7)

    // https://fontawesome.com/icons/book-medical?style=solid
    // Solid icon : BookMedical
    @Suppress("unused") val BookMedical = SolidIcon(0xf7e6)

    // https://fontawesome.com/icons/book-open?style=solid
    // Solid icon : BookOpen
    @Suppress("unused") val BookOpen = SolidIcon(0xf518)

    // https://fontawesome.com/icons/book-reader?style=solid
    // Solid icon : BookReader
    @Suppress("unused") val BookReader = SolidIcon(0xf5da)

    // https://fontawesome.com/icons/bookmark?style=solid
    // Solid icon : Bookmark
    @Suppress("unused") val Bookmark = SolidIcon(0xf02e)

    // https://fontawesome.com/icons/bookmark?style=regular
    // Regular icon : Bookmark
    @Suppress("unused") val BookmarkRegular = RegularIcon(0xf02e)

    // https://fontawesome.com/icons/bootstrap?style=brands
    // Brands icon : Bootstrap
    @Suppress("unused") val Bootstrap = BrandIcon(0xf836)

    // https://fontawesome.com/icons/border-all?style=solid
    // Solid icon : BorderAll
    @Suppress("unused") val BorderAll = SolidIcon(0xf84c)

    // https://fontawesome.com/icons/border-none?style=solid
    // Solid icon : BorderNone
    @Suppress("unused") val BorderNone = SolidIcon(0xf850)

    // https://fontawesome.com/icons/border-style?style=solid
    // Solid icon : BorderStyle
    @Suppress("unused") val BorderStyle = SolidIcon(0xf853)

    // https://fontawesome.com/icons/bowling-ball?style=solid
    // Solid icon : BowlingBall
    @Suppress("unused") val BowlingBall = SolidIcon(0xf436)

    // https://fontawesome.com/icons/box?style=solid
    // Solid icon : Box
    @Suppress("unused") val Box = SolidIcon(0xf466)

    // https://fontawesome.com/icons/box-open?style=solid
    // Solid icon : BoxOpen
    @Suppress("unused") val BoxOpen = SolidIcon(0xf49e)

    // https://fontawesome.com/icons/box-tissue?style=solid
    // Solid icon : BoxTissue
    @Suppress("unused") val BoxTissue = SolidIcon(0xe05b)

    // https://fontawesome.com/icons/boxes?style=solid
    // Solid icon : Boxes
    @Suppress("unused") val Boxes = SolidIcon(0xf468)

    // https://fontawesome.com/icons/braille?style=solid
    // Solid icon : Braille
    @Suppress("unused") val Braille = SolidIcon(0xf2a1)

    // https://fontawesome.com/icons/brain?style=solid
    // Solid icon : Brain
    @Suppress("unused") val Brain = SolidIcon(0xf5dc)

    // https://fontawesome.com/icons/bread-slice?style=solid
    // Solid icon : BreadSlice
    @Suppress("unused") val BreadSlice = SolidIcon(0xf7ec)

    // https://fontawesome.com/icons/briefcase?style=solid
    // Solid icon : Briefcase
    @Suppress("unused") val Briefcase = SolidIcon(0xf0b1)

    // https://fontawesome.com/icons/briefcase-medical?style=solid
    // Solid icon : BriefcaseMedical
    @Suppress("unused") val BriefcaseMedical = SolidIcon(0xf469)

    // https://fontawesome.com/icons/broadcast-tower?style=solid
    // Solid icon : BroadcastTower
    @Suppress("unused") val BroadcastTower = SolidIcon(0xf519)

    // https://fontawesome.com/icons/broom?style=solid
    // Solid icon : Broom
    @Suppress("unused") val Broom = SolidIcon(0xf51a)

    // https://fontawesome.com/icons/brush?style=solid
    // Solid icon : Brush
    @Suppress("unused") val Brush = SolidIcon(0xf55d)

    // https://fontawesome.com/icons/btc?style=brands
    // Brands icon : Btc
    @Suppress("unused") val Btc = BrandIcon(0xf15a)

    // https://fontawesome.com/icons/buffer?style=brands
    // Brands icon : Buffer
    @Suppress("unused") val Buffer = BrandIcon(0xf837)

    // https://fontawesome.com/icons/bug?style=solid
    // Solid icon : Bug
    @Suppress("unused") val Bug = SolidIcon(0xf188)

    // https://fontawesome.com/icons/building?style=solid
    // Solid icon : Building
    @Suppress("unused") val Building = SolidIcon(0xf1ad)

    // https://fontawesome.com/icons/building?style=regular
    // Regular icon : Building
    @Suppress("unused") val BuildingRegular = RegularIcon(0xf1ad)

    // https://fontawesome.com/icons/bullhorn?style=solid
    // Solid icon : Bullhorn
    @Suppress("unused") val Bullhorn = SolidIcon(0xf0a1)

    // https://fontawesome.com/icons/bullseye?style=solid
    // Solid icon : Bullseye
    @Suppress("unused") val Bullseye = SolidIcon(0xf140)

    // https://fontawesome.com/icons/burn?style=solid
    // Solid icon : Burn
    @Suppress("unused") val Burn = SolidIcon(0xf46a)

    // https://fontawesome.com/icons/buromobelexperte?style=brands
    // Brands icon : Buromobelexperte
    @Suppress("unused") val Buromobelexperte = BrandIcon(0xf37f)

    // https://fontawesome.com/icons/bus?style=solid
    // Solid icon : Bus
    @Suppress("unused") val Bus = SolidIcon(0xf207)

    // https://fontawesome.com/icons/bus-alt?style=solid
    // Solid icon : BusAlt
    @Suppress("unused") val BusAlt = SolidIcon(0xf55e)

    // https://fontawesome.com/icons/business-time?style=solid
    // Solid icon : BusinessTime
    @Suppress("unused") val BusinessTime = SolidIcon(0xf64a)

    // https://fontawesome.com/icons/buy-n-large?style=brands
    // Brands icon : BuyNLarge
    @Suppress("unused") val BuyNLarge = BrandIcon(0xf8a6)

    // https://fontawesome.com/icons/buysellads?style=brands
    // Brands icon : Buysellads
    @Suppress("unused") val Buysellads = BrandIcon(0xf20d)

    // https://fontawesome.com/icons/calculator?style=solid
    // Solid icon : Calculator
    @Suppress("unused") val Calculator = SolidIcon(0xf1ec)

    // https://fontawesome.com/icons/calendar?style=solid
    // Solid icon : Calendar
    @Suppress("unused") val Calendar = SolidIcon(0xf133)

    // https://fontawesome.com/icons/calendar?style=regular
    // Regular icon : Calendar
    @Suppress("unused") val CalendarRegular = RegularIcon(0xf133)

    // https://fontawesome.com/icons/calendar-alt?style=solid
    // Solid icon : CalendarAlt
    @Suppress("unused") val CalendarAlt = SolidIcon(0xf073)

    // https://fontawesome.com/icons/calendar-alt?style=regular
    // Regular icon : CalendarAlt
    @Suppress("unused") val CalendarAltRegular = RegularIcon(0xf073)

    // https://fontawesome.com/icons/calendar-check?style=solid
    // Solid icon : CalendarCheck
    @Suppress("unused") val CalendarCheck = SolidIcon(0xf274)

    // https://fontawesome.com/icons/calendar-check?style=regular
    // Regular icon : CalendarCheck
    @Suppress("unused") val CalendarCheckRegular = RegularIcon(0xf274)

    // https://fontawesome.com/icons/calendar-day?style=solid
    // Solid icon : CalendarDay
    @Suppress("unused") val CalendarDay = SolidIcon(0xf783)

    // https://fontawesome.com/icons/calendar-minus?style=solid
    // Solid icon : CalendarMinus
    @Suppress("unused") val CalendarMinus = SolidIcon(0xf272)

    // https://fontawesome.com/icons/calendar-minus?style=regular
    // Regular icon : CalendarMinus
    @Suppress("unused") val CalendarMinusRegular = RegularIcon(0xf272)

    // https://fontawesome.com/icons/calendar-plus?style=solid
    // Solid icon : CalendarPlus
    @Suppress("unused") val CalendarPlus = SolidIcon(0xf271)

    // https://fontawesome.com/icons/calendar-plus?style=regular
    // Regular icon : CalendarPlus
    @Suppress("unused") val CalendarPlusRegular = RegularIcon(0xf271)

    // https://fontawesome.com/icons/calendar-times?style=solid
    // Solid icon : CalendarTimes
    @Suppress("unused") val CalendarTimes = SolidIcon(0xf273)

    // https://fontawesome.com/icons/calendar-times?style=regular
    // Regular icon : CalendarTimes
    @Suppress("unused") val CalendarTimesRegular = RegularIcon(0xf273)

    // https://fontawesome.com/icons/calendar-week?style=solid
    // Solid icon : CalendarWeek
    @Suppress("unused") val CalendarWeek = SolidIcon(0xf784)

    // https://fontawesome.com/icons/camera?style=solid
    // Solid icon : Camera
    @Suppress("unused") val Camera = SolidIcon(0xf030)

    // https://fontawesome.com/icons/camera-retro?style=solid
    // Solid icon : CameraRetro
    @Suppress("unused") val CameraRetro = SolidIcon(0xf083)

    // https://fontawesome.com/icons/campground?style=solid
    // Solid icon : Campground
    @Suppress("unused") val Campground = SolidIcon(0xf6bb)

    // https://fontawesome.com/icons/canadian-maple-leaf?style=brands
    // Brands icon : CanadianMapleLeaf
    @Suppress("unused") val CanadianMapleLeaf = BrandIcon(0xf785)

    // https://fontawesome.com/icons/candy-cane?style=solid
    // Solid icon : CandyCane
    @Suppress("unused") val CandyCane = SolidIcon(0xf786)

    // https://fontawesome.com/icons/cannabis?style=solid
    // Solid icon : Cannabis
    @Suppress("unused") val Cannabis = SolidIcon(0xf55f)

    // https://fontawesome.com/icons/capsules?style=solid
    // Solid icon : Capsules
    @Suppress("unused") val Capsules = SolidIcon(0xf46b)

    // https://fontawesome.com/icons/car?style=solid
    // Solid icon : Car
    @Suppress("unused") val Car = SolidIcon(0xf1b9)

    // https://fontawesome.com/icons/car-alt?style=solid
    // Solid icon : CarAlt
    @Suppress("unused") val CarAlt = SolidIcon(0xf5de)

    // https://fontawesome.com/icons/car-battery?style=solid
    // Solid icon : CarBattery
    @Suppress("unused") val CarBattery = SolidIcon(0xf5df)

    // https://fontawesome.com/icons/car-crash?style=solid
    // Solid icon : CarCrash
    @Suppress("unused") val CarCrash = SolidIcon(0xf5e1)

    // https://fontawesome.com/icons/car-side?style=solid
    // Solid icon : CarSide
    @Suppress("unused") val CarSide = SolidIcon(0xf5e4)

    // https://fontawesome.com/icons/caravan?style=solid
    // Solid icon : Caravan
    @Suppress("unused") val Caravan = SolidIcon(0xf8ff)

    // https://fontawesome.com/icons/caret-down?style=solid
    // Solid icon : CaretDown
    @Suppress("unused") val CaretDown = SolidIcon(0xf0d7)

    // https://fontawesome.com/icons/caret-left?style=solid
    // Solid icon : CaretLeft
    @Suppress("unused") val CaretLeft = SolidIcon(0xf0d9)

    // https://fontawesome.com/icons/caret-right?style=solid
    // Solid icon : CaretRight
    @Suppress("unused") val CaretRight = SolidIcon(0xf0da)

    // https://fontawesome.com/icons/caret-square-down?style=solid
    // Solid icon : CaretSquareDown
    @Suppress("unused") val CaretSquareDown = SolidIcon(0xf150)

    // https://fontawesome.com/icons/caret-square-down?style=regular
    // Regular icon : CaretSquareDown
    @Suppress("unused") val CaretSquareDownRegular = RegularIcon(0xf150)

    // https://fontawesome.com/icons/caret-square-left?style=solid
    // Solid icon : CaretSquareLeft
    @Suppress("unused") val CaretSquareLeft = SolidIcon(0xf191)

    // https://fontawesome.com/icons/caret-square-left?style=regular
    // Regular icon : CaretSquareLeft
    @Suppress("unused") val CaretSquareLeftRegular = RegularIcon(0xf191)

    // https://fontawesome.com/icons/caret-square-right?style=solid
    // Solid icon : CaretSquareRight
    @Suppress("unused") val CaretSquareRight = SolidIcon(0xf152)

    // https://fontawesome.com/icons/caret-square-right?style=regular
    // Regular icon : CaretSquareRight
    @Suppress("unused") val CaretSquareRightRegular = RegularIcon(0xf152)

    // https://fontawesome.com/icons/caret-square-up?style=solid
    // Solid icon : CaretSquareUp
    @Suppress("unused") val CaretSquareUp = SolidIcon(0xf151)

    // https://fontawesome.com/icons/caret-square-up?style=regular
    // Regular icon : CaretSquareUp
    @Suppress("unused") val CaretSquareUpRegular = RegularIcon(0xf151)

    // https://fontawesome.com/icons/caret-up?style=solid
    // Solid icon : CaretUp
    @Suppress("unused") val CaretUp = SolidIcon(0xf0d8)

    // https://fontawesome.com/icons/carrot?style=solid
    // Solid icon : Carrot
    @Suppress("unused") val Carrot = SolidIcon(0xf787)

    // https://fontawesome.com/icons/cart-arrow-down?style=solid
    // Solid icon : CartArrowDown
    @Suppress("unused") val CartArrowDown = SolidIcon(0xf218)

    // https://fontawesome.com/icons/cart-plus?style=solid
    // Solid icon : CartPlus
    @Suppress("unused") val CartPlus = SolidIcon(0xf217)

    // https://fontawesome.com/icons/cash-register?style=solid
    // Solid icon : CashRegister
    @Suppress("unused") val CashRegister = SolidIcon(0xf788)

    // https://fontawesome.com/icons/cat?style=solid
    // Solid icon : Cat
    @Suppress("unused") val Cat = SolidIcon(0xf6be)

    // https://fontawesome.com/icons/cc-amazon-pay?style=brands
    // Brands icon : CcAmazonPay
    @Suppress("unused") val CcAmazonPay = BrandIcon(0xf42d)

    // https://fontawesome.com/icons/cc-amex?style=brands
    // Brands icon : CcAmex
    @Suppress("unused") val CcAmex = BrandIcon(0xf1f3)

    // https://fontawesome.com/icons/cc-apple-pay?style=brands
    // Brands icon : CcApplePay
    @Suppress("unused") val CcApplePay = BrandIcon(0xf416)

    // https://fontawesome.com/icons/cc-diners-club?style=brands
    // Brands icon : CcDinersClub
    @Suppress("unused") val CcDinersClub = BrandIcon(0xf24c)

    // https://fontawesome.com/icons/cc-discover?style=brands
    // Brands icon : CcDiscover
    @Suppress("unused") val CcDiscover = BrandIcon(0xf1f2)

    // https://fontawesome.com/icons/cc-jcb?style=brands
    // Brands icon : CcJcb
    @Suppress("unused") val CcJcb = BrandIcon(0xf24b)

    // https://fontawesome.com/icons/cc-mastercard?style=brands
    // Brands icon : CcMastercard
    @Suppress("unused") val CcMastercard = BrandIcon(0xf1f1)

    // https://fontawesome.com/icons/cc-paypal?style=brands
    // Brands icon : CcPaypal
    @Suppress("unused") val CcPaypal = BrandIcon(0xf1f4)

    // https://fontawesome.com/icons/cc-stripe?style=brands
    // Brands icon : CcStripe
    @Suppress("unused") val CcStripe = BrandIcon(0xf1f5)

    // https://fontawesome.com/icons/cc-visa?style=brands
    // Brands icon : CcVisa
    @Suppress("unused") val CcVisa = BrandIcon(0xf1f0)

    // https://fontawesome.com/icons/centercode?style=brands
    // Brands icon : Centercode
    @Suppress("unused") val Centercode = BrandIcon(0xf380)

    // https://fontawesome.com/icons/centos?style=brands
    // Brands icon : Centos
    @Suppress("unused") val Centos = BrandIcon(0xf789)

    // https://fontawesome.com/icons/certificate?style=solid
    // Solid icon : Certificate
    @Suppress("unused") val Certificate = SolidIcon(0xf0a3)

    // https://fontawesome.com/icons/chair?style=solid
    // Solid icon : Chair
    @Suppress("unused") val Chair = SolidIcon(0xf6c0)

    // https://fontawesome.com/icons/chalkboard?style=solid
    // Solid icon : Chalkboard
    @Suppress("unused") val Chalkboard = SolidIcon(0xf51b)

    // https://fontawesome.com/icons/chalkboard-teacher?style=solid
    // Solid icon : ChalkboardTeacher
    @Suppress("unused") val ChalkboardTeacher = SolidIcon(0xf51c)

    // https://fontawesome.com/icons/charging-station?style=solid
    // Solid icon : ChargingStation
    @Suppress("unused") val ChargingStation = SolidIcon(0xf5e7)

    // https://fontawesome.com/icons/chart-area?style=solid
    // Solid icon : ChartArea
    @Suppress("unused") val ChartArea = SolidIcon(0xf1fe)

    // https://fontawesome.com/icons/chart-bar?style=solid
    // Solid icon : ChartBar
    @Suppress("unused") val ChartBar = SolidIcon(0xf080)

    // https://fontawesome.com/icons/chart-bar?style=regular
    // Regular icon : ChartBar
    @Suppress("unused") val ChartBarRegular = RegularIcon(0xf080)

    // https://fontawesome.com/icons/chart-line?style=solid
    // Solid icon : ChartLine
    @Suppress("unused") val ChartLine = SolidIcon(0xf201)

    // https://fontawesome.com/icons/chart-pie?style=solid
    // Solid icon : ChartPie
    @Suppress("unused") val ChartPie = SolidIcon(0xf200)

    // https://fontawesome.com/icons/check?style=solid
    // Solid icon : Check
    @Suppress("unused") val Check = SolidIcon(0xf00c)

    // https://fontawesome.com/icons/check-circle?style=solid
    // Solid icon : CheckCircle
    @Suppress("unused") val CheckCircle = SolidIcon(0xf058)

    // https://fontawesome.com/icons/check-circle?style=regular
    // Regular icon : CheckCircle
    @Suppress("unused") val CheckCircleRegular = RegularIcon(0xf058)

    // https://fontawesome.com/icons/check-double?style=solid
    // Solid icon : CheckDouble
    @Suppress("unused") val CheckDouble = SolidIcon(0xf560)

    // https://fontawesome.com/icons/check-square?style=solid
    // Solid icon : CheckSquare
    @Suppress("unused") val CheckSquare = SolidIcon(0xf14a)

    // https://fontawesome.com/icons/check-square?style=regular
    // Regular icon : CheckSquare
    @Suppress("unused") val CheckSquareRegular = RegularIcon(0xf14a)

    // https://fontawesome.com/icons/cheese?style=solid
    // Solid icon : Cheese
    @Suppress("unused") val Cheese = SolidIcon(0xf7ef)

    // https://fontawesome.com/icons/chess?style=solid
    // Solid icon : Chess
    @Suppress("unused") val Chess = SolidIcon(0xf439)

    // https://fontawesome.com/icons/chess-bishop?style=solid
    // Solid icon : ChessBishop
    @Suppress("unused") val ChessBishop = SolidIcon(0xf43a)

    // https://fontawesome.com/icons/chess-board?style=solid
    // Solid icon : ChessBoard
    @Suppress("unused") val ChessBoard = SolidIcon(0xf43c)

    // https://fontawesome.com/icons/chess-king?style=solid
    // Solid icon : ChessKing
    @Suppress("unused") val ChessKing = SolidIcon(0xf43f)

    // https://fontawesome.com/icons/chess-knight?style=solid
    // Solid icon : ChessKnight
    @Suppress("unused") val ChessKnight = SolidIcon(0xf441)

    // https://fontawesome.com/icons/chess-pawn?style=solid
    // Solid icon : ChessPawn
    @Suppress("unused") val ChessPawn = SolidIcon(0xf443)

    // https://fontawesome.com/icons/chess-queen?style=solid
    // Solid icon : ChessQueen
    @Suppress("unused") val ChessQueen = SolidIcon(0xf445)

    // https://fontawesome.com/icons/chess-rook?style=solid
    // Solid icon : ChessRook
    @Suppress("unused") val ChessRook = SolidIcon(0xf447)

    // https://fontawesome.com/icons/chevron-circle-down?style=solid
    // Solid icon : ChevronCircleDown
    @Suppress("unused") val ChevronCircleDown = SolidIcon(0xf13a)

    // https://fontawesome.com/icons/chevron-circle-left?style=solid
    // Solid icon : ChevronCircleLeft
    @Suppress("unused") val ChevronCircleLeft = SolidIcon(0xf137)

    // https://fontawesome.com/icons/chevron-circle-right?style=solid
    // Solid icon : ChevronCircleRight
    @Suppress("unused") val ChevronCircleRight = SolidIcon(0xf138)

    // https://fontawesome.com/icons/chevron-circle-up?style=solid
    // Solid icon : ChevronCircleUp
    @Suppress("unused") val ChevronCircleUp = SolidIcon(0xf139)

    // https://fontawesome.com/icons/chevron-down?style=solid
    // Solid icon : ChevronDown
    @Suppress("unused") val ChevronDown = SolidIcon(0xf078)

    // https://fontawesome.com/icons/chevron-left?style=solid
    // Solid icon : ChevronLeft
    @Suppress("unused") val ChevronLeft = SolidIcon(0xf053)

    // https://fontawesome.com/icons/chevron-right?style=solid
    // Solid icon : ChevronRight
    @Suppress("unused") val ChevronRight = SolidIcon(0xf054)

    // https://fontawesome.com/icons/chevron-up?style=solid
    // Solid icon : ChevronUp
    @Suppress("unused") val ChevronUp = SolidIcon(0xf077)

    // https://fontawesome.com/icons/child?style=solid
    // Solid icon : Child
    @Suppress("unused") val Child = SolidIcon(0xf1ae)

    // https://fontawesome.com/icons/chrome?style=brands
    // Brands icon : Chrome
    @Suppress("unused") val Chrome = BrandIcon(0xf268)

    // https://fontawesome.com/icons/chromecast?style=brands
    // Brands icon : Chromecast
    @Suppress("unused") val Chromecast = BrandIcon(0xf838)

    // https://fontawesome.com/icons/church?style=solid
    // Solid icon : Church
    @Suppress("unused") val Church = SolidIcon(0xf51d)

    // https://fontawesome.com/icons/circle?style=solid
    // Solid icon : Circle
    @Suppress("unused") val Circle = SolidIcon(0xf111)

    // https://fontawesome.com/icons/circle?style=regular
    // Regular icon : Circle
    @Suppress("unused") val CircleRegular = RegularIcon(0xf111)

    // https://fontawesome.com/icons/circle-notch?style=solid
    // Solid icon : CircleNotch
    @Suppress("unused") val CircleNotch = SolidIcon(0xf1ce)

    // https://fontawesome.com/icons/city?style=solid
    // Solid icon : City
    @Suppress("unused") val City = SolidIcon(0xf64f)

    // https://fontawesome.com/icons/clinic-medical?style=solid
    // Solid icon : ClinicMedical
    @Suppress("unused") val ClinicMedical = SolidIcon(0xf7f2)

    // https://fontawesome.com/icons/clipboard?style=solid
    // Solid icon : Clipboard
    @Suppress("unused") val Clipboard = SolidIcon(0xf328)

    // https://fontawesome.com/icons/clipboard?style=regular
    // Regular icon : Clipboard
    @Suppress("unused") val ClipboardRegular = RegularIcon(0xf328)

    // https://fontawesome.com/icons/clipboard-check?style=solid
    // Solid icon : ClipboardCheck
    @Suppress("unused") val ClipboardCheck = SolidIcon(0xf46c)

    // https://fontawesome.com/icons/clipboard-list?style=solid
    // Solid icon : ClipboardList
    @Suppress("unused") val ClipboardList = SolidIcon(0xf46d)

    // https://fontawesome.com/icons/clock?style=solid
    // Solid icon : Clock
    @Suppress("unused") val Clock = SolidIcon(0xf017)

    // https://fontawesome.com/icons/clock?style=regular
    // Regular icon : Clock
    @Suppress("unused") val ClockRegular = RegularIcon(0xf017)

    // https://fontawesome.com/icons/clone?style=solid
    // Solid icon : Clone
    @Suppress("unused") val Clone = SolidIcon(0xf24d)

    // https://fontawesome.com/icons/clone?style=regular
    // Regular icon : Clone
    @Suppress("unused") val CloneRegular = RegularIcon(0xf24d)

    // https://fontawesome.com/icons/closed-captioning?style=solid
    // Solid icon : ClosedCaptioning
    @Suppress("unused") val ClosedCaptioning = SolidIcon(0xf20a)

    // https://fontawesome.com/icons/closed-captioning?style=regular
    // Regular icon : ClosedCaptioning
    @Suppress("unused") val ClosedCaptioningRegular = RegularIcon(0xf20a)

    // https://fontawesome.com/icons/cloud?style=solid
    // Solid icon : Cloud
    @Suppress("unused") val Cloud = SolidIcon(0xf0c2)

    // https://fontawesome.com/icons/cloud-download-alt?style=solid
    // Solid icon : CloudDownloadAlt
    @Suppress("unused") val CloudDownloadAlt = SolidIcon(0xf381)

    // https://fontawesome.com/icons/cloud-meatball?style=solid
    // Solid icon : CloudMeatball
    @Suppress("unused") val CloudMeatball = SolidIcon(0xf73b)

    // https://fontawesome.com/icons/cloud-moon?style=solid
    // Solid icon : CloudMoon
    @Suppress("unused") val CloudMoon = SolidIcon(0xf6c3)

    // https://fontawesome.com/icons/cloud-moon-rain?style=solid
    // Solid icon : CloudMoonRain
    @Suppress("unused") val CloudMoonRain = SolidIcon(0xf73c)

    // https://fontawesome.com/icons/cloud-rain?style=solid
    // Solid icon : CloudRain
    @Suppress("unused") val CloudRain = SolidIcon(0xf73d)

    // https://fontawesome.com/icons/cloud-showers-heavy?style=solid
    // Solid icon : CloudShowersHeavy
    @Suppress("unused") val CloudShowersHeavy = SolidIcon(0xf740)

    // https://fontawesome.com/icons/cloud-sun?style=solid
    // Solid icon : CloudSun
    @Suppress("unused") val CloudSun = SolidIcon(0xf6c4)

    // https://fontawesome.com/icons/cloud-sun-rain?style=solid
    // Solid icon : CloudSunRain
    @Suppress("unused") val CloudSunRain = SolidIcon(0xf743)

    // https://fontawesome.com/icons/cloud-upload-alt?style=solid
    // Solid icon : CloudUploadAlt
    @Suppress("unused") val CloudUploadAlt = SolidIcon(0xf382)

    // https://fontawesome.com/icons/cloudflare?style=brands
    // Brands icon : Cloudflare
    @Suppress("unused") val Cloudflare = BrandIcon(0xe07d)

    // https://fontawesome.com/icons/cloudscale?style=brands
    // Brands icon : Cloudscale
    @Suppress("unused") val Cloudscale = BrandIcon(0xf383)

    // https://fontawesome.com/icons/cloudsmith?style=brands
    // Brands icon : Cloudsmith
    @Suppress("unused") val Cloudsmith = BrandIcon(0xf384)

    // https://fontawesome.com/icons/cloudversify?style=brands
    // Brands icon : Cloudversify
    @Suppress("unused") val Cloudversify = BrandIcon(0xf385)

    // https://fontawesome.com/icons/cocktail?style=solid
    // Solid icon : Cocktail
    @Suppress("unused") val Cocktail = SolidIcon(0xf561)

    // https://fontawesome.com/icons/code?style=solid
    // Solid icon : Code
    @Suppress("unused") val Code = SolidIcon(0xf121)

    // https://fontawesome.com/icons/code-branch?style=solid
    // Solid icon : CodeBranch
    @Suppress("unused") val CodeBranch = SolidIcon(0xf126)

    // https://fontawesome.com/icons/codepen?style=brands
    // Brands icon : Codepen
    @Suppress("unused") val Codepen = BrandIcon(0xf1cb)

    // https://fontawesome.com/icons/codiepie?style=brands
    // Brands icon : Codiepie
    @Suppress("unused") val Codiepie = BrandIcon(0xf284)

    // https://fontawesome.com/icons/coffee?style=solid
    // Solid icon : Coffee
    @Suppress("unused") val Coffee = SolidIcon(0xf0f4)

    // https://fontawesome.com/icons/cog?style=solid
    // Solid icon : Cog
    @Suppress("unused") val Cog = SolidIcon(0xf013)

    // https://fontawesome.com/icons/cogs?style=solid
    // Solid icon : Cogs
    @Suppress("unused") val Cogs = SolidIcon(0xf085)

    // https://fontawesome.com/icons/coins?style=solid
    // Solid icon : Coins
    @Suppress("unused") val Coins = SolidIcon(0xf51e)

    // https://fontawesome.com/icons/columns?style=solid
    // Solid icon : Columns
    @Suppress("unused") val Columns = SolidIcon(0xf0db)

    // https://fontawesome.com/icons/comment?style=solid
    // Solid icon : Comment
    @Suppress("unused") val Comment = SolidIcon(0xf075)

    // https://fontawesome.com/icons/comment?style=regular
    // Regular icon : Comment
    @Suppress("unused") val CommentRegular = RegularIcon(0xf075)

    // https://fontawesome.com/icons/comment-alt?style=solid
    // Solid icon : CommentAlt
    @Suppress("unused") val CommentAlt = SolidIcon(0xf27a)

    // https://fontawesome.com/icons/comment-alt?style=regular
    // Regular icon : CommentAlt
    @Suppress("unused") val CommentAltRegular = RegularIcon(0xf27a)

    // https://fontawesome.com/icons/comment-dollar?style=solid
    // Solid icon : CommentDollar
    @Suppress("unused") val CommentDollar = SolidIcon(0xf651)

    // https://fontawesome.com/icons/comment-dots?style=solid
    // Solid icon : CommentDots
    @Suppress("unused") val CommentDots = SolidIcon(0xf4ad)

    // https://fontawesome.com/icons/comment-dots?style=regular
    // Regular icon : CommentDots
    @Suppress("unused") val CommentDotsRegular = RegularIcon(0xf4ad)

    // https://fontawesome.com/icons/comment-medical?style=solid
    // Solid icon : CommentMedical
    @Suppress("unused") val CommentMedical = SolidIcon(0xf7f5)

    // https://fontawesome.com/icons/comment-slash?style=solid
    // Solid icon : CommentSlash
    @Suppress("unused") val CommentSlash = SolidIcon(0xf4b3)

    // https://fontawesome.com/icons/comments?style=solid
    // Solid icon : Comments
    @Suppress("unused") val Comments = SolidIcon(0xf086)

    // https://fontawesome.com/icons/comments?style=regular
    // Regular icon : Comments
    @Suppress("unused") val CommentsRegular = RegularIcon(0xf086)

    // https://fontawesome.com/icons/comments-dollar?style=solid
    // Solid icon : CommentsDollar
    @Suppress("unused") val CommentsDollar = SolidIcon(0xf653)

    // https://fontawesome.com/icons/compact-disc?style=solid
    // Solid icon : CompactDisc
    @Suppress("unused") val CompactDisc = SolidIcon(0xf51f)

    // https://fontawesome.com/icons/compass?style=solid
    // Solid icon : Compass
    @Suppress("unused") val Compass = SolidIcon(0xf14e)

    // https://fontawesome.com/icons/compass?style=regular
    // Regular icon : Compass
    @Suppress("unused") val CompassRegular = RegularIcon(0xf14e)

    // https://fontawesome.com/icons/compress?style=solid
    // Solid icon : Compress
    @Suppress("unused") val Compress = SolidIcon(0xf066)

    // https://fontawesome.com/icons/compress-alt?style=solid
    // Solid icon : CompressAlt
    @Suppress("unused") val CompressAlt = SolidIcon(0xf422)

    // https://fontawesome.com/icons/compress-arrows-alt?style=solid
    // Solid icon : CompressArrowsAlt
    @Suppress("unused") val CompressArrowsAlt = SolidIcon(0xf78c)

    // https://fontawesome.com/icons/concierge-bell?style=solid
    // Solid icon : ConciergeBell
    @Suppress("unused") val ConciergeBell = SolidIcon(0xf562)

    // https://fontawesome.com/icons/confluence?style=brands
    // Brands icon : Confluence
    @Suppress("unused") val Confluence = BrandIcon(0xf78d)

    // https://fontawesome.com/icons/connectdevelop?style=brands
    // Brands icon : Connectdevelop
    @Suppress("unused") val Connectdevelop = BrandIcon(0xf20e)

    // https://fontawesome.com/icons/contao?style=brands
    // Brands icon : Contao
    @Suppress("unused") val Contao = BrandIcon(0xf26d)

    // https://fontawesome.com/icons/cookie?style=solid
    // Solid icon : Cookie
    @Suppress("unused") val Cookie = SolidIcon(0xf563)

    // https://fontawesome.com/icons/cookie-bite?style=solid
    // Solid icon : CookieBite
    @Suppress("unused") val CookieBite = SolidIcon(0xf564)

    // https://fontawesome.com/icons/copy?style=solid
    // Solid icon : Copy
    @Suppress("unused") val Copy = SolidIcon(0xf0c5)

    // https://fontawesome.com/icons/copy?style=regular
    // Regular icon : Copy
    @Suppress("unused") val CopyRegular = RegularIcon(0xf0c5)

    // https://fontawesome.com/icons/copyright?style=solid
    // Solid icon : Copyright
    @Suppress("unused") val Copyright = SolidIcon(0xf1f9)

    // https://fontawesome.com/icons/copyright?style=regular
    // Regular icon : Copyright
    @Suppress("unused") val CopyrightRegular = RegularIcon(0xf1f9)

    // https://fontawesome.com/icons/cotton-bureau?style=brands
    // Brands icon : CottonBureau
    @Suppress("unused") val CottonBureau = BrandIcon(0xf89e)

    // https://fontawesome.com/icons/couch?style=solid
    // Solid icon : Couch
    @Suppress("unused") val Couch = SolidIcon(0xf4b8)

    // https://fontawesome.com/icons/cpanel?style=brands
    // Brands icon : Cpanel
    @Suppress("unused") val Cpanel = BrandIcon(0xf388)

    // https://fontawesome.com/icons/creative-commons?style=brands
    // Brands icon : CreativeCommons
    @Suppress("unused") val CreativeCommons = BrandIcon(0xf25e)

    // https://fontawesome.com/icons/creative-commons-by?style=brands
    // Brands icon : CreativeCommonsBy
    @Suppress("unused") val CreativeCommonsBy = BrandIcon(0xf4e7)

    // https://fontawesome.com/icons/creative-commons-nc?style=brands
    // Brands icon : CreativeCommonsNc
    @Suppress("unused") val CreativeCommonsNc = BrandIcon(0xf4e8)

    // https://fontawesome.com/icons/creative-commons-nc-eu?style=brands
    // Brands icon : CreativeCommonsNcEu
    @Suppress("unused") val CreativeCommonsNcEu = BrandIcon(0xf4e9)

    // https://fontawesome.com/icons/creative-commons-nc-jp?style=brands
    // Brands icon : CreativeCommonsNcJp
    @Suppress("unused") val CreativeCommonsNcJp = BrandIcon(0xf4ea)

    // https://fontawesome.com/icons/creative-commons-nd?style=brands
    // Brands icon : CreativeCommonsNd
    @Suppress("unused") val CreativeCommonsNd = BrandIcon(0xf4eb)

    // https://fontawesome.com/icons/creative-commons-pd?style=brands
    // Brands icon : CreativeCommonsPd
    @Suppress("unused") val CreativeCommonsPd = BrandIcon(0xf4ec)

    // https://fontawesome.com/icons/creative-commons-pd-alt?style=brands
    // Brands icon : CreativeCommonsPdAlt
    @Suppress("unused") val CreativeCommonsPdAlt = BrandIcon(0xf4ed)

    // https://fontawesome.com/icons/creative-commons-remix?style=brands
    // Brands icon : CreativeCommonsRemix
    @Suppress("unused") val CreativeCommonsRemix = BrandIcon(0xf4ee)

    // https://fontawesome.com/icons/creative-commons-sa?style=brands
    // Brands icon : CreativeCommonsSa
    @Suppress("unused") val CreativeCommonsSa = BrandIcon(0xf4ef)

    // https://fontawesome.com/icons/creative-commons-sampling?style=brands
    // Brands icon : CreativeCommonsSampling
    @Suppress("unused") val CreativeCommonsSampling = BrandIcon(0xf4f0)

    // https://fontawesome.com/icons/creative-commons-sampling-plus?style=brands
    // Brands icon : CreativeCommonsSamplingPlus
    @Suppress("unused") val CreativeCommonsSamplingPlus = BrandIcon(0xf4f1)

    // https://fontawesome.com/icons/creative-commons-share?style=brands
    // Brands icon : CreativeCommonsShare
    @Suppress("unused") val CreativeCommonsShare = BrandIcon(0xf4f2)

    // https://fontawesome.com/icons/creative-commons-zero?style=brands
    // Brands icon : CreativeCommonsZero
    @Suppress("unused") val CreativeCommonsZero = BrandIcon(0xf4f3)

    // https://fontawesome.com/icons/credit-card?style=solid
    // Solid icon : CreditCard
    @Suppress("unused") val CreditCard = SolidIcon(0xf09d)

    // https://fontawesome.com/icons/credit-card?style=regular
    // Regular icon : CreditCard
    @Suppress("unused") val CreditCardRegular = RegularIcon(0xf09d)

    // https://fontawesome.com/icons/critical-role?style=brands
    // Brands icon : CriticalRole
    @Suppress("unused") val CriticalRole = BrandIcon(0xf6c9)

    // https://fontawesome.com/icons/crop?style=solid
    // Solid icon : Crop
    @Suppress("unused") val Crop = SolidIcon(0xf125)

    // https://fontawesome.com/icons/crop-alt?style=solid
    // Solid icon : CropAlt
    @Suppress("unused") val CropAlt = SolidIcon(0xf565)

    // https://fontawesome.com/icons/cross?style=solid
    // Solid icon : Cross
    @Suppress("unused") val Cross = SolidIcon(0xf654)

    // https://fontawesome.com/icons/crosshairs?style=solid
    // Solid icon : Crosshairs
    @Suppress("unused") val Crosshairs = SolidIcon(0xf05b)

    // https://fontawesome.com/icons/crow?style=solid
    // Solid icon : Crow
    @Suppress("unused") val Crow = SolidIcon(0xf520)

    // https://fontawesome.com/icons/crown?style=solid
    // Solid icon : Crown
    @Suppress("unused") val Crown = SolidIcon(0xf521)

    // https://fontawesome.com/icons/crutch?style=solid
    // Solid icon : Crutch
    @Suppress("unused") val Crutch = SolidIcon(0xf7f7)

    // https://fontawesome.com/icons/css3?style=brands
    // Brands icon : Css3
    @Suppress("unused") val Css3 = BrandIcon(0xf13c)

    // https://fontawesome.com/icons/css3-alt?style=brands
    // Brands icon : Css3Alt
    @Suppress("unused") val Css3Alt = BrandIcon(0xf38b)

    // https://fontawesome.com/icons/cube?style=solid
    // Solid icon : Cube
    @Suppress("unused") val Cube = SolidIcon(0xf1b2)

    // https://fontawesome.com/icons/cubes?style=solid
    // Solid icon : Cubes
    @Suppress("unused") val Cubes = SolidIcon(0xf1b3)

    // https://fontawesome.com/icons/cut?style=solid
    // Solid icon : Cut
    @Suppress("unused") val Cut = SolidIcon(0xf0c4)

    // https://fontawesome.com/icons/cuttlefish?style=brands
    // Brands icon : Cuttlefish
    @Suppress("unused") val Cuttlefish = BrandIcon(0xf38c)

    // https://fontawesome.com/icons/d-and-d?style=brands
    // Brands icon : DAndD
    @Suppress("unused") val DAndD = BrandIcon(0xf38d)

    // https://fontawesome.com/icons/d-and-d-beyond?style=brands
    // Brands icon : DAndDBeyond
    @Suppress("unused") val DAndDBeyond = BrandIcon(0xf6ca)

    // https://fontawesome.com/icons/dailymotion?style=brands
    // Brands icon : Dailymotion
    @Suppress("unused") val Dailymotion = BrandIcon(0xe052)

    // https://fontawesome.com/icons/dashcube?style=brands
    // Brands icon : Dashcube
    @Suppress("unused") val Dashcube = BrandIcon(0xf210)

    // https://fontawesome.com/icons/database?style=solid
    // Solid icon : Database
    @Suppress("unused") val Database = SolidIcon(0xf1c0)

    // https://fontawesome.com/icons/deaf?style=solid
    // Solid icon : Deaf
    @Suppress("unused") val Deaf = SolidIcon(0xf2a4)

    // https://fontawesome.com/icons/deezer?style=brands
    // Brands icon : Deezer
    @Suppress("unused") val Deezer = BrandIcon(0xe077)

    // https://fontawesome.com/icons/delicious?style=brands
    // Brands icon : Delicious
    @Suppress("unused") val Delicious = BrandIcon(0xf1a5)

    // https://fontawesome.com/icons/democrat?style=solid
    // Solid icon : Democrat
    @Suppress("unused") val Democrat = SolidIcon(0xf747)

    // https://fontawesome.com/icons/deploydog?style=brands
    // Brands icon : Deploydog
    @Suppress("unused") val Deploydog = BrandIcon(0xf38e)

    // https://fontawesome.com/icons/deskpro?style=brands
    // Brands icon : Deskpro
    @Suppress("unused") val Deskpro = BrandIcon(0xf38f)

    // https://fontawesome.com/icons/desktop?style=solid
    // Solid icon : Desktop
    @Suppress("unused") val Desktop = SolidIcon(0xf108)

    // https://fontawesome.com/icons/dev?style=brands
    // Brands icon : Dev
    @Suppress("unused") val Dev = BrandIcon(0xf6cc)

    // https://fontawesome.com/icons/deviantart?style=brands
    // Brands icon : Deviantart
    @Suppress("unused") val Deviantart = BrandIcon(0xf1bd)

    // https://fontawesome.com/icons/dharmachakra?style=solid
    // Solid icon : Dharmachakra
    @Suppress("unused") val Dharmachakra = SolidIcon(0xf655)

    // https://fontawesome.com/icons/dhl?style=brands
    // Brands icon : Dhl
    @Suppress("unused") val Dhl = BrandIcon(0xf790)

    // https://fontawesome.com/icons/diagnoses?style=solid
    // Solid icon : Diagnoses
    @Suppress("unused") val Diagnoses = SolidIcon(0xf470)

    // https://fontawesome.com/icons/diaspora?style=brands
    // Brands icon : Diaspora
    @Suppress("unused") val Diaspora = BrandIcon(0xf791)

    // https://fontawesome.com/icons/dice?style=solid
    // Solid icon : Dice
    @Suppress("unused") val Dice = SolidIcon(0xf522)

    // https://fontawesome.com/icons/dice-d20?style=solid
    // Solid icon : DiceD20
    @Suppress("unused") val DiceD20 = SolidIcon(0xf6cf)

    // https://fontawesome.com/icons/dice-d6?style=solid
    // Solid icon : DiceD6
    @Suppress("unused") val DiceD6 = SolidIcon(0xf6d1)

    // https://fontawesome.com/icons/dice-five?style=solid
    // Solid icon : DiceFive
    @Suppress("unused") val DiceFive = SolidIcon(0xf523)

    // https://fontawesome.com/icons/dice-four?style=solid
    // Solid icon : DiceFour
    @Suppress("unused") val DiceFour = SolidIcon(0xf524)

    // https://fontawesome.com/icons/dice-one?style=solid
    // Solid icon : DiceOne
    @Suppress("unused") val DiceOne = SolidIcon(0xf525)

    // https://fontawesome.com/icons/dice-six?style=solid
    // Solid icon : DiceSix
    @Suppress("unused") val DiceSix = SolidIcon(0xf526)

    // https://fontawesome.com/icons/dice-three?style=solid
    // Solid icon : DiceThree
    @Suppress("unused") val DiceThree = SolidIcon(0xf527)

    // https://fontawesome.com/icons/dice-two?style=solid
    // Solid icon : DiceTwo
    @Suppress("unused") val DiceTwo = SolidIcon(0xf528)

    // https://fontawesome.com/icons/digg?style=brands
    // Brands icon : Digg
    @Suppress("unused") val Digg = BrandIcon(0xf1a6)

    // https://fontawesome.com/icons/digital-ocean?style=brands
    // Brands icon : DigitalOcean
    @Suppress("unused") val DigitalOcean = BrandIcon(0xf391)

    // https://fontawesome.com/icons/digital-tachograph?style=solid
    // Solid icon : DigitalTachograph
    @Suppress("unused") val DigitalTachograph = SolidIcon(0xf566)

    // https://fontawesome.com/icons/directions?style=solid
    // Solid icon : Directions
    @Suppress("unused") val Directions = SolidIcon(0xf5eb)

    // https://fontawesome.com/icons/discord?style=brands
    // Brands icon : Discord
    @Suppress("unused") val Discord = BrandIcon(0xf392)

    // https://fontawesome.com/icons/discourse?style=brands
    // Brands icon : Discourse
    @Suppress("unused") val Discourse = BrandIcon(0xf393)

    // https://fontawesome.com/icons/disease?style=solid
    // Solid icon : Disease
    @Suppress("unused") val Disease = SolidIcon(0xf7fa)

    // https://fontawesome.com/icons/divide?style=solid
    // Solid icon : Divide
    @Suppress("unused") val Divide = SolidIcon(0xf529)

    // https://fontawesome.com/icons/dizzy?style=solid
    // Solid icon : Dizzy
    @Suppress("unused") val Dizzy = SolidIcon(0xf567)

    // https://fontawesome.com/icons/dizzy?style=regular
    // Regular icon : Dizzy
    @Suppress("unused") val DizzyRegular = RegularIcon(0xf567)

    // https://fontawesome.com/icons/dna?style=solid
    // Solid icon : Dna
    @Suppress("unused") val Dna = SolidIcon(0xf471)

    // https://fontawesome.com/icons/dochub?style=brands
    // Brands icon : Dochub
    @Suppress("unused") val Dochub = BrandIcon(0xf394)

    // https://fontawesome.com/icons/docker?style=brands
    // Brands icon : Docker
    @Suppress("unused") val Docker = BrandIcon(0xf395)

    // https://fontawesome.com/icons/dog?style=solid
    // Solid icon : Dog
    @Suppress("unused") val Dog = SolidIcon(0xf6d3)

    // https://fontawesome.com/icons/dollar-sign?style=solid
    // Solid icon : DollarSign
    @Suppress("unused") val DollarSign = SolidIcon(0xf155)

    // https://fontawesome.com/icons/dolly?style=solid
    // Solid icon : Dolly
    @Suppress("unused") val Dolly = SolidIcon(0xf472)

    // https://fontawesome.com/icons/dolly-flatbed?style=solid
    // Solid icon : DollyFlatbed
    @Suppress("unused") val DollyFlatbed = SolidIcon(0xf474)

    // https://fontawesome.com/icons/donate?style=solid
    // Solid icon : Donate
    @Suppress("unused") val Donate = SolidIcon(0xf4b9)

    // https://fontawesome.com/icons/door-closed?style=solid
    // Solid icon : DoorClosed
    @Suppress("unused") val DoorClosed = SolidIcon(0xf52a)

    // https://fontawesome.com/icons/door-open?style=solid
    // Solid icon : DoorOpen
    @Suppress("unused") val DoorOpen = SolidIcon(0xf52b)

    // https://fontawesome.com/icons/dot-circle?style=solid
    // Solid icon : DotCircle
    @Suppress("unused") val DotCircle = SolidIcon(0xf192)

    // https://fontawesome.com/icons/dot-circle?style=regular
    // Regular icon : DotCircle
    @Suppress("unused") val DotCircleRegular = RegularIcon(0xf192)

    // https://fontawesome.com/icons/dove?style=solid
    // Solid icon : Dove
    @Suppress("unused") val Dove = SolidIcon(0xf4ba)

    // https://fontawesome.com/icons/download?style=solid
    // Solid icon : Download
    @Suppress("unused") val Download = SolidIcon(0xf019)

    // https://fontawesome.com/icons/draft2digital?style=brands
    // Brands icon : Draft2digital
    @Suppress("unused") val Draft2digital = BrandIcon(0xf396)

    // https://fontawesome.com/icons/drafting-compass?style=solid
    // Solid icon : DraftingCompass
    @Suppress("unused") val DraftingCompass = SolidIcon(0xf568)

    // https://fontawesome.com/icons/dragon?style=solid
    // Solid icon : Dragon
    @Suppress("unused") val Dragon = SolidIcon(0xf6d5)

    // https://fontawesome.com/icons/draw-polygon?style=solid
    // Solid icon : DrawPolygon
    @Suppress("unused") val DrawPolygon = SolidIcon(0xf5ee)

    // https://fontawesome.com/icons/dribbble?style=brands
    // Brands icon : Dribbble
    @Suppress("unused") val Dribbble = BrandIcon(0xf17d)

    // https://fontawesome.com/icons/dribbble-square?style=brands
    // Brands icon : DribbbleSquare
    @Suppress("unused") val DribbbleSquare = BrandIcon(0xf397)

    // https://fontawesome.com/icons/dropbox?style=brands
    // Brands icon : Dropbox
    @Suppress("unused") val Dropbox = BrandIcon(0xf16b)

    // https://fontawesome.com/icons/drum?style=solid
    // Solid icon : Drum
    @Suppress("unused") val Drum = SolidIcon(0xf569)

    // https://fontawesome.com/icons/drum-steelpan?style=solid
    // Solid icon : DrumSteelpan
    @Suppress("unused") val DrumSteelpan = SolidIcon(0xf56a)

    // https://fontawesome.com/icons/drumstick-bite?style=solid
    // Solid icon : DrumstickBite
    @Suppress("unused") val DrumstickBite = SolidIcon(0xf6d7)

    // https://fontawesome.com/icons/drupal?style=brands
    // Brands icon : Drupal
    @Suppress("unused") val Drupal = BrandIcon(0xf1a9)

    // https://fontawesome.com/icons/dumbbell?style=solid
    // Solid icon : Dumbbell
    @Suppress("unused") val Dumbbell = SolidIcon(0xf44b)

    // https://fontawesome.com/icons/dumpster?style=solid
    // Solid icon : Dumpster
    @Suppress("unused") val Dumpster = SolidIcon(0xf793)

    // https://fontawesome.com/icons/dumpster-fire?style=solid
    // Solid icon : DumpsterFire
    @Suppress("unused") val DumpsterFire = SolidIcon(0xf794)

    // https://fontawesome.com/icons/dungeon?style=solid
    // Solid icon : Dungeon
    @Suppress("unused") val Dungeon = SolidIcon(0xf6d9)

    // https://fontawesome.com/icons/dyalog?style=brands
    // Brands icon : Dyalog
    @Suppress("unused") val Dyalog = BrandIcon(0xf399)

    // https://fontawesome.com/icons/earlybirds?style=brands
    // Brands icon : Earlybirds
    @Suppress("unused") val Earlybirds = BrandIcon(0xf39a)

    // https://fontawesome.com/icons/ebay?style=brands
    // Brands icon : Ebay
    @Suppress("unused") val Ebay = BrandIcon(0xf4f4)

    // https://fontawesome.com/icons/edge?style=brands
    // Brands icon : Edge
    @Suppress("unused") val Edge = BrandIcon(0xf282)

    // https://fontawesome.com/icons/edge-legacy?style=brands
    // Brands icon : EdgeLegacy
    @Suppress("unused") val EdgeLegacy = BrandIcon(0xe078)

    // https://fontawesome.com/icons/edit?style=solid
    // Solid icon : Edit
    @Suppress("unused") val Edit = SolidIcon(0xf044)

    // https://fontawesome.com/icons/edit?style=regular
    // Regular icon : Edit
    @Suppress("unused") val EditRegular = RegularIcon(0xf044)

    // https://fontawesome.com/icons/egg?style=solid
    // Solid icon : Egg
    @Suppress("unused") val Egg = SolidIcon(0xf7fb)

    // https://fontawesome.com/icons/eject?style=solid
    // Solid icon : Eject
    @Suppress("unused") val Eject = SolidIcon(0xf052)

    // https://fontawesome.com/icons/elementor?style=brands
    // Brands icon : Elementor
    @Suppress("unused") val Elementor = BrandIcon(0xf430)

    // https://fontawesome.com/icons/ellipsis-h?style=solid
    // Solid icon : EllipsisH
    @Suppress("unused") val EllipsisH = SolidIcon(0xf141)

    // https://fontawesome.com/icons/ellipsis-v?style=solid
    // Solid icon : EllipsisV
    @Suppress("unused") val EllipsisV = SolidIcon(0xf142)

    // https://fontawesome.com/icons/ello?style=brands
    // Brands icon : Ello
    @Suppress("unused") val Ello = BrandIcon(0xf5f1)

    // https://fontawesome.com/icons/ember?style=brands
    // Brands icon : Ember
    @Suppress("unused") val Ember = BrandIcon(0xf423)

    // https://fontawesome.com/icons/empire?style=brands
    // Brands icon : Empire
    @Suppress("unused") val Empire = BrandIcon(0xf1d1)

    // https://fontawesome.com/icons/envelope?style=solid
    // Solid icon : Envelope
    @Suppress("unused") val Envelope = SolidIcon(0xf0e0)

    // https://fontawesome.com/icons/envelope?style=regular
    // Regular icon : Envelope
    @Suppress("unused") val EnvelopeRegular = RegularIcon(0xf0e0)

    // https://fontawesome.com/icons/envelope-open?style=solid
    // Solid icon : EnvelopeOpen
    @Suppress("unused") val EnvelopeOpen = SolidIcon(0xf2b6)

    // https://fontawesome.com/icons/envelope-open?style=regular
    // Regular icon : EnvelopeOpen
    @Suppress("unused") val EnvelopeOpenRegular = RegularIcon(0xf2b6)

    // https://fontawesome.com/icons/envelope-open-text?style=solid
    // Solid icon : EnvelopeOpenText
    @Suppress("unused") val EnvelopeOpenText = SolidIcon(0xf658)

    // https://fontawesome.com/icons/envelope-square?style=solid
    // Solid icon : EnvelopeSquare
    @Suppress("unused") val EnvelopeSquare = SolidIcon(0xf199)

    // https://fontawesome.com/icons/envira?style=brands
    // Brands icon : Envira
    @Suppress("unused") val Envira = BrandIcon(0xf299)

    // https://fontawesome.com/icons/equals?style=solid
    // Solid icon : Equals
    @Suppress("unused") val Equals = SolidIcon(0xf52c)

    // https://fontawesome.com/icons/eraser?style=solid
    // Solid icon : Eraser
    @Suppress("unused") val Eraser = SolidIcon(0xf12d)

    // https://fontawesome.com/icons/erlang?style=brands
    // Brands icon : Erlang
    @Suppress("unused") val Erlang = BrandIcon(0xf39d)

    // https://fontawesome.com/icons/ethereum?style=brands
    // Brands icon : Ethereum
    @Suppress("unused") val Ethereum = BrandIcon(0xf42e)

    // https://fontawesome.com/icons/ethernet?style=solid
    // Solid icon : Ethernet
    @Suppress("unused") val Ethernet = SolidIcon(0xf796)

    // https://fontawesome.com/icons/etsy?style=brands
    // Brands icon : Etsy
    @Suppress("unused") val Etsy = BrandIcon(0xf2d7)

    // https://fontawesome.com/icons/euro-sign?style=solid
    // Solid icon : EuroSign
    @Suppress("unused") val EuroSign = SolidIcon(0xf153)

    // https://fontawesome.com/icons/evernote?style=brands
    // Brands icon : Evernote
    @Suppress("unused") val Evernote = BrandIcon(0xf839)

    // https://fontawesome.com/icons/exchange-alt?style=solid
    // Solid icon : ExchangeAlt
    @Suppress("unused") val ExchangeAlt = SolidIcon(0xf362)

    // https://fontawesome.com/icons/exclamation?style=solid
    // Solid icon : Exclamation
    @Suppress("unused") val Exclamation = SolidIcon(0xf12a)

    // https://fontawesome.com/icons/exclamation-circle?style=solid
    // Solid icon : ExclamationCircle
    @Suppress("unused") val ExclamationCircle = SolidIcon(0xf06a)

    // https://fontawesome.com/icons/exclamation-triangle?style=solid
    // Solid icon : ExclamationTriangle
    @Suppress("unused") val ExclamationTriangle = SolidIcon(0xf071)

    // https://fontawesome.com/icons/expand?style=solid
    // Solid icon : Expand
    @Suppress("unused") val Expand = SolidIcon(0xf065)

    // https://fontawesome.com/icons/expand-alt?style=solid
    // Solid icon : ExpandAlt
    @Suppress("unused") val ExpandAlt = SolidIcon(0xf424)

    // https://fontawesome.com/icons/expand-arrows-alt?style=solid
    // Solid icon : ExpandArrowsAlt
    @Suppress("unused") val ExpandArrowsAlt = SolidIcon(0xf31e)

    // https://fontawesome.com/icons/expeditedssl?style=brands
    // Brands icon : Expeditedssl
    @Suppress("unused") val Expeditedssl = BrandIcon(0xf23e)

    // https://fontawesome.com/icons/external-link-alt?style=solid
    // Solid icon : ExternalLinkAlt
    @Suppress("unused") val ExternalLinkAlt = SolidIcon(0xf35d)

    // https://fontawesome.com/icons/external-link-square-alt?style=solid
    // Solid icon : ExternalLinkSquareAlt
    @Suppress("unused") val ExternalLinkSquareAlt = SolidIcon(0xf360)

    // https://fontawesome.com/icons/eye?style=solid
    // Solid icon : Eye
    @Suppress("unused") val Eye = SolidIcon(0xf06e)

    // https://fontawesome.com/icons/eye?style=regular
    // Regular icon : Eye
    @Suppress("unused") val EyeRegular = RegularIcon(0xf06e)

    // https://fontawesome.com/icons/eye-dropper?style=solid
    // Solid icon : EyeDropper
    @Suppress("unused") val EyeDropper = SolidIcon(0xf1fb)

    // https://fontawesome.com/icons/eye-slash?style=solid
    // Solid icon : EyeSlash
    @Suppress("unused") val EyeSlash = SolidIcon(0xf070)

    // https://fontawesome.com/icons/eye-slash?style=regular
    // Regular icon : EyeSlash
    @Suppress("unused") val EyeSlashRegular = RegularIcon(0xf070)

    // https://fontawesome.com/icons/facebook?style=brands
    // Brands icon : Facebook
    @Suppress("unused") val Facebook = BrandIcon(0xf09a)

    // https://fontawesome.com/icons/facebook-f?style=brands
    // Brands icon : FacebookF
    @Suppress("unused") val FacebookF = BrandIcon(0xf39e)

    // https://fontawesome.com/icons/facebook-messenger?style=brands
    // Brands icon : FacebookMessenger
    @Suppress("unused") val FacebookMessenger = BrandIcon(0xf39f)

    // https://fontawesome.com/icons/facebook-square?style=brands
    // Brands icon : FacebookSquare
    @Suppress("unused") val FacebookSquare = BrandIcon(0xf082)

    // https://fontawesome.com/icons/fan?style=solid
    // Solid icon : Fan
    @Suppress("unused") val Fan = SolidIcon(0xf863)

    // https://fontawesome.com/icons/fantasy-flight-games?style=brands
    // Brands icon : FantasyFlightGames
    @Suppress("unused") val FantasyFlightGames = BrandIcon(0xf6dc)

    // https://fontawesome.com/icons/fast-backward?style=solid
    // Solid icon : FastBackward
    @Suppress("unused") val FastBackward = SolidIcon(0xf049)

    // https://fontawesome.com/icons/fast-forward?style=solid
    // Solid icon : FastForward
    @Suppress("unused") val FastForward = SolidIcon(0xf050)

    // https://fontawesome.com/icons/faucet?style=solid
    // Solid icon : Faucet
    @Suppress("unused") val Faucet = SolidIcon(0xe005)

    // https://fontawesome.com/icons/fax?style=solid
    // Solid icon : Fax
    @Suppress("unused") val Fax = SolidIcon(0xf1ac)

    // https://fontawesome.com/icons/feather?style=solid
    // Solid icon : Feather
    @Suppress("unused") val Feather = SolidIcon(0xf52d)

    // https://fontawesome.com/icons/feather-alt?style=solid
    // Solid icon : FeatherAlt
    @Suppress("unused") val FeatherAlt = SolidIcon(0xf56b)

    // https://fontawesome.com/icons/fedex?style=brands
    // Brands icon : Fedex
    @Suppress("unused") val Fedex = BrandIcon(0xf797)

    // https://fontawesome.com/icons/fedora?style=brands
    // Brands icon : Fedora
    @Suppress("unused") val Fedora = BrandIcon(0xf798)

    // https://fontawesome.com/icons/female?style=solid
    // Solid icon : Female
    @Suppress("unused") val Female = SolidIcon(0xf182)

    // https://fontawesome.com/icons/fighter-jet?style=solid
    // Solid icon : FighterJet
    @Suppress("unused") val FighterJet = SolidIcon(0xf0fb)

    // https://fontawesome.com/icons/figma?style=brands
    // Brands icon : Figma
    @Suppress("unused") val Figma = BrandIcon(0xf799)

    // https://fontawesome.com/icons/file?style=solid
    // Solid icon : File
    @Suppress("unused") val File = SolidIcon(0xf15b)

    // https://fontawesome.com/icons/file?style=regular
    // Regular icon : File
    @Suppress("unused") val FileRegular = RegularIcon(0xf15b)

    // https://fontawesome.com/icons/file-alt?style=solid
    // Solid icon : FileAlt
    @Suppress("unused") val FileAlt = SolidIcon(0xf15c)

    // https://fontawesome.com/icons/file-alt?style=regular
    // Regular icon : FileAlt
    @Suppress("unused") val FileAltRegular = RegularIcon(0xf15c)

    // https://fontawesome.com/icons/file-archive?style=solid
    // Solid icon : FileArchive
    @Suppress("unused") val FileArchive = SolidIcon(0xf1c6)

    // https://fontawesome.com/icons/file-archive?style=regular
    // Regular icon : FileArchive
    @Suppress("unused") val FileArchiveRegular = RegularIcon(0xf1c6)

    // https://fontawesome.com/icons/file-audio?style=solid
    // Solid icon : FileAudio
    @Suppress("unused") val FileAudio = SolidIcon(0xf1c7)

    // https://fontawesome.com/icons/file-audio?style=regular
    // Regular icon : FileAudio
    @Suppress("unused") val FileAudioRegular = RegularIcon(0xf1c7)

    // https://fontawesome.com/icons/file-code?style=solid
    // Solid icon : FileCode
    @Suppress("unused") val FileCode = SolidIcon(0xf1c9)

    // https://fontawesome.com/icons/file-code?style=regular
    // Regular icon : FileCode
    @Suppress("unused") val FileCodeRegular = RegularIcon(0xf1c9)

    // https://fontawesome.com/icons/file-contract?style=solid
    // Solid icon : FileContract
    @Suppress("unused") val FileContract = SolidIcon(0xf56c)

    // https://fontawesome.com/icons/file-csv?style=solid
    // Solid icon : FileCsv
    @Suppress("unused") val FileCsv = SolidIcon(0xf6dd)

    // https://fontawesome.com/icons/file-download?style=solid
    // Solid icon : FileDownload
    @Suppress("unused") val FileDownload = SolidIcon(0xf56d)

    // https://fontawesome.com/icons/file-excel?style=solid
    // Solid icon : FileExcel
    @Suppress("unused") val FileExcel = SolidIcon(0xf1c3)

    // https://fontawesome.com/icons/file-excel?style=regular
    // Regular icon : FileExcel
    @Suppress("unused") val FileExcelRegular = RegularIcon(0xf1c3)

    // https://fontawesome.com/icons/file-export?style=solid
    // Solid icon : FileExport
    @Suppress("unused") val FileExport = SolidIcon(0xf56e)

    // https://fontawesome.com/icons/file-image?style=solid
    // Solid icon : FileImage
    @Suppress("unused") val FileImage = SolidIcon(0xf1c5)

    // https://fontawesome.com/icons/file-image?style=regular
    // Regular icon : FileImage
    @Suppress("unused") val FileImageRegular = RegularIcon(0xf1c5)

    // https://fontawesome.com/icons/file-import?style=solid
    // Solid icon : FileImport
    @Suppress("unused") val FileImport = SolidIcon(0xf56f)

    // https://fontawesome.com/icons/file-invoice?style=solid
    // Solid icon : FileInvoice
    @Suppress("unused") val FileInvoice = SolidIcon(0xf570)

    // https://fontawesome.com/icons/file-invoice-dollar?style=solid
    // Solid icon : FileInvoiceDollar
    @Suppress("unused") val FileInvoiceDollar = SolidIcon(0xf571)

    // https://fontawesome.com/icons/file-medical?style=solid
    // Solid icon : FileMedical
    @Suppress("unused") val FileMedical = SolidIcon(0xf477)

    // https://fontawesome.com/icons/file-medical-alt?style=solid
    // Solid icon : FileMedicalAlt
    @Suppress("unused") val FileMedicalAlt = SolidIcon(0xf478)

    // https://fontawesome.com/icons/file-pdf?style=solid
    // Solid icon : FilePdf
    @Suppress("unused") val FilePdf = SolidIcon(0xf1c1)

    // https://fontawesome.com/icons/file-pdf?style=regular
    // Regular icon : FilePdf
    @Suppress("unused") val FilePdfRegular = RegularIcon(0xf1c1)

    // https://fontawesome.com/icons/file-powerpoint?style=solid
    // Solid icon : FilePowerpoint
    @Suppress("unused") val FilePowerpoint = SolidIcon(0xf1c4)

    // https://fontawesome.com/icons/file-powerpoint?style=regular
    // Regular icon : FilePowerpoint
    @Suppress("unused") val FilePowerpointRegular = RegularIcon(0xf1c4)

    // https://fontawesome.com/icons/file-prescription?style=solid
    // Solid icon : FilePrescription
    @Suppress("unused") val FilePrescription = SolidIcon(0xf572)

    // https://fontawesome.com/icons/file-signature?style=solid
    // Solid icon : FileSignature
    @Suppress("unused") val FileSignature = SolidIcon(0xf573)

    // https://fontawesome.com/icons/file-upload?style=solid
    // Solid icon : FileUpload
    @Suppress("unused") val FileUpload = SolidIcon(0xf574)

    // https://fontawesome.com/icons/file-video?style=solid
    // Solid icon : FileVideo
    @Suppress("unused") val FileVideo = SolidIcon(0xf1c8)

    // https://fontawesome.com/icons/file-video?style=regular
    // Regular icon : FileVideo
    @Suppress("unused") val FileVideoRegular = RegularIcon(0xf1c8)

    // https://fontawesome.com/icons/file-word?style=solid
    // Solid icon : FileWord
    @Suppress("unused") val FileWord = SolidIcon(0xf1c2)

    // https://fontawesome.com/icons/file-word?style=regular
    // Regular icon : FileWord
    @Suppress("unused") val FileWordRegular = RegularIcon(0xf1c2)

    // https://fontawesome.com/icons/fill?style=solid
    // Solid icon : Fill
    @Suppress("unused") val Fill = SolidIcon(0xf575)

    // https://fontawesome.com/icons/fill-drip?style=solid
    // Solid icon : FillDrip
    @Suppress("unused") val FillDrip = SolidIcon(0xf576)

    // https://fontawesome.com/icons/film?style=solid
    // Solid icon : Film
    @Suppress("unused") val Film = SolidIcon(0xf008)

    // https://fontawesome.com/icons/filter?style=solid
    // Solid icon : Filter
    @Suppress("unused") val Filter = SolidIcon(0xf0b0)

    // https://fontawesome.com/icons/fingerprint?style=solid
    // Solid icon : Fingerprint
    @Suppress("unused") val Fingerprint = SolidIcon(0xf577)

    // https://fontawesome.com/icons/fire?style=solid
    // Solid icon : Fire
    @Suppress("unused") val Fire = SolidIcon(0xf06d)

    // https://fontawesome.com/icons/fire-alt?style=solid
    // Solid icon : FireAlt
    @Suppress("unused") val FireAlt = SolidIcon(0xf7e4)

    // https://fontawesome.com/icons/fire-extinguisher?style=solid
    // Solid icon : FireExtinguisher
    @Suppress("unused") val FireExtinguisher = SolidIcon(0xf134)

    // https://fontawesome.com/icons/firefox?style=brands
    // Brands icon : Firefox
    @Suppress("unused") val Firefox = BrandIcon(0xf269)

    // https://fontawesome.com/icons/firefox-browser?style=brands
    // Brands icon : FirefoxBrowser
    @Suppress("unused") val FirefoxBrowser = BrandIcon(0xe007)

    // https://fontawesome.com/icons/first-aid?style=solid
    // Solid icon : FirstAid
    @Suppress("unused") val FirstAid = SolidIcon(0xf479)

    // https://fontawesome.com/icons/first-order?style=brands
    // Brands icon : FirstOrder
    @Suppress("unused") val FirstOrder = BrandIcon(0xf2b0)

    // https://fontawesome.com/icons/first-order-alt?style=brands
    // Brands icon : FirstOrderAlt
    @Suppress("unused") val FirstOrderAlt = BrandIcon(0xf50a)

    // https://fontawesome.com/icons/firstdraft?style=brands
    // Brands icon : Firstdraft
    @Suppress("unused") val Firstdraft = BrandIcon(0xf3a1)

    // https://fontawesome.com/icons/fish?style=solid
    // Solid icon : Fish
    @Suppress("unused") val Fish = SolidIcon(0xf578)

    // https://fontawesome.com/icons/fist-raised?style=solid
    // Solid icon : FistRaised
    @Suppress("unused") val FistRaised = SolidIcon(0xf6de)

    // https://fontawesome.com/icons/500px?style=brands
    // Brands icon : 500px
    @Suppress("unused") val FiveHundredPX = BrandIcon(0xf26e)

    // https://fontawesome.com/icons/flag?style=solid
    // Solid icon : Flag
    @Suppress("unused") val Flag = SolidIcon(0xf024)

    // https://fontawesome.com/icons/flag?style=regular
    // Regular icon : Flag
    @Suppress("unused") val FlagRegular = RegularIcon(0xf024)

    // https://fontawesome.com/icons/flag-checkered?style=solid
    // Solid icon : FlagCheckered
    @Suppress("unused") val FlagCheckered = SolidIcon(0xf11e)

    // https://fontawesome.com/icons/flag-usa?style=solid
    // Solid icon : FlagUsa
    @Suppress("unused") val FlagUsa = SolidIcon(0xf74d)

    // https://fontawesome.com/icons/flask?style=solid
    // Solid icon : Flask
    @Suppress("unused") val Flask = SolidIcon(0xf0c3)

    // https://fontawesome.com/icons/flickr?style=brands
    // Brands icon : Flickr
    @Suppress("unused") val Flickr = BrandIcon(0xf16e)

    // https://fontawesome.com/icons/flipboard?style=brands
    // Brands icon : Flipboard
    @Suppress("unused") val Flipboard = BrandIcon(0xf44d)

    // https://fontawesome.com/icons/flushed?style=solid
    // Solid icon : Flushed
    @Suppress("unused") val Flushed = SolidIcon(0xf579)

    // https://fontawesome.com/icons/flushed?style=regular
    // Regular icon : Flushed
    @Suppress("unused") val FlushedRegular = RegularIcon(0xf579)

    // https://fontawesome.com/icons/fly?style=brands
    // Brands icon : Fly
    @Suppress("unused") val Fly = BrandIcon(0xf417)

    // https://fontawesome.com/icons/folder?style=solid
    // Solid icon : Folder
    @Suppress("unused") val Folder = SolidIcon(0xf07b)

    // https://fontawesome.com/icons/folder?style=regular
    // Regular icon : Folder
    @Suppress("unused") val FolderRegular = RegularIcon(0xf07b)

    // https://fontawesome.com/icons/folder-minus?style=solid
    // Solid icon : FolderMinus
    @Suppress("unused") val FolderMinus = SolidIcon(0xf65d)

    // https://fontawesome.com/icons/folder-open?style=solid
    // Solid icon : FolderOpen
    @Suppress("unused") val FolderOpen = SolidIcon(0xf07c)

    // https://fontawesome.com/icons/folder-open?style=regular
    // Regular icon : FolderOpen
    @Suppress("unused") val FolderOpenRegular = RegularIcon(0xf07c)

    // https://fontawesome.com/icons/folder-plus?style=solid
    // Solid icon : FolderPlus
    @Suppress("unused") val FolderPlus = SolidIcon(0xf65e)

    // https://fontawesome.com/icons/font?style=solid
    // Solid icon : Font
    @Suppress("unused") val Font = SolidIcon(0xf031)

    // https://fontawesome.com/icons/font-awesome?style=brands
    // Brands icon : FontAwesome
    @Suppress("unused") val FontAwesome = BrandIcon(0xf2b4)

    // https://fontawesome.com/icons/font-awesome-alt?style=brands
    // Brands icon : FontAwesomeAlt
    @Suppress("unused") val FontAwesomeAlt = BrandIcon(0xf35c)

    // https://fontawesome.com/icons/font-awesome-flag?style=brands
    // Brands icon : FontAwesomeFlag
    @Suppress("unused") val FontAwesomeFlag = BrandIcon(0xf425)

    // https://fontawesome.com/icons/fonticons?style=brands
    // Brands icon : Fonticons
    @Suppress("unused") val Fonticons = BrandIcon(0xf280)

    // https://fontawesome.com/icons/fonticons-fi?style=brands
    // Brands icon : FonticonsFi
    @Suppress("unused") val FonticonsFi = BrandIcon(0xf3a2)

    // https://fontawesome.com/icons/football-ball?style=solid
    // Solid icon : FootballBall
    @Suppress("unused") val FootballBall = SolidIcon(0xf44e)

    // https://fontawesome.com/icons/fort-awesome?style=brands
    // Brands icon : FortAwesome
    @Suppress("unused") val FortAwesome = BrandIcon(0xf286)

    // https://fontawesome.com/icons/fort-awesome-alt?style=brands
    // Brands icon : FortAwesomeAlt
    @Suppress("unused") val FortAwesomeAlt = BrandIcon(0xf3a3)

    // https://fontawesome.com/icons/forumbee?style=brands
    // Brands icon : Forumbee
    @Suppress("unused") val Forumbee = BrandIcon(0xf211)

    // https://fontawesome.com/icons/forward?style=solid
    // Solid icon : Forward
    @Suppress("unused") val Forward = SolidIcon(0xf04e)

    // https://fontawesome.com/icons/foursquare?style=brands
    // Brands icon : Foursquare
    @Suppress("unused") val Foursquare = BrandIcon(0xf180)

    // https://fontawesome.com/icons/free-code-camp?style=brands
    // Brands icon : FreeCodeCamp
    @Suppress("unused") val FreeCodeCamp = BrandIcon(0xf2c5)

    // https://fontawesome.com/icons/freebsd?style=brands
    // Brands icon : Freebsd
    @Suppress("unused") val Freebsd = BrandIcon(0xf3a4)

    // https://fontawesome.com/icons/frog?style=solid
    // Solid icon : Frog
    @Suppress("unused") val Frog = SolidIcon(0xf52e)

    // https://fontawesome.com/icons/frown?style=solid
    // Solid icon : Frown
    @Suppress("unused") val Frown = SolidIcon(0xf119)

    // https://fontawesome.com/icons/frown?style=regular
    // Regular icon : Frown
    @Suppress("unused") val FrownRegular = RegularIcon(0xf119)

    // https://fontawesome.com/icons/frown-open?style=solid
    // Solid icon : FrownOpen
    @Suppress("unused") val FrownOpen = SolidIcon(0xf57a)

    // https://fontawesome.com/icons/frown-open?style=regular
    // Regular icon : FrownOpen
    @Suppress("unused") val FrownOpenRegular = RegularIcon(0xf57a)

    // https://fontawesome.com/icons/fulcrum?style=brands
    // Brands icon : Fulcrum
    @Suppress("unused") val Fulcrum = BrandIcon(0xf50b)

    // https://fontawesome.com/icons/funnel-dollar?style=solid
    // Solid icon : FunnelDollar
    @Suppress("unused") val FunnelDollar = SolidIcon(0xf662)

    // https://fontawesome.com/icons/futbol?style=solid
    // Solid icon : Futbol
    @Suppress("unused") val Futbol = SolidIcon(0xf1e3)

    // https://fontawesome.com/icons/futbol?style=regular
    // Regular icon : Futbol
    @Suppress("unused") val FutbolRegular = RegularIcon(0xf1e3)

    // https://fontawesome.com/icons/galactic-republic?style=brands
    // Brands icon : GalacticRepublic
    @Suppress("unused") val GalacticRepublic = BrandIcon(0xf50c)

    // https://fontawesome.com/icons/galactic-senate?style=brands
    // Brands icon : GalacticSenate
    @Suppress("unused") val GalacticSenate = BrandIcon(0xf50d)

    // https://fontawesome.com/icons/gamepad?style=solid
    // Solid icon : Gamepad
    @Suppress("unused") val Gamepad = SolidIcon(0xf11b)

    // https://fontawesome.com/icons/gas-pump?style=solid
    // Solid icon : GasPump
    @Suppress("unused") val GasPump = SolidIcon(0xf52f)

    // https://fontawesome.com/icons/gavel?style=solid
    // Solid icon : Gavel
    @Suppress("unused") val Gavel = SolidIcon(0xf0e3)

    // https://fontawesome.com/icons/gem?style=solid
    // Solid icon : Gem
    @Suppress("unused") val Gem = SolidIcon(0xf3a5)

    // https://fontawesome.com/icons/gem?style=regular
    // Regular icon : Gem
    @Suppress("unused") val GemRegular = RegularIcon(0xf3a5)

    // https://fontawesome.com/icons/genderless?style=solid
    // Solid icon : Genderless
    @Suppress("unused") val Genderless = SolidIcon(0xf22d)

    // https://fontawesome.com/icons/get-pocket?style=brands
    // Brands icon : GetPocket
    @Suppress("unused") val GetPocket = BrandIcon(0xf265)

    // https://fontawesome.com/icons/gg?style=brands
    // Brands icon : Gg
    @Suppress("unused") val Gg = BrandIcon(0xf260)

    // https://fontawesome.com/icons/gg-circle?style=brands
    // Brands icon : GgCircle
    @Suppress("unused") val GgCircle = BrandIcon(0xf261)

    // https://fontawesome.com/icons/ghost?style=solid
    // Solid icon : Ghost
    @Suppress("unused") val Ghost = SolidIcon(0xf6e2)

    // https://fontawesome.com/icons/gift?style=solid
    // Solid icon : Gift
    @Suppress("unused") val Gift = SolidIcon(0xf06b)

    // https://fontawesome.com/icons/gifts?style=solid
    // Solid icon : Gifts
    @Suppress("unused") val Gifts = SolidIcon(0xf79c)

    // https://fontawesome.com/icons/git?style=brands
    // Brands icon : Git
    @Suppress("unused") val Git = BrandIcon(0xf1d3)

    // https://fontawesome.com/icons/git-alt?style=brands
    // Brands icon : GitAlt
    @Suppress("unused") val GitAlt = BrandIcon(0xf841)

    // https://fontawesome.com/icons/git-square?style=brands
    // Brands icon : GitSquare
    @Suppress("unused") val GitSquare = BrandIcon(0xf1d2)

    // https://fontawesome.com/icons/github?style=brands
    // Brands icon : Github
    @Suppress("unused") val Github = BrandIcon(0xf09b)

    // https://fontawesome.com/icons/github-alt?style=brands
    // Brands icon : GithubAlt
    @Suppress("unused") val GithubAlt = BrandIcon(0xf113)

    // https://fontawesome.com/icons/github-square?style=brands
    // Brands icon : GithubSquare
    @Suppress("unused") val GithubSquare = BrandIcon(0xf092)

    // https://fontawesome.com/icons/gitkraken?style=brands
    // Brands icon : Gitkraken
    @Suppress("unused") val Gitkraken = BrandIcon(0xf3a6)

    // https://fontawesome.com/icons/gitlab?style=brands
    // Brands icon : Gitlab
    @Suppress("unused") val Gitlab = BrandIcon(0xf296)

    // https://fontawesome.com/icons/gitter?style=brands
    // Brands icon : Gitter
    @Suppress("unused") val Gitter = BrandIcon(0xf426)

    // https://fontawesome.com/icons/glass-cheers?style=solid
    // Solid icon : GlassCheers
    @Suppress("unused") val GlassCheers = SolidIcon(0xf79f)

    // https://fontawesome.com/icons/glass-martini?style=solid
    // Solid icon : GlassMartini
    @Suppress("unused") val GlassMartini = SolidIcon(0xf000)

    // https://fontawesome.com/icons/glass-martini-alt?style=solid
    // Solid icon : GlassMartiniAlt
    @Suppress("unused") val GlassMartiniAlt = SolidIcon(0xf57b)

    // https://fontawesome.com/icons/glass-whiskey?style=solid
    // Solid icon : GlassWhiskey
    @Suppress("unused") val GlassWhiskey = SolidIcon(0xf7a0)

    // https://fontawesome.com/icons/glasses?style=solid
    // Solid icon : Glasses
    @Suppress("unused") val Glasses = SolidIcon(0xf530)

    // https://fontawesome.com/icons/glide?style=brands
    // Brands icon : Glide
    @Suppress("unused") val Glide = BrandIcon(0xf2a5)

    // https://fontawesome.com/icons/glide-g?style=brands
    // Brands icon : GlideG
    @Suppress("unused") val GlideG = BrandIcon(0xf2a6)

    // https://fontawesome.com/icons/globe?style=solid
    // Solid icon : Globe
    @Suppress("unused") val Globe = SolidIcon(0xf0ac)

    // https://fontawesome.com/icons/globe-africa?style=solid
    // Solid icon : GlobeAfrica
    @Suppress("unused") val GlobeAfrica = SolidIcon(0xf57c)

    // https://fontawesome.com/icons/globe-americas?style=solid
    // Solid icon : GlobeAmericas
    @Suppress("unused") val GlobeAmericas = SolidIcon(0xf57d)

    // https://fontawesome.com/icons/globe-asia?style=solid
    // Solid icon : GlobeAsia
    @Suppress("unused") val GlobeAsia = SolidIcon(0xf57e)

    // https://fontawesome.com/icons/globe-europe?style=solid
    // Solid icon : GlobeEurope
    @Suppress("unused") val GlobeEurope = SolidIcon(0xf7a2)

    // https://fontawesome.com/icons/gofore?style=brands
    // Brands icon : Gofore
    @Suppress("unused") val Gofore = BrandIcon(0xf3a7)

    // https://fontawesome.com/icons/golf-ball?style=solid
    // Solid icon : GolfBall
    @Suppress("unused") val GolfBall = SolidIcon(0xf450)

    // https://fontawesome.com/icons/goodreads?style=brands
    // Brands icon : Goodreads
    @Suppress("unused") val Goodreads = BrandIcon(0xf3a8)

    // https://fontawesome.com/icons/goodreads-g?style=brands
    // Brands icon : GoodreadsG
    @Suppress("unused") val GoodreadsG = BrandIcon(0xf3a9)

    // https://fontawesome.com/icons/google?style=brands
    // Brands icon : Google
    @Suppress("unused") val Google = BrandIcon(0xf1a0)

    // https://fontawesome.com/icons/google-drive?style=brands
    // Brands icon : GoogleDrive
    @Suppress("unused") val GoogleDrive = BrandIcon(0xf3aa)

    // https://fontawesome.com/icons/google-pay?style=brands
    // Brands icon : GooglePay
    @Suppress("unused") val GooglePay = BrandIcon(0xe079)

    // https://fontawesome.com/icons/google-play?style=brands
    // Brands icon : GooglePlay
    @Suppress("unused") val GooglePlay = BrandIcon(0xf3ab)

    // https://fontawesome.com/icons/google-plus?style=brands
    // Brands icon : GooglePlus
    @Suppress("unused") val GooglePlus = BrandIcon(0xf2b3)

    // https://fontawesome.com/icons/google-plus-g?style=brands
    // Brands icon : GooglePlusG
    @Suppress("unused") val GooglePlusG = BrandIcon(0xf0d5)

    // https://fontawesome.com/icons/google-plus-square?style=brands
    // Brands icon : GooglePlusSquare
    @Suppress("unused") val GooglePlusSquare = BrandIcon(0xf0d4)

    // https://fontawesome.com/icons/google-wallet?style=brands
    // Brands icon : GoogleWallet
    @Suppress("unused") val GoogleWallet = BrandIcon(0xf1ee)

    // https://fontawesome.com/icons/gopuram?style=solid
    // Solid icon : Gopuram
    @Suppress("unused") val Gopuram = SolidIcon(0xf664)

    // https://fontawesome.com/icons/graduation-cap?style=solid
    // Solid icon : GraduationCap
    @Suppress("unused") val GraduationCap = SolidIcon(0xf19d)

    // https://fontawesome.com/icons/gratipay?style=brands
    // Brands icon : Gratipay
    @Suppress("unused") val Gratipay = BrandIcon(0xf184)

    // https://fontawesome.com/icons/grav?style=brands
    // Brands icon : Grav
    @Suppress("unused") val Grav = BrandIcon(0xf2d6)

    // https://fontawesome.com/icons/greater-than?style=solid
    // Solid icon : GreaterThan
    @Suppress("unused") val GreaterThan = SolidIcon(0xf531)

    // https://fontawesome.com/icons/greater-than-equal?style=solid
    // Solid icon : GreaterThanEqual
    @Suppress("unused") val GreaterThanEqual = SolidIcon(0xf532)

    // https://fontawesome.com/icons/grimace?style=solid
    // Solid icon : Grimace
    @Suppress("unused") val Grimace = SolidIcon(0xf57f)

    // https://fontawesome.com/icons/grimace?style=regular
    // Regular icon : Grimace
    @Suppress("unused") val GrimaceRegular = RegularIcon(0xf57f)

    // https://fontawesome.com/icons/grin?style=solid
    // Solid icon : Grin
    @Suppress("unused") val Grin = SolidIcon(0xf580)

    // https://fontawesome.com/icons/grin?style=regular
    // Regular icon : Grin
    @Suppress("unused") val GrinRegular = RegularIcon(0xf580)

    // https://fontawesome.com/icons/grin-alt?style=solid
    // Solid icon : GrinAlt
    @Suppress("unused") val GrinAlt = SolidIcon(0xf581)

    // https://fontawesome.com/icons/grin-alt?style=regular
    // Regular icon : GrinAlt
    @Suppress("unused") val GrinAltRegular = RegularIcon(0xf581)

    // https://fontawesome.com/icons/grin-beam?style=solid
    // Solid icon : GrinBeam
    @Suppress("unused") val GrinBeam = SolidIcon(0xf582)

    // https://fontawesome.com/icons/grin-beam?style=regular
    // Regular icon : GrinBeam
    @Suppress("unused") val GrinBeamRegular = RegularIcon(0xf582)

    // https://fontawesome.com/icons/grin-beam-sweat?style=solid
    // Solid icon : GrinBeamSweat
    @Suppress("unused") val GrinBeamSweat = SolidIcon(0xf583)

    // https://fontawesome.com/icons/grin-beam-sweat?style=regular
    // Regular icon : GrinBeamSweat
    @Suppress("unused") val GrinBeamSweatRegular = RegularIcon(0xf583)

    // https://fontawesome.com/icons/grin-hearts?style=solid
    // Solid icon : GrinHearts
    @Suppress("unused") val GrinHearts = SolidIcon(0xf584)

    // https://fontawesome.com/icons/grin-hearts?style=regular
    // Regular icon : GrinHearts
    @Suppress("unused") val GrinHeartsRegular = RegularIcon(0xf584)

    // https://fontawesome.com/icons/grin-squint?style=solid
    // Solid icon : GrinSquint
    @Suppress("unused") val GrinSquint = SolidIcon(0xf585)

    // https://fontawesome.com/icons/grin-squint?style=regular
    // Regular icon : GrinSquint
    @Suppress("unused") val GrinSquintRegular = RegularIcon(0xf585)

    // https://fontawesome.com/icons/grin-squint-tears?style=solid
    // Solid icon : GrinSquintTears
    @Suppress("unused") val GrinSquintTears = SolidIcon(0xf586)

    // https://fontawesome.com/icons/grin-squint-tears?style=regular
    // Regular icon : GrinSquintTears
    @Suppress("unused") val GrinSquintTearsRegular = RegularIcon(0xf586)

    // https://fontawesome.com/icons/grin-stars?style=solid
    // Solid icon : GrinStars
    @Suppress("unused") val GrinStars = SolidIcon(0xf587)

    // https://fontawesome.com/icons/grin-stars?style=regular
    // Regular icon : GrinStars
    @Suppress("unused") val GrinStarsRegular = RegularIcon(0xf587)

    // https://fontawesome.com/icons/grin-tears?style=solid
    // Solid icon : GrinTears
    @Suppress("unused") val GrinTears = SolidIcon(0xf588)

    // https://fontawesome.com/icons/grin-tears?style=regular
    // Regular icon : GrinTears
    @Suppress("unused") val GrinTearsRegular = RegularIcon(0xf588)

    // https://fontawesome.com/icons/grin-tongue?style=solid
    // Solid icon : GrinTongue
    @Suppress("unused") val GrinTongue = SolidIcon(0xf589)

    // https://fontawesome.com/icons/grin-tongue?style=regular
    // Regular icon : GrinTongue
    @Suppress("unused") val GrinTongueRegular = RegularIcon(0xf589)

    // https://fontawesome.com/icons/grin-tongue-squint?style=solid
    // Solid icon : GrinTongueSquint
    @Suppress("unused") val GrinTongueSquint = SolidIcon(0xf58a)

    // https://fontawesome.com/icons/grin-tongue-squint?style=regular
    // Regular icon : GrinTongueSquint
    @Suppress("unused") val GrinTongueSquintRegular = RegularIcon(0xf58a)

    // https://fontawesome.com/icons/grin-tongue-wink?style=solid
    // Solid icon : GrinTongueWink
    @Suppress("unused") val GrinTongueWink = SolidIcon(0xf58b)

    // https://fontawesome.com/icons/grin-tongue-wink?style=regular
    // Regular icon : GrinTongueWink
    @Suppress("unused") val GrinTongueWinkRegular = RegularIcon(0xf58b)

    // https://fontawesome.com/icons/grin-wink?style=solid
    // Solid icon : GrinWink
    @Suppress("unused") val GrinWink = SolidIcon(0xf58c)

    // https://fontawesome.com/icons/grin-wink?style=regular
    // Regular icon : GrinWink
    @Suppress("unused") val GrinWinkRegular = RegularIcon(0xf58c)

    // https://fontawesome.com/icons/grip-horizontal?style=solid
    // Solid icon : GripHorizontal
    @Suppress("unused") val GripHorizontal = SolidIcon(0xf58d)

    // https://fontawesome.com/icons/grip-lines?style=solid
    // Solid icon : GripLines
    @Suppress("unused") val GripLines = SolidIcon(0xf7a4)

    // https://fontawesome.com/icons/grip-lines-vertical?style=solid
    // Solid icon : GripLinesVertical
    @Suppress("unused") val GripLinesVertical = SolidIcon(0xf7a5)

    // https://fontawesome.com/icons/grip-vertical?style=solid
    // Solid icon : GripVertical
    @Suppress("unused") val GripVertical = SolidIcon(0xf58e)

    // https://fontawesome.com/icons/gripfire?style=brands
    // Brands icon : Gripfire
    @Suppress("unused") val Gripfire = BrandIcon(0xf3ac)

    // https://fontawesome.com/icons/grunt?style=brands
    // Brands icon : Grunt
    @Suppress("unused") val Grunt = BrandIcon(0xf3ad)

    // https://fontawesome.com/icons/guilded?style=brands
    // Brands icon : Guilded
    @Suppress("unused") val Guilded = BrandIcon(0xe07e)

    // https://fontawesome.com/icons/guitar?style=solid
    // Solid icon : Guitar
    @Suppress("unused") val Guitar = SolidIcon(0xf7a6)

    // https://fontawesome.com/icons/gulp?style=brands
    // Brands icon : Gulp
    @Suppress("unused") val Gulp = BrandIcon(0xf3ae)

    // https://fontawesome.com/icons/h-square?style=solid
    // Solid icon : HSquare
    @Suppress("unused") val HSquare = SolidIcon(0xf0fd)

    // https://fontawesome.com/icons/hacker-news?style=brands
    // Brands icon : HackerNews
    @Suppress("unused") val HackerNews = BrandIcon(0xf1d4)

    // https://fontawesome.com/icons/hacker-news-square?style=brands
    // Brands icon : HackerNewsSquare
    @Suppress("unused") val HackerNewsSquare = BrandIcon(0xf3af)

    // https://fontawesome.com/icons/hackerrank?style=brands
    // Brands icon : Hackerrank
    @Suppress("unused") val Hackerrank = BrandIcon(0xf5f7)

    // https://fontawesome.com/icons/hamburger?style=solid
    // Solid icon : Hamburger
    @Suppress("unused") val Hamburger = SolidIcon(0xf805)

    // https://fontawesome.com/icons/hammer?style=solid
    // Solid icon : Hammer
    @Suppress("unused") val Hammer = SolidIcon(0xf6e3)

    // https://fontawesome.com/icons/hamsa?style=solid
    // Solid icon : Hamsa
    @Suppress("unused") val Hamsa = SolidIcon(0xf665)

    // https://fontawesome.com/icons/hand-holding?style=solid
    // Solid icon : HandHolding
    @Suppress("unused") val HandHolding = SolidIcon(0xf4bd)

    // https://fontawesome.com/icons/hand-holding-heart?style=solid
    // Solid icon : HandHoldingHeart
    @Suppress("unused") val HandHoldingHeart = SolidIcon(0xf4be)

    // https://fontawesome.com/icons/hand-holding-medical?style=solid
    // Solid icon : HandHoldingMedical
    @Suppress("unused") val HandHoldingMedical = SolidIcon(0xe05c)

    // https://fontawesome.com/icons/hand-holding-usd?style=solid
    // Solid icon : HandHoldingUsd
    @Suppress("unused") val HandHoldingUsd = SolidIcon(0xf4c0)

    // https://fontawesome.com/icons/hand-holding-water?style=solid
    // Solid icon : HandHoldingWater
    @Suppress("unused") val HandHoldingWater = SolidIcon(0xf4c1)

    // https://fontawesome.com/icons/hand-lizard?style=solid
    // Solid icon : HandLizard
    @Suppress("unused") val HandLizard = SolidIcon(0xf258)

    // https://fontawesome.com/icons/hand-lizard?style=regular
    // Regular icon : HandLizard
    @Suppress("unused") val HandLizardRegular = RegularIcon(0xf258)

    // https://fontawesome.com/icons/hand-middle-finger?style=solid
    // Solid icon : HandMiddleFinger
    @Suppress("unused") val HandMiddleFinger = SolidIcon(0xf806)

    // https://fontawesome.com/icons/hand-paper?style=solid
    // Solid icon : HandPaper
    @Suppress("unused") val HandPaper = SolidIcon(0xf256)

    // https://fontawesome.com/icons/hand-paper?style=regular
    // Regular icon : HandPaper
    @Suppress("unused") val HandPaperRegular = RegularIcon(0xf256)

    // https://fontawesome.com/icons/hand-peace?style=solid
    // Solid icon : HandPeace
    @Suppress("unused") val HandPeace = SolidIcon(0xf25b)

    // https://fontawesome.com/icons/hand-peace?style=regular
    // Regular icon : HandPeace
    @Suppress("unused") val HandPeaceRegular = RegularIcon(0xf25b)

    // https://fontawesome.com/icons/hand-point-down?style=solid
    // Solid icon : HandPointDown
    @Suppress("unused") val HandPointDown = SolidIcon(0xf0a7)

    // https://fontawesome.com/icons/hand-point-down?style=regular
    // Regular icon : HandPointDown
    @Suppress("unused") val HandPointDownRegular = RegularIcon(0xf0a7)

    // https://fontawesome.com/icons/hand-point-left?style=solid
    // Solid icon : HandPointLeft
    @Suppress("unused") val HandPointLeft = SolidIcon(0xf0a5)

    // https://fontawesome.com/icons/hand-point-left?style=regular
    // Regular icon : HandPointLeft
    @Suppress("unused") val HandPointLeftRegular = RegularIcon(0xf0a5)

    // https://fontawesome.com/icons/hand-point-right?style=solid
    // Solid icon : HandPointRight
    @Suppress("unused") val HandPointRight = SolidIcon(0xf0a4)

    // https://fontawesome.com/icons/hand-point-right?style=regular
    // Regular icon : HandPointRight
    @Suppress("unused") val HandPointRightRegular = RegularIcon(0xf0a4)

    // https://fontawesome.com/icons/hand-point-up?style=solid
    // Solid icon : HandPointUp
    @Suppress("unused") val HandPointUp = SolidIcon(0xf0a6)

    // https://fontawesome.com/icons/hand-point-up?style=regular
    // Regular icon : HandPointUp
    @Suppress("unused") val HandPointUpRegular = RegularIcon(0xf0a6)

    // https://fontawesome.com/icons/hand-pointer?style=solid
    // Solid icon : HandPointer
    @Suppress("unused") val HandPointer = SolidIcon(0xf25a)

    // https://fontawesome.com/icons/hand-pointer?style=regular
    // Regular icon : HandPointer
    @Suppress("unused") val HandPointerRegular = RegularIcon(0xf25a)

    // https://fontawesome.com/icons/hand-rock?style=solid
    // Solid icon : HandRock
    @Suppress("unused") val HandRock = SolidIcon(0xf255)

    // https://fontawesome.com/icons/hand-rock?style=regular
    // Regular icon : HandRock
    @Suppress("unused") val HandRockRegular = RegularIcon(0xf255)

    // https://fontawesome.com/icons/hand-scissors?style=solid
    // Solid icon : HandScissors
    @Suppress("unused") val HandScissors = SolidIcon(0xf257)

    // https://fontawesome.com/icons/hand-scissors?style=regular
    // Regular icon : HandScissors
    @Suppress("unused") val HandScissorsRegular = RegularIcon(0xf257)

    // https://fontawesome.com/icons/hand-sparkles?style=solid
    // Solid icon : HandSparkles
    @Suppress("unused") val HandSparkles = SolidIcon(0xe05d)

    // https://fontawesome.com/icons/hand-spock?style=solid
    // Solid icon : HandSpock
    @Suppress("unused") val HandSpock = SolidIcon(0xf259)

    // https://fontawesome.com/icons/hand-spock?style=regular
    // Regular icon : HandSpock
    @Suppress("unused") val HandSpockRegular = RegularIcon(0xf259)

    // https://fontawesome.com/icons/hands?style=solid
    // Solid icon : Hands
    @Suppress("unused") val Hands = SolidIcon(0xf4c2)

    // https://fontawesome.com/icons/hands-helping?style=solid
    // Solid icon : HandsHelping
    @Suppress("unused") val HandsHelping = SolidIcon(0xf4c4)

    // https://fontawesome.com/icons/hands-wash?style=solid
    // Solid icon : HandsWash
    @Suppress("unused") val HandsWash = SolidIcon(0xe05e)

    // https://fontawesome.com/icons/handshake?style=solid
    // Solid icon : Handshake
    @Suppress("unused") val Handshake = SolidIcon(0xf2b5)

    // https://fontawesome.com/icons/handshake?style=regular
    // Regular icon : Handshake
    @Suppress("unused") val HandshakeRegular = RegularIcon(0xf2b5)

    // https://fontawesome.com/icons/handshake-alt-slash?style=solid
    // Solid icon : HandshakeAltSlash
    @Suppress("unused") val HandshakeAltSlash = SolidIcon(0xe05f)

    // https://fontawesome.com/icons/handshake-slash?style=solid
    // Solid icon : HandshakeSlash
    @Suppress("unused") val HandshakeSlash = SolidIcon(0xe060)

    // https://fontawesome.com/icons/hanukiah?style=solid
    // Solid icon : Hanukiah
    @Suppress("unused") val Hanukiah = SolidIcon(0xf6e6)

    // https://fontawesome.com/icons/hard-hat?style=solid
    // Solid icon : HardHat
    @Suppress("unused") val HardHat = SolidIcon(0xf807)

    // https://fontawesome.com/icons/hashtag?style=solid
    // Solid icon : Hashtag
    @Suppress("unused") val Hashtag = SolidIcon(0xf292)

    // https://fontawesome.com/icons/hat-cowboy?style=solid
    // Solid icon : HatCowboy
    @Suppress("unused") val HatCowboy = SolidIcon(0xf8c0)

    // https://fontawesome.com/icons/hat-cowboy-side?style=solid
    // Solid icon : HatCowboySide
    @Suppress("unused") val HatCowboySide = SolidIcon(0xf8c1)

    // https://fontawesome.com/icons/hat-wizard?style=solid
    // Solid icon : HatWizard
    @Suppress("unused") val HatWizard = SolidIcon(0xf6e8)

    // https://fontawesome.com/icons/hdd?style=solid
    // Solid icon : Hdd
    @Suppress("unused") val Hdd = SolidIcon(0xf0a0)

    // https://fontawesome.com/icons/hdd?style=regular
    // Regular icon : Hdd
    @Suppress("unused") val HddRegular = RegularIcon(0xf0a0)

    // https://fontawesome.com/icons/head-side-cough?style=solid
    // Solid icon : HeadSideCough
    @Suppress("unused") val HeadSideCough = SolidIcon(0xe061)

    // https://fontawesome.com/icons/head-side-cough-slash?style=solid
    // Solid icon : HeadSideCoughSlash
    @Suppress("unused") val HeadSideCoughSlash = SolidIcon(0xe062)

    // https://fontawesome.com/icons/head-side-mask?style=solid
    // Solid icon : HeadSideMask
    @Suppress("unused") val HeadSideMask = SolidIcon(0xe063)

    // https://fontawesome.com/icons/head-side-virus?style=solid
    // Solid icon : HeadSideVirus
    @Suppress("unused") val HeadSideVirus = SolidIcon(0xe064)

    // https://fontawesome.com/icons/heading?style=solid
    // Solid icon : Heading
    @Suppress("unused") val Heading = SolidIcon(0xf1dc)

    // https://fontawesome.com/icons/headphones?style=solid
    // Solid icon : Headphones
    @Suppress("unused") val Headphones = SolidIcon(0xf025)

    // https://fontawesome.com/icons/headphones-alt?style=solid
    // Solid icon : HeadphonesAlt
    @Suppress("unused") val HeadphonesAlt = SolidIcon(0xf58f)

    // https://fontawesome.com/icons/headset?style=solid
    // Solid icon : Headset
    @Suppress("unused") val Headset = SolidIcon(0xf590)

    // https://fontawesome.com/icons/heart?style=solid
    // Solid icon : Heart
    @Suppress("unused") val Heart = SolidIcon(0xf004)

    // https://fontawesome.com/icons/heart?style=regular
    // Regular icon : Heart
    @Suppress("unused") val HeartRegular = RegularIcon(0xf004)

    // https://fontawesome.com/icons/heart-broken?style=solid
    // Solid icon : HeartBroken
    @Suppress("unused") val HeartBroken = SolidIcon(0xf7a9)

    // https://fontawesome.com/icons/heartbeat?style=solid
    // Solid icon : Heartbeat
    @Suppress("unused") val Heartbeat = SolidIcon(0xf21e)

    // https://fontawesome.com/icons/helicopter?style=solid
    // Solid icon : Helicopter
    @Suppress("unused") val Helicopter = SolidIcon(0xf533)

    // https://fontawesome.com/icons/highlighter?style=solid
    // Solid icon : Highlighter
    @Suppress("unused") val Highlighter = SolidIcon(0xf591)

    // https://fontawesome.com/icons/hiking?style=solid
    // Solid icon : Hiking
    @Suppress("unused") val Hiking = SolidIcon(0xf6ec)

    // https://fontawesome.com/icons/hippo?style=solid
    // Solid icon : Hippo
    @Suppress("unused") val Hippo = SolidIcon(0xf6ed)

    // https://fontawesome.com/icons/hips?style=brands
    // Brands icon : Hips
    @Suppress("unused") val Hips = BrandIcon(0xf452)

    // https://fontawesome.com/icons/hire-a-helper?style=brands
    // Brands icon : HireAHelper
    @Suppress("unused") val HireAHelper = BrandIcon(0xf3b0)

    // https://fontawesome.com/icons/history?style=solid
    // Solid icon : History
    @Suppress("unused") val History = SolidIcon(0xf1da)

    // https://fontawesome.com/icons/hive?style=brands
    // Brands icon : Hive
    @Suppress("unused") val Hive = BrandIcon(0xe07f)

    // https://fontawesome.com/icons/hockey-puck?style=solid
    // Solid icon : HockeyPuck
    @Suppress("unused") val HockeyPuck = SolidIcon(0xf453)

    // https://fontawesome.com/icons/holly-berry?style=solid
    // Solid icon : HollyBerry
    @Suppress("unused") val HollyBerry = SolidIcon(0xf7aa)

    // https://fontawesome.com/icons/home?style=solid
    // Solid icon : Home
    @Suppress("unused") val Home = SolidIcon(0xf015)

    // https://fontawesome.com/icons/hooli?style=brands
    // Brands icon : Hooli
    @Suppress("unused") val Hooli = BrandIcon(0xf427)

    // https://fontawesome.com/icons/hornbill?style=brands
    // Brands icon : Hornbill
    @Suppress("unused") val Hornbill = BrandIcon(0xf592)

    // https://fontawesome.com/icons/horse?style=solid
    // Solid icon : Horse
    @Suppress("unused") val Horse = SolidIcon(0xf6f0)

    // https://fontawesome.com/icons/horse-head?style=solid
    // Solid icon : HorseHead
    @Suppress("unused") val HorseHead = SolidIcon(0xf7ab)

    // https://fontawesome.com/icons/hospital?style=solid
    // Solid icon : Hospital
    @Suppress("unused") val Hospital = SolidIcon(0xf0f8)

    // https://fontawesome.com/icons/hospital?style=regular
    // Regular icon : Hospital
    @Suppress("unused") val HospitalRegular = RegularIcon(0xf0f8)

    // https://fontawesome.com/icons/hospital-alt?style=solid
    // Solid icon : HospitalAlt
    @Suppress("unused") val HospitalAlt = SolidIcon(0xf47d)

    // https://fontawesome.com/icons/hospital-symbol?style=solid
    // Solid icon : HospitalSymbol
    @Suppress("unused") val HospitalSymbol = SolidIcon(0xf47e)

    // https://fontawesome.com/icons/hospital-user?style=solid
    // Solid icon : HospitalUser
    @Suppress("unused") val HospitalUser = SolidIcon(0xf80d)

    // https://fontawesome.com/icons/hot-tub?style=solid
    // Solid icon : HotTub
    @Suppress("unused") val HotTub = SolidIcon(0xf593)

    // https://fontawesome.com/icons/hotdog?style=solid
    // Solid icon : Hotdog
    @Suppress("unused") val Hotdog = SolidIcon(0xf80f)

    // https://fontawesome.com/icons/hotel?style=solid
    // Solid icon : Hotel
    @Suppress("unused") val Hotel = SolidIcon(0xf594)

    // https://fontawesome.com/icons/hotjar?style=brands
    // Brands icon : Hotjar
    @Suppress("unused") val Hotjar = BrandIcon(0xf3b1)

    // https://fontawesome.com/icons/hourglass?style=solid
    // Solid icon : Hourglass
    @Suppress("unused") val Hourglass = SolidIcon(0xf254)

    // https://fontawesome.com/icons/hourglass?style=regular
    // Regular icon : Hourglass
    @Suppress("unused") val HourglassRegular = RegularIcon(0xf254)

    // https://fontawesome.com/icons/hourglass-end?style=solid
    // Solid icon : HourglassEnd
    @Suppress("unused") val HourglassEnd = SolidIcon(0xf253)

    // https://fontawesome.com/icons/hourglass-half?style=solid
    // Solid icon : HourglassHalf
    @Suppress("unused") val HourglassHalf = SolidIcon(0xf252)

    // https://fontawesome.com/icons/hourglass-start?style=solid
    // Solid icon : HourglassStart
    @Suppress("unused") val HourglassStart = SolidIcon(0xf251)

    // https://fontawesome.com/icons/house-damage?style=solid
    // Solid icon : HouseDamage
    @Suppress("unused") val HouseDamage = SolidIcon(0xf6f1)

    // https://fontawesome.com/icons/house-user?style=solid
    // Solid icon : HouseUser
    @Suppress("unused") val HouseUser = SolidIcon(0xe065)

    // https://fontawesome.com/icons/houzz?style=brands
    // Brands icon : Houzz
    @Suppress("unused") val Houzz = BrandIcon(0xf27c)

    // https://fontawesome.com/icons/hryvnia?style=solid
    // Solid icon : Hryvnia
    @Suppress("unused") val Hryvnia = SolidIcon(0xf6f2)

    // https://fontawesome.com/icons/html5?style=brands
    // Brands icon : Html5
    @Suppress("unused") val Html5 = BrandIcon(0xf13b)

    // https://fontawesome.com/icons/hubspot?style=brands
    // Brands icon : Hubspot
    @Suppress("unused") val Hubspot = BrandIcon(0xf3b2)

    // https://fontawesome.com/icons/i-cursor?style=solid
    // Solid icon : ICursor
    @Suppress("unused") val ICursor = SolidIcon(0xf246)

    // https://fontawesome.com/icons/ice-cream?style=solid
    // Solid icon : IceCream
    @Suppress("unused") val IceCream = SolidIcon(0xf810)

    // https://fontawesome.com/icons/icicles?style=solid
    // Solid icon : Icicles
    @Suppress("unused") val Icicles = SolidIcon(0xf7ad)

    // https://fontawesome.com/icons/icons?style=solid
    // Solid icon : Icons
    @Suppress("unused") val Icons = SolidIcon(0xf86d)

    // https://fontawesome.com/icons/id-badge?style=solid
    // Solid icon : IdBadge
    @Suppress("unused") val IdBadge = SolidIcon(0xf2c1)

    // https://fontawesome.com/icons/id-badge?style=regular
    // Regular icon : IdBadge
    @Suppress("unused") val IdBadgeRegular = RegularIcon(0xf2c1)

    // https://fontawesome.com/icons/id-card?style=solid
    // Solid icon : IdCard
    @Suppress("unused") val IdCard = SolidIcon(0xf2c2)

    // https://fontawesome.com/icons/id-card?style=regular
    // Regular icon : IdCard
    @Suppress("unused") val IdCardRegular = RegularIcon(0xf2c2)

    // https://fontawesome.com/icons/id-card-alt?style=solid
    // Solid icon : IdCardAlt
    @Suppress("unused") val IdCardAlt = SolidIcon(0xf47f)

    // https://fontawesome.com/icons/ideal?style=brands
    // Brands icon : Ideal
    @Suppress("unused") val Ideal = BrandIcon(0xe013)

    // https://fontawesome.com/icons/igloo?style=solid
    // Solid icon : Igloo
    @Suppress("unused") val Igloo = SolidIcon(0xf7ae)

    // https://fontawesome.com/icons/image?style=solid
    // Solid icon : Image
    @Suppress("unused") val Image = SolidIcon(0xf03e)

    // https://fontawesome.com/icons/image?style=regular
    // Regular icon : Image
    @Suppress("unused") val ImageRegular = RegularIcon(0xf03e)

    // https://fontawesome.com/icons/images?style=solid
    // Solid icon : Images
    @Suppress("unused") val Images = SolidIcon(0xf302)

    // https://fontawesome.com/icons/images?style=regular
    // Regular icon : Images
    @Suppress("unused") val ImagesRegular = RegularIcon(0xf302)

    // https://fontawesome.com/icons/imdb?style=brands
    // Brands icon : Imdb
    @Suppress("unused") val Imdb = BrandIcon(0xf2d8)

    // https://fontawesome.com/icons/inbox?style=solid
    // Solid icon : Inbox
    @Suppress("unused") val Inbox = SolidIcon(0xf01c)

    // https://fontawesome.com/icons/indent?style=solid
    // Solid icon : Indent
    @Suppress("unused") val Indent = SolidIcon(0xf03c)

    // https://fontawesome.com/icons/industry?style=solid
    // Solid icon : Industry
    @Suppress("unused") val Industry = SolidIcon(0xf275)

    // https://fontawesome.com/icons/infinity?style=solid
    // Solid icon : Infinity
    @Suppress("unused") val Infinity = SolidIcon(0xf534)

    // https://fontawesome.com/icons/info?style=solid
    // Solid icon : Info
    @Suppress("unused") val Info = SolidIcon(0xf129)

    // https://fontawesome.com/icons/info-circle?style=solid
    // Solid icon : InfoCircle
    @Suppress("unused") val InfoCircle = SolidIcon(0xf05a)

    // https://fontawesome.com/icons/innosoft?style=brands
    // Brands icon : Innosoft
    @Suppress("unused") val Innosoft = BrandIcon(0xe080)

    // https://fontawesome.com/icons/instagram?style=brands
    // Brands icon : Instagram
    @Suppress("unused") val Instagram = BrandIcon(0xf16d)

    // https://fontawesome.com/icons/instagram-square?style=brands
    // Brands icon : InstagramSquare
    @Suppress("unused") val InstagramSquare = BrandIcon(0xe055)

    // https://fontawesome.com/icons/instalod?style=brands
    // Brands icon : Instalod
    @Suppress("unused") val Instalod = BrandIcon(0xe081)

    // https://fontawesome.com/icons/intercom?style=brands
    // Brands icon : Intercom
    @Suppress("unused") val Intercom = BrandIcon(0xf7af)

    // https://fontawesome.com/icons/internet-explorer?style=brands
    // Brands icon : InternetExplorer
    @Suppress("unused") val InternetExplorer = BrandIcon(0xf26b)

    // https://fontawesome.com/icons/invision?style=brands
    // Brands icon : Invision
    @Suppress("unused") val Invision = BrandIcon(0xf7b0)

    // https://fontawesome.com/icons/ioxhost?style=brands
    // Brands icon : Ioxhost
    @Suppress("unused") val Ioxhost = BrandIcon(0xf208)

    // https://fontawesome.com/icons/italic?style=solid
    // Solid icon : Italic
    @Suppress("unused") val Italic = SolidIcon(0xf033)

    // https://fontawesome.com/icons/itch-io?style=brands
    // Brands icon : ItchIo
    @Suppress("unused") val ItchIo = BrandIcon(0xf83a)

    // https://fontawesome.com/icons/itunes?style=brands
    // Brands icon : Itunes
    @Suppress("unused") val Itunes = BrandIcon(0xf3b4)

    // https://fontawesome.com/icons/itunes-note?style=brands
    // Brands icon : ItunesNote
    @Suppress("unused") val ItunesNote = BrandIcon(0xf3b5)

    // https://fontawesome.com/icons/java?style=brands
    // Brands icon : Java
    @Suppress("unused") val Java = BrandIcon(0xf4e4)

    // https://fontawesome.com/icons/jedi?style=solid
    // Solid icon : Jedi
    @Suppress("unused") val Jedi = SolidIcon(0xf669)

    // https://fontawesome.com/icons/jedi-order?style=brands
    // Brands icon : JediOrder
    @Suppress("unused") val JediOrder = BrandIcon(0xf50e)

    // https://fontawesome.com/icons/jenkins?style=brands
    // Brands icon : Jenkins
    @Suppress("unused") val Jenkins = BrandIcon(0xf3b6)

    // https://fontawesome.com/icons/jira?style=brands
    // Brands icon : Jira
    @Suppress("unused") val Jira = BrandIcon(0xf7b1)

    // https://fontawesome.com/icons/joget?style=brands
    // Brands icon : Joget
    @Suppress("unused") val Joget = BrandIcon(0xf3b7)

    // https://fontawesome.com/icons/joint?style=solid
    // Solid icon : Joint
    @Suppress("unused") val Joint = SolidIcon(0xf595)

    // https://fontawesome.com/icons/joomla?style=brands
    // Brands icon : Joomla
    @Suppress("unused") val Joomla = BrandIcon(0xf1aa)

    // https://fontawesome.com/icons/journal-whills?style=solid
    // Solid icon : JournalWhills
    @Suppress("unused") val JournalWhills = SolidIcon(0xf66a)

    // https://fontawesome.com/icons/js?style=brands
    // Brands icon : Js
    @Suppress("unused") val Js = BrandIcon(0xf3b8)

    // https://fontawesome.com/icons/js-square?style=brands
    // Brands icon : JsSquare
    @Suppress("unused") val JsSquare = BrandIcon(0xf3b9)

    // https://fontawesome.com/icons/jsfiddle?style=brands
    // Brands icon : Jsfiddle
    @Suppress("unused") val Jsfiddle = BrandIcon(0xf1cc)

    // https://fontawesome.com/icons/kaaba?style=solid
    // Solid icon : Kaaba
    @Suppress("unused") val Kaaba = SolidIcon(0xf66b)

    // https://fontawesome.com/icons/kaggle?style=brands
    // Brands icon : Kaggle
    @Suppress("unused") val Kaggle = BrandIcon(0xf5fa)

    // https://fontawesome.com/icons/key?style=solid
    // Solid icon : Key
    @Suppress("unused") val Key = SolidIcon(0xf084)

    // https://fontawesome.com/icons/keybase?style=brands
    // Brands icon : Keybase
    @Suppress("unused") val Keybase = BrandIcon(0xf4f5)

    // https://fontawesome.com/icons/keyboard?style=solid
    // Solid icon : Keyboard
    @Suppress("unused") val Keyboard = SolidIcon(0xf11c)

    // https://fontawesome.com/icons/keyboard?style=regular
    // Regular icon : Keyboard
    @Suppress("unused") val KeyboardRegular = RegularIcon(0xf11c)

    // https://fontawesome.com/icons/keycdn?style=brands
    // Brands icon : Keycdn
    @Suppress("unused") val Keycdn = BrandIcon(0xf3ba)

    // https://fontawesome.com/icons/khanda?style=solid
    // Solid icon : Khanda
    @Suppress("unused") val Khanda = SolidIcon(0xf66d)

    // https://fontawesome.com/icons/kickstarter?style=brands
    // Brands icon : Kickstarter
    @Suppress("unused") val Kickstarter = BrandIcon(0xf3bb)

    // https://fontawesome.com/icons/kickstarter-k?style=brands
    // Brands icon : KickstarterK
    @Suppress("unused") val KickstarterK = BrandIcon(0xf3bc)

    // https://fontawesome.com/icons/kiss?style=solid
    // Solid icon : Kiss
    @Suppress("unused") val Kiss = SolidIcon(0xf596)

    // https://fontawesome.com/icons/kiss?style=regular
    // Regular icon : Kiss
    @Suppress("unused") val KissRegular = RegularIcon(0xf596)

    // https://fontawesome.com/icons/kiss-beam?style=solid
    // Solid icon : KissBeam
    @Suppress("unused") val KissBeam = SolidIcon(0xf597)

    // https://fontawesome.com/icons/kiss-beam?style=regular
    // Regular icon : KissBeam
    @Suppress("unused") val KissBeamRegular = RegularIcon(0xf597)

    // https://fontawesome.com/icons/kiss-wink-heart?style=solid
    // Solid icon : KissWinkHeart
    @Suppress("unused") val KissWinkHeart = SolidIcon(0xf598)

    // https://fontawesome.com/icons/kiss-wink-heart?style=regular
    // Regular icon : KissWinkHeart
    @Suppress("unused") val KissWinkHeartRegular = RegularIcon(0xf598)

    // https://fontawesome.com/icons/kiwi-bird?style=solid
    // Solid icon : KiwiBird
    @Suppress("unused") val KiwiBird = SolidIcon(0xf535)

    // https://fontawesome.com/icons/korvue?style=brands
    // Brands icon : Korvue
    @Suppress("unused") val Korvue = BrandIcon(0xf42f)

    // https://fontawesome.com/icons/landmark?style=solid
    // Solid icon : Landmark
    @Suppress("unused") val Landmark = SolidIcon(0xf66f)

    // https://fontawesome.com/icons/language?style=solid
    // Solid icon : Language
    @Suppress("unused") val Language = SolidIcon(0xf1ab)

    // https://fontawesome.com/icons/laptop?style=solid
    // Solid icon : Laptop
    @Suppress("unused") val Laptop = SolidIcon(0xf109)

    // https://fontawesome.com/icons/laptop-code?style=solid
    // Solid icon : LaptopCode
    @Suppress("unused") val LaptopCode = SolidIcon(0xf5fc)

    // https://fontawesome.com/icons/laptop-house?style=solid
    // Solid icon : LaptopHouse
    @Suppress("unused") val LaptopHouse = SolidIcon(0xe066)

    // https://fontawesome.com/icons/laptop-medical?style=solid
    // Solid icon : LaptopMedical
    @Suppress("unused") val LaptopMedical = SolidIcon(0xf812)

    // https://fontawesome.com/icons/laravel?style=brands
    // Brands icon : Laravel
    @Suppress("unused") val Laravel = BrandIcon(0xf3bd)

    // https://fontawesome.com/icons/lastfm?style=brands
    // Brands icon : Lastfm
    @Suppress("unused") val Lastfm = BrandIcon(0xf202)

    // https://fontawesome.com/icons/lastfm-square?style=brands
    // Brands icon : LastfmSquare
    @Suppress("unused") val LastfmSquare = BrandIcon(0xf203)

    // https://fontawesome.com/icons/laugh?style=solid
    // Solid icon : Laugh
    @Suppress("unused") val Laugh = SolidIcon(0xf599)

    // https://fontawesome.com/icons/laugh?style=regular
    // Regular icon : Laugh
    @Suppress("unused") val LaughRegular = RegularIcon(0xf599)

    // https://fontawesome.com/icons/laugh-beam?style=solid
    // Solid icon : LaughBeam
    @Suppress("unused") val LaughBeam = SolidIcon(0xf59a)

    // https://fontawesome.com/icons/laugh-beam?style=regular
    // Regular icon : LaughBeam
    @Suppress("unused") val LaughBeamRegular = RegularIcon(0xf59a)

    // https://fontawesome.com/icons/laugh-squint?style=solid
    // Solid icon : LaughSquint
    @Suppress("unused") val LaughSquint = SolidIcon(0xf59b)

    // https://fontawesome.com/icons/laugh-squint?style=regular
    // Regular icon : LaughSquint
    @Suppress("unused") val LaughSquintRegular = RegularIcon(0xf59b)

    // https://fontawesome.com/icons/laugh-wink?style=solid
    // Solid icon : LaughWink
    @Suppress("unused") val LaughWink = SolidIcon(0xf59c)

    // https://fontawesome.com/icons/laugh-wink?style=regular
    // Regular icon : LaughWink
    @Suppress("unused") val LaughWinkRegular = RegularIcon(0xf59c)

    // https://fontawesome.com/icons/layer-group?style=solid
    // Solid icon : LayerGroup
    @Suppress("unused") val LayerGroup = SolidIcon(0xf5fd)

    // https://fontawesome.com/icons/leaf?style=solid
    // Solid icon : Leaf
    @Suppress("unused") val Leaf = SolidIcon(0xf06c)

    // https://fontawesome.com/icons/leanpub?style=brands
    // Brands icon : Leanpub
    @Suppress("unused") val Leanpub = BrandIcon(0xf212)

    // https://fontawesome.com/icons/lemon?style=solid
    // Solid icon : Lemon
    @Suppress("unused") val Lemon = SolidIcon(0xf094)

    // https://fontawesome.com/icons/lemon?style=regular
    // Regular icon : Lemon
    @Suppress("unused") val LemonRegular = RegularIcon(0xf094)

    // https://fontawesome.com/icons/less?style=brands
    // Brands icon : Less
    @Suppress("unused") val Less = BrandIcon(0xf41d)

    // https://fontawesome.com/icons/less-than?style=solid
    // Solid icon : LessThan
    @Suppress("unused") val LessThan = SolidIcon(0xf536)

    // https://fontawesome.com/icons/less-than-equal?style=solid
    // Solid icon : LessThanEqual
    @Suppress("unused") val LessThanEqual = SolidIcon(0xf537)

    // https://fontawesome.com/icons/level-down-alt?style=solid
    // Solid icon : LevelDownAlt
    @Suppress("unused") val LevelDownAlt = SolidIcon(0xf3be)

    // https://fontawesome.com/icons/level-up-alt?style=solid
    // Solid icon : LevelUpAlt
    @Suppress("unused") val LevelUpAlt = SolidIcon(0xf3bf)

    // https://fontawesome.com/icons/life-ring?style=solid
    // Solid icon : LifeRing
    @Suppress("unused") val LifeRing = SolidIcon(0xf1cd)

    // https://fontawesome.com/icons/life-ring?style=regular
    // Regular icon : LifeRing
    @Suppress("unused") val LifeRingRegular = RegularIcon(0xf1cd)

    // https://fontawesome.com/icons/lightbulb?style=solid
    // Solid icon : Lightbulb
    @Suppress("unused") val Lightbulb = SolidIcon(0xf0eb)

    // https://fontawesome.com/icons/lightbulb?style=regular
    // Regular icon : Lightbulb
    @Suppress("unused") val LightbulbRegular = RegularIcon(0xf0eb)

    // https://fontawesome.com/icons/line?style=brands
    // Brands icon : Line
    @Suppress("unused") val Line = BrandIcon(0xf3c0)

    // https://fontawesome.com/icons/link?style=solid
    // Solid icon : Link
    @Suppress("unused") val Link = SolidIcon(0xf0c1)

    // https://fontawesome.com/icons/linkedin?style=brands
    // Brands icon : Linkedin
    @Suppress("unused") val Linkedin = BrandIcon(0xf08c)

    // https://fontawesome.com/icons/linkedin-in?style=brands
    // Brands icon : LinkedinIn
    @Suppress("unused") val LinkedinIn = BrandIcon(0xf0e1)

    // https://fontawesome.com/icons/linode?style=brands
    // Brands icon : Linode
    @Suppress("unused") val Linode = BrandIcon(0xf2b8)

    // https://fontawesome.com/icons/linux?style=brands
    // Brands icon : Linux
    @Suppress("unused") val Linux = BrandIcon(0xf17c)

    // https://fontawesome.com/icons/lira-sign?style=solid
    // Solid icon : LiraSign
    @Suppress("unused") val LiraSign = SolidIcon(0xf195)

    // https://fontawesome.com/icons/list?style=solid
    // Solid icon : List
    @Suppress("unused") val List = SolidIcon(0xf03a)

    // https://fontawesome.com/icons/list-alt?style=solid
    // Solid icon : ListAlt
    @Suppress("unused") val ListAlt = SolidIcon(0xf022)

    // https://fontawesome.com/icons/list-alt?style=regular
    // Regular icon : ListAlt
    @Suppress("unused") val ListAltRegular = RegularIcon(0xf022)

    // https://fontawesome.com/icons/list-ol?style=solid
    // Solid icon : ListOl
    @Suppress("unused") val ListOl = SolidIcon(0xf0cb)

    // https://fontawesome.com/icons/list-ul?style=solid
    // Solid icon : ListUl
    @Suppress("unused") val ListUl = SolidIcon(0xf0ca)

    // https://fontawesome.com/icons/location-arrow?style=solid
    // Solid icon : LocationArrow
    @Suppress("unused") val LocationArrow = SolidIcon(0xf124)

    // https://fontawesome.com/icons/lock?style=solid
    // Solid icon : Lock
    @Suppress("unused") val Lock = SolidIcon(0xf023)

    // https://fontawesome.com/icons/lock-open?style=solid
    // Solid icon : LockOpen
    @Suppress("unused") val LockOpen = SolidIcon(0xf3c1)

    // https://fontawesome.com/icons/long-arrow-alt-down?style=solid
    // Solid icon : LongArrowAltDown
    @Suppress("unused") val LongArrowAltDown = SolidIcon(0xf309)

    // https://fontawesome.com/icons/long-arrow-alt-left?style=solid
    // Solid icon : LongArrowAltLeft
    @Suppress("unused") val LongArrowAltLeft = SolidIcon(0xf30a)

    // https://fontawesome.com/icons/long-arrow-alt-right?style=solid
    // Solid icon : LongArrowAltRight
    @Suppress("unused") val LongArrowAltRight = SolidIcon(0xf30b)

    // https://fontawesome.com/icons/long-arrow-alt-up?style=solid
    // Solid icon : LongArrowAltUp
    @Suppress("unused") val LongArrowAltUp = SolidIcon(0xf30c)

    // https://fontawesome.com/icons/low-vision?style=solid
    // Solid icon : LowVision
    @Suppress("unused") val LowVision = SolidIcon(0xf2a8)

    // https://fontawesome.com/icons/luggage-cart?style=solid
    // Solid icon : LuggageCart
    @Suppress("unused") val LuggageCart = SolidIcon(0xf59d)

    // https://fontawesome.com/icons/lungs?style=solid
    // Solid icon : Lungs
    @Suppress("unused") val Lungs = SolidIcon(0xf604)

    // https://fontawesome.com/icons/lungs-virus?style=solid
    // Solid icon : LungsVirus
    @Suppress("unused") val LungsVirus = SolidIcon(0xe067)

    // https://fontawesome.com/icons/lyft?style=brands
    // Brands icon : Lyft
    @Suppress("unused") val Lyft = BrandIcon(0xf3c3)

    // https://fontawesome.com/icons/magento?style=brands
    // Brands icon : Magento
    @Suppress("unused") val Magento = BrandIcon(0xf3c4)

    // https://fontawesome.com/icons/magic?style=solid
    // Solid icon : Magic
    @Suppress("unused") val Magic = SolidIcon(0xf0d0)

    // https://fontawesome.com/icons/magnet?style=solid
    // Solid icon : Magnet
    @Suppress("unused") val Magnet = SolidIcon(0xf076)

    // https://fontawesome.com/icons/mail-bulk?style=solid
    // Solid icon : MailBulk
    @Suppress("unused") val MailBulk = SolidIcon(0xf674)

    // https://fontawesome.com/icons/mailchimp?style=brands
    // Brands icon : Mailchimp
    @Suppress("unused") val Mailchimp = BrandIcon(0xf59e)

    // https://fontawesome.com/icons/male?style=solid
    // Solid icon : Male
    @Suppress("unused") val Male = SolidIcon(0xf183)

    // https://fontawesome.com/icons/mandalorian?style=brands
    // Brands icon : Mandalorian
    @Suppress("unused") val Mandalorian = BrandIcon(0xf50f)

    // https://fontawesome.com/icons/map?style=solid
    // Solid icon : Map
    @Suppress("unused") val Map = SolidIcon(0xf279)

    // https://fontawesome.com/icons/map?style=regular
    // Regular icon : Map
    @Suppress("unused") val MapRegular = RegularIcon(0xf279)

    // https://fontawesome.com/icons/map-marked?style=solid
    // Solid icon : MapMarked
    @Suppress("unused") val MapMarked = SolidIcon(0xf59f)

    // https://fontawesome.com/icons/map-marked-alt?style=solid
    // Solid icon : MapMarkedAlt
    @Suppress("unused") val MapMarkedAlt = SolidIcon(0xf5a0)

    // https://fontawesome.com/icons/map-marker?style=solid
    // Solid icon : MapMarker
    @Suppress("unused") val MapMarker = SolidIcon(0xf041)

    // https://fontawesome.com/icons/map-marker-alt?style=solid
    // Solid icon : MapMarkerAlt
    @Suppress("unused") val MapMarkerAlt = SolidIcon(0xf3c5)

    // https://fontawesome.com/icons/map-pin?style=solid
    // Solid icon : MapPin
    @Suppress("unused") val MapPin = SolidIcon(0xf276)

    // https://fontawesome.com/icons/map-signs?style=solid
    // Solid icon : MapSigns
    @Suppress("unused") val MapSigns = SolidIcon(0xf277)

    // https://fontawesome.com/icons/markdown?style=brands
    // Brands icon : Markdown
    @Suppress("unused") val Markdown = BrandIcon(0xf60f)

    // https://fontawesome.com/icons/marker?style=solid
    // Solid icon : Marker
    @Suppress("unused") val Marker = SolidIcon(0xf5a1)

    // https://fontawesome.com/icons/mars?style=solid
    // Solid icon : Mars
    @Suppress("unused") val Mars = SolidIcon(0xf222)

    // https://fontawesome.com/icons/mars-double?style=solid
    // Solid icon : MarsDouble
    @Suppress("unused") val MarsDouble = SolidIcon(0xf227)

    // https://fontawesome.com/icons/mars-stroke?style=solid
    // Solid icon : MarsStroke
    @Suppress("unused") val MarsStroke = SolidIcon(0xf229)

    // https://fontawesome.com/icons/mars-stroke-h?style=solid
    // Solid icon : MarsStrokeH
    @Suppress("unused") val MarsStrokeH = SolidIcon(0xf22b)

    // https://fontawesome.com/icons/mars-stroke-v?style=solid
    // Solid icon : MarsStrokeV
    @Suppress("unused") val MarsStrokeV = SolidIcon(0xf22a)

    // https://fontawesome.com/icons/mask?style=solid
    // Solid icon : Mask
    @Suppress("unused") val Mask = SolidIcon(0xf6fa)

    // https://fontawesome.com/icons/mastodon?style=brands
    // Brands icon : Mastodon
    @Suppress("unused") val Mastodon = BrandIcon(0xf4f6)

    // https://fontawesome.com/icons/maxcdn?style=brands
    // Brands icon : Maxcdn
    @Suppress("unused") val Maxcdn = BrandIcon(0xf136)

    // https://fontawesome.com/icons/mdb?style=brands
    // Brands icon : Mdb
    @Suppress("unused") val Mdb = BrandIcon(0xf8ca)

    // https://fontawesome.com/icons/medal?style=solid
    // Solid icon : Medal
    @Suppress("unused") val Medal = SolidIcon(0xf5a2)

    // https://fontawesome.com/icons/medapps?style=brands
    // Brands icon : Medapps
    @Suppress("unused") val Medapps = BrandIcon(0xf3c6)

    // https://fontawesome.com/icons/medium?style=brands
    // Brands icon : Medium
    @Suppress("unused") val Medium = BrandIcon(0xf23a)

    // https://fontawesome.com/icons/medium-m?style=brands
    // Brands icon : MediumM
    @Suppress("unused") val MediumM = BrandIcon(0xf3c7)

    // https://fontawesome.com/icons/medkit?style=solid
    // Solid icon : Medkit
    @Suppress("unused") val Medkit = SolidIcon(0xf0fa)

    // https://fontawesome.com/icons/medrt?style=brands
    // Brands icon : Medrt
    @Suppress("unused") val Medrt = BrandIcon(0xf3c8)

    // https://fontawesome.com/icons/meetup?style=brands
    // Brands icon : Meetup
    @Suppress("unused") val Meetup = BrandIcon(0xf2e0)

    // https://fontawesome.com/icons/megaport?style=brands
    // Brands icon : Megaport
    @Suppress("unused") val Megaport = BrandIcon(0xf5a3)

    // https://fontawesome.com/icons/meh?style=solid
    // Solid icon : Meh
    @Suppress("unused") val Meh = SolidIcon(0xf11a)

    // https://fontawesome.com/icons/meh?style=regular
    // Regular icon : Meh
    @Suppress("unused") val MehRegular = RegularIcon(0xf11a)

    // https://fontawesome.com/icons/meh-blank?style=solid
    // Solid icon : MehBlank
    @Suppress("unused") val MehBlank = SolidIcon(0xf5a4)

    // https://fontawesome.com/icons/meh-blank?style=regular
    // Regular icon : MehBlank
    @Suppress("unused") val MehBlankRegular = RegularIcon(0xf5a4)

    // https://fontawesome.com/icons/meh-rolling-eyes?style=solid
    // Solid icon : MehRollingEyes
    @Suppress("unused") val MehRollingEyes = SolidIcon(0xf5a5)

    // https://fontawesome.com/icons/meh-rolling-eyes?style=regular
    // Regular icon : MehRollingEyes
    @Suppress("unused") val MehRollingEyesRegular = RegularIcon(0xf5a5)

    // https://fontawesome.com/icons/memory?style=solid
    // Solid icon : Memory
    @Suppress("unused") val Memory = SolidIcon(0xf538)

    // https://fontawesome.com/icons/mendeley?style=brands
    // Brands icon : Mendeley
    @Suppress("unused") val Mendeley = BrandIcon(0xf7b3)

    // https://fontawesome.com/icons/menorah?style=solid
    // Solid icon : Menorah
    @Suppress("unused") val Menorah = SolidIcon(0xf676)

    // https://fontawesome.com/icons/mercury?style=solid
    // Solid icon : Mercury
    @Suppress("unused") val Mercury = SolidIcon(0xf223)

    // https://fontawesome.com/icons/meteor?style=solid
    // Solid icon : Meteor
    @Suppress("unused") val Meteor = SolidIcon(0xf753)

    // https://fontawesome.com/icons/microblog?style=brands
    // Brands icon : Microblog
    @Suppress("unused") val Microblog = BrandIcon(0xe01a)

    // https://fontawesome.com/icons/microchip?style=solid
    // Solid icon : Microchip
    @Suppress("unused") val Microchip = SolidIcon(0xf2db)

    // https://fontawesome.com/icons/microphone?style=solid
    // Solid icon : Microphone
    @Suppress("unused") val Microphone = SolidIcon(0xf130)

    // https://fontawesome.com/icons/microphone-alt?style=solid
    // Solid icon : MicrophoneAlt
    @Suppress("unused") val MicrophoneAlt = SolidIcon(0xf3c9)

    // https://fontawesome.com/icons/microphone-alt-slash?style=solid
    // Solid icon : MicrophoneAltSlash
    @Suppress("unused") val MicrophoneAltSlash = SolidIcon(0xf539)

    // https://fontawesome.com/icons/microphone-slash?style=solid
    // Solid icon : MicrophoneSlash
    @Suppress("unused") val MicrophoneSlash = SolidIcon(0xf131)

    // https://fontawesome.com/icons/microscope?style=solid
    // Solid icon : Microscope
    @Suppress("unused") val Microscope = SolidIcon(0xf610)

    // https://fontawesome.com/icons/microsoft?style=brands
    // Brands icon : Microsoft
    @Suppress("unused") val Microsoft = BrandIcon(0xf3ca)

    // https://fontawesome.com/icons/minus?style=solid
    // Solid icon : Minus
    @Suppress("unused") val Minus = SolidIcon(0xf068)

    // https://fontawesome.com/icons/minus-circle?style=solid
    // Solid icon : MinusCircle
    @Suppress("unused") val MinusCircle = SolidIcon(0xf056)

    // https://fontawesome.com/icons/minus-square?style=solid
    // Solid icon : MinusSquare
    @Suppress("unused") val MinusSquare = SolidIcon(0xf146)

    // https://fontawesome.com/icons/minus-square?style=regular
    // Regular icon : MinusSquare
    @Suppress("unused") val MinusSquareRegular = RegularIcon(0xf146)

    // https://fontawesome.com/icons/mitten?style=solid
    // Solid icon : Mitten
    @Suppress("unused") val Mitten = SolidIcon(0xf7b5)

    // https://fontawesome.com/icons/mix?style=brands
    // Brands icon : Mix
    @Suppress("unused") val Mix = BrandIcon(0xf3cb)

    // https://fontawesome.com/icons/mixcloud?style=brands
    // Brands icon : Mixcloud
    @Suppress("unused") val Mixcloud = BrandIcon(0xf289)

    // https://fontawesome.com/icons/mixer?style=brands
    // Brands icon : Mixer
    @Suppress("unused") val Mixer = BrandIcon(0xe056)

    // https://fontawesome.com/icons/mizuni?style=brands
    // Brands icon : Mizuni
    @Suppress("unused") val Mizuni = BrandIcon(0xf3cc)

    // https://fontawesome.com/icons/mobile?style=solid
    // Solid icon : Mobile
    @Suppress("unused") val Mobile = SolidIcon(0xf10b)

    // https://fontawesome.com/icons/mobile-alt?style=solid
    // Solid icon : MobileAlt
    @Suppress("unused") val MobileAlt = SolidIcon(0xf3cd)

    // https://fontawesome.com/icons/modx?style=brands
    // Brands icon : Modx
    @Suppress("unused") val Modx = BrandIcon(0xf285)

    // https://fontawesome.com/icons/monero?style=brands
    // Brands icon : Monero
    @Suppress("unused") val Monero = BrandIcon(0xf3d0)

    // https://fontawesome.com/icons/money-bill?style=solid
    // Solid icon : MoneyBill
    @Suppress("unused") val MoneyBill = SolidIcon(0xf0d6)

    // https://fontawesome.com/icons/money-bill-alt?style=solid
    // Solid icon : MoneyBillAlt
    @Suppress("unused") val MoneyBillAlt = SolidIcon(0xf3d1)

    // https://fontawesome.com/icons/money-bill-alt?style=regular
    // Regular icon : MoneyBillAlt
    @Suppress("unused") val MoneyBillAltRegular = RegularIcon(0xf3d1)

    // https://fontawesome.com/icons/money-bill-wave?style=solid
    // Solid icon : MoneyBillWave
    @Suppress("unused") val MoneyBillWave = SolidIcon(0xf53a)

    // https://fontawesome.com/icons/money-bill-wave-alt?style=solid
    // Solid icon : MoneyBillWaveAlt
    @Suppress("unused") val MoneyBillWaveAlt = SolidIcon(0xf53b)

    // https://fontawesome.com/icons/money-check?style=solid
    // Solid icon : MoneyCheck
    @Suppress("unused") val MoneyCheck = SolidIcon(0xf53c)

    // https://fontawesome.com/icons/money-check-alt?style=solid
    // Solid icon : MoneyCheckAlt
    @Suppress("unused") val MoneyCheckAlt = SolidIcon(0xf53d)

    // https://fontawesome.com/icons/monument?style=solid
    // Solid icon : Monument
    @Suppress("unused") val Monument = SolidIcon(0xf5a6)

    // https://fontawesome.com/icons/moon?style=solid
    // Solid icon : Moon
    @Suppress("unused") val Moon = SolidIcon(0xf186)

    // https://fontawesome.com/icons/moon?style=regular
    // Regular icon : Moon
    @Suppress("unused") val MoonRegular = RegularIcon(0xf186)

    // https://fontawesome.com/icons/mortar-pestle?style=solid
    // Solid icon : MortarPestle
    @Suppress("unused") val MortarPestle = SolidIcon(0xf5a7)

    // https://fontawesome.com/icons/mosque?style=solid
    // Solid icon : Mosque
    @Suppress("unused") val Mosque = SolidIcon(0xf678)

    // https://fontawesome.com/icons/motorcycle?style=solid
    // Solid icon : Motorcycle
    @Suppress("unused") val Motorcycle = SolidIcon(0xf21c)

    // https://fontawesome.com/icons/mountain?style=solid
    // Solid icon : Mountain
    @Suppress("unused") val Mountain = SolidIcon(0xf6fc)

    // https://fontawesome.com/icons/mouse?style=solid
    // Solid icon : Mouse
    @Suppress("unused") val Mouse = SolidIcon(0xf8cc)

    // https://fontawesome.com/icons/mouse-pointer?style=solid
    // Solid icon : MousePointer
    @Suppress("unused") val MousePointer = SolidIcon(0xf245)

    // https://fontawesome.com/icons/mug-hot?style=solid
    // Solid icon : MugHot
    @Suppress("unused") val MugHot = SolidIcon(0xf7b6)

    // https://fontawesome.com/icons/music?style=solid
    // Solid icon : Music
    @Suppress("unused") val Music = SolidIcon(0xf001)

    // https://fontawesome.com/icons/napster?style=brands
    // Brands icon : Napster
    @Suppress("unused") val Napster = BrandIcon(0xf3d2)

    // https://fontawesome.com/icons/neos?style=brands
    // Brands icon : Neos
    @Suppress("unused") val Neos = BrandIcon(0xf612)

    // https://fontawesome.com/icons/network-wired?style=solid
    // Solid icon : NetworkWired
    @Suppress("unused") val NetworkWired = SolidIcon(0xf6ff)

    // https://fontawesome.com/icons/neuter?style=solid
    // Solid icon : Neuter
    @Suppress("unused") val Neuter = SolidIcon(0xf22c)

    // https://fontawesome.com/icons/newspaper?style=solid
    // Solid icon : Newspaper
    @Suppress("unused") val Newspaper = SolidIcon(0xf1ea)

    // https://fontawesome.com/icons/newspaper?style=regular
    // Regular icon : Newspaper
    @Suppress("unused") val NewspaperRegular = RegularIcon(0xf1ea)

    // https://fontawesome.com/icons/nimblr?style=brands
    // Brands icon : Nimblr
    @Suppress("unused") val Nimblr = BrandIcon(0xf5a8)

    // https://fontawesome.com/icons/node?style=brands
    // Brands icon : Node
    @Suppress("unused") val Node = BrandIcon(0xf419)

    // https://fontawesome.com/icons/node-js?style=brands
    // Brands icon : NodeJs
    @Suppress("unused") val NodeJs = BrandIcon(0xf3d3)

    // https://fontawesome.com/icons/not-equal?style=solid
    // Solid icon : NotEqual
    @Suppress("unused") val NotEqual = SolidIcon(0xf53e)

    // https://fontawesome.com/icons/notes-medical?style=solid
    // Solid icon : NotesMedical
    @Suppress("unused") val NotesMedical = SolidIcon(0xf481)

    // https://fontawesome.com/icons/npm?style=brands
    // Brands icon : Npm
    @Suppress("unused") val Npm = BrandIcon(0xf3d4)

    // https://fontawesome.com/icons/ns8?style=brands
    // Brands icon : Ns8
    @Suppress("unused") val Ns8 = BrandIcon(0xf3d5)

    // https://fontawesome.com/icons/nutritionix?style=brands
    // Brands icon : Nutritionix
    @Suppress("unused") val Nutritionix = BrandIcon(0xf3d6)

    // https://fontawesome.com/icons/object-group?style=solid
    // Solid icon : ObjectGroup
    @Suppress("unused") val ObjectGroup = SolidIcon(0xf247)

    // https://fontawesome.com/icons/object-group?style=regular
    // Regular icon : ObjectGroup
    @Suppress("unused") val ObjectGroupRegular = RegularIcon(0xf247)

    // https://fontawesome.com/icons/object-ungroup?style=solid
    // Solid icon : ObjectUngroup
    @Suppress("unused") val ObjectUngroup = SolidIcon(0xf248)

    // https://fontawesome.com/icons/object-ungroup?style=regular
    // Regular icon : ObjectUngroup
    @Suppress("unused") val ObjectUngroupRegular = RegularIcon(0xf248)

    // https://fontawesome.com/icons/octopus-deploy?style=brands
    // Brands icon : OctopusDeploy
    @Suppress("unused") val OctopusDeploy = BrandIcon(0xe082)

    // https://fontawesome.com/icons/odnoklassniki?style=brands
    // Brands icon : Odnoklassniki
    @Suppress("unused") val Odnoklassniki = BrandIcon(0xf263)

    // https://fontawesome.com/icons/odnoklassniki-square?style=brands
    // Brands icon : OdnoklassnikiSquare
    @Suppress("unused") val OdnoklassnikiSquare = BrandIcon(0xf264)

    // https://fontawesome.com/icons/oil-can?style=solid
    // Solid icon : OilCan
    @Suppress("unused") val OilCan = SolidIcon(0xf613)

    // https://fontawesome.com/icons/old-republic?style=brands
    // Brands icon : OldRepublic
    @Suppress("unused") val OldRepublic = BrandIcon(0xf510)

    // https://fontawesome.com/icons/om?style=solid
    // Solid icon : Om
    @Suppress("unused") val Om = SolidIcon(0xf679)

    // https://fontawesome.com/icons/opencart?style=brands
    // Brands icon : Opencart
    @Suppress("unused") val Opencart = BrandIcon(0xf23d)

    // https://fontawesome.com/icons/openid?style=brands
    // Brands icon : Openid
    @Suppress("unused") val Openid = BrandIcon(0xf19b)

    // https://fontawesome.com/icons/opera?style=brands
    // Brands icon : Opera
    @Suppress("unused") val Opera = BrandIcon(0xf26a)

    // https://fontawesome.com/icons/optin-monster?style=brands
    // Brands icon : OptinMonster
    @Suppress("unused") val OptinMonster = BrandIcon(0xf23c)

    // https://fontawesome.com/icons/orcid?style=brands
    // Brands icon : Orcid
    @Suppress("unused") val Orcid = BrandIcon(0xf8d2)

    // https://fontawesome.com/icons/osi?style=brands
    // Brands icon : Osi
    @Suppress("unused") val Osi = BrandIcon(0xf41a)

    // https://fontawesome.com/icons/otter?style=solid
    // Solid icon : Otter
    @Suppress("unused") val Otter = SolidIcon(0xf700)

    // https://fontawesome.com/icons/outdent?style=solid
    // Solid icon : Outdent
    @Suppress("unused") val Outdent = SolidIcon(0xf03b)

    // https://fontawesome.com/icons/page4?style=brands
    // Brands icon : Page4
    @Suppress("unused") val Page4 = BrandIcon(0xf3d7)

    // https://fontawesome.com/icons/pagelines?style=brands
    // Brands icon : Pagelines
    @Suppress("unused") val Pagelines = BrandIcon(0xf18c)

    // https://fontawesome.com/icons/pager?style=solid
    // Solid icon : Pager
    @Suppress("unused") val Pager = SolidIcon(0xf815)

    // https://fontawesome.com/icons/paint-brush?style=solid
    // Solid icon : PaintBrush
    @Suppress("unused") val PaintBrush = SolidIcon(0xf1fc)

    // https://fontawesome.com/icons/paint-roller?style=solid
    // Solid icon : PaintRoller
    @Suppress("unused") val PaintRoller = SolidIcon(0xf5aa)

    // https://fontawesome.com/icons/palette?style=solid
    // Solid icon : Palette
    @Suppress("unused") val Palette = SolidIcon(0xf53f)

    // https://fontawesome.com/icons/palfed?style=brands
    // Brands icon : Palfed
    @Suppress("unused") val Palfed = BrandIcon(0xf3d8)

    // https://fontawesome.com/icons/pallet?style=solid
    // Solid icon : Pallet
    @Suppress("unused") val Pallet = SolidIcon(0xf482)

    // https://fontawesome.com/icons/paper-plane?style=solid
    // Solid icon : PaperPlane
    @Suppress("unused") val PaperPlane = SolidIcon(0xf1d8)

    // https://fontawesome.com/icons/paper-plane?style=regular
    // Regular icon : PaperPlane
    @Suppress("unused") val PaperPlaneRegular = RegularIcon(0xf1d8)

    // https://fontawesome.com/icons/paperclip?style=solid
    // Solid icon : Paperclip
    @Suppress("unused") val Paperclip = SolidIcon(0xf0c6)

    // https://fontawesome.com/icons/parachute-box?style=solid
    // Solid icon : ParachuteBox
    @Suppress("unused") val ParachuteBox = SolidIcon(0xf4cd)

    // https://fontawesome.com/icons/paragraph?style=solid
    // Solid icon : Paragraph
    @Suppress("unused") val Paragraph = SolidIcon(0xf1dd)

    // https://fontawesome.com/icons/parking?style=solid
    // Solid icon : Parking
    @Suppress("unused") val Parking = SolidIcon(0xf540)

    // https://fontawesome.com/icons/passport?style=solid
    // Solid icon : Passport
    @Suppress("unused") val Passport = SolidIcon(0xf5ab)

    // https://fontawesome.com/icons/pastafarianism?style=solid
    // Solid icon : Pastafarianism
    @Suppress("unused") val Pastafarianism = SolidIcon(0xf67b)

    // https://fontawesome.com/icons/paste?style=solid
    // Solid icon : Paste
    @Suppress("unused") val Paste = SolidIcon(0xf0ea)

    // https://fontawesome.com/icons/patreon?style=brands
    // Brands icon : Patreon
    @Suppress("unused") val Patreon = BrandIcon(0xf3d9)

    // https://fontawesome.com/icons/pause?style=solid
    // Solid icon : Pause
    @Suppress("unused") val Pause = SolidIcon(0xf04c)

    // https://fontawesome.com/icons/pause-circle?style=solid
    // Solid icon : PauseCircle
    @Suppress("unused") val PauseCircle = SolidIcon(0xf28b)

    // https://fontawesome.com/icons/pause-circle?style=regular
    // Regular icon : PauseCircle
    @Suppress("unused") val PauseCircleRegular = RegularIcon(0xf28b)

    // https://fontawesome.com/icons/paw?style=solid
    // Solid icon : Paw
    @Suppress("unused") val Paw = SolidIcon(0xf1b0)

    // https://fontawesome.com/icons/paypal?style=brands
    // Brands icon : Paypal
    @Suppress("unused") val Paypal = BrandIcon(0xf1ed)

    // https://fontawesome.com/icons/peace?style=solid
    // Solid icon : Peace
    @Suppress("unused") val Peace = SolidIcon(0xf67c)

    // https://fontawesome.com/icons/pen?style=solid
    // Solid icon : Pen
    @Suppress("unused") val Pen = SolidIcon(0xf304)

    // https://fontawesome.com/icons/pen-alt?style=solid
    // Solid icon : PenAlt
    @Suppress("unused") val PenAlt = SolidIcon(0xf305)

    // https://fontawesome.com/icons/pen-fancy?style=solid
    // Solid icon : PenFancy
    @Suppress("unused") val PenFancy = SolidIcon(0xf5ac)

    // https://fontawesome.com/icons/pen-nib?style=solid
    // Solid icon : PenNib
    @Suppress("unused") val PenNib = SolidIcon(0xf5ad)

    // https://fontawesome.com/icons/pen-square?style=solid
    // Solid icon : PenSquare
    @Suppress("unused") val PenSquare = SolidIcon(0xf14b)

    // https://fontawesome.com/icons/pencil-alt?style=solid
    // Solid icon : PencilAlt
    @Suppress("unused") val PencilAlt = SolidIcon(0xf303)

    // https://fontawesome.com/icons/pencil-ruler?style=solid
    // Solid icon : PencilRuler
    @Suppress("unused") val PencilRuler = SolidIcon(0xf5ae)

    // https://fontawesome.com/icons/penny-arcade?style=brands
    // Brands icon : PennyArcade
    @Suppress("unused") val PennyArcade = BrandIcon(0xf704)

    // https://fontawesome.com/icons/people-arrows?style=solid
    // Solid icon : PeopleArrows
    @Suppress("unused") val PeopleArrows = SolidIcon(0xe068)

    // https://fontawesome.com/icons/people-carry?style=solid
    // Solid icon : PeopleCarry
    @Suppress("unused") val PeopleCarry = SolidIcon(0xf4ce)

    // https://fontawesome.com/icons/pepper-hot?style=solid
    // Solid icon : PepperHot
    @Suppress("unused") val PepperHot = SolidIcon(0xf816)

    // https://fontawesome.com/icons/perbyte?style=brands
    // Brands icon : Perbyte
    @Suppress("unused") val Perbyte = BrandIcon(0xe083)

    // https://fontawesome.com/icons/percent?style=solid
    // Solid icon : Percent
    @Suppress("unused") val Percent = SolidIcon(0xf295)

    // https://fontawesome.com/icons/percentage?style=solid
    // Solid icon : Percentage
    @Suppress("unused") val Percentage = SolidIcon(0xf541)

    // https://fontawesome.com/icons/periscope?style=brands
    // Brands icon : Periscope
    @Suppress("unused") val Periscope = BrandIcon(0xf3da)

    // https://fontawesome.com/icons/person-booth?style=solid
    // Solid icon : PersonBooth
    @Suppress("unused") val PersonBooth = SolidIcon(0xf756)

    // https://fontawesome.com/icons/phabricator?style=brands
    // Brands icon : Phabricator
    @Suppress("unused") val Phabricator = BrandIcon(0xf3db)

    // https://fontawesome.com/icons/phoenix-framework?style=brands
    // Brands icon : PhoenixFramework
    @Suppress("unused") val PhoenixFramework = BrandIcon(0xf3dc)

    // https://fontawesome.com/icons/phoenix-squadron?style=brands
    // Brands icon : PhoenixSquadron
    @Suppress("unused") val PhoenixSquadron = BrandIcon(0xf511)

    // https://fontawesome.com/icons/phone?style=solid
    // Solid icon : Phone
    @Suppress("unused") val Phone = SolidIcon(0xf095)

    // https://fontawesome.com/icons/phone-alt?style=solid
    // Solid icon : PhoneAlt
    @Suppress("unused") val PhoneAlt = SolidIcon(0xf879)

    // https://fontawesome.com/icons/phone-slash?style=solid
    // Solid icon : PhoneSlash
    @Suppress("unused") val PhoneSlash = SolidIcon(0xf3dd)

    // https://fontawesome.com/icons/phone-square?style=solid
    // Solid icon : PhoneSquare
    @Suppress("unused") val PhoneSquare = SolidIcon(0xf098)

    // https://fontawesome.com/icons/phone-square-alt?style=solid
    // Solid icon : PhoneSquareAlt
    @Suppress("unused") val PhoneSquareAlt = SolidIcon(0xf87b)

    // https://fontawesome.com/icons/phone-volume?style=solid
    // Solid icon : PhoneVolume
    @Suppress("unused") val PhoneVolume = SolidIcon(0xf2a0)

    // https://fontawesome.com/icons/photo-video?style=solid
    // Solid icon : PhotoVideo
    @Suppress("unused") val PhotoVideo = SolidIcon(0xf87c)

    // https://fontawesome.com/icons/php?style=brands
    // Brands icon : Php
    @Suppress("unused") val Php = BrandIcon(0xf457)

    // https://fontawesome.com/icons/pied-piper?style=brands
    // Brands icon : PiedPiper
    @Suppress("unused") val PiedPiper = BrandIcon(0xf2ae)

    // https://fontawesome.com/icons/pied-piper-alt?style=brands
    // Brands icon : PiedPiperAlt
    @Suppress("unused") val PiedPiperAlt = BrandIcon(0xf1a8)

    // https://fontawesome.com/icons/pied-piper-hat?style=brands
    // Brands icon : PiedPiperHat
    @Suppress("unused") val PiedPiperHat = BrandIcon(0xf4e5)

    // https://fontawesome.com/icons/pied-piper-pp?style=brands
    // Brands icon : PiedPiperPp
    @Suppress("unused") val PiedPiperPp = BrandIcon(0xf1a7)

    // https://fontawesome.com/icons/pied-piper-square?style=brands
    // Brands icon : PiedPiperSquare
    @Suppress("unused") val PiedPiperSquare = BrandIcon(0xe01e)

    // https://fontawesome.com/icons/piggy-bank?style=solid
    // Solid icon : PiggyBank
    @Suppress("unused") val PiggyBank = SolidIcon(0xf4d3)

    // https://fontawesome.com/icons/pills?style=solid
    // Solid icon : Pills
    @Suppress("unused") val Pills = SolidIcon(0xf484)

    // https://fontawesome.com/icons/pinterest?style=brands
    // Brands icon : Pinterest
    @Suppress("unused") val Pinterest = BrandIcon(0xf0d2)

    // https://fontawesome.com/icons/pinterest-p?style=brands
    // Brands icon : PinterestP
    @Suppress("unused") val PinterestP = BrandIcon(0xf231)

    // https://fontawesome.com/icons/pinterest-square?style=brands
    // Brands icon : PinterestSquare
    @Suppress("unused") val PinterestSquare = BrandIcon(0xf0d3)

    // https://fontawesome.com/icons/pizza-slice?style=solid
    // Solid icon : PizzaSlice
    @Suppress("unused") val PizzaSlice = SolidIcon(0xf818)

    // https://fontawesome.com/icons/place-of-worship?style=solid
    // Solid icon : PlaceOfWorship
    @Suppress("unused") val PlaceOfWorship = SolidIcon(0xf67f)

    // https://fontawesome.com/icons/plane?style=solid
    // Solid icon : Plane
    @Suppress("unused") val Plane = SolidIcon(0xf072)

    // https://fontawesome.com/icons/plane-arri@Suppress("unused")  val?style=solid
    // Solid icon : PlaneArrival
    @Suppress("unused") val PlaneArrival = SolidIcon(0xf5af)

    // https://fontawesome.com/icons/plane-departure?style=solid
    // Solid icon : PlaneDeparture
    @Suppress("unused") val PlaneDeparture = SolidIcon(0xf5b0)

    // https://fontawesome.com/icons/plane-slash?style=solid
    // Solid icon : PlaneSlash
    @Suppress("unused") val PlaneSlash = SolidIcon(0xe069)

    // https://fontawesome.com/icons/play?style=solid
    // Solid icon : Play
    @Suppress("unused") val Play = SolidIcon(0xf04b)

    // https://fontawesome.com/icons/play-circle?style=solid
    // Solid icon : PlayCircle
    @Suppress("unused") val PlayCircle = SolidIcon(0xf144)

    // https://fontawesome.com/icons/play-circle?style=regular
    // Regular icon : PlayCircle
    @Suppress("unused") val PlayCircleRegular = RegularIcon(0xf144)

    // https://fontawesome.com/icons/playstation?style=brands
    // Brands icon : Playstation
    @Suppress("unused") val Playstation = BrandIcon(0xf3df)

    // https://fontawesome.com/icons/plug?style=solid
    // Solid icon : Plug
    @Suppress("unused") val Plug = SolidIcon(0xf1e6)

    // https://fontawesome.com/icons/plus?style=solid
    // Solid icon : Plus
    @Suppress("unused") val Plus = SolidIcon(0xf067)

    // https://fontawesome.com/icons/plus-circle?style=solid
    // Solid icon : PlusCircle
    @Suppress("unused") val PlusCircle = SolidIcon(0xf055)

    // https://fontawesome.com/icons/plus-square?style=solid
    // Solid icon : PlusSquare
    @Suppress("unused") val PlusSquare = SolidIcon(0xf0fe)

    // https://fontawesome.com/icons/plus-square?style=regular
    // Regular icon : PlusSquare
    @Suppress("unused") val PlusSquareRegular = RegularIcon(0xf0fe)

    // https://fontawesome.com/icons/podcast?style=solid
    // Solid icon : Podcast
    @Suppress("unused") val Podcast = SolidIcon(0xf2ce)

    // https://fontawesome.com/icons/poll?style=solid
    // Solid icon : Poll
    @Suppress("unused") val Poll = SolidIcon(0xf681)

    // https://fontawesome.com/icons/poll-h?style=solid
    // Solid icon : PollH
    @Suppress("unused") val PollH = SolidIcon(0xf682)

    // https://fontawesome.com/icons/poo?style=solid
    // Solid icon : Poo
    @Suppress("unused") val Poo = SolidIcon(0xf2fe)

    // https://fontawesome.com/icons/poo-storm?style=solid
    // Solid icon : PooStorm
    @Suppress("unused") val PooStorm = SolidIcon(0xf75a)

    // https://fontawesome.com/icons/poop?style=solid
    // Solid icon : Poop
    @Suppress("unused") val Poop = SolidIcon(0xf619)

    // https://fontawesome.com/icons/portrait?style=solid
    // Solid icon : Portrait
    @Suppress("unused") val Portrait = SolidIcon(0xf3e0)

    // https://fontawesome.com/icons/pound-sign?style=solid
    // Solid icon : PoundSign
    @Suppress("unused") val PoundSign = SolidIcon(0xf154)

    // https://fontawesome.com/icons/power-off?style=solid
    // Solid icon : PowerOff
    @Suppress("unused") val PowerOff = SolidIcon(0xf011)

    // https://fontawesome.com/icons/pray?style=solid
    // Solid icon : Pray
    @Suppress("unused") val Pray = SolidIcon(0xf683)

    // https://fontawesome.com/icons/praying-hands?style=solid
    // Solid icon : PrayingHands
    @Suppress("unused") val PrayingHands = SolidIcon(0xf684)

    // https://fontawesome.com/icons/prescription?style=solid
    // Solid icon : Prescription
    @Suppress("unused") val Prescription = SolidIcon(0xf5b1)

    // https://fontawesome.com/icons/prescription-bottle?style=solid
    // Solid icon : PrescriptionBottle
    @Suppress("unused") val PrescriptionBottle = SolidIcon(0xf485)

    // https://fontawesome.com/icons/prescription-bottle-alt?style=solid
    // Solid icon : PrescriptionBottleAlt
    @Suppress("unused") val PrescriptionBottleAlt = SolidIcon(0xf486)

    // https://fontawesome.com/icons/print?style=solid
    // Solid icon : Print
    @Suppress("unused") val Print = SolidIcon(0xf02f)

    // https://fontawesome.com/icons/procedures?style=solid
    // Solid icon : Procedures
    @Suppress("unused") val Procedures = SolidIcon(0xf487)

    // https://fontawesome.com/icons/product-hunt?style=brands
    // Brands icon : ProductHunt
    @Suppress("unused") val ProductHunt = BrandIcon(0xf288)

    // https://fontawesome.com/icons/project-diagram?style=solid
    // Solid icon : ProjectDiagram
    @Suppress("unused") val ProjectDiagram = SolidIcon(0xf542)

    // https://fontawesome.com/icons/pump-medical?style=solid
    // Solid icon : PumpMedical
    @Suppress("unused") val PumpMedical = SolidIcon(0xe06a)

    // https://fontawesome.com/icons/pump-soap?style=solid
    // Solid icon : PumpSoap
    @Suppress("unused") val PumpSoap = SolidIcon(0xe06b)

    // https://fontawesome.com/icons/pushed?style=brands
    // Brands icon : Pushed
    @Suppress("unused") val Pushed = BrandIcon(0xf3e1)

    // https://fontawesome.com/icons/puzzle-piece?style=solid
    // Solid icon : PuzzlePiece
    @Suppress("unused") val PuzzlePiece = SolidIcon(0xf12e)

    // https://fontawesome.com/icons/python?style=brands
    // Brands icon : Python
    @Suppress("unused") val Python = BrandIcon(0xf3e2)

    // https://fontawesome.com/icons/qq?style=brands
    // Brands icon : Qq
    @Suppress("unused") val Qq = BrandIcon(0xf1d6)

    // https://fontawesome.com/icons/qrcode?style=solid
    // Solid icon : Qrcode
    @Suppress("unused") val Qrcode = SolidIcon(0xf029)

    // https://fontawesome.com/icons/question?style=solid
    // Solid icon : Question
    @Suppress("unused") val Question = SolidIcon(0xf128)

    // https://fontawesome.com/icons/question-circle?style=solid
    // Solid icon : QuestionCircle
    @Suppress("unused") val QuestionCircle = SolidIcon(0xf059)

    // https://fontawesome.com/icons/question-circle?style=regular
    // Regular icon : QuestionCircle
    @Suppress("unused") val QuestionCircleRegular = RegularIcon(0xf059)

    // https://fontawesome.com/icons/quidditch?style=solid
    // Solid icon : Quidditch
    @Suppress("unused") val Quidditch = SolidIcon(0xf458)

    // https://fontawesome.com/icons/quinscape?style=brands
    // Brands icon : Quinscape
    @Suppress("unused") val Quinscape = BrandIcon(0xf459)

    // https://fontawesome.com/icons/quora?style=brands
    // Brands icon : Quora
    @Suppress("unused") val Quora = BrandIcon(0xf2c4)

    // https://fontawesome.com/icons/quote-left?style=solid
    // Solid icon : QuoteLeft
    @Suppress("unused") val QuoteLeft = SolidIcon(0xf10d)

    // https://fontawesome.com/icons/quote-right?style=solid
    // Solid icon : QuoteRight
    @Suppress("unused") val QuoteRight = SolidIcon(0xf10e)

    // https://fontawesome.com/icons/quran?style=solid
    // Solid icon : Quran
    @Suppress("unused") val Quran = SolidIcon(0xf687)

    // https://fontawesome.com/icons/r-project?style=brands
    // Brands icon : RProject
    @Suppress("unused") val RProject = BrandIcon(0xf4f7)

    // https://fontawesome.com/icons/radiation?style=solid
    // Solid icon : Radiation
    @Suppress("unused") val Radiation = SolidIcon(0xf7b9)

    // https://fontawesome.com/icons/radiation-alt?style=solid
    // Solid icon : RadiationAlt
    @Suppress("unused") val RadiationAlt = SolidIcon(0xf7ba)

    // https://fontawesome.com/icons/rainbow?style=solid
    // Solid icon : Rainbow
    @Suppress("unused") val Rainbow = SolidIcon(0xf75b)

    // https://fontawesome.com/icons/random?style=solid
    // Solid icon : Random
    @Suppress("unused") val Random = SolidIcon(0xf074)

    // https://fontawesome.com/icons/raspberry-pi?style=brands
    // Brands icon : RaspberryPi
    @Suppress("unused") val RaspberryPi = BrandIcon(0xf7bb)

    // https://fontawesome.com/icons/ravelry?style=brands
    // Brands icon : Ravelry
    @Suppress("unused") val Ravelry = BrandIcon(0xf2d9)

    // https://fontawesome.com/icons/react?style=brands
    // Brands icon : React
    @Suppress("unused") val React = BrandIcon(0xf41b)

    // https://fontawesome.com/icons/reacteurope?style=brands
    // Brands icon : Reacteurope
    @Suppress("unused") val Reacteurope = BrandIcon(0xf75d)

    // https://fontawesome.com/icons/readme?style=brands
    // Brands icon : Readme
    @Suppress("unused") val Readme = BrandIcon(0xf4d5)

    // https://fontawesome.com/icons/rebel?style=brands
    // Brands icon : Rebel
    @Suppress("unused") val Rebel = BrandIcon(0xf1d0)

    // https://fontawesome.com/icons/receipt?style=solid
    // Solid icon : Receipt
    @Suppress("unused") val Receipt = SolidIcon(0xf543)

    // https://fontawesome.com/icons/record-vinyl?style=solid
    // Solid icon : RecordVinyl
    @Suppress("unused") val RecordVinyl = SolidIcon(0xf8d9)

    // https://fontawesome.com/icons/recycle?style=solid
    // Solid icon : Recycle
    @Suppress("unused") val Recycle = SolidIcon(0xf1b8)

    // https://fontawesome.com/icons/red-river?style=brands
    // Brands icon : RedRiver
    @Suppress("unused") val RedRiver = BrandIcon(0xf3e3)

    // https://fontawesome.com/icons/reddit?style=brands
    // Brands icon : Reddit
    @Suppress("unused") val Reddit = BrandIcon(0xf1a1)

    // https://fontawesome.com/icons/reddit-alien?style=brands
    // Brands icon : RedditAlien
    @Suppress("unused") val RedditAlien = BrandIcon(0xf281)

    // https://fontawesome.com/icons/reddit-square?style=brands
    // Brands icon : RedditSquare
    @Suppress("unused") val RedditSquare = BrandIcon(0xf1a2)

    // https://fontawesome.com/icons/redhat?style=brands
    // Brands icon : Redhat
    @Suppress("unused") val Redhat = BrandIcon(0xf7bc)

    // https://fontawesome.com/icons/redo?style=solid
    // Solid icon : Redo
    @Suppress("unused") val Redo = SolidIcon(0xf01e)

    // https://fontawesome.com/icons/redo-alt?style=solid
    // Solid icon : RedoAlt
    @Suppress("unused") val RedoAlt = SolidIcon(0xf2f9)

    // https://fontawesome.com/icons/registered?style=solid
    // Solid icon : Registered
    @Suppress("unused") val Registered = SolidIcon(0xf25d)

    // https://fontawesome.com/icons/registered?style=regular
    // Regular icon : Registered
    @Suppress("unused") val RegisteredRegular = RegularIcon(0xf25d)

    // https://fontawesome.com/icons/remove-format?style=solid
    // Solid icon : RemoveFormat
    @Suppress("unused") val RemoveFormat = SolidIcon(0xf87d)

    // https://fontawesome.com/icons/renren?style=brands
    // Brands icon : Renren
    @Suppress("unused") val Renren = BrandIcon(0xf18b)

    // https://fontawesome.com/icons/reply?style=solid
    // Solid icon : Reply
    @Suppress("unused") val Reply = SolidIcon(0xf3e5)

    // https://fontawesome.com/icons/reply-all?style=solid
    // Solid icon : ReplyAll
    @Suppress("unused") val ReplyAll = SolidIcon(0xf122)

    // https://fontawesome.com/icons/replyd?style=brands
    // Brands icon : Replyd
    @Suppress("unused") val Replyd = BrandIcon(0xf3e6)

    // https://fontawesome.com/icons/republican?style=solid
    // Solid icon : Republican
    @Suppress("unused") val Republican = SolidIcon(0xf75e)

    // https://fontawesome.com/icons/researchgate?style=brands
    // Brands icon : Researchgate
    @Suppress("unused") val Researchgate = BrandIcon(0xf4f8)

    // https://fontawesome.com/icons/resolving?style=brands
    // Brands icon : Resolving
    @Suppress("unused") val Resolving = BrandIcon(0xf3e7)

    // https://fontawesome.com/icons/restroom?style=solid
    // Solid icon : Restroom
    @Suppress("unused") val Restroom = SolidIcon(0xf7bd)

    // https://fontawesome.com/icons/retweet?style=solid
    // Solid icon : Retweet
    @Suppress("unused") val Retweet = SolidIcon(0xf079)

    // https://fontawesome.com/icons/rev?style=brands
    // Brands icon : Rev
    @Suppress("unused") val Rev = BrandIcon(0xf5b2)

    // https://fontawesome.com/icons/ribbon?style=solid
    // Solid icon : Ribbon
    @Suppress("unused") val Ribbon = SolidIcon(0xf4d6)

    // https://fontawesome.com/icons/ring?style=solid
    // Solid icon : Ring
    @Suppress("unused") val Ring = SolidIcon(0xf70b)

    // https://fontawesome.com/icons/road?style=solid
    // Solid icon : Road
    @Suppress("unused") val Road = SolidIcon(0xf018)

    // https://fontawesome.com/icons/robot?style=solid
    // Solid icon : Robot
    @Suppress("unused") val Robot = SolidIcon(0xf544)

    // https://fontawesome.com/icons/rocket?style=solid
    // Solid icon : Rocket
    @Suppress("unused") val Rocket = SolidIcon(0xf135)

    // https://fontawesome.com/icons/rocketchat?style=brands
    // Brands icon : Rocketchat
    @Suppress("unused") val Rocketchat = BrandIcon(0xf3e8)

    // https://fontawesome.com/icons/rockrms?style=brands
    // Brands icon : Rockrms
    @Suppress("unused") val Rockrms = BrandIcon(0xf3e9)

    // https://fontawesome.com/icons/route?style=solid
    // Solid icon : Route
    @Suppress("unused") val Route = SolidIcon(0xf4d7)

    // https://fontawesome.com/icons/rss?style=solid
    // Solid icon : Rss
    @Suppress("unused") val Rss = SolidIcon(0xf09e)

    // https://fontawesome.com/icons/rss-square?style=solid
    // Solid icon : RssSquare
    @Suppress("unused") val RssSquare = SolidIcon(0xf143)

    // https://fontawesome.com/icons/ruble-sign?style=solid
    // Solid icon : RubleSign
    @Suppress("unused") val RubleSign = SolidIcon(0xf158)

    // https://fontawesome.com/icons/ruler?style=solid
    // Solid icon : Ruler
    @Suppress("unused") val Ruler = SolidIcon(0xf545)

    // https://fontawesome.com/icons/ruler-combined?style=solid
    // Solid icon : RulerCombined
    @Suppress("unused") val RulerCombined = SolidIcon(0xf546)

    // https://fontawesome.com/icons/ruler-horizontal?style=solid
    // Solid icon : RulerHorizontal
    @Suppress("unused") val RulerHorizontal = SolidIcon(0xf547)

    // https://fontawesome.com/icons/ruler-vertical?style=solid
    // Solid icon : RulerVertical
    @Suppress("unused") val RulerVertical = SolidIcon(0xf548)

    // https://fontawesome.com/icons/running?style=solid
    // Solid icon : Running
    @Suppress("unused") val Running = SolidIcon(0xf70c)

    // https://fontawesome.com/icons/rupee-sign?style=solid
    // Solid icon : RupeeSign
    @Suppress("unused") val RupeeSign = SolidIcon(0xf156)

    // https://fontawesome.com/icons/rust?style=brands
    // Brands icon : Rust
    @Suppress("unused") val Rust = BrandIcon(0xe07a)

    // https://fontawesome.com/icons/sad-cry?style=solid
    // Solid icon : SadCry
    @Suppress("unused") val SadCry = SolidIcon(0xf5b3)

    // https://fontawesome.com/icons/sad-cry?style=regular
    // Regular icon : SadCry
    @Suppress("unused") val SadCryRegular = RegularIcon(0xf5b3)

    // https://fontawesome.com/icons/sad-tear?style=solid
    // Solid icon : SadTear
    @Suppress("unused") val SadTear = SolidIcon(0xf5b4)

    // https://fontawesome.com/icons/sad-tear?style=regular
    // Regular icon : SadTear
    @Suppress("unused") val SadTearRegular = RegularIcon(0xf5b4)

    // https://fontawesome.com/icons/safari?style=brands
    // Brands icon : Safari
    @Suppress("unused") val Safari = BrandIcon(0xf267)

    // https://fontawesome.com/icons/salesforce?style=brands
    // Brands icon : Salesforce
    @Suppress("unused") val Salesforce = BrandIcon(0xf83b)

    // https://fontawesome.com/icons/sass?style=brands
    // Brands icon : Sass
    @Suppress("unused") val Sass = BrandIcon(0xf41e)

    // https://fontawesome.com/icons/satellite?style=solid
    // Solid icon : Satellite
    @Suppress("unused") val Satellite = SolidIcon(0xf7bf)

    // https://fontawesome.com/icons/satellite-dish?style=solid
    // Solid icon : SatelliteDish
    @Suppress("unused") val SatelliteDish = SolidIcon(0xf7c0)

    // https://fontawesome.com/icons/save?style=solid
    // Solid icon : Save
    @Suppress("unused") val Save = SolidIcon(0xf0c7)

    // https://fontawesome.com/icons/save?style=regular
    // Regular icon : Save
    @Suppress("unused") val SaveRegular = RegularIcon(0xf0c7)

    // https://fontawesome.com/icons/schlix?style=brands
    // Brands icon : Schlix
    @Suppress("unused") val Schlix = BrandIcon(0xf3ea)

    // https://fontawesome.com/icons/school?style=solid
    // Solid icon : School
    @Suppress("unused") val School = SolidIcon(0xf549)

    // https://fontawesome.com/icons/screwdriver?style=solid
    // Solid icon : Screwdriver
    @Suppress("unused") val Screwdriver = SolidIcon(0xf54a)

    // https://fontawesome.com/icons/scribd?style=brands
    // Brands icon : Scribd
    @Suppress("unused") val Scribd = BrandIcon(0xf28a)

    // https://fontawesome.com/icons/scroll?style=solid
    // Solid icon : Scroll
    @Suppress("unused") val Scroll = SolidIcon(0xf70e)

    // https://fontawesome.com/icons/sd-card?style=solid
    // Solid icon : SdCard
    @Suppress("unused") val SdCard = SolidIcon(0xf7c2)

    // https://fontawesome.com/icons/search?style=solid
    // Solid icon : Search
    @Suppress("unused") val Search = SolidIcon(0xf002)

    // https://fontawesome.com/icons/search-dollar?style=solid
    // Solid icon : SearchDollar
    @Suppress("unused") val SearchDollar = SolidIcon(0xf688)

    // https://fontawesome.com/icons/search-location?style=solid
    // Solid icon : SearchLocation
    @Suppress("unused") val SearchLocation = SolidIcon(0xf689)

    // https://fontawesome.com/icons/search-minus?style=solid
    // Solid icon : SearchMinus
    @Suppress("unused") val SearchMinus = SolidIcon(0xf010)

    // https://fontawesome.com/icons/search-plus?style=solid
    // Solid icon : SearchPlus
    @Suppress("unused") val SearchPlus = SolidIcon(0xf00e)

    // https://fontawesome.com/icons/searchengin?style=brands
    // Brands icon : Searchengin
    @Suppress("unused") val Searchengin = BrandIcon(0xf3eb)

    // https://fontawesome.com/icons/seedling?style=solid
    // Solid icon : Seedling
    @Suppress("unused") val Seedling = SolidIcon(0xf4d8)

    // https://fontawesome.com/icons/sellcast?style=brands
    // Brands icon : Sellcast
    @Suppress("unused") val Sellcast = BrandIcon(0xf2da)

    // https://fontawesome.com/icons/sellsy?style=brands
    // Brands icon : Sellsy
    @Suppress("unused") val Sellsy = BrandIcon(0xf213)

    // https://fontawesome.com/icons/server?style=solid
    // Solid icon : Server
    @Suppress("unused") val Server = SolidIcon(0xf233)

    // https://fontawesome.com/icons/servicestack?style=brands
    // Brands icon : Servicestack
    @Suppress("unused") val Servicestack = BrandIcon(0xf3ec)

    // https://fontawesome.com/icons/shapes?style=solid
    // Solid icon : Shapes
    @Suppress("unused") val Shapes = SolidIcon(0xf61f)

    // https://fontawesome.com/icons/share?style=solid
    // Solid icon : Share
    @Suppress("unused") val Share = SolidIcon(0xf064)

    // https://fontawesome.com/icons/share-alt?style=solid
    // Solid icon : ShareAlt
    @Suppress("unused") val ShareAlt = SolidIcon(0xf1e0)

    // https://fontawesome.com/icons/share-alt-square?style=solid
    // Solid icon : ShareAltSquare
    @Suppress("unused") val ShareAltSquare = SolidIcon(0xf1e1)

    // https://fontawesome.com/icons/share-square?style=solid
    // Solid icon : ShareSquare
    @Suppress("unused") val ShareSquare = SolidIcon(0xf14d)

    // https://fontawesome.com/icons/share-square?style=regular
    // Regular icon : ShareSquare
    @Suppress("unused") val ShareSquareRegular = RegularIcon(0xf14d)

    // https://fontawesome.com/icons/shekel-sign?style=solid
    // Solid icon : ShekelSign
    @Suppress("unused") val ShekelSign = SolidIcon(0xf20b)

    // https://fontawesome.com/icons/shield-alt?style=solid
    // Solid icon : ShieldAlt
    @Suppress("unused") val ShieldAlt = SolidIcon(0xf3ed)

    // https://fontawesome.com/icons/shield-virus?style=solid
    // Solid icon : ShieldVirus
    @Suppress("unused") val ShieldVirus = SolidIcon(0xe06c)

    // https://fontawesome.com/icons/ship?style=solid
    // Solid icon : Ship
    @Suppress("unused") val Ship = SolidIcon(0xf21a)

    // https://fontawesome.com/icons/shipping-fast?style=solid
    // Solid icon : ShippingFast
    @Suppress("unused") val ShippingFast = SolidIcon(0xf48b)

    // https://fontawesome.com/icons/shirtsinbulk?style=brands
    // Brands icon : Shirtsinbulk
    @Suppress("unused") val Shirtsinbulk = BrandIcon(0xf214)

    // https://fontawesome.com/icons/shoe-prints?style=solid
    // Solid icon : ShoePrints
    @Suppress("unused") val ShoePrints = SolidIcon(0xf54b)

    // https://fontawesome.com/icons/shopify?style=brands
    // Brands icon : Shopify
    @Suppress("unused") val Shopify = BrandIcon(0xe057)

    // https://fontawesome.com/icons/shopping-bag?style=solid
    // Solid icon : ShoppingBag
    @Suppress("unused") val ShoppingBag = SolidIcon(0xf290)

    // https://fontawesome.com/icons/shopping-basket?style=solid
    // Solid icon : ShoppingBasket
    @Suppress("unused") val ShoppingBasket = SolidIcon(0xf291)

    // https://fontawesome.com/icons/shopping-cart?style=solid
    // Solid icon : ShoppingCart
    @Suppress("unused") val ShoppingCart = SolidIcon(0xf07a)

    // https://fontawesome.com/icons/shopware?style=brands
    // Brands icon : Shopware
    @Suppress("unused") val Shopware = BrandIcon(0xf5b5)

    // https://fontawesome.com/icons/shower?style=solid
    // Solid icon : Shower
    @Suppress("unused") val Shower = SolidIcon(0xf2cc)

    // https://fontawesome.com/icons/shuttle-van?style=solid
    // Solid icon : ShuttleVan
    @Suppress("unused") val ShuttleVan = SolidIcon(0xf5b6)

    // https://fontawesome.com/icons/sign?style=solid
    // Solid icon : Sign
    @Suppress("unused") val Sign = SolidIcon(0xf4d9)

    // https://fontawesome.com/icons/sign-in-alt?style=solid
    // Solid icon : SignInAlt
    @Suppress("unused") val SignInAlt = SolidIcon(0xf2f6)

    // https://fontawesome.com/icons/sign-language?style=solid
    // Solid icon : SignLanguage
    @Suppress("unused") val SignLanguage = SolidIcon(0xf2a7)

    // https://fontawesome.com/icons/sign-out-alt?style=solid
    // Solid icon : SignOutAlt
    @Suppress("unused") val SignOutAlt = SolidIcon(0xf2f5)

    // https://fontawesome.com/icons/signal?style=solid
    // Solid icon : Signal
    @Suppress("unused") val Signal = SolidIcon(0xf012)

    // https://fontawesome.com/icons/signature?style=solid
    // Solid icon : Signature
    @Suppress("unused") val Signature = SolidIcon(0xf5b7)

    // https://fontawesome.com/icons/sim-card?style=solid
    // Solid icon : SimCard
    @Suppress("unused") val SimCard = SolidIcon(0xf7c4)

    // https://fontawesome.com/icons/simplybuilt?style=brands
    // Brands icon : Simplybuilt
    @Suppress("unused") val Simplybuilt = BrandIcon(0xf215)

    // https://fontawesome.com/icons/sink?style=solid
    // Solid icon : Sink
    @Suppress("unused") val Sink = SolidIcon(0xe06d)

    // https://fontawesome.com/icons/sistrix?style=brands
    // Brands icon : Sistrix
    @Suppress("unused") val Sistrix = BrandIcon(0xf3ee)

    // https://fontawesome.com/icons/sitemap?style=solid
    // Solid icon : Sitemap
    @Suppress("unused") val Sitemap = SolidIcon(0xf0e8)

    // https://fontawesome.com/icons/sith?style=brands
    // Brands icon : Sith
    @Suppress("unused") val Sith = BrandIcon(0xf512)

    // https://fontawesome.com/icons/skating?style=solid
    // Solid icon : Skating
    @Suppress("unused") val Skating = SolidIcon(0xf7c5)

    // https://fontawesome.com/icons/sketch?style=brands
    // Brands icon : Sketch
    @Suppress("unused") val Sketch = BrandIcon(0xf7c6)

    // https://fontawesome.com/icons/skiing?style=solid
    // Solid icon : Skiing
    @Suppress("unused") val Skiing = SolidIcon(0xf7c9)

    // https://fontawesome.com/icons/skiing-nordic?style=solid
    // Solid icon : SkiingNordic
    @Suppress("unused") val SkiingNordic = SolidIcon(0xf7ca)

    // https://fontawesome.com/icons/skull?style=solid
    // Solid icon : Skull
    @Suppress("unused") val Skull = SolidIcon(0xf54c)

    // https://fontawesome.com/icons/skull-crossbones?style=solid
    // Solid icon : SkullCrossbones
    @Suppress("unused") val SkullCrossbones = SolidIcon(0xf714)

    // https://fontawesome.com/icons/skyatlas?style=brands
    // Brands icon : Skyatlas
    @Suppress("unused") val Skyatlas = BrandIcon(0xf216)

    // https://fontawesome.com/icons/skype?style=brands
    // Brands icon : Skype
    @Suppress("unused") val Skype = BrandIcon(0xf17e)

    // https://fontawesome.com/icons/slack?style=brands
    // Brands icon : Slack
    @Suppress("unused") val Slack = BrandIcon(0xf198)

    // https://fontawesome.com/icons/slack-hash?style=brands
    // Brands icon : SlackHash
    @Suppress("unused") val SlackHash = BrandIcon(0xf3ef)

    // https://fontawesome.com/icons/slash?style=solid
    // Solid icon : Slash
    @Suppress("unused") val Slash = SolidIcon(0xf715)

    // https://fontawesome.com/icons/sleigh?style=solid
    // Solid icon : Sleigh
    @Suppress("unused") val Sleigh = SolidIcon(0xf7cc)

    // https://fontawesome.com/icons/sliders-h?style=solid
    // Solid icon : SlidersH
    @Suppress("unused") val SlidersH = SolidIcon(0xf1de)

    // https://fontawesome.com/icons/slideshare?style=brands
    // Brands icon : Slideshare
    @Suppress("unused") val Slideshare = BrandIcon(0xf1e7)

    // https://fontawesome.com/icons/smile?style=solid
    // Solid icon : Smile
    @Suppress("unused") val Smile = SolidIcon(0xf118)

    // https://fontawesome.com/icons/smile?style=regular
    // Regular icon : Smile
    @Suppress("unused") val SmileRegular = RegularIcon(0xf118)

    // https://fontawesome.com/icons/smile-beam?style=solid
    // Solid icon : SmileBeam
    @Suppress("unused") val SmileBeam = SolidIcon(0xf5b8)

    // https://fontawesome.com/icons/smile-beam?style=regular
    // Regular icon : SmileBeam
    @Suppress("unused") val SmileBeamRegular = RegularIcon(0xf5b8)

    // https://fontawesome.com/icons/smile-wink?style=solid
    // Solid icon : SmileWink
    @Suppress("unused") val SmileWink = SolidIcon(0xf4da)

    // https://fontawesome.com/icons/smile-wink?style=regular
    // Regular icon : SmileWink
    @Suppress("unused") val SmileWinkRegular = RegularIcon(0xf4da)

    // https://fontawesome.com/icons/smog?style=solid
    // Solid icon : Smog
    @Suppress("unused") val Smog = SolidIcon(0xf75f)

    // https://fontawesome.com/icons/smoking?style=solid
    // Solid icon : Smoking
    @Suppress("unused") val Smoking = SolidIcon(0xf48d)

    // https://fontawesome.com/icons/smoking-ban?style=solid
    // Solid icon : SmokingBan
    @Suppress("unused") val SmokingBan = SolidIcon(0xf54d)

    // https://fontawesome.com/icons/sms?style=solid
    // Solid icon : Sms
    @Suppress("unused") val Sms = SolidIcon(0xf7cd)

    // https://fontawesome.com/icons/snapchat?style=brands
    // Brands icon : Snapchat
    @Suppress("unused") val Snapchat = BrandIcon(0xf2ab)

    // https://fontawesome.com/icons/snapchat-ghost?style=brands
    // Brands icon : SnapchatGhost
    @Suppress("unused") val SnapchatGhost = BrandIcon(0xf2ac)

    // https://fontawesome.com/icons/snapchat-square?style=brands
    // Brands icon : SnapchatSquare
    @Suppress("unused") val SnapchatSquare = BrandIcon(0xf2ad)

    // https://fontawesome.com/icons/snowboarding?style=solid
    // Solid icon : Snowboarding
    @Suppress("unused") val Snowboarding = SolidIcon(0xf7ce)

    // https://fontawesome.com/icons/snowflake?style=solid
    // Solid icon : Snowflake
    @Suppress("unused") val Snowflake = SolidIcon(0xf2dc)

    // https://fontawesome.com/icons/snowflake?style=regular
    // Regular icon : Snowflake
    @Suppress("unused") val SnowflakeRegular = RegularIcon(0xf2dc)

    // https://fontawesome.com/icons/snowman?style=solid
    // Solid icon : Snowman
    @Suppress("unused") val Snowman = SolidIcon(0xf7d0)

    // https://fontawesome.com/icons/snowplow?style=solid
    // Solid icon : Snowplow
    @Suppress("unused") val Snowplow = SolidIcon(0xf7d2)

    // https://fontawesome.com/icons/soap?style=solid
    // Solid icon : Soap
    @Suppress("unused") val Soap = SolidIcon(0xe06e)

    // https://fontawesome.com/icons/socks?style=solid
    // Solid icon : Socks
    @Suppress("unused") val Socks = SolidIcon(0xf696)

    // https://fontawesome.com/icons/solar-panel?style=solid
    // Solid icon : SolarPanel
    @Suppress("unused") val SolarPanel = SolidIcon(0xf5ba)

    // https://fontawesome.com/icons/sort?style=solid
    // Solid icon : Sort
    @Suppress("unused") val Sort = SolidIcon(0xf0dc)

    // https://fontawesome.com/icons/sort-alpha-down?style=solid
    // Solid icon : SortAlphaDown
    @Suppress("unused") val SortAlphaDown = SolidIcon(0xf15d)

    // https://fontawesome.com/icons/sort-alpha-down-alt?style=solid
    // Solid icon : SortAlphaDownAlt
    @Suppress("unused") val SortAlphaDownAlt = SolidIcon(0xf881)

    // https://fontawesome.com/icons/sort-alpha-up?style=solid
    // Solid icon : SortAlphaUp
    @Suppress("unused") val SortAlphaUp = SolidIcon(0xf15e)

    // https://fontawesome.com/icons/sort-alpha-up-alt?style=solid
    // Solid icon : SortAlphaUpAlt
    @Suppress("unused") val SortAlphaUpAlt = SolidIcon(0xf882)

    // https://fontawesome.com/icons/sort-amount-down?style=solid
    // Solid icon : SortAmountDown
    @Suppress("unused") val SortAmountDown = SolidIcon(0xf160)

    // https://fontawesome.com/icons/sort-amount-down-alt?style=solid
    // Solid icon : SortAmountDownAlt
    @Suppress("unused") val SortAmountDownAlt = SolidIcon(0xf884)

    // https://fontawesome.com/icons/sort-amount-up?style=solid
    // Solid icon : SortAmountUp
    @Suppress("unused") val SortAmountUp = SolidIcon(0xf161)

    // https://fontawesome.com/icons/sort-amount-up-alt?style=solid
    // Solid icon : SortAmountUpAlt
    @Suppress("unused") val SortAmountUpAlt = SolidIcon(0xf885)

    // https://fontawesome.com/icons/sort-down?style=solid
    // Solid icon : SortDown
    @Suppress("unused") val SortDown = SolidIcon(0xf0dd)

    // https://fontawesome.com/icons/sort-numeric-down?style=solid
    // Solid icon : SortNumericDown
    @Suppress("unused") val SortNumericDown = SolidIcon(0xf162)

    // https://fontawesome.com/icons/sort-numeric-down-alt?style=solid
    // Solid icon : SortNumericDownAlt
    @Suppress("unused") val SortNumericDownAlt = SolidIcon(0xf886)

    // https://fontawesome.com/icons/sort-numeric-up?style=solid
    // Solid icon : SortNumericUp
    @Suppress("unused") val SortNumericUp = SolidIcon(0xf163)

    // https://fontawesome.com/icons/sort-numeric-up-alt?style=solid
    // Solid icon : SortNumericUpAlt
    @Suppress("unused") val SortNumericUpAlt = SolidIcon(0xf887)

    // https://fontawesome.com/icons/sort-up?style=solid
    // Solid icon : SortUp
    @Suppress("unused") val SortUp = SolidIcon(0xf0de)

    // https://fontawesome.com/icons/soundcloud?style=brands
    // Brands icon : Soundcloud
    @Suppress("unused") val Soundcloud = BrandIcon(0xf1be)

    // https://fontawesome.com/icons/sourcetree?style=brands
    // Brands icon : Sourcetree
    @Suppress("unused") val Sourcetree = BrandIcon(0xf7d3)

    // https://fontawesome.com/icons/spa?style=solid
    // Solid icon : Spa
    @Suppress("unused") val Spa = SolidIcon(0xf5bb)

    // https://fontawesome.com/icons/space-shuttle?style=solid
    // Solid icon : SpaceShuttle
    @Suppress("unused") val SpaceShuttle = SolidIcon(0xf197)

    // https://fontawesome.com/icons/speakap?style=brands
    // Brands icon : Speakap
    @Suppress("unused") val Speakap = BrandIcon(0xf3f3)

    // https://fontawesome.com/icons/speaker-deck?style=brands
    // Brands icon : SpeakerDeck
    @Suppress("unused") val SpeakerDeck = BrandIcon(0xf83c)

    // https://fontawesome.com/icons/spell-check?style=solid
    // Solid icon : SpellCheck
    @Suppress("unused") val SpellCheck = SolidIcon(0xf891)

    // https://fontawesome.com/icons/spider?style=solid
    // Solid icon : Spider
    @Suppress("unused") val Spider = SolidIcon(0xf717)

    // https://fontawesome.com/icons/spinner?style=solid
    // Solid icon : Spinner
    @Suppress("unused") val Spinner = SolidIcon(0xf110)

    // https://fontawesome.com/icons/splotch?style=solid
    // Solid icon : Splotch
    @Suppress("unused") val Splotch = SolidIcon(0xf5bc)

    // https://fontawesome.com/icons/spotify?style=brands
    // Brands icon : Spotify
    @Suppress("unused") val Spotify = BrandIcon(0xf1bc)

    // https://fontawesome.com/icons/spray-can?style=solid
    // Solid icon : SprayCan
    @Suppress("unused") val SprayCan = SolidIcon(0xf5bd)

    // https://fontawesome.com/icons/square?style=solid
    // Solid icon : Square
    @Suppress("unused") val Square = SolidIcon(0xf0c8)

    // https://fontawesome.com/icons/square?style=regular
    // Regular icon : Square
    @Suppress("unused") val SquareRegular = RegularIcon(0xf0c8)

    // https://fontawesome.com/icons/square-full?style=solid
    // Solid icon : SquareFull
    @Suppress("unused") val SquareFull = SolidIcon(0xf45c)

    // https://fontawesome.com/icons/square-root-alt?style=solid
    // Solid icon : SquareRootAlt
    @Suppress("unused") val SquareRootAlt = SolidIcon(0xf698)

    // https://fontawesome.com/icons/squarespace?style=brands
    // Brands icon : Squarespace
    @Suppress("unused") val Squarespace = BrandIcon(0xf5be)

    // https://fontawesome.com/icons/stack-exchange?style=brands
    // Brands icon : StackExchange
    @Suppress("unused") val StackExchange = BrandIcon(0xf18d)

    // https://fontawesome.com/icons/stack-overflow?style=brands
    // Brands icon : StackOverflow
    @Suppress("unused") val StackOverflow = BrandIcon(0xf16c)

    // https://fontawesome.com/icons/stackpath?style=brands
    // Brands icon : Stackpath
    @Suppress("unused") val Stackpath = BrandIcon(0xf842)

    // https://fontawesome.com/icons/stamp?style=solid
    // Solid icon : Stamp
    @Suppress("unused") val Stamp = SolidIcon(0xf5bf)

    // https://fontawesome.com/icons/star?style=solid
    // Solid icon : Star
    @Suppress("unused") val Star = SolidIcon(0xf005)

    // https://fontawesome.com/icons/star?style=regular
    // Regular icon : Star
    @Suppress("unused") val StarRegular = RegularIcon(0xf005)

    // https://fontawesome.com/icons/star-and-crescent?style=solid
    // Solid icon : StarAndCrescent
    @Suppress("unused") val StarAndCrescent = SolidIcon(0xf699)

    // https://fontawesome.com/icons/star-half?style=solid
    // Solid icon : StarHalf
    @Suppress("unused") val StarHalf = SolidIcon(0xf089)

    // https://fontawesome.com/icons/star-half?style=regular
    // Regular icon : StarHalf
    @Suppress("unused") val StarHalfRegular = RegularIcon(0xf089)

    // https://fontawesome.com/icons/star-half-alt?style=solid
    // Solid icon : StarHalfAlt
    @Suppress("unused") val StarHalfAlt = SolidIcon(0xf5c0)

    // https://fontawesome.com/icons/star-of-david?style=solid
    // Solid icon : StarOfDavid
    @Suppress("unused") val StarOfDavid = SolidIcon(0xf69a)

    // https://fontawesome.com/icons/star-of-life?style=solid
    // Solid icon : StarOfLife
    @Suppress("unused") val StarOfLife = SolidIcon(0xf621)

    // https://fontawesome.com/icons/staylinked?style=brands
    // Brands icon : Staylinked
    @Suppress("unused") val Staylinked = BrandIcon(0xf3f5)

    // https://fontawesome.com/icons/steam?style=brands
    // Brands icon : Steam
    @Suppress("unused") val Steam = BrandIcon(0xf1b6)

    // https://fontawesome.com/icons/steam-square?style=brands
    // Brands icon : SteamSquare
    @Suppress("unused") val SteamSquare = BrandIcon(0xf1b7)

    // https://fontawesome.com/icons/steam-symbol?style=brands
    // Brands icon : SteamSymbol
    @Suppress("unused") val SteamSymbol = BrandIcon(0xf3f6)

    // https://fontawesome.com/icons/step-backward?style=solid
    // Solid icon : StepBackward
    @Suppress("unused") val StepBackward = SolidIcon(0xf048)

    // https://fontawesome.com/icons/step-forward?style=solid
    // Solid icon : StepForward
    @Suppress("unused") val StepForward = SolidIcon(0xf051)

    // https://fontawesome.com/icons/stethoscope?style=solid
    // Solid icon : Stethoscope
    @Suppress("unused") val Stethoscope = SolidIcon(0xf0f1)

    // https://fontawesome.com/icons/sticker-mule?style=brands
    // Brands icon : StickerMule
    @Suppress("unused") val StickerMule = BrandIcon(0xf3f7)

    // https://fontawesome.com/icons/sticky-note?style=solid
    // Solid icon : StickyNote
    @Suppress("unused") val StickyNote = SolidIcon(0xf249)

    // https://fontawesome.com/icons/sticky-note?style=regular
    // Regular icon : StickyNote
    @Suppress("unused") val StickyNoteRegular = RegularIcon(0xf249)

    // https://fontawesome.com/icons/stop?style=solid
    // Solid icon : Stop
    @Suppress("unused") val Stop = SolidIcon(0xf04d)

    // https://fontawesome.com/icons/stop-circle?style=solid
    // Solid icon : StopCircle
    @Suppress("unused") val StopCircle = SolidIcon(0xf28d)

    // https://fontawesome.com/icons/stop-circle?style=regular
    // Regular icon : StopCircle
    @Suppress("unused") val StopCircleRegular = RegularIcon(0xf28d)

    // https://fontawesome.com/icons/stopwatch?style=solid
    // Solid icon : Stopwatch
    @Suppress("unused") val Stopwatch = SolidIcon(0xf2f2)

    // https://fontawesome.com/icons/stopwatch-20?style=solid
    // Solid icon : Stopwatch20
    @Suppress("unused") val Stopwatch20 = SolidIcon(0xe06f)

    // https://fontawesome.com/icons/store?style=solid
    // Solid icon : Store
    @Suppress("unused") val Store = SolidIcon(0xf54e)

    // https://fontawesome.com/icons/store-alt?style=solid
    // Solid icon : StoreAlt
    @Suppress("unused") val StoreAlt = SolidIcon(0xf54f)

    // https://fontawesome.com/icons/store-alt-slash?style=solid
    // Solid icon : StoreAltSlash
    @Suppress("unused") val StoreAltSlash = SolidIcon(0xe070)

    // https://fontawesome.com/icons/store-slash?style=solid
    // Solid icon : StoreSlash
    @Suppress("unused") val StoreSlash = SolidIcon(0xe071)

    // https://fontawesome.com/icons/strava?style=brands
    // Brands icon : Strava
    @Suppress("unused") val Strava = BrandIcon(0xf428)

    // https://fontawesome.com/icons/stream?style=solid
    // Solid icon : Stream
    @Suppress("unused") val Stream = SolidIcon(0xf550)

    // https://fontawesome.com/icons/street-view?style=solid
    // Solid icon : StreetView
    @Suppress("unused") val StreetView = SolidIcon(0xf21d)

    // https://fontawesome.com/icons/strikethrough?style=solid
    // Solid icon : Strikethrough
    @Suppress("unused") val Strikethrough = SolidIcon(0xf0cc)

    // https://fontawesome.com/icons/stripe?style=brands
    // Brands icon : Stripe
    @Suppress("unused") val Stripe = BrandIcon(0xf429)

    // https://fontawesome.com/icons/stripe-s?style=brands
    // Brands icon : StripeS
    @Suppress("unused") val StripeS = BrandIcon(0xf42a)

    // https://fontawesome.com/icons/stroopwafel?style=solid
    // Solid icon : Stroopwafel
    @Suppress("unused") val Stroopwafel = SolidIcon(0xf551)

    // https://fontawesome.com/icons/studiovinari?style=brands
    // Brands icon : Studiovinari
    @Suppress("unused") val Studiovinari = BrandIcon(0xf3f8)

    // https://fontawesome.com/icons/stumbleupon?style=brands
    // Brands icon : Stumbleupon
    @Suppress("unused") val Stumbleupon = BrandIcon(0xf1a4)

    // https://fontawesome.com/icons/stumbleupon-circle?style=brands
    // Brands icon : StumbleuponCircle
    @Suppress("unused") val StumbleuponCircle = BrandIcon(0xf1a3)

    // https://fontawesome.com/icons/subscript?style=solid
    // Solid icon : Subscript
    @Suppress("unused") val Subscript = SolidIcon(0xf12c)

    // https://fontawesome.com/icons/subway?style=solid
    // Solid icon : Subway
    @Suppress("unused") val Subway = SolidIcon(0xf239)

    // https://fontawesome.com/icons/suitcase?style=solid
    // Solid icon : Suitcase
    @Suppress("unused") val Suitcase = SolidIcon(0xf0f2)

    // https://fontawesome.com/icons/suitcase-rolling?style=solid
    // Solid icon : SuitcaseRolling
    @Suppress("unused") val SuitcaseRolling = SolidIcon(0xf5c1)

    // https://fontawesome.com/icons/sun?style=solid
    // Solid icon : Sun
    @Suppress("unused") val Sun = SolidIcon(0xf185)

    // https://fontawesome.com/icons/sun?style=regular
    // Regular icon : Sun
    @Suppress("unused") val SunRegular = RegularIcon(0xf185)

    // https://fontawesome.com/icons/superpowers?style=brands
    // Brands icon : Superpowers
    @Suppress("unused") val Superpowers = BrandIcon(0xf2dd)

    // https://fontawesome.com/icons/superscript?style=solid
    // Solid icon : Superscript
    @Suppress("unused") val Superscript = SolidIcon(0xf12b)

    // https://fontawesome.com/icons/supple?style=brands
    // Brands icon : Supple
    @Suppress("unused") val Supple = BrandIcon(0xf3f9)

    // https://fontawesome.com/icons/surprise?style=solid
    // Solid icon : Surprise
    @Suppress("unused") val Surprise = SolidIcon(0xf5c2)

    // https://fontawesome.com/icons/surprise?style=regular
    // Regular icon : Surprise
    @Suppress("unused") val SurpriseRegular = RegularIcon(0xf5c2)

    // https://fontawesome.com/icons/suse?style=brands
    // Brands icon : Suse
    @Suppress("unused") val Suse = BrandIcon(0xf7d6)

    // https://fontawesome.com/icons/swatchbook?style=solid
    // Solid icon : Swatchbook
    @Suppress("unused") val Swatchbook = SolidIcon(0xf5c3)

    // https://fontawesome.com/icons/swift?style=brands
    // Brands icon : Swift
    @Suppress("unused") val Swift = BrandIcon(0xf8e1)

    // https://fontawesome.com/icons/swimmer?style=solid
    // Solid icon : Swimmer
    @Suppress("unused") val Swimmer = SolidIcon(0xf5c4)

    // https://fontawesome.com/icons/swimming-pool?style=solid
    // Solid icon : SwimmingPool
    @Suppress("unused") val SwimmingPool = SolidIcon(0xf5c5)

    // https://fontawesome.com/icons/symfony?style=brands
    // Brands icon : Symfony
    @Suppress("unused") val Symfony = BrandIcon(0xf83d)

    // https://fontawesome.com/icons/synagogue?style=solid
    // Solid icon : Synagogue
    @Suppress("unused") val Synagogue = SolidIcon(0xf69b)

    // https://fontawesome.com/icons/sync?style=solid
    // Solid icon : Sync
    @Suppress("unused") val Sync = SolidIcon(0xf021)

    // https://fontawesome.com/icons/sync-alt?style=solid
    // Solid icon : SyncAlt
    @Suppress("unused") val SyncAlt = SolidIcon(0xf2f1)

    // https://fontawesome.com/icons/syringe?style=solid
    // Solid icon : Syringe
    @Suppress("unused") val Syringe = SolidIcon(0xf48e)

    // https://fontawesome.com/icons/table?style=solid
    // Solid icon : Table
    @Suppress("unused") val Table = SolidIcon(0xf0ce)

    // https://fontawesome.com/icons/table-tennis?style=solid
    // Solid icon : TableTennis
    @Suppress("unused") val TableTennis = SolidIcon(0xf45d)

    // https://fontawesome.com/icons/tablet?style=solid
    // Solid icon : Tablet
    @Suppress("unused") val Tablet = SolidIcon(0xf10a)

    // https://fontawesome.com/icons/tablet-alt?style=solid
    // Solid icon : TabletAlt
    @Suppress("unused") val TabletAlt = SolidIcon(0xf3fa)

    // https://fontawesome.com/icons/tablets?style=solid
    // Solid icon : Tablets
    @Suppress("unused") val Tablets = SolidIcon(0xf490)

    // https://fontawesome.com/icons/tachometer-alt?style=solid
    // Solid icon : TachometerAlt
    @Suppress("unused") val TachometerAlt = SolidIcon(0xf3fd)

    // https://fontawesome.com/icons/tag?style=solid
    // Solid icon : Tag
    @Suppress("unused") val Tag = SolidIcon(0xf02b)

    // https://fontawesome.com/icons/tags?style=solid
    // Solid icon : Tags
    @Suppress("unused") val Tags = SolidIcon(0xf02c)

    // https://fontawesome.com/icons/tape?style=solid
    // Solid icon : Tape
    @Suppress("unused") val Tape = SolidIcon(0xf4db)

    // https://fontawesome.com/icons/tasks?style=solid
    // Solid icon : Tasks
    @Suppress("unused") val Tasks = SolidIcon(0xf0ae)

    // https://fontawesome.com/icons/taxi?style=solid
    // Solid icon : Taxi
    @Suppress("unused") val Taxi = SolidIcon(0xf1ba)

    // https://fontawesome.com/icons/teamspeak?style=brands
    // Brands icon : Teamspeak
    @Suppress("unused") val Teamspeak = BrandIcon(0xf4f9)

    // https://fontawesome.com/icons/teeth?style=solid
    // Solid icon : Teeth
    @Suppress("unused") val Teeth = SolidIcon(0xf62e)

    // https://fontawesome.com/icons/teeth-open?style=solid
    // Solid icon : TeethOpen
    @Suppress("unused") val TeethOpen = SolidIcon(0xf62f)

    // https://fontawesome.com/icons/telegram?style=brands
    // Brands icon : Telegram
    @Suppress("unused") val Telegram = BrandIcon(0xf2c6)

    // https://fontawesome.com/icons/telegram-plane?style=brands
    // Brands icon : TelegramPlane
    @Suppress("unused") val TelegramPlane = BrandIcon(0xf3fe)

    // https://fontawesome.com/icons/temperature-high?style=solid
    // Solid icon : TemperatureHigh
    @Suppress("unused") val TemperatureHigh = SolidIcon(0xf769)

    // https://fontawesome.com/icons/temperature-low?style=solid
    // Solid icon : TemperatureLow
    @Suppress("unused") val TemperatureLow = SolidIcon(0xf76b)

    // https://fontawesome.com/icons/tencent-weibo?style=brands
    // Brands icon : TencentWeibo
    @Suppress("unused") val TencentWeibo = BrandIcon(0xf1d5)

    // https://fontawesome.com/icons/tenge?style=solid
    // Solid icon : Tenge
    @Suppress("unused") val Tenge = SolidIcon(0xf7d7)

    // https://fontawesome.com/icons/terminal?style=solid
    // Solid icon : Terminal
    @Suppress("unused") val Terminal = SolidIcon(0xf120)

    // https://fontawesome.com/icons/text-height?style=solid
    // Solid icon : TextHeight
    @Suppress("unused") val TextHeight = SolidIcon(0xf034)

    // https://fontawesome.com/icons/text-width?style=solid
    // Solid icon : TextWidth
    @Suppress("unused") val TextWidth = SolidIcon(0xf035)

    // https://fontawesome.com/icons/th?style=solid
    // Solid icon : Th
    @Suppress("unused") val Th = SolidIcon(0xf00a)

    // https://fontawesome.com/icons/th-large?style=solid
    // Solid icon : ThLarge
    @Suppress("unused") val ThLarge = SolidIcon(0xf009)

    // https://fontawesome.com/icons/th-list?style=solid
    // Solid icon : ThList
    @Suppress("unused") val ThList = SolidIcon(0xf00b)

    // https://fontawesome.com/icons/the-red-yeti?style=brands
    // Brands icon : TheRedYeti
    @Suppress("unused") val TheRedYeti = BrandIcon(0xf69d)

    // https://fontawesome.com/icons/theater-masks?style=solid
    // Solid icon : TheaterMasks
    @Suppress("unused") val TheaterMasks = SolidIcon(0xf630)

    // https://fontawesome.com/icons/themeco?style=brands
    // Brands icon : Themeco
    @Suppress("unused") val Themeco = BrandIcon(0xf5c6)

    // https://fontawesome.com/icons/themeisle?style=brands
    // Brands icon : Themeisle
    @Suppress("unused") val Themeisle = BrandIcon(0xf2b2)

    // https://fontawesome.com/icons/thermometer?style=solid
    // Solid icon : Thermometer
    @Suppress("unused") val Thermometer = SolidIcon(0xf491)

    // https://fontawesome.com/icons/thermometer-empty?style=solid
    // Solid icon : ThermometerEmpty
    @Suppress("unused") val ThermometerEmpty = SolidIcon(0xf2cb)

    // https://fontawesome.com/icons/thermometer-full?style=solid
    // Solid icon : ThermometerFull
    @Suppress("unused") val ThermometerFull = SolidIcon(0xf2c7)

    // https://fontawesome.com/icons/thermometer-half?style=solid
    // Solid icon : ThermometerHalf
    @Suppress("unused") val ThermometerHalf = SolidIcon(0xf2c9)

    // https://fontawesome.com/icons/thermometer-quarter?style=solid
    // Solid icon : ThermometerQuarter
    @Suppress("unused") val ThermometerQuarter = SolidIcon(0xf2ca)

    // https://fontawesome.com/icons/thermometer-three-quarters?style=solid
    // Solid icon : ThermometerThreeQuarters
    @Suppress("unused") val ThermometerThreeQuarters = SolidIcon(0xf2c8)

    // https://fontawesome.com/icons/think-peaks?style=brands
    // Brands icon : ThinkPeaks
    @Suppress("unused") val ThinkPeaks = BrandIcon(0xf731)

    // https://fontawesome.com/icons/thumbs-down?style=solid
    // Solid icon : ThumbsDown
    @Suppress("unused") val ThumbsDown = SolidIcon(0xf165)

    // https://fontawesome.com/icons/thumbs-down?style=regular
    // Regular icon : ThumbsDown
    @Suppress("unused") val ThumbsDownRegular = RegularIcon(0xf165)

    // https://fontawesome.com/icons/thumbs-up?style=solid
    // Solid icon : ThumbsUp
    @Suppress("unused") val ThumbsUp = SolidIcon(0xf164)

    // https://fontawesome.com/icons/thumbs-up?style=regular
    // Regular icon : ThumbsUp
    @Suppress("unused") val ThumbsUpRegular = RegularIcon(0xf164)

    // https://fontawesome.com/icons/thumbtack?style=solid
    // Solid icon : Thumbtack
    @Suppress("unused") val Thumbtack = SolidIcon(0xf08d)

    // https://fontawesome.com/icons/ticket-alt?style=solid
    // Solid icon : TicketAlt
    @Suppress("unused") val TicketAlt = SolidIcon(0xf3ff)

    // https://fontawesome.com/icons/tiktok?style=brands
    // Brands icon : Tiktok
    @Suppress("unused") val Tiktok = BrandIcon(0xe07b)

    // https://fontawesome.com/icons/times?style=solid
    // Solid icon : Times
    @Suppress("unused") val Times = SolidIcon(0xf00d)

    // https://fontawesome.com/icons/times-circle?style=solid
    // Solid icon : TimesCircle
    @Suppress("unused") val TimesCircle = SolidIcon(0xf057)

    // https://fontawesome.com/icons/times-circle?style=regular
    // Regular icon : TimesCircle
    @Suppress("unused") val TimesCircleRegular = RegularIcon(0xf057)

    // https://fontawesome.com/icons/tint?style=solid
    // Solid icon : Tint
    @Suppress("unused") val Tint = SolidIcon(0xf043)

    // https://fontawesome.com/icons/tint-slash?style=solid
    // Solid icon : TintSlash
    @Suppress("unused") val TintSlash = SolidIcon(0xf5c7)

    // https://fontawesome.com/icons/tired?style=solid
    // Solid icon : Tired
    @Suppress("unused") val Tired = SolidIcon(0xf5c8)

    // https://fontawesome.com/icons/tired?style=regular
    // Regular icon : Tired
    @Suppress("unused") val TiredRegular = RegularIcon(0xf5c8)

    // https://fontawesome.com/icons/toggle-off?style=solid
    // Solid icon : ToggleOff
    @Suppress("unused") val ToggleOff = SolidIcon(0xf204)

    // https://fontawesome.com/icons/toggle-on?style=solid
    // Solid icon : ToggleOn
    @Suppress("unused") val ToggleOn = SolidIcon(0xf205)

    // https://fontawesome.com/icons/toilet?style=solid
    // Solid icon : Toilet
    @Suppress("unused") val Toilet = SolidIcon(0xf7d8)

    // https://fontawesome.com/icons/toilet-paper?style=solid
    // Solid icon : ToiletPaper
    @Suppress("unused") val ToiletPaper = SolidIcon(0xf71e)

    // https://fontawesome.com/icons/toilet-paper-slash?style=solid
    // Solid icon : ToiletPaperSlash
    @Suppress("unused") val ToiletPaperSlash = SolidIcon(0xe072)

    // https://fontawesome.com/icons/toolbox?style=solid
    // Solid icon : Toolbox
    @Suppress("unused") val Toolbox = SolidIcon(0xf552)

    // https://fontawesome.com/icons/tools?style=solid
    // Solid icon : Tools
    @Suppress("unused") val Tools = SolidIcon(0xf7d9)

    // https://fontawesome.com/icons/tooth?style=solid
    // Solid icon : Tooth
    @Suppress("unused") val Tooth = SolidIcon(0xf5c9)

    // https://fontawesome.com/icons/torah?style=solid
    // Solid icon : Torah
    @Suppress("unused") val Torah = SolidIcon(0xf6a0)

    // https://fontawesome.com/icons/torii-gate?style=solid
    // Solid icon : ToriiGate
    @Suppress("unused") val ToriiGate = SolidIcon(0xf6a1)

    // https://fontawesome.com/icons/tractor?style=solid
    // Solid icon : Tractor
    @Suppress("unused") val Tractor = SolidIcon(0xf722)

    // https://fontawesome.com/icons/trade-federation?style=brands
    // Brands icon : TradeFederation
    @Suppress("unused") val TradeFederation = BrandIcon(0xf513)

    // https://fontawesome.com/icons/trademark?style=solid
    // Solid icon : Trademark
    @Suppress("unused") val Trademark = SolidIcon(0xf25c)

    // https://fontawesome.com/icons/traffic-light?style=solid
    // Solid icon : TrafficLight
    @Suppress("unused") val TrafficLight = SolidIcon(0xf637)

    // https://fontawesome.com/icons/trailer?style=solid
    // Solid icon : Trailer
    @Suppress("unused") val Trailer = SolidIcon(0xe041)

    // https://fontawesome.com/icons/train?style=solid
    // Solid icon : Train
    @Suppress("unused") val Train = SolidIcon(0xf238)

    // https://fontawesome.com/icons/tram?style=solid
    // Solid icon : Tram
    @Suppress("unused") val Tram = SolidIcon(0xf7da)

    // https://fontawesome.com/icons/transgender?style=solid
    // Solid icon : Transgender
    @Suppress("unused") val Transgender = SolidIcon(0xf224)

    // https://fontawesome.com/icons/transgender-alt?style=solid
    // Solid icon : TransgenderAlt
    @Suppress("unused") val TransgenderAlt = SolidIcon(0xf225)

    // https://fontawesome.com/icons/trash?style=solid
    // Solid icon : Trash
    @Suppress("unused") val Trash = SolidIcon(0xf1f8)

    // https://fontawesome.com/icons/trash-alt?style=solid
    // Solid icon : TrashAlt
    @Suppress("unused") val TrashAlt = SolidIcon(0xf2ed)

    // https://fontawesome.com/icons/trash-alt?style=regular
    // Regular icon : TrashAlt
    @Suppress("unused") val TrashAltRegular = RegularIcon(0xf2ed)

    // https://fontawesome.com/icons/trash-restore?style=solid
    // Solid icon : TrashRestore
    @Suppress("unused") val TrashRestore = SolidIcon(0xf829)

    // https://fontawesome.com/icons/trash-restore-alt?style=solid
    // Solid icon : TrashRestoreAlt
    @Suppress("unused") val TrashRestoreAlt = SolidIcon(0xf82a)

    // https://fontawesome.com/icons/tree?style=solid
    // Solid icon : Tree
    @Suppress("unused") val Tree = SolidIcon(0xf1bb)

    // https://fontawesome.com/icons/trello?style=brands
    // Brands icon : Trello
    @Suppress("unused") val Trello = BrandIcon(0xf181)

    // https://fontawesome.com/icons/tripadvisor?style=brands
    // Brands icon : Tripadvisor
    @Suppress("unused") val Tripadvisor = BrandIcon(0xf262)

    // https://fontawesome.com/icons/trophy?style=solid
    // Solid icon : Trophy
    @Suppress("unused") val Trophy = SolidIcon(0xf091)

    // https://fontawesome.com/icons/truck?style=solid
    // Solid icon : Truck
    @Suppress("unused") val Truck = SolidIcon(0xf0d1)

    // https://fontawesome.com/icons/truck-loading?style=solid
    // Solid icon : TruckLoading
    @Suppress("unused") val TruckLoading = SolidIcon(0xf4de)

    // https://fontawesome.com/icons/truck-monster?style=solid
    // Solid icon : TruckMonster
    @Suppress("unused") val TruckMonster = SolidIcon(0xf63b)

    // https://fontawesome.com/icons/truck-moving?style=solid
    // Solid icon : TruckMoving
    @Suppress("unused") val TruckMoving = SolidIcon(0xf4df)

    // https://fontawesome.com/icons/truck-pickup?style=solid
    // Solid icon : TruckPickup
    @Suppress("unused") val TruckPickup = SolidIcon(0xf63c)

    // https://fontawesome.com/icons/tshirt?style=solid
    // Solid icon : Tshirt
    @Suppress("unused") val Tshirt = SolidIcon(0xf553)

    // https://fontawesome.com/icons/tty?style=solid
    // Solid icon : Tty
    @Suppress("unused") val Tty = SolidIcon(0xf1e4)

    // https://fontawesome.com/icons/tumblr?style=brands
    // Brands icon : Tumblr
    @Suppress("unused") val Tumblr = BrandIcon(0xf173)

    // https://fontawesome.com/icons/tumblr-square?style=brands
    // Brands icon : TumblrSquare
    @Suppress("unused") val TumblrSquare = BrandIcon(0xf174)

    // https://fontawesome.com/icons/tv?style=solid
    // Solid icon : Tv
    @Suppress("unused") val Tv = SolidIcon(0xf26c)

    // https://fontawesome.com/icons/twitch?style=brands
    // Brands icon : Twitch
    @Suppress("unused") val Twitch = BrandIcon(0xf1e8)

    // https://fontawesome.com/icons/twitter?style=brands
    // Brands icon : Twitter
    @Suppress("unused") val Twitter = BrandIcon(0xf099)

    // https://fontawesome.com/icons/twitter-square?style=brands
    // Brands icon : TwitterSquare
    @Suppress("unused") val TwitterSquare = BrandIcon(0xf081)

    // https://fontawesome.com/icons/typo3?style=brands
    // Brands icon : Typo3
    @Suppress("unused") val Typo3 = BrandIcon(0xf42b)

    // https://fontawesome.com/icons/uber?style=brands
    // Brands icon : Uber
    @Suppress("unused") val Uber = BrandIcon(0xf402)

    // https://fontawesome.com/icons/ubuntu?style=brands
    // Brands icon : Ubuntu
    @Suppress("unused") val Ubuntu = BrandIcon(0xf7df)

    // https://fontawesome.com/icons/uikit?style=brands
    // Brands icon : Uikit
    @Suppress("unused") val Uikit = BrandIcon(0xf403)

    // https://fontawesome.com/icons/umbraco?style=brands
    // Brands icon : Umbraco
    @Suppress("unused") val Umbraco = BrandIcon(0xf8e8)

    // https://fontawesome.com/icons/umbrella?style=solid
    // Solid icon : Umbrella
    @Suppress("unused") val Umbrella = SolidIcon(0xf0e9)

    // https://fontawesome.com/icons/umbrella-beach?style=solid
    // Solid icon : UmbrellaBeach
    @Suppress("unused") val UmbrellaBeach = SolidIcon(0xf5ca)

    // https://fontawesome.com/icons/uncharted?style=brands
    // Brands icon : Uncharted
    @Suppress("unused") val Uncharted = BrandIcon(0xe084)

    // https://fontawesome.com/icons/underline?style=solid
    // Solid icon : Underline
    @Suppress("unused") val Underline = SolidIcon(0xf0cd)

    // https://fontawesome.com/icons/undo?style=solid
    // Solid icon : Undo
    @Suppress("unused") val Undo = SolidIcon(0xf0e2)

    // https://fontawesome.com/icons/undo-alt?style=solid
    // Solid icon : UndoAlt
    @Suppress("unused") val UndoAlt = SolidIcon(0xf2ea)

    // https://fontawesome.com/icons/uniregistry?style=brands
    // Brands icon : Uniregistry
    @Suppress("unused") val Uniregistry = BrandIcon(0xf404)

    // https://fontawesome.com/icons/unity?style=brands
    // Brands icon : Unity
    @Suppress("unused") val Unity = BrandIcon(0xe049)

    // https://fontawesome.com/icons/universal-access?style=solid
    // Solid icon : UniversalAccess
    @Suppress("unused") val UniversalAccess = SolidIcon(0xf29a)

    // https://fontawesome.com/icons/university?style=solid
    // Solid icon : University
    @Suppress("unused") val University = SolidIcon(0xf19c)

    // https://fontawesome.com/icons/unlink?style=solid
    // Solid icon : Unlink
    @Suppress("unused") val Unlink = SolidIcon(0xf127)

    // https://fontawesome.com/icons/unlock?style=solid
    // Solid icon : Unlock
    @Suppress("unused") val Unlock = SolidIcon(0xf09c)

    // https://fontawesome.com/icons/unlock-alt?style=solid
    // Solid icon : UnlockAlt
    @Suppress("unused") val UnlockAlt = SolidIcon(0xf13e)

    // https://fontawesome.com/icons/unsplash?style=brands
    // Brands icon : Unsplash
    @Suppress("unused") val Unsplash = BrandIcon(0xe07c)

    // https://fontawesome.com/icons/untappd?style=brands
    // Brands icon : Untappd
    @Suppress("unused") val Untappd = BrandIcon(0xf405)

    // https://fontawesome.com/icons/upload?style=solid
    // Solid icon : Upload
    @Suppress("unused") val Upload = SolidIcon(0xf093)

    // https://fontawesome.com/icons/ups?style=brands
    // Brands icon : Ups
    @Suppress("unused") val Ups = BrandIcon(0xf7e0)

    // https://fontawesome.com/icons/usb?style=brands
    // Brands icon : Usb
    @Suppress("unused") val Usb = BrandIcon(0xf287)

    // https://fontawesome.com/icons/user?style=solid
    // Solid icon : User
    @Suppress("unused") val User = SolidIcon(0xf007)

    // https://fontawesome.com/icons/user?style=regular
    // Regular icon : User
    @Suppress("unused") val UserRegular = RegularIcon(0xf007)

    // https://fontawesome.com/icons/user-alt?style=solid
    // Solid icon : UserAlt
    @Suppress("unused") val UserAlt = SolidIcon(0xf406)

    // https://fontawesome.com/icons/user-alt-slash?style=solid
    // Solid icon : UserAltSlash
    @Suppress("unused") val UserAltSlash = SolidIcon(0xf4fa)

    // https://fontawesome.com/icons/user-astronaut?style=solid
    // Solid icon : UserAstronaut
    @Suppress("unused") val UserAstronaut = SolidIcon(0xf4fb)

    // https://fontawesome.com/icons/user-check?style=solid
    // Solid icon : UserCheck
    @Suppress("unused") val UserCheck = SolidIcon(0xf4fc)

    // https://fontawesome.com/icons/user-circle?style=solid
    // Solid icon : UserCircle
    @Suppress("unused") val UserCircle = SolidIcon(0xf2bd)

    // https://fontawesome.com/icons/user-circle?style=regular
    // Regular icon : UserCircle
    @Suppress("unused") val UserCircleRegular = RegularIcon(0xf2bd)

    // https://fontawesome.com/icons/user-clock?style=solid
    // Solid icon : UserClock
    @Suppress("unused") val UserClock = SolidIcon(0xf4fd)

    // https://fontawesome.com/icons/user-cog?style=solid
    // Solid icon : UserCog
    @Suppress("unused") val UserCog = SolidIcon(0xf4fe)

    // https://fontawesome.com/icons/user-edit?style=solid
    // Solid icon : UserEdit
    @Suppress("unused") val UserEdit = SolidIcon(0xf4ff)

    // https://fontawesome.com/icons/user-friends?style=solid
    // Solid icon : UserFriends
    @Suppress("unused") val UserFriends = SolidIcon(0xf500)

    // https://fontawesome.com/icons/user-graduate?style=solid
    // Solid icon : UserGraduate
    @Suppress("unused") val UserGraduate = SolidIcon(0xf501)

    // https://fontawesome.com/icons/user-injured?style=solid
    // Solid icon : UserInjured
    @Suppress("unused") val UserInjured = SolidIcon(0xf728)

    // https://fontawesome.com/icons/user-lock?style=solid
    // Solid icon : UserLock
    @Suppress("unused") val UserLock = SolidIcon(0xf502)

    // https://fontawesome.com/icons/user-md?style=solid
    // Solid icon : UserMd
    @Suppress("unused") val UserMd = SolidIcon(0xf0f0)

    // https://fontawesome.com/icons/user-minus?style=solid
    // Solid icon : UserMinus
    @Suppress("unused") val UserMinus = SolidIcon(0xf503)

    // https://fontawesome.com/icons/user-ninja?style=solid
    // Solid icon : UserNinja
    @Suppress("unused") val UserNinja = SolidIcon(0xf504)

    // https://fontawesome.com/icons/user-nurse?style=solid
    // Solid icon : UserNurse
    @Suppress("unused") val UserNurse = SolidIcon(0xf82f)

    // https://fontawesome.com/icons/user-plus?style=solid
    // Solid icon : UserPlus
    @Suppress("unused") val UserPlus = SolidIcon(0xf234)

    // https://fontawesome.com/icons/user-secret?style=solid
    // Solid icon : UserSecret
    @Suppress("unused") val UserSecret = SolidIcon(0xf21b)

    // https://fontawesome.com/icons/user-shield?style=solid
    // Solid icon : UserShield
    @Suppress("unused") val UserShield = SolidIcon(0xf505)

    // https://fontawesome.com/icons/user-slash?style=solid
    // Solid icon : UserSlash
    @Suppress("unused") val UserSlash = SolidIcon(0xf506)

    // https://fontawesome.com/icons/user-tag?style=solid
    // Solid icon : UserTag
    @Suppress("unused") val UserTag = SolidIcon(0xf507)

    // https://fontawesome.com/icons/user-tie?style=solid
    // Solid icon : UserTie
    @Suppress("unused") val UserTie = SolidIcon(0xf508)

    // https://fontawesome.com/icons/user-times?style=solid
    // Solid icon : UserTimes
    @Suppress("unused") val UserTimes = SolidIcon(0xf235)

    // https://fontawesome.com/icons/users?style=solid
    // Solid icon : Users
    @Suppress("unused") val Users = SolidIcon(0xf0c0)

    // https://fontawesome.com/icons/users-cog?style=solid
    // Solid icon : UsersCog
    @Suppress("unused") val UsersCog = SolidIcon(0xf509)

    // https://fontawesome.com/icons/users-slash?style=solid
    // Solid icon : UsersSlash
    @Suppress("unused") val UsersSlash = SolidIcon(0xe073)

    // https://fontawesome.com/icons/usps?style=brands
    // Brands icon : Usps
    @Suppress("unused") val Usps = BrandIcon(0xf7e1)

    // https://fontawesome.com/icons/ussunnah?style=brands
    // Brands icon : Ussunnah
    @Suppress("unused") val Ussunnah = BrandIcon(0xf407)

    // https://fontawesome.com/icons/utensil-spoon?style=solid
    // Solid icon : UtensilSpoon
    @Suppress("unused") val UtensilSpoon = SolidIcon(0xf2e5)

    // https://fontawesome.com/icons/utensils?style=solid
    // Solid icon : Utensils
    @Suppress("unused") val Utensils = SolidIcon(0xf2e7)

    // https://fontawesome.com/icons/vaadin?style=brands
    // Brands icon : Vaadin
    @Suppress("unused") val Vaadin = BrandIcon(0xf408)

    // https://fontawesome.com/icons/vector-square?style=solid
    // Solid icon : VectorSquare
    @Suppress("unused") val VectorSquare = SolidIcon(0xf5cb)

    // https://fontawesome.com/icons/venus?style=solid
    // Solid icon : Venus
    @Suppress("unused") val Venus = SolidIcon(0xf221)

    // https://fontawesome.com/icons/venus-double?style=solid
    // Solid icon : VenusDouble
    @Suppress("unused") val VenusDouble = SolidIcon(0xf226)

    // https://fontawesome.com/icons/venus-mars?style=solid
    // Solid icon : VenusMars
    @Suppress("unused") val VenusMars = SolidIcon(0xf228)

    // https://fontawesome.com/icons/vest?style=solid
    // Solid icon : Vest
    @Suppress("unused") val Vest = SolidIcon(0xe085)

    // https://fontawesome.com/icons/vest-patches?style=solid
    // Solid icon : VestPatches
    @Suppress("unused") val VestPatches = SolidIcon(0xe086)

    // https://fontawesome.com/icons/viacoin?style=brands
    // Brands icon : Viacoin
    @Suppress("unused") val Viacoin = BrandIcon(0xf237)

    // https://fontawesome.com/icons/viadeo?style=brands
    // Brands icon : Viadeo
    @Suppress("unused") val Viadeo = BrandIcon(0xf2a9)

    // https://fontawesome.com/icons/viadeo-square?style=brands
    // Brands icon : ViadeoSquare
    @Suppress("unused") val ViadeoSquare = BrandIcon(0xf2aa)

    // https://fontawesome.com/icons/vial?style=solid
    // Solid icon : Vial
    @Suppress("unused") val Vial = SolidIcon(0xf492)

    // https://fontawesome.com/icons/vials?style=solid
    // Solid icon : Vials
    @Suppress("unused") val Vials = SolidIcon(0xf493)

    // https://fontawesome.com/icons/viber?style=brands
    // Brands icon : Viber
    @Suppress("unused") val Viber = BrandIcon(0xf409)

    // https://fontawesome.com/icons/video?style=solid
    // Solid icon : Video
    @Suppress("unused") val Video = SolidIcon(0xf03d)

    // https://fontawesome.com/icons/video-slash?style=solid
    // Solid icon : VideoSlash
    @Suppress("unused") val VideoSlash = SolidIcon(0xf4e2)

    // https://fontawesome.com/icons/vihara?style=solid
    // Solid icon : Vihara
    @Suppress("unused") val Vihara = SolidIcon(0xf6a7)

    // https://fontawesome.com/icons/vimeo?style=brands
    // Brands icon : Vimeo
    @Suppress("unused") val Vimeo = BrandIcon(0xf40a)

    // https://fontawesome.com/icons/vimeo-square?style=brands
    // Brands icon : VimeoSquare
    @Suppress("unused") val VimeoSquare = BrandIcon(0xf194)

    // https://fontawesome.com/icons/vimeo-v?style=brands
    // Brands icon : VimeoV
    @Suppress("unused") val VimeoV = BrandIcon(0xf27d)

    // https://fontawesome.com/icons/vine?style=brands
    // Brands icon : Vine
    @Suppress("unused") val Vine = BrandIcon(0xf1ca)

    // https://fontawesome.com/icons/virus?style=solid
    // Solid icon : Virus
    @Suppress("unused") val Virus = SolidIcon(0xe074)

    // https://fontawesome.com/icons/virus-slash?style=solid
    // Solid icon : VirusSlash
    @Suppress("unused") val VirusSlash = SolidIcon(0xe075)

    // https://fontawesome.com/icons/viruses?style=solid
    // Solid icon : Viruses
    @Suppress("unused") val Viruses = SolidIcon(0xe076)

    // https://fontawesome.com/icons/vk?style=brands
    // Brands icon : Vk
    @Suppress("unused") val Vk = BrandIcon(0xf189)

    // https://fontawesome.com/icons/vnv?style=brands
    // Brands icon : Vnv
    @Suppress("unused") val Vnv = BrandIcon(0xf40b)

    // https://fontawesome.com/icons/voicemail?style=solid
    // Solid icon : Voicemail
    @Suppress("unused") val Voicemail = SolidIcon(0xf897)

    // https://fontawesome.com/icons/volleyball-ball?style=solid
    // Solid icon : VolleyballBall
    @Suppress("unused") val VolleyballBall = SolidIcon(0xf45f)

    // https://fontawesome.com/icons/volume-down?style=solid
    // Solid icon : VolumeDown
    @Suppress("unused") val VolumeDown = SolidIcon(0xf027)

    // https://fontawesome.com/icons/volume-mute?style=solid
    // Solid icon : VolumeMute
    @Suppress("unused") val VolumeMute = SolidIcon(0xf6a9)

    // https://fontawesome.com/icons/volume-off?style=solid
    // Solid icon : VolumeOff
    @Suppress("unused") val VolumeOff = SolidIcon(0xf026)

    // https://fontawesome.com/icons/volume-up?style=solid
    // Solid icon : VolumeUp
    @Suppress("unused") val VolumeUp = SolidIcon(0xf028)

    // https://fontawesome.com/icons/vote-yea?style=solid
    // Solid icon : VoteYea
    @Suppress("unused") val VoteYea = SolidIcon(0xf772)

    // https://fontawesome.com/icons/vr-cardboard?style=solid
    // Solid icon : VrCardboard
    @Suppress("unused") val VrCardboard = SolidIcon(0xf729)

    // https://fontawesome.com/icons/vuejs?style=brands
    // Brands icon : Vuejs
    @Suppress("unused") val Vuejs = BrandIcon(0xf41f)

    // https://fontawesome.com/icons/walking?style=solid
    // Solid icon : Walking
    @Suppress("unused") val Walking = SolidIcon(0xf554)

    // https://fontawesome.com/icons/wallet?style=solid
    // Solid icon : Wallet
    @Suppress("unused") val Wallet = SolidIcon(0xf555)

    // https://fontawesome.com/icons/warehouse?style=solid
    // Solid icon : Warehouse
    @Suppress("unused") val Warehouse = SolidIcon(0xf494)

    // https://fontawesome.com/icons/watchman-monitoring?style=brands
    // Brands icon : WatchmanMonitoring
    @Suppress("unused") val WatchmanMonitoring = BrandIcon(0xe087)

    // https://fontawesome.com/icons/water?style=solid
    // Solid icon : Water
    @Suppress("unused") val Water = SolidIcon(0xf773)

    // https://fontawesome.com/icons/wave-square?style=solid
    // Solid icon : WaveSquare
    @Suppress("unused") val WaveSquare = SolidIcon(0xf83e)

    // https://fontawesome.com/icons/waze?style=brands
    // Brands icon : Waze
    @Suppress("unused") val Waze = BrandIcon(0xf83f)

    // https://fontawesome.com/icons/weebly?style=brands
    // Brands icon : Weebly
    @Suppress("unused") val Weebly = BrandIcon(0xf5cc)

    // https://fontawesome.com/icons/weibo?style=brands
    // Brands icon : Weibo
    @Suppress("unused") val Weibo = BrandIcon(0xf18a)

    // https://fontawesome.com/icons/weight?style=solid
    // Solid icon : Weight
    @Suppress("unused") val Weight = SolidIcon(0xf496)

    // https://fontawesome.com/icons/weight-hanging?style=solid
    // Solid icon : WeightHanging
    @Suppress("unused") val WeightHanging = SolidIcon(0xf5cd)

    // https://fontawesome.com/icons/weixin?style=brands
    // Brands icon : Weixin
    @Suppress("unused") val Weixin = BrandIcon(0xf1d7)

    // https://fontawesome.com/icons/whatsapp?style=brands
    // Brands icon : Whatsapp
    @Suppress("unused") val Whatsapp = BrandIcon(0xf232)

    // https://fontawesome.com/icons/whatsapp-square?style=brands
    // Brands icon : WhatsappSquare
    @Suppress("unused") val WhatsappSquare = BrandIcon(0xf40c)

    // https://fontawesome.com/icons/wheelchair?style=solid
    // Solid icon : Wheelchair
    @Suppress("unused") val Wheelchair = SolidIcon(0xf193)

    // https://fontawesome.com/icons/whmcs?style=brands
    // Brands icon : Whmcs
    @Suppress("unused") val Whmcs = BrandIcon(0xf40d)

    // https://fontawesome.com/icons/wifi?style=solid
    // Solid icon : Wifi
    @Suppress("unused") val Wifi = SolidIcon(0xf1eb)

    // https://fontawesome.com/icons/wikipedia-w?style=brands
    // Brands icon : WikipediaW
    @Suppress("unused") val WikipediaW = BrandIcon(0xf266)

    // https://fontawesome.com/icons/wind?style=solid
    // Solid icon : Wind
    @Suppress("unused") val Wind = SolidIcon(0xf72e)

    // https://fontawesome.com/icons/window-close?style=solid
    // Solid icon : WindowClose
    @Suppress("unused") val WindowClose = SolidIcon(0xf410)

    // https://fontawesome.com/icons/window-close?style=regular
    // Regular icon : WindowClose
    @Suppress("unused") val WindowCloseRegular = RegularIcon(0xf410)

    // https://fontawesome.com/icons/window-maximize?style=solid
    // Solid icon : WindowMaximize
    @Suppress("unused") val WindowMaximize = SolidIcon(0xf2d0)

    // https://fontawesome.com/icons/window-maximize?style=regular
    // Regular icon : WindowMaximize
    @Suppress("unused") val WindowMaximizeRegular = RegularIcon(0xf2d0)

    // https://fontawesome.com/icons/window-minimize?style=solid
    // Solid icon : WindowMinimize
    @Suppress("unused") val WindowMinimize = SolidIcon(0xf2d1)

    // https://fontawesome.com/icons/window-minimize?style=regular
    // Regular icon : WindowMinimize
    @Suppress("unused") val WindowMinimizeRegular = RegularIcon(0xf2d1)

    // https://fontawesome.com/icons/window-restore?style=solid
    // Solid icon : WindowRestore
    @Suppress("unused") val WindowRestore = SolidIcon(0xf2d2)

    // https://fontawesome.com/icons/window-restore?style=regular
    // Regular icon : WindowRestore
    @Suppress("unused") val WindowRestoreRegular = RegularIcon(0xf2d2)

    // https://fontawesome.com/icons/windows?style=brands
    // Brands icon : Windows
    @Suppress("unused") val Windows = BrandIcon(0xf17a)

    // https://fontawesome.com/icons/wine-bottle?style=solid
    // Solid icon : WineBottle
    @Suppress("unused") val WineBottle = SolidIcon(0xf72f)

    // https://fontawesome.com/icons/wine-glass?style=solid
    // Solid icon : WineGlass
    @Suppress("unused") val WineGlass = SolidIcon(0xf4e3)

    // https://fontawesome.com/icons/wine-glass-alt?style=solid
    // Solid icon : WineGlassAlt
    @Suppress("unused") val WineGlassAlt = SolidIcon(0xf5ce)

    // https://fontawesome.com/icons/wix?style=brands
    // Brands icon : Wix
    @Suppress("unused") val Wix = BrandIcon(0xf5cf)

    // https://fontawesome.com/icons/wizards-of-the-coast?style=brands
    // Brands icon : WizardsOfTheCoast
    @Suppress("unused") val WizardsOfTheCoast = BrandIcon(0xf730)

    // https://fontawesome.com/icons/wodu?style=brands
    // Brands icon : Wodu
    @Suppress("unused") val Wodu = BrandIcon(0xe088)

    // https://fontawesome.com/icons/wolf-pack-battalion?style=brands
    // Brands icon : WolfPackBattalion
    @Suppress("unused") val WolfPackBattalion = BrandIcon(0xf514)

    // https://fontawesome.com/icons/won-sign?style=solid
    // Solid icon : WonSign
    @Suppress("unused") val WonSign = SolidIcon(0xf159)

    // https://fontawesome.com/icons/wordpress?style=brands
    // Brands icon : Wordpress
    @Suppress("unused") val Wordpress = BrandIcon(0xf19a)

    // https://fontawesome.com/icons/wordpress-simple?style=brands
    // Brands icon : WordpressSimple
    @Suppress("unused") val WordpressSimple = BrandIcon(0xf411)

    // https://fontawesome.com/icons/wpbeginner?style=brands
    // Brands icon : Wpbeginner
    @Suppress("unused") val Wpbeginner = BrandIcon(0xf297)

    // https://fontawesome.com/icons/wpexplorer?style=brands
    // Brands icon : Wpexplorer
    @Suppress("unused") val Wpexplorer = BrandIcon(0xf2de)

    // https://fontawesome.com/icons/wpforms?style=brands
    // Brands icon : Wpforms
    @Suppress("unused") val Wpforms = BrandIcon(0xf298)

    // https://fontawesome.com/icons/wpressr?style=brands
    // Brands icon : Wpressr
    @Suppress("unused") val Wpressr = BrandIcon(0xf3e4)

    // https://fontawesome.com/icons/wrench?style=solid
    // Solid icon : Wrench
    @Suppress("unused") val Wrench = SolidIcon(0xf0ad)

    // https://fontawesome.com/icons/x-ray?style=solid
    // Solid icon : XRay
    @Suppress("unused") val XRay = SolidIcon(0xf497)

    // https://fontawesome.com/icons/xbox?style=brands
    // Brands icon : Xbox
    @Suppress("unused") val Xbox = BrandIcon(0xf412)

    // https://fontawesome.com/icons/xing?style=brands
    // Brands icon : Xing
    @Suppress("unused") val Xing = BrandIcon(0xf168)

    // https://fontawesome.com/icons/xing-square?style=brands
    // Brands icon : XingSquare
    @Suppress("unused") val XingSquare = BrandIcon(0xf169)

    // https://fontawesome.com/icons/y-combinator?style=brands
    // Brands icon : YCombinator
    @Suppress("unused") val YCombinator = BrandIcon(0xf23b)

    // https://fontawesome.com/icons/yahoo?style=brands
    // Brands icon : Yahoo
    @Suppress("unused") val Yahoo = BrandIcon(0xf19e)

    // https://fontawesome.com/icons/yammer?style=brands
    // Brands icon : Yammer
    @Suppress("unused") val Yammer = BrandIcon(0xf840)

    // https://fontawesome.com/icons/yandex?style=brands
    // Brands icon : Yandex
    @Suppress("unused") val Yandex = BrandIcon(0xf413)

    // https://fontawesome.com/icons/yandex-international?style=brands
    // Brands icon : YandexInternational
    @Suppress("unused") val YandexInternational = BrandIcon(0xf414)

    // https://fontawesome.com/icons/yarn?style=brands
    // Brands icon : Yarn
    @Suppress("unused") val Yarn = BrandIcon(0xf7e3)

    // https://fontawesome.com/icons/yelp?style=brands
    // Brands icon : Yelp
    @Suppress("unused") val Yelp = BrandIcon(0xf1e9)

    // https://fontawesome.com/icons/yen-sign?style=solid
    // Solid icon : YenSign
    @Suppress("unused") val YenSign = SolidIcon(0xf157)

    // https://fontawesome.com/icons/yin-yang?style=solid
    // Solid icon : YinYang
    @Suppress("unused") val YinYang = SolidIcon(0xf6ad)

    // https://fontawesome.com/icons/yoast?style=brands
    // Brands icon : Yoast
    @Suppress("unused") val Yoast = BrandIcon(0xf2b1)

    // https://fontawesome.com/icons/youtube?style=brands
    // Brands icon : Youtube
    @Suppress("unused") val Youtube = BrandIcon(0xf167)

    // https://fontawesome.com/icons/youtube-square?style=brands
    // Brands icon : YoutubeSquare
    @Suppress("unused") val YoutubeSquare = BrandIcon(0xf431)

    // https://fontawesome.com/icons/zhihu?style=brands
    // Brands icon : Zhihu
    @Suppress("unused") val Zhihu = BrandIcon(0xf63f)

    private val iconsByName = mapOf(
        "accessible-icon" to AccessibleIcon,
        "accusoft" to Accusoft,
        "acquisitions-incorporated" to AcquisitionsIncorporated,
        "ad" to Ad,
        "address-book" to AddressBook,
        "address-book-regular" to AddressBookRegular,
        "address-card" to AddressCard,
        "address-card-regular" to AddressCardRegular,
        "adjust" to Adjust,
        "adn" to Adn,
        "adversal" to Adversal,
        "affiliatetheme" to Affiliatetheme,
        "air-freshener" to AirFreshener,
        "airbnb" to Airbnb,
        "algolia" to Algolia,
        "align-center" to AlignCenter,
        "align-justify" to AlignJustify,
        "align-left" to AlignLeft,
        "align-right" to AlignRight,
        "alipay" to Alipay,
        "allergies" to Allergies,
        "amazon" to Amazon,
        "amazon-pay" to AmazonPay,
        "ambulance" to Ambulance,
        "american-sign-language-interpreting" to AmericanSignLanguageInterpreting,
        "amilia" to Amilia,
        "anchor" to Anchor,
        "android" to Android,
        "angellist" to Angellist,
        "angle-double-down" to AngleDoubleDown,
        "angle-double-left" to AngleDoubleLeft,
        "angle-double-right" to AngleDoubleRight,
        "angle-double-up" to AngleDoubleUp,
        "angle-down" to AngleDown,
        "angle-left" to AngleLeft,
        "angle-right" to AngleRight,
        "angle-up" to AngleUp,
        "angry" to Angry,
        "angry-regular" to AngryRegular,
        "angrycreative" to Angrycreative,
        "angular" to Angular,
        "ankh" to Ankh,
        "app-store" to AppStore,
        "app-store-ios" to AppStoreIos,
        "apper" to Apper,
        "apple" to Apple,
        "apple-alt" to AppleAlt,
        "apple-pay" to ApplePay,
        "archive" to Archive,
        "archway" to Archway,
        "arrow-alt-circle-down" to ArrowAltCircleDown,
        "arrow-alt-circle-down-regular" to ArrowAltCircleDownRegular,
        "arrow-alt-circle-left" to ArrowAltCircleLeft,
        "arrow-alt-circle-left-regular" to ArrowAltCircleLeftRegular,
        "arrow-alt-circle-right" to ArrowAltCircleRight,
        "arrow-alt-circle-right-regular" to ArrowAltCircleRightRegular,
        "arrow-alt-circle-up" to ArrowAltCircleUp,
        "arrow-alt-circle-up-regular" to ArrowAltCircleUpRegular,
        "arrow-circle-down" to ArrowCircleDown,
        "arrow-circle-left" to ArrowCircleLeft,
        "arrow-circle-right" to ArrowCircleRight,
        "arrow-circle-up" to ArrowCircleUp,
        "arrow-down" to ArrowDown,
        "arrow-left" to ArrowLeft,
        "arrow-right" to ArrowRight,
        "arrow-up" to ArrowUp,
        "arrows-alt" to ArrowsAlt,
        "arrows-alth" to ArrowsAltH,
        "arrows-altv" to ArrowsAltV,
        "artstation" to Artstation,
        "assistive-listening-systems" to AssistiveListeningSystems,
        "asterisk" to Asterisk,
        "asymmetrik" to Asymmetrik,
        "at" to At,
        "atlas" to Atlas,
        "atlassian" to Atlassian,
        "atom" to Atom,
        "audible" to Audible,
        "audio-description" to AudioDescription,
        "autoprefixer" to Autoprefixer,
        "avianex" to Avianex,
        "aviato" to Aviato,
        "award" to Award,
        "aws" to Aws,
        "baby" to Baby,
        "baby-carriage" to BabyCarriage,
        "backspace" to Backspace,
        "backward" to Backward,
        "bacon" to Bacon,
        "bacteria" to Bacteria,
        "bacterium" to Bacterium,
        "bahai" to Bahai,
        "balance-scale" to BalanceScale,
        "balance-scale-left" to BalanceScaleLeft,
        "balance-scale-right" to BalanceScaleRight,
        "ban" to Ban,
        "band-aid" to BandAid,
        "bandcamp" to Bandcamp,
        "barcode" to Barcode,
        "bars" to Bars,
        "baseball-ball" to BaseballBall,
        "basketball-ball" to BasketballBall,
        "bath" to Bath,
        "battery-empty" to BatteryEmpty,
        "battery-full" to BatteryFull,
        "battery-half" to BatteryHalf,
        "battery-quarter" to BatteryQuarter,
        "battery-three-quarters" to BatteryThreeQuarters,
        "battle-net" to BattleNet,
        "bed" to Bed,
        "beer" to Beer,
        "behance" to Behance,
        "behance-square" to BehanceSquare,
        "bell" to Bell,
        "bell-regular" to BellRegular,
        "bell-slash" to BellSlash,
        "bell-slash-regular" to BellSlashRegular,
        "bezier-curve" to BezierCurve,
        "bible" to Bible,
        "bicycle" to Bicycle,
        "biking" to Biking,
        "bimobject" to Bimobject,
        "binoculars" to Binoculars,
        "biohazard" to Biohazard,
        "birthday-cake" to BirthdayCake,
        "bitbucket" to Bitbucket,
        "bitcoin" to Bitcoin,
        "bity" to Bity,
        "black-tie" to BlackTie,
        "blackberry" to Blackberry,
        "blender" to Blender,
        "blender-phone" to BlenderPhone,
        "blind" to Blind,
        "blog" to Blog,
        "blogger" to Blogger,
        "bloggerb" to BloggerB,
        "bluetooth" to Bluetooth,
        "bluetoothb" to BluetoothB,
        "bold" to Bold,
        "bolt" to Bolt,
        "bomb" to Bomb,
        "bone" to Bone,
        "bong" to Bong,
        "book" to Book,
        "book-dead" to BookDead,
        "book-medical" to BookMedical,
        "book-open" to BookOpen,
        "book-reader" to BookReader,
        "bookmark" to Bookmark,
        "bookmark-regular" to BookmarkRegular,
        "bootstrap" to Bootstrap,
        "border-all" to BorderAll,
        "border-none" to BorderNone,
        "border-style" to BorderStyle,
        "bowling-ball" to BowlingBall,
        "box" to Box,
        "box-open" to BoxOpen,
        "box-tissue" to BoxTissue,
        "boxes" to Boxes,
        "braille" to Braille,
        "brain" to Brain,
        "bread-slice" to BreadSlice,
        "briefcase" to Briefcase,
        "briefcase-medical" to BriefcaseMedical,
        "broadcast-tower" to BroadcastTower,
        "broom" to Broom,
        "brush" to Brush,
        "btc" to Btc,
        "buffer" to Buffer,
        "bug" to Bug,
        "building" to Building,
        "building-regular" to BuildingRegular,
        "bullhorn" to Bullhorn,
        "bullseye" to Bullseye,
        "burn" to Burn,
        "buromobelexperte" to Buromobelexperte,
        "bus" to Bus,
        "bus-alt" to BusAlt,
        "business-time" to BusinessTime,
        "buy-nlarge" to BuyNLarge,
        "buysellads" to Buysellads,
        "calculator" to Calculator,
        "calendar" to Calendar,
        "calendar-regular" to CalendarRegular,
        "calendar-alt" to CalendarAlt,
        "calendar-alt-regular" to CalendarAltRegular,
        "calendar-check" to CalendarCheck,
        "calendar-check-regular" to CalendarCheckRegular,
        "calendar-day" to CalendarDay,
        "calendar-minus" to CalendarMinus,
        "calendar-minus-regular" to CalendarMinusRegular,
        "calendar-plus" to CalendarPlus,
        "calendar-plus-regular" to CalendarPlusRegular,
        "calendar-times" to CalendarTimes,
        "calendar-times-regular" to CalendarTimesRegular,
        "calendar-week" to CalendarWeek,
        "camera" to Camera,
        "camera-retro" to CameraRetro,
        "campground" to Campground,
        "canadian-maple-leaf" to CanadianMapleLeaf,
        "candy-cane" to CandyCane,
        "cannabis" to Cannabis,
        "capsules" to Capsules,
        "car" to Car,
        "car-alt" to CarAlt,
        "car-battery" to CarBattery,
        "car-crash" to CarCrash,
        "car-side" to CarSide,
        "caravan" to Caravan,
        "caret-down" to CaretDown,
        "caret-left" to CaretLeft,
        "caret-right" to CaretRight,
        "caret-square-down" to CaretSquareDown,
        "caret-square-down-regular" to CaretSquareDownRegular,
        "caret-square-left" to CaretSquareLeft,
        "caret-square-left-regular" to CaretSquareLeftRegular,
        "caret-square-right" to CaretSquareRight,
        "caret-square-right-regular" to CaretSquareRightRegular,
        "caret-square-up" to CaretSquareUp,
        "caret-square-up-regular" to CaretSquareUpRegular,
        "caret-up" to CaretUp,
        "carrot" to Carrot,
        "cart-arrow-down" to CartArrowDown,
        "cart-plus" to CartPlus,
        "cash-register" to CashRegister,
        "cat" to Cat,
        "cc-amazon-pay" to CcAmazonPay,
        "cc-amex" to CcAmex,
        "cc-apple-pay" to CcApplePay,
        "cc-diners-club" to CcDinersClub,
        "cc-discover" to CcDiscover,
        "cc-jcb" to CcJcb,
        "cc-mastercard" to CcMastercard,
        "cc-paypal" to CcPaypal,
        "cc-stripe" to CcStripe,
        "cc-visa" to CcVisa,
        "centercode" to Centercode,
        "centos" to Centos,
        "certificate" to Certificate,
        "chair" to Chair,
        "chalkboard" to Chalkboard,
        "chalkboard-teacher" to ChalkboardTeacher,
        "charging-station" to ChargingStation,
        "chart-area" to ChartArea,
        "chart-bar" to ChartBar,
        "chart-bar-regular" to ChartBarRegular,
        "chart-line" to ChartLine,
        "chart-pie" to ChartPie,
        "check" to Check,
        "check-circle" to CheckCircle,
        "check-circle-regular" to CheckCircleRegular,
        "check-double" to CheckDouble,
        "check-square" to CheckSquare,
        "check-square-regular" to CheckSquareRegular,
        "cheese" to Cheese,
        "chess" to Chess,
        "chess-bishop" to ChessBishop,
        "chess-board" to ChessBoard,
        "chess-king" to ChessKing,
        "chess-knight" to ChessKnight,
        "chess-pawn" to ChessPawn,
        "chess-queen" to ChessQueen,
        "chess-rook" to ChessRook,
        "chevron-circle-down" to ChevronCircleDown,
        "chevron-circle-left" to ChevronCircleLeft,
        "chevron-circle-right" to ChevronCircleRight,
        "chevron-circle-up" to ChevronCircleUp,
        "chevron-down" to ChevronDown,
        "chevron-left" to ChevronLeft,
        "chevron-right" to ChevronRight,
        "chevron-up" to ChevronUp,
        "child" to Child,
        "chrome" to Chrome,
        "chromecast" to Chromecast,
        "church" to Church,
        "circle" to Circle,
        "circle-regular" to CircleRegular,
        "circle-notch" to CircleNotch,
        "city" to City,
        "clinic-medical" to ClinicMedical,
        "clipboard" to Clipboard,
        "clipboard-regular" to ClipboardRegular,
        "clipboard-check" to ClipboardCheck,
        "clipboard-list" to ClipboardList,
        "clock" to Clock,
        "clock-regular" to ClockRegular,
        "clone" to Clone,
        "clone-regular" to CloneRegular,
        "closed-captioning" to ClosedCaptioning,
        "closed-captioning-regular" to ClosedCaptioningRegular,
        "cloud" to Cloud,
        "cloud-download-alt" to CloudDownloadAlt,
        "cloud-meatball" to CloudMeatball,
        "cloud-moon" to CloudMoon,
        "cloud-moon-rain" to CloudMoonRain,
        "cloud-rain" to CloudRain,
        "cloud-showers-heavy" to CloudShowersHeavy,
        "cloud-sun" to CloudSun,
        "cloud-sun-rain" to CloudSunRain,
        "cloud-upload-alt" to CloudUploadAlt,
        "cloudflare" to Cloudflare,
        "cloudscale" to Cloudscale,
        "cloudsmith" to Cloudsmith,
        "cloudversify" to Cloudversify,
        "cocktail" to Cocktail,
        "code" to Code,
        "code-branch" to CodeBranch,
        "codepen" to Codepen,
        "codiepie" to Codiepie,
        "coffee" to Coffee,
        "cog" to Cog,
        "cogs" to Cogs,
        "coins" to Coins,
        "columns" to Columns,
        "comment" to Comment,
        "comment-regular" to CommentRegular,
        "comment-alt" to CommentAlt,
        "comment-alt-regular" to CommentAltRegular,
        "comment-dollar" to CommentDollar,
        "comment-dots" to CommentDots,
        "comment-dots-regular" to CommentDotsRegular,
        "comment-medical" to CommentMedical,
        "comment-slash" to CommentSlash,
        "comments" to Comments,
        "comments-regular" to CommentsRegular,
        "comments-dollar" to CommentsDollar,
        "compact-disc" to CompactDisc,
        "compass" to Compass,
        "compass-regular" to CompassRegular,
        "compress" to Compress,
        "compress-alt" to CompressAlt,
        "compress-arrows-alt" to CompressArrowsAlt,
        "concierge-bell" to ConciergeBell,
        "confluence" to Confluence,
        "connectdevelop" to Connectdevelop,
        "contao" to Contao,
        "cookie" to Cookie,
        "cookie-bite" to CookieBite,
        "copy" to Copy,
        "copy-regular" to CopyRegular,
        "copyright" to Copyright,
        "copyright-regular" to CopyrightRegular,
        "cotton-bureau" to CottonBureau,
        "couch" to Couch,
        "cpanel" to Cpanel,
        "creative-commons" to CreativeCommons,
        "creative-commons-by" to CreativeCommonsBy,
        "creative-commons-nc" to CreativeCommonsNc,
        "creative-commons-nc-eu" to CreativeCommonsNcEu,
        "creative-commons-nc-jp" to CreativeCommonsNcJp,
        "creative-commons-nd" to CreativeCommonsNd,
        "creative-commons-pd" to CreativeCommonsPd,
        "creative-commons-pd-alt" to CreativeCommonsPdAlt,
        "creative-commons-remix" to CreativeCommonsRemix,
        "creative-commons-sa" to CreativeCommonsSa,
        "creative-commons-sampling" to CreativeCommonsSampling,
        "creative-commons-sampling-plus" to CreativeCommonsSamplingPlus,
        "creative-commons-share" to CreativeCommonsShare,
        "creative-commons-zero" to CreativeCommonsZero,
        "credit-card" to CreditCard,
        "credit-card-regular" to CreditCardRegular,
        "critical-role" to CriticalRole,
        "crop" to Crop,
        "crop-alt" to CropAlt,
        "cross" to Cross,
        "crosshairs" to Crosshairs,
        "crow" to Crow,
        "crown" to Crown,
        "crutch" to Crutch,
        "css3" to Css3,
        "css3alt" to Css3Alt,
        "cube" to Cube,
        "cubes" to Cubes,
        "cut" to Cut,
        "cuttlefish" to Cuttlefish,
        "dandd" to DAndD,
        "dand-dbeyond" to DAndDBeyond,
        "dailymotion" to Dailymotion,
        "dashcube" to Dashcube,
        "database" to Database,
        "deaf" to Deaf,
        "deezer" to Deezer,
        "delicious" to Delicious,
        "democrat" to Democrat,
        "deploydog" to Deploydog,
        "deskpro" to Deskpro,
        "desktop" to Desktop,
        "dev" to Dev,
        "deviantart" to Deviantart,
        "dharmachakra" to Dharmachakra,
        "dhl" to Dhl,
        "diagnoses" to Diagnoses,
        "diaspora" to Diaspora,
        "dice" to Dice,
        "dice-d20" to DiceD20,
        "dice-d6" to DiceD6,
        "dice-five" to DiceFive,
        "dice-four" to DiceFour,
        "dice-one" to DiceOne,
        "dice-six" to DiceSix,
        "dice-three" to DiceThree,
        "dice-two" to DiceTwo,
        "digg" to Digg,
        "digital-ocean" to DigitalOcean,
        "digital-tachograph" to DigitalTachograph,
        "directions" to Directions,
        "discord" to Discord,
        "discourse" to Discourse,
        "disease" to Disease,
        "divide" to Divide,
        "dizzy" to Dizzy,
        "dizzy-regular" to DizzyRegular,
        "dna" to Dna,
        "dochub" to Dochub,
        "docker" to Docker,
        "dog" to Dog,
        "dollar-sign" to DollarSign,
        "dolly" to Dolly,
        "dolly-flatbed" to DollyFlatbed,
        "donate" to Donate,
        "door-closed" to DoorClosed,
        "door-open" to DoorOpen,
        "dot-circle" to DotCircle,
        "dot-circle-regular" to DotCircleRegular,
        "dove" to Dove,
        "download" to Download,
        "draft2digital" to Draft2digital,
        "drafting-compass" to DraftingCompass,
        "dragon" to Dragon,
        "draw-polygon" to DrawPolygon,
        "dribbble" to Dribbble,
        "dribbble-square" to DribbbleSquare,
        "dropbox" to Dropbox,
        "drum" to Drum,
        "drum-steelpan" to DrumSteelpan,
        "drumstick-bite" to DrumstickBite,
        "drupal" to Drupal,
        "dumbbell" to Dumbbell,
        "dumpster" to Dumpster,
        "dumpster-fire" to DumpsterFire,
        "dungeon" to Dungeon,
        "dyalog" to Dyalog,
        "earlybirds" to Earlybirds,
        "ebay" to Ebay,
        "edge" to Edge,
        "edge-legacy" to EdgeLegacy,
        "edit" to Edit,
        "edit-regular" to EditRegular,
        "egg" to Egg,
        "eject" to Eject,
        "elementor" to Elementor,
        "ellipsish" to EllipsisH,
        "ellipsisv" to EllipsisV,
        "ello" to Ello,
        "ember" to Ember,
        "empire" to Empire,
        "envelope" to Envelope,
        "envelope-regular" to EnvelopeRegular,
        "envelope-open" to EnvelopeOpen,
        "envelope-open-regular" to EnvelopeOpenRegular,
        "envelope-open-text" to EnvelopeOpenText,
        "envelope-square" to EnvelopeSquare,
        "envira" to Envira,
        "equals" to Equals,
        "eraser" to Eraser,
        "erlang" to Erlang,
        "ethereum" to Ethereum,
        "ethernet" to Ethernet,
        "etsy" to Etsy,
        "euro-sign" to EuroSign,
        "evernote" to Evernote,
        "exchange-alt" to ExchangeAlt,
        "exclamation" to Exclamation,
        "exclamation-circle" to ExclamationCircle,
        "exclamation-triangle" to ExclamationTriangle,
        "expand" to Expand,
        "expand-alt" to ExpandAlt,
        "expand-arrows-alt" to ExpandArrowsAlt,
        "expeditedssl" to Expeditedssl,
        "external-link-alt" to ExternalLinkAlt,
        "external-link-square-alt" to ExternalLinkSquareAlt,
        "eye" to Eye,
        "eye-regular" to EyeRegular,
        "eye-dropper" to EyeDropper,
        "eye-slash" to EyeSlash,
        "eye-slash-regular" to EyeSlashRegular,
        "facebook" to Facebook,
        "facebookf" to FacebookF,
        "facebook-messenger" to FacebookMessenger,
        "facebook-square" to FacebookSquare,
        "fan" to Fan,
        "fantasy-flight-games" to FantasyFlightGames,
        "fast-backward" to FastBackward,
        "fast-forward" to FastForward,
        "faucet" to Faucet,
        "fax" to Fax,
        "feather" to Feather,
        "feather-alt" to FeatherAlt,
        "fedex" to Fedex,
        "fedora" to Fedora,
        "female" to Female,
        "fighter-jet" to FighterJet,
        "figma" to Figma,
        "file" to File,
        "file-regular" to FileRegular,
        "file-alt" to FileAlt,
        "file-alt-regular" to FileAltRegular,
        "file-archive" to FileArchive,
        "file-archive-regular" to FileArchiveRegular,
        "file-audio" to FileAudio,
        "file-audio-regular" to FileAudioRegular,
        "file-code" to FileCode,
        "file-code-regular" to FileCodeRegular,
        "file-contract" to FileContract,
        "file-csv" to FileCsv,
        "file-download" to FileDownload,
        "file-excel" to FileExcel,
        "file-excel-regular" to FileExcelRegular,
        "file-export" to FileExport,
        "file-image" to FileImage,
        "file-image-regular" to FileImageRegular,
        "file-import" to FileImport,
        "file-invoice" to FileInvoice,
        "file-invoice-dollar" to FileInvoiceDollar,
        "file-medical" to FileMedical,
        "file-medical-alt" to FileMedicalAlt,
        "file-pdf" to FilePdf,
        "file-pdf-regular" to FilePdfRegular,
        "file-powerpoint" to FilePowerpoint,
        "file-powerpoint-regular" to FilePowerpointRegular,
        "file-prescription" to FilePrescription,
        "file-signature" to FileSignature,
        "file-upload" to FileUpload,
        "file-video" to FileVideo,
        "file-video-regular" to FileVideoRegular,
        "file-word" to FileWord,
        "file-word-regular" to FileWordRegular,
        "fill" to Fill,
        "fill-drip" to FillDrip,
        "film" to Film,
        "filter" to Filter,
        "fingerprint" to Fingerprint,
        "fire" to Fire,
        "fire-alt" to FireAlt,
        "fire-extinguisher" to FireExtinguisher,
        "firefox" to Firefox,
        "firefox-browser" to FirefoxBrowser,
        "first-aid" to FirstAid,
        "first-order" to FirstOrder,
        "first-order-alt" to FirstOrderAlt,
        "firstdraft" to Firstdraft,
        "fish" to Fish,
        "fist-raised" to FistRaised,
        "five-hundred-px" to FiveHundredPX,
        "flag" to Flag,
        "flag-regular" to FlagRegular,
        "flag-checkered" to FlagCheckered,
        "flag-usa" to FlagUsa,
        "flask" to Flask,
        "flickr" to Flickr,
        "flipboard" to Flipboard,
        "flushed" to Flushed,
        "flushed-regular" to FlushedRegular,
        "fly" to Fly,
        "folder" to Folder,
        "folder-regular" to FolderRegular,
        "folder-minus" to FolderMinus,
        "folder-open" to FolderOpen,
        "folder-open-regular" to FolderOpenRegular,
        "folder-plus" to FolderPlus,
        "font" to Font,
        "font-awesome" to FontAwesome,
        "font-awesome-alt" to FontAwesomeAlt,
        "font-awesome-flag" to FontAwesomeFlag,
        "fonticons" to Fonticons,
        "fonticons-fi" to FonticonsFi,
        "football-ball" to FootballBall,
        "fort-awesome" to FortAwesome,
        "fort-awesome-alt" to FortAwesomeAlt,
        "forumbee" to Forumbee,
        "forward" to Forward,
        "foursquare" to Foursquare,
        "free-code-camp" to FreeCodeCamp,
        "freebsd" to Freebsd,
        "frog" to Frog,
        "frown" to Frown,
        "frown-regular" to FrownRegular,
        "frown-open" to FrownOpen,
        "frown-open-regular" to FrownOpenRegular,
        "fulcrum" to Fulcrum,
        "funnel-dollar" to FunnelDollar,
        "futbol" to Futbol,
        "futbol-regular" to FutbolRegular,
        "galactic-republic" to GalacticRepublic,
        "galactic-senate" to GalacticSenate,
        "gamepad" to Gamepad,
        "gas-pump" to GasPump,
        "gavel" to Gavel,
        "gem" to Gem,
        "gem-regular" to GemRegular,
        "genderless" to Genderless,
        "get-pocket" to GetPocket,
        "gg" to Gg,
        "gg-circle" to GgCircle,
        "ghost" to Ghost,
        "gift" to Gift,
        "gifts" to Gifts,
        "git" to Git,
        "git-alt" to GitAlt,
        "git-square" to GitSquare,
        "github" to Github,
        "github-alt" to GithubAlt,
        "github-square" to GithubSquare,
        "gitkraken" to Gitkraken,
        "gitlab" to Gitlab,
        "gitter" to Gitter,
        "glass-cheers" to GlassCheers,
        "glass-martini" to GlassMartini,
        "glass-martini-alt" to GlassMartiniAlt,
        "glass-whiskey" to GlassWhiskey,
        "glasses" to Glasses,
        "glide" to Glide,
        "glideg" to GlideG,
        "globe" to Globe,
        "globe-africa" to GlobeAfrica,
        "globe-americas" to GlobeAmericas,
        "globe-asia" to GlobeAsia,
        "globe-europe" to GlobeEurope,
        "gofore" to Gofore,
        "golf-ball" to GolfBall,
        "goodreads" to Goodreads,
        "goodreadsg" to GoodreadsG,
        "google" to Google,
        "google-drive" to GoogleDrive,
        "google-pay" to GooglePay,
        "google-play" to GooglePlay,
        "google-plus" to GooglePlus,
        "google-plusg" to GooglePlusG,
        "google-plus-square" to GooglePlusSquare,
        "google-wallet" to GoogleWallet,
        "gopuram" to Gopuram,
        "graduation-cap" to GraduationCap,
        "gratipay" to Gratipay,
        "grav" to Grav,
        "greater-than" to GreaterThan,
        "greater-than-equal" to GreaterThanEqual,
        "grimace" to Grimace,
        "grimace-regular" to GrimaceRegular,
        "grin" to Grin,
        "grin-regular" to GrinRegular,
        "grin-alt" to GrinAlt,
        "grin-alt-regular" to GrinAltRegular,
        "grin-beam" to GrinBeam,
        "grin-beam-regular" to GrinBeamRegular,
        "grin-beam-sweat" to GrinBeamSweat,
        "grin-beam-sweat-regular" to GrinBeamSweatRegular,
        "grin-hearts" to GrinHearts,
        "grin-hearts-regular" to GrinHeartsRegular,
        "grin-squint" to GrinSquint,
        "grin-squint-regular" to GrinSquintRegular,
        "grin-squint-tears" to GrinSquintTears,
        "grin-squint-tears-regular" to GrinSquintTearsRegular,
        "grin-stars" to GrinStars,
        "grin-stars-regular" to GrinStarsRegular,
        "grin-tears" to GrinTears,
        "grin-tears-regular" to GrinTearsRegular,
        "grin-tongue" to GrinTongue,
        "grin-tongue-regular" to GrinTongueRegular,
        "grin-tongue-squint" to GrinTongueSquint,
        "grin-tongue-squint-regular" to GrinTongueSquintRegular,
        "grin-tongue-wink" to GrinTongueWink,
        "grin-tongue-wink-regular" to GrinTongueWinkRegular,
        "grin-wink" to GrinWink,
        "grin-wink-regular" to GrinWinkRegular,
        "grip-horizontal" to GripHorizontal,
        "grip-lines" to GripLines,
        "grip-lines-vertical" to GripLinesVertical,
        "grip-vertical" to GripVertical,
        "gripfire" to Gripfire,
        "grunt" to Grunt,
        "guilded" to Guilded,
        "guitar" to Guitar,
        "gulp" to Gulp,
        "hsquare" to HSquare,
        "hacker-news" to HackerNews,
        "hacker-news-square" to HackerNewsSquare,
        "hackerrank" to Hackerrank,
        "hamburger" to Hamburger,
        "hammer" to Hammer,
        "hamsa" to Hamsa,
        "hand-holding" to HandHolding,
        "hand-holding-heart" to HandHoldingHeart,
        "hand-holding-medical" to HandHoldingMedical,
        "hand-holding-usd" to HandHoldingUsd,
        "hand-holding-water" to HandHoldingWater,
        "hand-lizard" to HandLizard,
        "hand-lizard-regular" to HandLizardRegular,
        "hand-middle-finger" to HandMiddleFinger,
        "hand-paper" to HandPaper,
        "hand-paper-regular" to HandPaperRegular,
        "hand-peace" to HandPeace,
        "hand-peace-regular" to HandPeaceRegular,
        "hand-point-down" to HandPointDown,
        "hand-point-down-regular" to HandPointDownRegular,
        "hand-point-left" to HandPointLeft,
        "hand-point-left-regular" to HandPointLeftRegular,
        "hand-point-right" to HandPointRight,
        "hand-point-right-regular" to HandPointRightRegular,
        "hand-point-up" to HandPointUp,
        "hand-point-up-regular" to HandPointUpRegular,
        "hand-pointer" to HandPointer,
        "hand-pointer-regular" to HandPointerRegular,
        "hand-rock" to HandRock,
        "hand-rock-regular" to HandRockRegular,
        "hand-scissors" to HandScissors,
        "hand-scissors-regular" to HandScissorsRegular,
        "hand-sparkles" to HandSparkles,
        "hand-spock" to HandSpock,
        "hand-spock-regular" to HandSpockRegular,
        "hands" to Hands,
        "hands-helping" to HandsHelping,
        "hands-wash" to HandsWash,
        "handshake" to Handshake,
        "handshake-regular" to HandshakeRegular,
        "handshake-alt-slash" to HandshakeAltSlash,
        "handshake-slash" to HandshakeSlash,
        "hanukiah" to Hanukiah,
        "hard-hat" to HardHat,
        "hashtag" to Hashtag,
        "hat-cowboy" to HatCowboy,
        "hat-cowboy-side" to HatCowboySide,
        "hat-wizard" to HatWizard,
        "hdd" to Hdd,
        "hdd-regular" to HddRegular,
        "head-side-cough" to HeadSideCough,
        "head-side-cough-slash" to HeadSideCoughSlash,
        "head-side-mask" to HeadSideMask,
        "head-side-virus" to HeadSideVirus,
        "heading" to Heading,
        "headphones" to Headphones,
        "headphones-alt" to HeadphonesAlt,
        "headset" to Headset,
        "heart" to Heart,
        "heart-regular" to HeartRegular,
        "heart-broken" to HeartBroken,
        "heartbeat" to Heartbeat,
        "helicopter" to Helicopter,
        "highlighter" to Highlighter,
        "hiking" to Hiking,
        "hippo" to Hippo,
        "hips" to Hips,
        "hire-ahelper" to HireAHelper,
        "history" to History,
        "hive" to Hive,
        "hockey-puck" to HockeyPuck,
        "holly-berry" to HollyBerry,
        "home" to Home,
        "hooli" to Hooli,
        "hornbill" to Hornbill,
        "horse" to Horse,
        "horse-head" to HorseHead,
        "hospital" to Hospital,
        "hospital-regular" to HospitalRegular,
        "hospital-alt" to HospitalAlt,
        "hospital-symbol" to HospitalSymbol,
        "hospital-user" to HospitalUser,
        "hot-tub" to HotTub,
        "hotdog" to Hotdog,
        "hotel" to Hotel,
        "hotjar" to Hotjar,
        "hourglass" to Hourglass,
        "hourglass-regular" to HourglassRegular,
        "hourglass-end" to HourglassEnd,
        "hourglass-half" to HourglassHalf,
        "hourglass-start" to HourglassStart,
        "house-damage" to HouseDamage,
        "house-user" to HouseUser,
        "houzz" to Houzz,
        "hryvnia" to Hryvnia,
        "html5" to Html5,
        "hubspot" to Hubspot,
        "icursor" to ICursor,
        "ice-cream" to IceCream,
        "icicles" to Icicles,
        "icons" to Icons,
        "id-badge" to IdBadge,
        "id-badge-regular" to IdBadgeRegular,
        "id-card" to IdCard,
        "id-card-regular" to IdCardRegular,
        "id-card-alt" to IdCardAlt,
        "ideal" to Ideal,
        "igloo" to Igloo,
        "image" to Image,
        "image-regular" to ImageRegular,
        "images" to Images,
        "images-regular" to ImagesRegular,
        "imdb" to Imdb,
        "inbox" to Inbox,
        "indent" to Indent,
        "industry" to Industry,
        "infinity" to Infinity,
        "info" to Info,
        "info-circle" to InfoCircle,
        "innosoft" to Innosoft,
        "instagram" to Instagram,
        "instagram-square" to InstagramSquare,
        "instalod" to Instalod,
        "intercom" to Intercom,
        "internet-explorer" to InternetExplorer,
        "invision" to Invision,
        "ioxhost" to Ioxhost,
        "italic" to Italic,
        "itch-io" to ItchIo,
        "itunes" to Itunes,
        "itunes-note" to ItunesNote,
        "java" to Java,
        "jedi" to Jedi,
        "jedi-order" to JediOrder,
        "jenkins" to Jenkins,
        "jira" to Jira,
        "joget" to Joget,
        "joint" to Joint,
        "joomla" to Joomla,
        "journal-whills" to JournalWhills,
        "js" to Js,
        "js-square" to JsSquare,
        "jsfiddle" to Jsfiddle,
        "kaaba" to Kaaba,
        "kaggle" to Kaggle,
        "key" to Key,
        "keybase" to Keybase,
        "keyboard" to Keyboard,
        "keyboard-regular" to KeyboardRegular,
        "keycdn" to Keycdn,
        "khanda" to Khanda,
        "kickstarter" to Kickstarter,
        "kickstarterk" to KickstarterK,
        "kiss" to Kiss,
        "kiss-regular" to KissRegular,
        "kiss-beam" to KissBeam,
        "kiss-beam-regular" to KissBeamRegular,
        "kiss-wink-heart" to KissWinkHeart,
        "kiss-wink-heart-regular" to KissWinkHeartRegular,
        "kiwi-bird" to KiwiBird,
        "korvue" to Korvue,
        "landmark" to Landmark,
        "language" to Language,
        "laptop" to Laptop,
        "laptop-code" to LaptopCode,
        "laptop-house" to LaptopHouse,
        "laptop-medical" to LaptopMedical,
        "laravel" to Laravel,
        "lastfm" to Lastfm,
        "lastfm-square" to LastfmSquare,
        "laugh" to Laugh,
        "laugh-regular" to LaughRegular,
        "laugh-beam" to LaughBeam,
        "laugh-beam-regular" to LaughBeamRegular,
        "laugh-squint" to LaughSquint,
        "laugh-squint-regular" to LaughSquintRegular,
        "laugh-wink" to LaughWink,
        "laugh-wink-regular" to LaughWinkRegular,
        "layer-group" to LayerGroup,
        "leaf" to Leaf,
        "leanpub" to Leanpub,
        "lemon" to Lemon,
        "lemon-regular" to LemonRegular,
        "less" to Less,
        "less-than" to LessThan,
        "less-than-equal" to LessThanEqual,
        "level-down-alt" to LevelDownAlt,
        "level-up-alt" to LevelUpAlt,
        "life-ring" to LifeRing,
        "life-ring-regular" to LifeRingRegular,
        "lightbulb" to Lightbulb,
        "lightbulb-regular" to LightbulbRegular,
        "line" to Line,
        "link" to Link,
        "linkedin" to Linkedin,
        "linkedin-in" to LinkedinIn,
        "linode" to Linode,
        "linux" to Linux,
        "lira-sign" to LiraSign,
        "list" to List,
        "list-alt" to ListAlt,
        "list-alt-regular" to ListAltRegular,
        "list-ol" to ListOl,
        "list-ul" to ListUl,
        "location-arrow" to LocationArrow,
        "lock" to Lock,
        "lock-open" to LockOpen,
        "long-arrow-alt-down" to LongArrowAltDown,
        "long-arrow-alt-left" to LongArrowAltLeft,
        "long-arrow-alt-right" to LongArrowAltRight,
        "long-arrow-alt-up" to LongArrowAltUp,
        "low-vision" to LowVision,
        "luggage-cart" to LuggageCart,
        "lungs" to Lungs,
        "lungs-virus" to LungsVirus,
        "lyft" to Lyft,
        "magento" to Magento,
        "magic" to Magic,
        "magnet" to Magnet,
        "mail-bulk" to MailBulk,
        "mailchimp" to Mailchimp,
        "male" to Male,
        "mandalorian" to Mandalorian,
        "map" to Map,
        "map-regular" to MapRegular,
        "map-marked" to MapMarked,
        "map-marked-alt" to MapMarkedAlt,
        "map-marker" to MapMarker,
        "map-marker-alt" to MapMarkerAlt,
        "map-pin" to MapPin,
        "map-signs" to MapSigns,
        "markdown" to Markdown,
        "marker" to Marker,
        "mars" to Mars,
        "mars-double" to MarsDouble,
        "mars-stroke" to MarsStroke,
        "mars-strokeh" to MarsStrokeH,
        "mars-strokev" to MarsStrokeV,
        "mask" to Mask,
        "mastodon" to Mastodon,
        "maxcdn" to Maxcdn,
        "mdb" to Mdb,
        "medal" to Medal,
        "medapps" to Medapps,
        "medium" to Medium,
        "mediumm" to MediumM,
        "medkit" to Medkit,
        "medrt" to Medrt,
        "meetup" to Meetup,
        "megaport" to Megaport,
        "meh" to Meh,
        "meh-regular" to MehRegular,
        "meh-blank" to MehBlank,
        "meh-blank-regular" to MehBlankRegular,
        "meh-rolling-eyes" to MehRollingEyes,
        "meh-rolling-eyes-regular" to MehRollingEyesRegular,
        "memory" to Memory,
        "mendeley" to Mendeley,
        "menorah" to Menorah,
        "mercury" to Mercury,
        "meteor" to Meteor,
        "microblog" to Microblog,
        "microchip" to Microchip,
        "microphone" to Microphone,
        "microphone-alt" to MicrophoneAlt,
        "microphone-alt-slash" to MicrophoneAltSlash,
        "microphone-slash" to MicrophoneSlash,
        "microscope" to Microscope,
        "microsoft" to Microsoft,
        "minus" to Minus,
        "minus-circle" to MinusCircle,
        "minus-square" to MinusSquare,
        "minus-square-regular" to MinusSquareRegular,
        "mitten" to Mitten,
        "mix" to Mix,
        "mixcloud" to Mixcloud,
        "mixer" to Mixer,
        "mizuni" to Mizuni,
        "mobile" to Mobile,
        "mobile-alt" to MobileAlt,
        "modx" to Modx,
        "monero" to Monero,
        "money-bill" to MoneyBill,
        "money-bill-alt" to MoneyBillAlt,
        "money-bill-alt-regular" to MoneyBillAltRegular,
        "money-bill-wave" to MoneyBillWave,
        "money-bill-wave-alt" to MoneyBillWaveAlt,
        "money-check" to MoneyCheck,
        "money-check-alt" to MoneyCheckAlt,
        "monument" to Monument,
        "moon" to Moon,
        "moon-regular" to MoonRegular,
        "mortar-pestle" to MortarPestle,
        "mosque" to Mosque,
        "motorcycle" to Motorcycle,
        "mountain" to Mountain,
        "mouse" to Mouse,
        "mouse-pointer" to MousePointer,
        "mug-hot" to MugHot,
        "music" to Music,
        "napster" to Napster,
        "neos" to Neos,
        "network-wired" to NetworkWired,
        "neuter" to Neuter,
        "newspaper" to Newspaper,
        "newspaper-regular" to NewspaperRegular,
        "nimblr" to Nimblr,
        "node" to Node,
        "node-js" to NodeJs,
        "not-equal" to NotEqual,
        "notes-medical" to NotesMedical,
        "npm" to Npm,
        "ns8" to Ns8,
        "nutritionix" to Nutritionix,
        "object-group" to ObjectGroup,
        "object-group-regular" to ObjectGroupRegular,
        "object-ungroup" to ObjectUngroup,
        "object-ungroup-regular" to ObjectUngroupRegular,
        "octopus-deploy" to OctopusDeploy,
        "odnoklassniki" to Odnoklassniki,
        "odnoklassniki-square" to OdnoklassnikiSquare,
        "oil-can" to OilCan,
        "old-republic" to OldRepublic,
        "om" to Om,
        "opencart" to Opencart,
        "openid" to Openid,
        "opera" to Opera,
        "optin-monster" to OptinMonster,
        "orcid" to Orcid,
        "osi" to Osi,
        "otter" to Otter,
        "outdent" to Outdent,
        "page4" to Page4,
        "pagelines" to Pagelines,
        "pager" to Pager,
        "paint-brush" to PaintBrush,
        "paint-roller" to PaintRoller,
        "palette" to Palette,
        "palfed" to Palfed,
        "pallet" to Pallet,
        "paper-plane" to PaperPlane,
        "paper-plane-regular" to PaperPlaneRegular,
        "paperclip" to Paperclip,
        "parachute-box" to ParachuteBox,
        "paragraph" to Paragraph,
        "parking" to Parking,
        "passport" to Passport,
        "pastafarianism" to Pastafarianism,
        "paste" to Paste,
        "patreon" to Patreon,
        "pause" to Pause,
        "pause-circle" to PauseCircle,
        "pause-circle-regular" to PauseCircleRegular,
        "paw" to Paw,
        "paypal" to Paypal,
        "peace" to Peace,
        "pen" to Pen,
        "pen-alt" to PenAlt,
        "pen-fancy" to PenFancy,
        "pen-nib" to PenNib,
        "pen-square" to PenSquare,
        "pencil-alt" to PencilAlt,
        "pencil-ruler" to PencilRuler,
        "penny-arcade" to PennyArcade,
        "people-arrows" to PeopleArrows,
        "people-carry" to PeopleCarry,
        "pepper-hot" to PepperHot,
        "perbyte" to Perbyte,
        "percent" to Percent,
        "percentage" to Percentage,
        "periscope" to Periscope,
        "person-booth" to PersonBooth,
        "phabricator" to Phabricator,
        "phoenix-framework" to PhoenixFramework,
        "phoenix-squadron" to PhoenixSquadron,
        "phone" to Phone,
        "phone-alt" to PhoneAlt,
        "phone-slash" to PhoneSlash,
        "phone-square" to PhoneSquare,
        "phone-square-alt" to PhoneSquareAlt,
        "phone-volume" to PhoneVolume,
        "photo-video" to PhotoVideo,
        "php" to Php,
        "pied-piper" to PiedPiper,
        "pied-piper-alt" to PiedPiperAlt,
        "pied-piper-hat" to PiedPiperHat,
        "pied-piper-pp" to PiedPiperPp,
        "pied-piper-square" to PiedPiperSquare,
        "piggy-bank" to PiggyBank,
        "pills" to Pills,
        "pinterest" to Pinterest,
        "pinterestp" to PinterestP,
        "pinterest-square" to PinterestSquare,
        "pizza-slice" to PizzaSlice,
        "place-of-worship" to PlaceOfWorship,
        "plane" to Plane,
        "plane-arrival" to PlaneArrival,
        "plane-departure" to PlaneDeparture,
        "plane-slash" to PlaneSlash,
        "play" to Play,
        "play-circle" to PlayCircle,
        "play-circle-regular" to PlayCircleRegular,
        "playstation" to Playstation,
        "plug" to Plug,
        "plus" to Plus,
        "plus-circle" to PlusCircle,
        "plus-square" to PlusSquare,
        "plus-square-regular" to PlusSquareRegular,
        "podcast" to Podcast,
        "poll" to Poll,
        "pollh" to PollH,
        "poo" to Poo,
        "poo-storm" to PooStorm,
        "poop" to Poop,
        "portrait" to Portrait,
        "pound-sign" to PoundSign,
        "power-off" to PowerOff,
        "pray" to Pray,
        "praying-hands" to PrayingHands,
        "prescription" to Prescription,
        "prescription-bottle" to PrescriptionBottle,
        "prescription-bottle-alt" to PrescriptionBottleAlt,
        "print" to Print,
        "procedures" to Procedures,
        "product-hunt" to ProductHunt,
        "project-diagram" to ProjectDiagram,
        "pump-medical" to PumpMedical,
        "pump-soap" to PumpSoap,
        "pushed" to Pushed,
        "puzzle-piece" to PuzzlePiece,
        "python" to Python,
        "qq" to Qq,
        "qrcode" to Qrcode,
        "question" to Question,
        "question-circle" to QuestionCircle,
        "question-circle-regular" to QuestionCircleRegular,
        "quidditch" to Quidditch,
        "quinscape" to Quinscape,
        "quora" to Quora,
        "quote-left" to QuoteLeft,
        "quote-right" to QuoteRight,
        "quran" to Quran,
        "rproject" to RProject,
        "radiation" to Radiation,
        "radiation-alt" to RadiationAlt,
        "rainbow" to Rainbow,
        "random" to Random,
        "raspberry-pi" to RaspberryPi,
        "ravelry" to Ravelry,
        "react" to React,
        "reacteurope" to Reacteurope,
        "readme" to Readme,
        "rebel" to Rebel,
        "receipt" to Receipt,
        "record-vinyl" to RecordVinyl,
        "recycle" to Recycle,
        "red-river" to RedRiver,
        "reddit" to Reddit,
        "reddit-alien" to RedditAlien,
        "reddit-square" to RedditSquare,
        "redhat" to Redhat,
        "redo" to Redo,
        "redo-alt" to RedoAlt,
        "registered" to Registered,
        "registered-regular" to RegisteredRegular,
        "remove-format" to RemoveFormat,
        "renren" to Renren,
        "reply" to Reply,
        "reply-all" to ReplyAll,
        "replyd" to Replyd,
        "republican" to Republican,
        "researchgate" to Researchgate,
        "resolving" to Resolving,
        "restroom" to Restroom,
        "retweet" to Retweet,
        "rev" to Rev,
        "ribbon" to Ribbon,
        "ring" to Ring,
        "road" to Road,
        "robot" to Robot,
        "rocket" to Rocket,
        "rocketchat" to Rocketchat,
        "rockrms" to Rockrms,
        "route" to Route,
        "rss" to Rss,
        "rss-square" to RssSquare,
        "ruble-sign" to RubleSign,
        "ruler" to Ruler,
        "ruler-combined" to RulerCombined,
        "ruler-horizontal" to RulerHorizontal,
        "ruler-vertical" to RulerVertical,
        "running" to Running,
        "rupee-sign" to RupeeSign,
        "rust" to Rust,
        "sad-cry" to SadCry,
        "sad-cry-regular" to SadCryRegular,
        "sad-tear" to SadTear,
        "sad-tear-regular" to SadTearRegular,
        "safari" to Safari,
        "salesforce" to Salesforce,
        "sass" to Sass,
        "satellite" to Satellite,
        "satellite-dish" to SatelliteDish,
        "save" to Save,
        "save-regular" to SaveRegular,
        "schlix" to Schlix,
        "school" to School,
        "screwdriver" to Screwdriver,
        "scribd" to Scribd,
        "scroll" to Scroll,
        "sd-card" to SdCard,
        "search" to Search,
        "search-dollar" to SearchDollar,
        "search-location" to SearchLocation,
        "search-minus" to SearchMinus,
        "search-plus" to SearchPlus,
        "searchengin" to Searchengin,
        "seedling" to Seedling,
        "sellcast" to Sellcast,
        "sellsy" to Sellsy,
        "server" to Server,
        "servicestack" to Servicestack,
        "shapes" to Shapes,
        "share" to Share,
        "share-alt" to ShareAlt,
        "share-alt-square" to ShareAltSquare,
        "share-square" to ShareSquare,
        "share-square-regular" to ShareSquareRegular,
        "shekel-sign" to ShekelSign,
        "shield-alt" to ShieldAlt,
        "shield-virus" to ShieldVirus,
        "ship" to Ship,
        "shipping-fast" to ShippingFast,
        "shirtsinbulk" to Shirtsinbulk,
        "shoe-prints" to ShoePrints,
        "shopify" to Shopify,
        "shopping-bag" to ShoppingBag,
        "shopping-basket" to ShoppingBasket,
        "shopping-cart" to ShoppingCart,
        "shopware" to Shopware,
        "shower" to Shower,
        "shuttle-van" to ShuttleVan,
        "sign" to Sign,
        "sign-in-alt" to SignInAlt,
        "sign-language" to SignLanguage,
        "sign-out-alt" to SignOutAlt,
        "signal" to Signal,
        "signature" to Signature,
        "sim-card" to SimCard,
        "simplybuilt" to Simplybuilt,
        "sink" to Sink,
        "sistrix" to Sistrix,
        "sitemap" to Sitemap,
        "sith" to Sith,
        "skating" to Skating,
        "sketch" to Sketch,
        "skiing" to Skiing,
        "skiing-nordic" to SkiingNordic,
        "skull" to Skull,
        "skull-crossbones" to SkullCrossbones,
        "skyatlas" to Skyatlas,
        "skype" to Skype,
        "slack" to Slack,
        "slack-hash" to SlackHash,
        "slash" to Slash,
        "sleigh" to Sleigh,
        "slidersh" to SlidersH,
        "slideshare" to Slideshare,
        "smile" to Smile,
        "smile-regular" to SmileRegular,
        "smile-beam" to SmileBeam,
        "smile-beam-regular" to SmileBeamRegular,
        "smile-wink" to SmileWink,
        "smile-wink-regular" to SmileWinkRegular,
        "smog" to Smog,
        "smoking" to Smoking,
        "smoking-ban" to SmokingBan,
        "sms" to Sms,
        "snapchat" to Snapchat,
        "snapchat-ghost" to SnapchatGhost,
        "snapchat-square" to SnapchatSquare,
        "snowboarding" to Snowboarding,
        "snowflake" to Snowflake,
        "snowflake-regular" to SnowflakeRegular,
        "snowman" to Snowman,
        "snowplow" to Snowplow,
        "soap" to Soap,
        "socks" to Socks,
        "solar-panel" to SolarPanel,
        "sort" to Sort,
        "sort-alpha-down" to SortAlphaDown,
        "sort-alpha-down-alt" to SortAlphaDownAlt,
        "sort-alpha-up" to SortAlphaUp,
        "sort-alpha-up-alt" to SortAlphaUpAlt,
        "sort-amount-down" to SortAmountDown,
        "sort-amount-down-alt" to SortAmountDownAlt,
        "sort-amount-up" to SortAmountUp,
        "sort-amount-up-alt" to SortAmountUpAlt,
        "sort-down" to SortDown,
        "sort-numeric-down" to SortNumericDown,
        "sort-numeric-down-alt" to SortNumericDownAlt,
        "sort-numeric-up" to SortNumericUp,
        "sort-numeric-up-alt" to SortNumericUpAlt,
        "sort-up" to SortUp,
        "soundcloud" to Soundcloud,
        "sourcetree" to Sourcetree,
        "spa" to Spa,
        "space-shuttle" to SpaceShuttle,
        "speakap" to Speakap,
        "speaker-deck" to SpeakerDeck,
        "spell-check" to SpellCheck,
        "spider" to Spider,
        "spinner" to Spinner,
        "splotch" to Splotch,
        "spotify" to Spotify,
        "spray-can" to SprayCan,
        "square" to Square,
        "square-regular" to SquareRegular,
        "square-full" to SquareFull,
        "square-root-alt" to SquareRootAlt,
        "squarespace" to Squarespace,
        "stack-exchange" to StackExchange,
        "stack-overflow" to StackOverflow,
        "stackpath" to Stackpath,
        "stamp" to Stamp,
        "star" to Star,
        "star-regular" to StarRegular,
        "star-and-crescent" to StarAndCrescent,
        "star-half" to StarHalf,
        "star-half-regular" to StarHalfRegular,
        "star-half-alt" to StarHalfAlt,
        "star-of-david" to StarOfDavid,
        "star-of-life" to StarOfLife,
        "staylinked" to Staylinked,
        "steam" to Steam,
        "steam-square" to SteamSquare,
        "steam-symbol" to SteamSymbol,
        "step-backward" to StepBackward,
        "step-forward" to StepForward,
        "stethoscope" to Stethoscope,
        "sticker-mule" to StickerMule,
        "sticky-note" to StickyNote,
        "sticky-note-regular" to StickyNoteRegular,
        "stop" to Stop,
        "stop-circle" to StopCircle,
        "stop-circle-regular" to StopCircleRegular,
        "stopwatch" to Stopwatch,
        "stopwatch20" to Stopwatch20,
        "store" to Store,
        "store-alt" to StoreAlt,
        "store-alt-slash" to StoreAltSlash,
        "store-slash" to StoreSlash,
        "strava" to Strava,
        "stream" to Stream,
        "street-view" to StreetView,
        "strikethrough" to Strikethrough,
        "stripe" to Stripe,
        "stripes" to StripeS,
        "stroopwafel" to Stroopwafel,
        "studiovinari" to Studiovinari,
        "stumbleupon" to Stumbleupon,
        "stumbleupon-circle" to StumbleuponCircle,
        "subscript" to Subscript,
        "subway" to Subway,
        "suitcase" to Suitcase,
        "suitcase-rolling" to SuitcaseRolling,
        "sun" to Sun,
        "sun-regular" to SunRegular,
        "superpowers" to Superpowers,
        "superscript" to Superscript,
        "supple" to Supple,
        "surprise" to Surprise,
        "surprise-regular" to SurpriseRegular,
        "suse" to Suse,
        "swatchbook" to Swatchbook,
        "swift" to Swift,
        "swimmer" to Swimmer,
        "swimming-pool" to SwimmingPool,
        "symfony" to Symfony,
        "synagogue" to Synagogue,
        "sync" to Sync,
        "sync-alt" to SyncAlt,
        "syringe" to Syringe,
        "table" to Table,
        "table-tennis" to TableTennis,
        "tablet" to Tablet,
        "tablet-alt" to TabletAlt,
        "tablets" to Tablets,
        "tachometer-alt" to TachometerAlt,
        "tag" to Tag,
        "tags" to Tags,
        "tape" to Tape,
        "tasks" to Tasks,
        "taxi" to Taxi,
        "teamspeak" to Teamspeak,
        "teeth" to Teeth,
        "teeth-open" to TeethOpen,
        "telegram" to Telegram,
        "telegram-plane" to TelegramPlane,
        "temperature-high" to TemperatureHigh,
        "temperature-low" to TemperatureLow,
        "tencent-weibo" to TencentWeibo,
        "tenge" to Tenge,
        "terminal" to Terminal,
        "text-height" to TextHeight,
        "text-width" to TextWidth,
        "th" to Th,
        "th-large" to ThLarge,
        "th-list" to ThList,
        "the-red-yeti" to TheRedYeti,
        "theater-masks" to TheaterMasks,
        "themeco" to Themeco,
        "themeisle" to Themeisle,
        "thermometer" to Thermometer,
        "thermometer-empty" to ThermometerEmpty,
        "thermometer-full" to ThermometerFull,
        "thermometer-half" to ThermometerHalf,
        "thermometer-quarter" to ThermometerQuarter,
        "thermometer-three-quarters" to ThermometerThreeQuarters,
        "think-peaks" to ThinkPeaks,
        "thumbs-down" to ThumbsDown,
        "thumbs-down-regular" to ThumbsDownRegular,
        "thumbs-up" to ThumbsUp,
        "thumbs-up-regular" to ThumbsUpRegular,
        "thumbtack" to Thumbtack,
        "ticket-alt" to TicketAlt,
        "tiktok" to Tiktok,
        "times" to Times,
        "times-circle" to TimesCircle,
        "times-circle-regular" to TimesCircleRegular,
        "tint" to Tint,
        "tint-slash" to TintSlash,
        "tired" to Tired,
        "tired-regular" to TiredRegular,
        "toggle-off" to ToggleOff,
        "toggle-on" to ToggleOn,
        "toilet" to Toilet,
        "toilet-paper" to ToiletPaper,
        "toilet-paper-slash" to ToiletPaperSlash,
        "toolbox" to Toolbox,
        "tools" to Tools,
        "tooth" to Tooth,
        "torah" to Torah,
        "torii-gate" to ToriiGate,
        "tractor" to Tractor,
        "trade-federation" to TradeFederation,
        "trademark" to Trademark,
        "traffic-light" to TrafficLight,
        "trailer" to Trailer,
        "train" to Train,
        "tram" to Tram,
        "transgender" to Transgender,
        "transgender-alt" to TransgenderAlt,
        "trash" to Trash,
        "trash-alt" to TrashAlt,
        "trash-alt-regular" to TrashAltRegular,
        "trash-restore" to TrashRestore,
        "trash-restore-alt" to TrashRestoreAlt,
        "tree" to Tree,
        "trello" to Trello,
        "tripadvisor" to Tripadvisor,
        "trophy" to Trophy,
        "truck" to Truck,
        "truck-loading" to TruckLoading,
        "truck-monster" to TruckMonster,
        "truck-moving" to TruckMoving,
        "truck-pickup" to TruckPickup,
        "tshirt" to Tshirt,
        "tty" to Tty,
        "tumblr" to Tumblr,
        "tumblr-square" to TumblrSquare,
        "tv" to Tv,
        "twitch" to Twitch,
        "twitter" to Twitter,
        "twitter-square" to TwitterSquare,
        "typo3" to Typo3,
        "uber" to Uber,
        "ubuntu" to Ubuntu,
        "uikit" to Uikit,
        "umbraco" to Umbraco,
        "umbrella" to Umbrella,
        "umbrella-beach" to UmbrellaBeach,
        "uncharted" to Uncharted,
        "underline" to Underline,
        "undo" to Undo,
        "undo-alt" to UndoAlt,
        "uniregistry" to Uniregistry,
        "unity" to Unity,
        "universal-access" to UniversalAccess,
        "university" to University,
        "unlink" to Unlink,
        "unlock" to Unlock,
        "unlock-alt" to UnlockAlt,
        "unsplash" to Unsplash,
        "untappd" to Untappd,
        "upload" to Upload,
        "ups" to Ups,
        "usb" to Usb,
        "user" to User,
        "user-regular" to UserRegular,
        "user-alt" to UserAlt,
        "user-alt-slash" to UserAltSlash,
        "user-astronaut" to UserAstronaut,
        "user-check" to UserCheck,
        "user-circle" to UserCircle,
        "user-circle-regular" to UserCircleRegular,
        "user-clock" to UserClock,
        "user-cog" to UserCog,
        "user-edit" to UserEdit,
        "user-friends" to UserFriends,
        "user-graduate" to UserGraduate,
        "user-injured" to UserInjured,
        "user-lock" to UserLock,
        "user-md" to UserMd,
        "user-minus" to UserMinus,
        "user-ninja" to UserNinja,
        "user-nurse" to UserNurse,
        "user-plus" to UserPlus,
        "user-secret" to UserSecret,
        "user-shield" to UserShield,
        "user-slash" to UserSlash,
        "user-tag" to UserTag,
        "user-tie" to UserTie,
        "user-times" to UserTimes,
        "users" to Users,
        "users-cog" to UsersCog,
        "users-slash" to UsersSlash,
        "usps" to Usps,
        "ussunnah" to Ussunnah,
        "utensil-spoon" to UtensilSpoon,
        "utensils" to Utensils,
        "vaadin" to Vaadin,
        "vector-square" to VectorSquare,
        "venus" to Venus,
        "venus-double" to VenusDouble,
        "venus-mars" to VenusMars,
        "vest" to Vest,
        "vest-patches" to VestPatches,
        "viacoin" to Viacoin,
        "viadeo" to Viadeo,
        "viadeo-square" to ViadeoSquare,
        "vial" to Vial,
        "vials" to Vials,
        "viber" to Viber,
        "video" to Video,
        "video-slash" to VideoSlash,
        "vihara" to Vihara,
        "vimeo" to Vimeo,
        "vimeo-square" to VimeoSquare,
        "vimeov" to VimeoV,
        "vine" to Vine,
        "virus" to Virus,
        "virus-slash" to VirusSlash,
        "viruses" to Viruses,
        "vk" to Vk,
        "vnv" to Vnv,
        "voicemail" to Voicemail,
        "volleyball-ball" to VolleyballBall,
        "volume-down" to VolumeDown,
        "volume-mute" to VolumeMute,
        "volume-off" to VolumeOff,
        "volume-up" to VolumeUp,
        "vote-yea" to VoteYea,
        "vr-cardboard" to VrCardboard,
        "vuejs" to Vuejs,
        "walking" to Walking,
        "wallet" to Wallet,
        "warehouse" to Warehouse,
        "watchman-monitoring" to WatchmanMonitoring,
        "water" to Water,
        "wave-square" to WaveSquare,
        "waze" to Waze,
        "weebly" to Weebly,
        "weibo" to Weibo,
        "weight" to Weight,
        "weight-hanging" to WeightHanging,
        "weixin" to Weixin,
        "whatsapp" to Whatsapp,
        "whatsapp-square" to WhatsappSquare,
        "wheelchair" to Wheelchair,
        "whmcs" to Whmcs,
        "wifi" to Wifi,
        "wikipediaw" to WikipediaW,
        "wind" to Wind,
        "window-close" to WindowClose,
        "window-close-regular" to WindowCloseRegular,
        "window-maximize" to WindowMaximize,
        "window-maximize-regular" to WindowMaximizeRegular,
        "window-minimize" to WindowMinimize,
        "window-minimize-regular" to WindowMinimizeRegular,
        "window-restore" to WindowRestore,
        "window-restore-regular" to WindowRestoreRegular,
        "windows" to Windows,
        "wine-bottle" to WineBottle,
        "wine-glass" to WineGlass,
        "wine-glass-alt" to WineGlassAlt,
        "wix" to Wix,
        "wizards-of-the-coast" to WizardsOfTheCoast,
        "wodu" to Wodu,
        "wolf-pack-battalion" to WolfPackBattalion,
        "won-sign" to WonSign,
        "wordpress" to Wordpress,
        "wordpress-simple" to WordpressSimple,
        "wpbeginner" to Wpbeginner,
        "wpexplorer" to Wpexplorer,
        "wpforms" to Wpforms,
        "wpressr" to Wpressr,
        "wrench" to Wrench,
        "xray" to XRay,
        "xbox" to Xbox,
        "xing" to Xing,
        "xing-square" to XingSquare,
        "ycombinator" to YCombinator,
        "yahoo" to Yahoo,
        "yammer" to Yammer,
        "yandex" to Yandex,
        "yandex-international" to YandexInternational,
        "yarn" to Yarn,
        "yelp" to Yelp,
        "yen-sign" to YenSign,
        "yin-yang" to YinYang,
        "yoast" to Yoast,
        "youtube" to Youtube,
        "youtube-square" to YoutubeSquare,
        "zhihu" to Zhihu,
    )

    fun byName(name: String?): FaIconType? {
        if (name == null) return null
        return iconsByName[name]
    }
}
