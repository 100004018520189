package digital.steva.dot.app.domain

import digital.steva.formumat.FormumatPublicLibrary
import io.github.irgaly.kottage.get
import kotlinx.collections.immutable.PersistentList
import kotlinx.collections.immutable.toPersistentList
import kotlinx.coroutines.CoroutineScope
import kotlin.reflect.typeOf


object Storage {
    val DOCUMENT_TYPES_INFOS = "document_types_infos"
    val DOCUMENTS_INFOS = "documents_infos"

    suspend fun getDocumentTypesInfos(scope: CoroutineScope): PersistentList<DocumentTypeInfo> =
        try {
            kottage(scope).get(DOCUMENT_TYPES_INFOS)
        } catch (e: RuntimeException) {
            listOf<DocumentTypeInfo>()
        }.toPersistentList()

    suspend fun putDocumentTypesInfos(scope: CoroutineScope, documentTypesInfos: List<DocumentTypeInfo>) =
        kottage(scope).put(DOCUMENT_TYPES_INFOS, documentTypesInfos, typeOf<List<DocumentTypeInfo>>())

    suspend fun getDocumentsInfos(scope: CoroutineScope): PersistentList<DocumentInfo> =
        try {
            kottage(scope).get(DOCUMENTS_INFOS)
        } catch (e: RuntimeException) {
            listOf<DocumentInfo>()
        }.toPersistentList()

    suspend fun putDocumentsInfos(scope: CoroutineScope, documentsInfos: List<DocumentInfo>) =
        kottage(scope).put(DOCUMENTS_INFOS, documentsInfos, typeOf<List<DocumentInfo>>())

    suspend fun getDocumentType(scope: CoroutineScope, id: String?): DocumentType? {
        return id?.let {
            try {
                kottage(scope).get(it)
            } catch (e: RuntimeException) {
                null
            }
        }
    }

    suspend fun putDocumentType(scope: CoroutineScope, documentType: DocumentType) =
        kottage(scope).put(documentType.id, documentType, typeOf<DocumentType>())

    suspend fun getDocument(scope: CoroutineScope, id: String?): Document? {
        return id?.let {
            try {
                kottage(scope).get(it)
            } catch (e: RuntimeException) {
                null
            }
        }
    }

    suspend fun putDocument(scope: CoroutineScope, document: Document) =
        kottage(scope).put(document.id, document, typeOf<Document>())

    suspend fun getFileData(scope: CoroutineScope, key: String?): ByteArray? {
        return key?.let {
            try {
                kottage(scope).get(key)
            } catch (e: RuntimeException) {
                null
            }
        }
    }

    suspend fun putFileData(scope: CoroutineScope, file: FormumatFile, data: ByteArray) =
        kottage(scope).put(file.key, data, typeOf<ByteArray>())

    private fun kottage(scope: CoroutineScope) = FormumatPublicLibrary.storage(scope)
}
