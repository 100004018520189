package digital.steva.formumat.ui

import Base64.decodeFromBase64
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.unit.dp
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.formumat.FormumatNonpublic
import digital.steva.formumat.FormumatPublicLibrary
import digital.steva.formumat.fontawesome.FaIcon
import digital.steva.formumat.fontawesome.FaIcons
import digital.steva.formumat.helpers.PermissionCallback
import digital.steva.formumat.helpers.PermissionStatus
import digital.steva.formumat.helpers.PermissionType
import digital.steva.formumat.helpers.PlatformId
import digital.steva.formumat.helpers.createPermissionsManager
import digital.steva.formumat.helpers.encodeToBase58String
import digital.steva.formumat.helpers.platformId
import digital.steva.formumat.helpers.rememberCameraManager
import digital.steva.formumat.helpers.rememberGalleryManager
import digital.steva.formumat.nonpublic.MR
import digital.steva.formumat.redux.ClearValue
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetValue
import digital.steva.formumat.redux.StorageDeleteValue
import digital.steva.formumat.redux.StorageSetValue
import digital.steva.formumat.schema.ImageField
import digital.steva.formumat.schema.PhotoField
import digital.steva.formumat.schema.StringEncoding
import digital.steva.formumat.schema.StringType
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import kotlinx.uuid.SecureRandom
import kotlin.reflect.typeOf

expect fun convertByteArrayToImageBitmap(imageBytes: ByteArray): ImageBitmap

@Suppress("UNUSED_PARAMETER")
@Composable
fun ImageView(
    imageField: ImageField,
    type: StringType?,
    values: FormumatValues,
    dispatch: Dispatcher,
    enabled: Boolean = true,
    modifier: Modifier = Modifier
) {
    val coroutineScope = rememberCoroutineScope()
    val value = (values[imageField.property?.eval(values)] ?: "").toString()
    val label = imageField.title.eval(values)
    var imageBytes by remember { mutableStateOf(ByteArray(0)) }

    Column(
        modifier = modifier
    ) {
        if (label.isNotBlank()) {
            Text(
                text = label,
                modifier = Modifier.padding(vertical = 8.dp)
            )
        }

        val fileKey = imageField.file?.eval(values)
        if (!fileKey.isNullOrBlank()) {
            coroutineScope.launch {
                imageBytes = FormumatPublicLibrary.storage(this).get(fileKey, typeOf<ByteArray>())
            }
        } else {
            val data = imageField.data?.eval(values)
            if (!data.isNullOrBlank()) {
                imageBytes = data.decodeFromBase64()
            } else {
                when (type?.contentEncoding) {
                    StringEncoding.BASE_64 -> {
                        imageBytes = value.decodeFromBase64()
                    }

                    else -> {}
                }
            }
        }

        if (imageBytes.isNotEmpty()) {
            Image(
                bitmap = convertByteArrayToImageBitmap(imageBytes),
                contentDescription = "",
            )
        } else {
            FaIcon(
                faIcon = FaIcons.Image,
                size = 64.dp
            )
        }
    }
}

@Composable
expect fun ImageFilePicker(
    show: Boolean,
    onClosePicker: () -> Unit,
    property: String,
    values: FormumatValues,
    dispatch: Dispatcher
)

@Suppress("UNUSED_PARAMETER")
@Composable
fun PhotoView(
    photoField: PhotoField,
    type: StringType?,
    values: FormumatValues,
    dispatch: Dispatcher,
    enabled: Boolean = true,
    modifier: Modifier = Modifier
) {
    val coroutineScope = rememberCoroutineScope()
    val value = (values[photoField.property?.eval(values)] ?: "").toString()
    val label = photoField.title.eval(values)
    var showImageFilePicker by remember { mutableStateOf(false) }
    var showCameraPicker by remember { mutableStateOf(CameraPickerType.SHOW_NONE) }
    var expanded by remember { mutableStateOf(false) }
    var imageBytes by remember { mutableStateOf(ByteArray(0)) }

    Column(
        modifier = modifier
    ) {
        if (label.isNotBlank()) {
            Text(
                text = label,
                modifier = Modifier.padding(vertical = 8.dp)
            )
        }
        Column {
            Row {
                if (platformId() == PlatformId.ANDROID || platformId() == PlatformId.IOS) {
                    OutlinedButton(
                        onClick = {
                            showCameraPicker = CameraPickerType.SHOW_CAMERA
                        },
                        shape = CircleShape,
                        border = BorderStroke(2.dp, MaterialTheme.colorScheme.primary),
                        contentPadding = PaddingValues(0.dp),
                        colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.primary),
                        modifier = Modifier.size(48.dp)
                    ) {
                        FaIcon(FaIcons.Camera)
                    }
                    Spacer(modifier = Modifier.size(8.dp))
                }
                OutlinedButton(
                    onClick = {
                        if (platformId() == PlatformId.ANDROID || platformId() == PlatformId.IOS) {
                            showCameraPicker = CameraPickerType.SHOW_GALLERY
                        } else {
                            showImageFilePicker = true
                        }
                    },
                    shape = CircleShape,
                    border = BorderStroke(2.dp, MaterialTheme.colorScheme.primary),
                    contentPadding = PaddingValues(0.dp),
                    colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.primary),
                    modifier = Modifier.size(48.dp)
                ) {
                    FaIcon(FaIcons.FolderOpenRegular)
                }
                if (value.isNotBlank()) {
                    Spacer(modifier = Modifier.size(8.dp))
                    OutlinedButton(
                        onClick = {
                            val key = photoField.property?.eval(values)
                            if (key != null) {
                                dispatch(StorageDeleteValue(key))
                                dispatch(ClearValue(photoField.property.eval(values), values.listContext))
                                imageBytes = ByteArray(0)
                            }
                        },
                        shape = CircleShape,
                        border = BorderStroke(2.dp, MaterialTheme.colorScheme.error),
                        contentPadding = PaddingValues(0.dp),
                        colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.error),
                        modifier = Modifier.size(48.dp)
                    ) {
                        FaIcon(FaIcons.Times)
                    }
                }
            }
            if (value.isNotEmpty()) {
                coroutineScope.launch {
                    if (FormumatPublicLibrary.storage(this).exists(value)) {
                        imageBytes =
                            FormumatPublicLibrary.storage(this).get(value, typeOf<ByteArray>())
                    }
                }
            }
            if (imageBytes.isNotEmpty()) {
                Spacer(modifier = Modifier.size(8.dp))
                Image(
                    bitmap = convertByteArrayToImageBitmap(imageBytes),
                    contentDescription = "",
                    alignment = Alignment.TopStart,
                    modifier = (if (expanded) Modifier.fillMaxWidth() else Modifier.heightIn(max = 400.dp)
                        .widthIn(max = 400.dp))
                        .clickable { expanded = !expanded }
                )
            }
        }

        ImageFilePicker(
            show = showImageFilePicker,
            onClosePicker = { showImageFilePicker = false },
            property = photoField.property?.eval(values) ?: "",
            values = values,
            dispatch = dispatch
        )

        CameraPicker(
            show = showCameraPicker,
            onClosePicker = { showCameraPicker = CameraPickerType.SHOW_NONE },
            property = photoField.property?.eval(values) ?: "",
            values = values,
            dispatch = dispatch
        )
    }
}

enum class CameraPickerType {
    SHOW_NONE,
    SHOW_CAMERA,
    SHOW_GALLERY
}

@Composable
fun CameraPicker(
    show: CameraPickerType,
    onClosePicker: () -> Unit,
    property: String,
    values: FormumatValues,
    dispatch: Dispatcher
) {
    val coroutineScope = rememberCoroutineScope()
    var launchCamera by remember { mutableStateOf(value = false) }
    var launchGallery by remember { mutableStateOf(value = false) }
    var permissionRationalDialog by remember { mutableStateOf(value = false) }
    var launchSetting by remember { mutableStateOf(value = false) }

    val permissionsManager = createPermissionsManager(object : PermissionCallback {
        override fun onPermissionStatus(
            permissionType: PermissionType,
            status: PermissionStatus
        ) {
            when (status) {
                PermissionStatus.GRANTED -> {
                    when (permissionType) {
                        PermissionType.CAMERA -> launchCamera = true
                        PermissionType.GALLERY -> launchGallery = true
                    }
                }

                else -> {
                    permissionRationalDialog = true
                }
            }
        }
    })

    val cameraManager = rememberCameraManager {
        coroutineScope.launch {
            val bitmapByteArray = withContext(Dispatchers.Default) {
                it?.toByteArray()
            }
            bitmapByteArray?.let {
                val key = SecureRandom.nextBytes(FormumatNonpublic.FILE_KEY_LENGTH).encodeToBase58String()
                dispatch(StorageSetValue(key, it, typeOf<ByteArray>()))
                dispatch(SetValue(property, key, values.listContext))
            }
        }
    }

    val galleryManager = rememberGalleryManager {
        coroutineScope.launch {
            val bitmapByteArray = withContext(Dispatchers.Default) {
                it?.toByteArray()
            }
            bitmapByteArray?.let {
                val key = SecureRandom.nextBytes(FormumatNonpublic.FILE_KEY_LENGTH).encodeToBase58String()
                dispatch(StorageSetValue(key, it, typeOf<ByteArray>()))
                dispatch(SetValue(property, key, values.listContext))
            }

        }
    }

    when (show) {
        CameraPickerType.SHOW_NONE -> {}
        CameraPickerType.SHOW_CAMERA -> {
            if (permissionsManager.isPermissionGranted(PermissionType.CAMERA)) {
                cameraManager.launch()
            } else {
                permissionsManager.askPermission(PermissionType.CAMERA)
            }
            onClosePicker()
        }

        CameraPickerType.SHOW_GALLERY -> {
            if (permissionsManager.isPermissionGranted(PermissionType.GALLERY)) {
                galleryManager.launch()
            } else {
                permissionsManager.askPermission(PermissionType.GALLERY)
            }
            onClosePicker()
        }
    }

    if (launchSetting) {
        permissionsManager.launchSettings()
        launchSetting = false
    }

    if (permissionRationalDialog) {
        AlertMessageDialog(title = stringResource(MR.strings.dialog_camera_picker_permission_title),
            message = stringResource(MR.strings.dialog_camera_picker_permission_message),
            positiveButtonText = stringResource(MR.strings.action_settings),
            negativeButtonText = stringResource(MR.strings.action_cancel),
            onPositiveClick = {
                permissionRationalDialog = false
                launchSetting = true

            },
            onNegativeClick = {
                permissionRationalDialog = false
            })
    }
}
